import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';
import {ENRoleGrantStatus} from '../../enums';


const isDefaultField = new FormField(
  'isDefault',
  'Is default',
  null,
  FormFieldType.boolean,
  false,
  true,
  false
)
const statusField = new FormField(
  'status',
  'Status',
  null,
  FormFieldType.enumeration,
  false,
)
statusField.enumeration = ENRoleGrantStatus


export const putRoleGrantFormFieldOptions: {[key: string]: FormField} = {
  isDefault: isDefaultField,
  status: statusField,
};