import {SORespEntityRequestAssociation} from '../../core/entity-request-association/so-resp-entity-request-association';
import {EntityRequestAssociationCore} from './entity-request-association.core';
import { ListEntry, ListEntryFactory, ListEntryFactoryProtocol} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';


export class EntityRequestAssociationListEntryFactory  extends ListEntryFactory implements ListEntryFactoryProtocol {

  makeListEntry(parentCoreViewSpec, entityCore: EntityCoreProtocol, entity: SORespEntityRequestAssociation, section: number = 0): ListEntry {
    const resp = super.makeListEntry(parentCoreViewSpec, entityCore, entity, section)
    return resp
  }
}
