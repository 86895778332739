import {SOPutWidget} from '../../core/widget/so-put-widget';
import {WidgetCore} from './widget.core';
import {SOGetWidget} from '../../core/widget/so-get-widget';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putWidgetFormFieldOptions} from './put-widget-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {EntityWidgetAssociationCore} from '../bv-entity-widget-association/entity-widget-association.core';
import {SOGetEntityWidgetAssociation} from '../../core/entity-widget-association/so-get-entity-widget-association';

export class RAPutWidget extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putWidgetFormFieldOptions);

  RequestClass = SOPutWidget

  constructor(
    public entityCore: WidgetCore
  ) {
    super();
    if (putWidgetFormFieldOptions.entity) {
      putWidgetFormFieldOptions.entity.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('widgetType'),
      this.getFormValueFromIdentifier('isDefault'),
      [this.getFormValueFromIdentifier('entity', SOGetEntityWidgetAssociation)],
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}