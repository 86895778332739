import { SOGetPrice } from '../price/so-get-price';
import { SOGetInvoice } from '../invoice/so-get-invoice';


export class SOPostInvoiceLine {

  constructor(
    public amount: number,
    public quantity: number,
    public dateCreated: Date,
    public price: SOGetPrice,
    public invoice: SOGetInvoice,
    public description: string = '',
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostInvoiceLine(
      'amount' in params ? params.amount : null,
      'quantity' in params ? params.quantity : null,
      'dateCreated' in params ? params.dateCreated : null,
      'description' in params ? params.description : null,
      'price' in params ? params.price : null,
      'invoice' in params ? params.invoice : null,
    );
  }
}
