import { SOGetApp } from '../app/so-get-app';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostCodable {

  constructor(
    public app: SOGetApp,
    public name: string,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostCodable(
      'app' in params ? params.app : null,
      'name' in params ? params.name : null,
      'organization' in params ? params.organization : null,
    );
  }
}
