import { SOGetEntity } from '../entity/so-get-entity';
import { SOGetStore } from '../store/so-get-store';
import { SOGetCommit } from '../commit/so-get-commit';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostEntityStoreAssociation {

  constructor(
    public entity: SOGetEntity,
    public store: SOGetStore,
    public commit: SOGetCommit,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostEntityStoreAssociation(
      'entity' in params ? params.entity : null,
      'store' in params ? params.store : null,
      'commit' in params ? params.commit : null,
      'organization' in params ? params.organization : null,
    );
  }
}
