import { Type } from 'class-transformer';
import { SORespEntity } from '../entity/so-resp-entity';
import { SORespResponse } from '../response/so-resp-response';
import { SORespCommit } from '../commit/so-resp-commit';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespEntityResponseAssociation implements ObjectiveProtocol {

  entityType: string = 'entityResponseAssociation';
  uid: string;
  @Type(() => SORespEntity)
  entity: SORespEntity;
  @Type(() => SORespResponse)
  response: SORespResponse;
  @Type(() => SORespCommit)
  commit: SORespCommit;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
}