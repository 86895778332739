import {SOPutRoleGrant} from '../../core/role-grant/so-put-role-grant';
import {RoleGrantCore} from './role-grant.core';
import {SOGetRoleGrant} from '../../core/role-grant/so-get-role-grant';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putRoleGrantFormFieldOptions} from './put-role-grant-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';

export class RAPutRoleGrant extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putRoleGrantFormFieldOptions);

  RequestClass = SOPutRoleGrant

  constructor(
    public entityCore: RoleGrantCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('isDefault'),
      this.getFormValueFromIdentifier('status'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}