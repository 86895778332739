import { Type } from 'class-transformer';
import { SORespPrice } from '../price/so-resp-price';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ENSubscriptionStatus} from '../../enums';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespSubscription implements ObjectiveProtocol {

  entityType: string = 'subscription';
  uid: string;
  @Type(() => SORespPrice)
  price: SORespPrice = null;
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => Date)
  dateUpdated: Date;
  @Type(() => Date)
  dateExpires: Date = null;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
  exRef: string = null;
  status: ENSubscriptionStatus;
}