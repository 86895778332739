import {SOPutHello} from '../../core/hello/so-put-hello';
import {HelloCore} from './hello.core';
import {SOGetHello} from '../../core/hello/so-get-hello';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putHelloFormFieldOptions} from './put-hello-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';

export class RAPutHello extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putHelloFormFieldOptions);

  RequestClass = SOPutHello

  constructor(
    public entityCore: HelloCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('loginStyle'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}