import { Type } from 'class-transformer';
import { SORespInvoice } from '../invoice/so-resp-invoice';
import {ENPaymentIntentStatus} from '../../enums';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespPaymentIntent implements ObjectiveProtocol {

  entityType: string = 'paymentIntent';
  uid: string;
  amount: number;
  @Type(() => Date)
  dateCreated: Date;
  status: ENPaymentIntentStatus;
  @Type(() => SORespInvoice)
  invoice: SORespInvoice;
}