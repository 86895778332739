import {SORespStoreStoreAttributeSpecAssociation} from '../../core/store-store-attribute-spec-association/so-resp-store-store-attribute-spec-association';
import {StoreStoreAttributeSpecAssociationCore} from './store-store-attribute-spec-association.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putStoreStoreAttributeSpecAssociationFormFieldOptions} from './put-store-store-attribute-spec-association-form-fields';
import {SOGetStoreStoreAttributeSpecAssociation} from '../../core/store-store-attribute-spec-association/so-get-store-store-attribute-spec-association';
import {SOGetStore} from '../../core/store/so-get-store';
import {SOGetStoreAttributeSpec} from '../../core/store-attribute-spec/so-get-store-attribute-spec';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetApp} from '../../core/app/so-get-app';


export class StoreStoreAttributeSpecAssociationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespStoreStoreAttributeSpecAssociation = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []


    const storeCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'store');
    storeCore.soGet = SOGetStore.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, storeCore)
    storeCore.title = heading ? heading : storeCore.title
    storeCore.listTitle = 'Store'

    if (entity.store) {
        displayStringFactory.reconfigure(entity.store, entityViewSpec.entityCore.type)
    }
    storeCore.configureForDisplay(entityViewSpec)

    const storeDetail = new DetailEntry(
      heading ? heading : storeCore.listTitle,
      entity.store ? displayStringFactory.primaryString : null,
      'store',
      BaseDetailType.pushedDetail,
      storeCore,
      EditableType.always,
      putStoreStoreAttributeSpecAssociationFormFieldOptions.store
    )
    if (entity.store) {
      storeDetail.cachedListEntry = storeCore.makeListEntry(entityViewSpec, storeCore, entity.store, 0)
    }
    storeDetail.singleFieldSelect = true
    attributes.push(storeDetail);

    const storeAttributeSpecCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'storeAttributeSpec');
    storeAttributeSpecCore.soGet = SOGetStoreAttributeSpec.construct({
      store: new SOGetStoreStoreAttributeSpecAssociation(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, storeAttributeSpecCore)
    storeAttributeSpecCore.title = heading ? heading : storeAttributeSpecCore.title
    storeAttributeSpecCore.listTitle = 'Store attribute spec'

    if (entity.storeAttributeSpec) {
        displayStringFactory.reconfigure(entity.storeAttributeSpec, entityViewSpec.entityCore.type)
    }
    storeAttributeSpecCore.configureForDisplay(entityViewSpec)

    const storeAttributeSpecDetail = new DetailEntry(
      heading ? heading : storeAttributeSpecCore.listTitle,
      entity.storeAttributeSpec ? displayStringFactory.primaryString : null,
      'storeAttributeSpec',
      BaseDetailType.pushedDetail,
      storeAttributeSpecCore,
      EditableType.always,
      putStoreStoreAttributeSpecAssociationFormFieldOptions.storeAttributeSpec
    )
    if (entity.storeAttributeSpec) {
      storeAttributeSpecDetail.cachedListEntry = storeAttributeSpecCore.makeListEntry(entityViewSpec, storeAttributeSpecCore, entity.storeAttributeSpec, 0)
    }
    storeAttributeSpecDetail.singleFieldSelect = true
    attributes.push(storeAttributeSpecDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
