import {SOPostAttributeStoreAttributeSpecAssociation} from '../../core/attribute-store-attribute-spec-association/so-post-attribute-store-attribute-spec-association';
import {AttributeStoreAttributeSpecAssociationCore} from './attribute-store-attribute-spec-association.core';
import {SOGetAttributeStoreAttributeSpecAssociation} from '../../core/attribute-store-attribute-spec-association/so-get-attribute-store-attribute-spec-association';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postAttributeStoreAttributeSpecAssociationFormFieldOptions} from './post-attribute-store-attribute-spec-association-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {AttributeCore} from '../bv-attribute/attribute.core';
import {SOGetAttribute} from '../../core/attribute/so-get-attribute';
import {StoreAttributeSpecCore} from '../bv-store-attribute-spec/store-attribute-spec.core';
import {SOGetStoreAttributeSpec} from '../../core/store-attribute-spec/so-get-store-attribute-spec';
import {CommitCore} from '../bv-commit/commit.core';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostAttributeStoreAttributeSpecAssociation extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postAttributeStoreAttributeSpecAssociationFormFieldOptions);

  RequestClass = SOPostAttributeStoreAttributeSpecAssociation

  constructor(
    public entityCore: AttributeStoreAttributeSpecAssociationCore
  ) {
    super();
    if (postAttributeStoreAttributeSpecAssociationFormFieldOptions.attribute) {
      postAttributeStoreAttributeSpecAssociationFormFieldOptions.attribute.makeCore = true;
    }
    if (postAttributeStoreAttributeSpecAssociationFormFieldOptions.store) {
      postAttributeStoreAttributeSpecAssociationFormFieldOptions.store.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'attribute',
        SOGetAttribute,
      ),
      this.getFormValueFromIdentifier(
        'store',
        SOGetStoreAttributeSpec,
      ),
      this.getFormValueFromIdentifier(
        'commit',
        SOGetCommit,
        true,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}