import {SORespInvoice} from '../../core/invoice/so-resp-invoice';
import {InvoiceCore} from './invoice.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putInvoiceFormFieldOptions} from './put-invoice-form-fields';
import {SOGetInvoice} from '../../core/invoice/so-get-invoice';
import {SOGetSubscription} from '../../core/subscription/so-get-subscription';
import {SOGetPaymentIntent} from '../../core/payment-intent/so-get-payment-intent';
import {SOGetInvoiceLine} from '../../core/invoice-line/so-get-invoice-line';


export class InvoiceDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespInvoice = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const refDetail = new DetailEntry(
        'Ref',
        entity.ref,
        'ref',
        BaseDetailType.text,
        null,
        EditableType.always,
        putInvoiceFormFieldOptions.ref
    )
    attributes.push(refDetail)

    const exRefDetail = new DetailEntry(
        'Ex ref',
        entity.exRef,
        'exRef',
        BaseDetailType.text,
        null,
        EditableType.always,
        putInvoiceFormFieldOptions.exRef
    )
    attributes.push(exRefDetail)

    const amountDueDetail = new DetailEntry(
        'Amount due',
        entity.amountDue,
        'amountDue',
        BaseDetailType.text,
        null,
        EditableType.always,
        putInvoiceFormFieldOptions.amountDue
    )
    attributes.push(amountDueDetail)

    const amountPaidDetail = new DetailEntry(
        'Amount paid',
        entity.amountPaid,
        'amountPaid',
        BaseDetailType.text,
        null,
        EditableType.always,
        putInvoiceFormFieldOptions.amountPaid
    )
    attributes.push(amountPaidDetail)

    const amountRemainingDetail = new DetailEntry(
        'Amount remaining',
        entity.amountRemaining,
        'amountRemaining',
        BaseDetailType.text,
        null,
        EditableType.always,
        putInvoiceFormFieldOptions.amountRemaining
    )
    attributes.push(amountRemainingDetail)

    const paidDetail = new DetailEntry(
        'Paid',
        entity.paid,
        'paid',
        BaseDetailType.toggle,
        null,
        EditableType.always,
        putInvoiceFormFieldOptions.paid
    )
    attributes.push(paidDetail)

    const dateUpdatedDetail = new DetailEntry(
        'Date updated',
        activeUserService.time.niceDate(entity.dateUpdated),
        'dateUpdated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateUpdatedDetail)

    const dateCreatedDetail = new DetailEntry(
        'Date created',
        activeUserService.time.niceDate(entity.dateCreated),
        'dateCreated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateCreatedDetail)

    const dateDueDetail = new DetailEntry(
        'Date due',
        activeUserService.time.niceDate(entity.dateDue),
        'dateDue',
        BaseDetailType.text,
        null,
        EditableType.always,
        putInvoiceFormFieldOptions.dateDue
    )
    attributes.push(dateDueDetail)

    const periodStartDetail = new DetailEntry(
        'Period start',
        activeUserService.time.niceDate(entity.periodStart),
        'periodStart',
        BaseDetailType.text,
        null,
        EditableType.always,
        putInvoiceFormFieldOptions.periodStart
    )
    attributes.push(periodStartDetail)

    const periodEndDetail = new DetailEntry(
        'Period end',
        activeUserService.time.niceDate(entity.periodEnd),
        'periodEnd',
        BaseDetailType.text,
        null,
        EditableType.always,
        putInvoiceFormFieldOptions.periodEnd
    )
    attributes.push(periodEndDetail)


    const subscriptionCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'subscription');
    subscriptionCore.soGet = SOGetSubscription.construct({
    });
    heading = getHeading(entityViewSpec.entityCore, subscriptionCore)
    subscriptionCore.title = heading ? heading : subscriptionCore.title
    subscriptionCore.listTitle = 'Subscription'

    if (entity.subscription) {
        displayStringFactory.reconfigure(entity.subscription, entityViewSpec.entityCore.type)
    }
    subscriptionCore.configureForDisplay(entityViewSpec)

    const subscriptionDetail = new DetailEntry(
      heading ? heading : subscriptionCore.listTitle,
      entity.subscription ? displayStringFactory.primaryString : null,
      'subscription',
      BaseDetailType.pushedDetail,
      subscriptionCore,
      EditableType.always,
      putInvoiceFormFieldOptions.subscription
    )
    if (entity.subscription) {
      subscriptionDetail.cachedListEntry = subscriptionCore.makeListEntry(entityViewSpec, subscriptionCore, entity.subscription, 0)
    }
    subscriptionDetail.singleFieldSelect = true
    attributes.push(subscriptionDetail);

    const paymentIntentsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'paymentIntent');
    paymentIntentsCore.soGet = SOGetPaymentIntent.construct({
      invoice: new SOGetInvoice(entity.uid),
    });
    heading = getHeading(entityViewSpec.entityCore, paymentIntentsCore)
    paymentIntentsCore.title = heading ? heading : paymentIntentsCore.title
    paymentIntentsCore.listTitle = 'Payment intents'

    paymentIntentsCore.configureForDisplay(entityViewSpec)

    const paymentIntentsDetail = new DetailEntry(
      heading ? heading : paymentIntentsCore.listTitle,
      null,
      'paymentIntents',
      BaseDetailType.embeddedList,
      paymentIntentsCore,
      EditableType.never
    )
    attributes.push(paymentIntentsDetail);

    const invoiceLinesCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'invoiceLine');
    invoiceLinesCore.soGet = SOGetInvoiceLine.construct({
      invoice: new SOGetInvoice(entity.uid),
    });
    heading = getHeading(entityViewSpec.entityCore, invoiceLinesCore)
    invoiceLinesCore.title = heading ? heading : invoiceLinesCore.title
    invoiceLinesCore.listTitle = 'Invoice lines'

    invoiceLinesCore.configureForDisplay(entityViewSpec)

    const invoiceLinesDetail = new DetailEntry(
      heading ? heading : invoiceLinesCore.listTitle,
      null,
      'invoiceLines',
      BaseDetailType.embeddedList,
      invoiceLinesCore,
      EditableType.never
    )
    attributes.push(invoiceLinesDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
