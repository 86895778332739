import { SOGetEntityRelationshipAssociation } from '../entity-relationship-association/so-get-entity-relationship-association';
import { SOGetRelationshipLink } from '../relationship-link/so-get-relationship-link';
import { SOGetOrganization } from '../organization/so-get-organization';
import { SOGetApp } from '../app/so-get-app';
import {MMQueryOptions} from 'ngx-lionheart-material';
import {QueryProtocol} from 'ngx-lionheart-material';


export class SOGetRelationship implements QueryProtocol {

  constructor(
    public uid: string = null,
    public entity: SOGetEntityRelationshipAssociation[] = null,
    public relationshipLink: SOGetRelationshipLink = null,
    public organization: SOGetOrganization = null,
    public app: SOGetApp = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetRelationship(
      'uid' in params ? params.uid : null,
      'entity' in params ? params.entity : null,
      'relationshipLink' in params ? params.relationshipLink : null,
      'organization' in params ? params.organization : null,
      'app' in params ? params.app : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
