import {SOPostWidget} from '../../core/widget/so-post-widget';
import {WidgetCore} from './widget.core';
import {SOGetWidget} from '../../core/widget/so-get-widget';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postWidgetFormFieldOptions} from './post-widget-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {EntityWidgetAssociationCore} from '../bv-entity-widget-association/entity-widget-association.core';
import {SOGetEntityWidgetAssociation} from '../../core/entity-widget-association/so-get-entity-widget-association';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {AppCore} from '../bv-app/app.core';
import {SOGetApp} from '../../core/app/so-get-app';

export class RAPostWidget extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postWidgetFormFieldOptions);

  RequestClass = SOPostWidget

  constructor(
    public entityCore: WidgetCore
  ) {
    super();
    if (postWidgetFormFieldOptions.entity) {
      postWidgetFormFieldOptions.entity.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('widgetType'),
      this.getFormValueFromIdentifier('isDefault'),
      [this.getFormValueFromIdentifier('entity', SOGetEntityWidgetAssociation)],
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier(
        'app',
        SOGetApp,
        true,
      ),
      this.getFormValueFromIdentifier('name'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}