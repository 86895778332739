import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const loginStyleField = new FormField(
  'loginStyle',
  'Login style',
  null,
  FormFieldType.text,
  false,
)


export const putHelloFormFieldOptions: {[key: string]: FormField} = {
  loginStyle: loginStyleField,
};