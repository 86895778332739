import { SOGetBranch } from '../branch/so-get-branch';
import { SOGetCommit } from '../commit/so-get-commit';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostBranchCommitAssociation {

  constructor(
    public branch: SOGetBranch,
    public commit: SOGetCommit,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostBranchCommitAssociation(
      'branch' in params ? params.branch : null,
      'commit' in params ? params.commit : null,
      'organization' in params ? params.organization : null,
    );
  }
}
