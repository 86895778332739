import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOEntityRelationshipAssociationService} from '../../core/entity-relationship-association/eo-entity-relationship-association';
import {EntityRelationshipAssociationListEntryFactory} from './entity-relationship-association-list-entry-factory';
import {EntityRelationshipAssociationDetailFactory} from './entity-relationship-association-detail-factory';
import {SOGetEntityRelationshipAssociation} from '../../core/entity-relationship-association/so-get-entity-relationship-association';
import {RAPutEntityRelationshipAssociation} from './ra-put-entity-relationship-association';
import {RAPostEntityRelationshipAssociation} from './ra-post-entity-relationship-association';
import {RADeleteEntityRelationshipAssociation} from './ra-delete-entity-relationship-association';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetCommit} from '../../core/commit/so-get-commit'
import {SOGetOrganization} from '../../core/organization/so-get-organization'


export class EntityRelationshipAssociationCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'entityRelationshipAssociation';
  title = 'Entity relationship association';
  listEntryFactory = new EntityRelationshipAssociationListEntryFactory();
  detailFactory = new EntityRelationshipAssociationDetailFactory();

  entityService: EOEntityRelationshipAssociationService;
  soGetClass = SOGetEntityRelationshipAssociation;
  soGet: SOGetEntityRelationshipAssociation;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOEntityRelationshipAssociationService
    );
    this.adapters = [
      new RAPostEntityRelationshipAssociation(this),
      new RAPutEntityRelationshipAssociation(this),
      new RADeleteEntityRelationshipAssociation(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetEntityRelationshipAssociation();
    this.soGet.commit = new SOGetCommit(
      this.activeUserService.getScope('commit').uid
    );
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
