import {SOPutSubscription} from '../../core/subscription/so-put-subscription';
import {SubscriptionCore} from './subscription.core';
import {SOGetSubscription} from '../../core/subscription/so-get-subscription';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putSubscriptionFormFieldOptions} from './put-subscription-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';

export class RAPutSubscription extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putSubscriptionFormFieldOptions);

  RequestClass = SOPutSubscription

  constructor(
    public entityCore: SubscriptionCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('dateExpires'),
      this.getFormValueFromIdentifier('status'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}