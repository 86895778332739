import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';
import {ENWidgetType} from '../../enums';


const nameField = new FormField(
  'name',
  'Name',
  null,
  FormFieldType.text,
  false,
  true,
  ""
)
const widgetTypeField = new FormField(
  'widgetType',
  'Widget type',
  null,
  FormFieldType.enumeration,
  false,
)
widgetTypeField.enumeration = ENWidgetType
const isDefaultField = new FormField(
  'isDefault',
  'Is default',
  null,
  FormFieldType.boolean,
  false,
)
const entityField = new FormField(
  'entity',
  'Entity',
  'entityWidgetAssociation',
  FormFieldType.pushedSelectionList,
  false,
)


export const putWidgetFormFieldOptions: {[key: string]: FormField} = {
  name: nameField,
  widgetType: widgetTypeField,
  isDefault: isDefaultField,
  entity: entityField,
};