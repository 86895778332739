import { Type } from 'class-transformer';
import { SORespAttribute } from '../attribute/so-resp-attribute';
import { SORespWidgetAttributeSpec } from '../widget-attribute-spec/so-resp-widget-attribute-spec';
import { SORespCommit } from '../commit/so-resp-commit';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespAttributeWidgetAttributeSpecAssociation implements ObjectiveProtocol {

  entityType: string = 'attributeWidgetAttributeSpecAssociation';
  uid: string;
  @Type(() => SORespAttribute)
  attribute: SORespAttribute;
  @Type(() => SORespWidgetAttributeSpec)
  widget: SORespWidgetAttributeSpec;
  @Type(() => SORespCommit)
  commit: SORespCommit;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
}