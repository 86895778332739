import { SOGetCommit } from '../commit/so-get-commit';
import { SOGetEntity } from '../entity/so-get-entity';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPutCommitEntityAssociation {

  constructor(
    public uid: string,
    public commit: SOGetCommit = null,
    public entity: SOGetEntity = null,
    public organization: SOGetOrganization = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutCommitEntityAssociation(
      'uid' in params ? params.uid : null,
      'commit' in params ? params.commit : null,
      'entity' in params ? params.entity : null,
      'organization' in params ? params.organization : null,
    );
  }
}
