import { SOGetStore } from '../store/so-get-store';
import { SOGetStoreRelationshipSpec } from '../store-relationship-spec/so-get-store-relationship-spec';


export class SOPutStoreStoreRelationshipSpecAssociation {

  constructor(
    public uid: string,
    public store: SOGetStore = null,
    public storeRelationshipSpec: SOGetStoreRelationshipSpec = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutStoreStoreRelationshipSpecAssociation(
      'uid' in params ? params.uid : null,
      'store' in params ? params.store : null,
      'storeRelationshipSpec' in params ? params.storeRelationshipSpec : null,
    );
  }
}
