import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOStoreStoreAttributeSpecAssociationService} from '../../core/store-store-attribute-spec-association/eo-store-store-attribute-spec-association';
import {StoreStoreAttributeSpecAssociationListEntryFactory} from './store-store-attribute-spec-association-list-entry-factory';
import {StoreStoreAttributeSpecAssociationDetailFactory} from './store-store-attribute-spec-association-detail-factory';
import {SOGetStoreStoreAttributeSpecAssociation} from '../../core/store-store-attribute-spec-association/so-get-store-store-attribute-spec-association';
import {RAPutStoreStoreAttributeSpecAssociation} from './ra-put-store-store-attribute-spec-association';
import {RAPostStoreStoreAttributeSpecAssociation} from './ra-post-store-store-attribute-spec-association';
import {RADeleteStoreStoreAttributeSpecAssociation} from './ra-delete-store-store-attribute-spec-association';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetCommit} from '../../core/commit/so-get-commit'
import {SOGetOrganization} from '../../core/organization/so-get-organization'


export class StoreStoreAttributeSpecAssociationCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'storeStoreAttributeSpecAssociation';
  title = 'Store store attribute spec association';
  listEntryFactory = new StoreStoreAttributeSpecAssociationListEntryFactory();
  detailFactory = new StoreStoreAttributeSpecAssociationDetailFactory();

  entityService: EOStoreStoreAttributeSpecAssociationService;
  soGetClass = SOGetStoreStoreAttributeSpecAssociation;
  soGet: SOGetStoreStoreAttributeSpecAssociation;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOStoreStoreAttributeSpecAssociationService
    );
    this.adapters = [
      new RAPostStoreStoreAttributeSpecAssociation(this),
      new RAPutStoreStoreAttributeSpecAssociation(this),
      new RADeleteStoreStoreAttributeSpecAssociation(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetStoreStoreAttributeSpecAssociation();
    this.soGet.commit = new SOGetCommit(
      this.activeUserService.getScope('commit').uid
    );
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
