import {SORespRequestRequestRelationshipSpecAssociation} from '../../core/request-request-relationship-spec-association/so-resp-request-request-relationship-spec-association';
import {RequestRequestRelationshipSpecAssociationCore} from './request-request-relationship-spec-association.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putRequestRequestRelationshipSpecAssociationFormFieldOptions} from './put-request-request-relationship-spec-association-form-fields';
import {SOGetRequestRequestRelationshipSpecAssociation} from '../../core/request-request-relationship-spec-association/so-get-request-request-relationship-spec-association';
import {SOGetRequest} from '../../core/request/so-get-request';
import {SOGetRequestRelationshipSpec} from '../../core/request-relationship-spec/so-get-request-relationship-spec';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetApp} from '../../core/app/so-get-app';


export class RequestRequestRelationshipSpecAssociationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespRequestRequestRelationshipSpecAssociation = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = [];


    const requestCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'request');
    requestCore.soGet = SOGetRequest.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, requestCore);
    requestCore.title = heading ? heading : requestCore.title;
    requestCore.listTitle = 'Request';

    if (entity.request) {
        displayStringFactory.reconfigure(entity.request, entityViewSpec.entityCore.type);
    }
    requestCore.configureForDisplay(entityViewSpec);

    const requestDetail = new DetailEntry(
      heading ? heading : requestCore.listTitle,
      entity.request ? displayStringFactory.primaryString : null,
      'request',
      BaseDetailType.pushedDetail,
      requestCore,
      EditableType.always,
      putRequestRequestRelationshipSpecAssociationFormFieldOptions.request
    );
    if (entity.request) {
      requestDetail.cachedListEntry = requestCore.makeListEntry(entityViewSpec, requestCore, entity.request, 0);
    }
    requestDetail.singleFieldSelect = true;
    attributes.push(requestDetail);

    const requestRelationshipSpecCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'requestRelationshipSpec');
    requestRelationshipSpecCore.soGet = SOGetRequestRelationshipSpec.construct({
      request: new SOGetRequestRequestRelationshipSpecAssociation(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, requestRelationshipSpecCore);
    requestRelationshipSpecCore.title = heading ? heading : requestRelationshipSpecCore.title;
    requestRelationshipSpecCore.listTitle = 'Request relationship spec';

    if (entity.requestRelationshipSpec) {
        displayStringFactory.reconfigure(entity.requestRelationshipSpec, entityViewSpec.entityCore.type);
    }
    requestRelationshipSpecCore.configureForDisplay(entityViewSpec);

    const requestRelationshipSpecDetail = new DetailEntry(
      heading ? heading : requestRelationshipSpecCore.listTitle,
      entity.requestRelationshipSpec ? displayStringFactory.primaryString : null,
      'requestRelationshipSpec',
      BaseDetailType.pushedDetail,
      requestRelationshipSpecCore,
      EditableType.always,
      putRequestRequestRelationshipSpecAssociationFormFieldOptions.requestRelationshipSpec
    );
    if (entity.requestRelationshipSpec) {
      requestRelationshipSpecDetail.cachedListEntry = requestRelationshipSpecCore.makeListEntry(entityViewSpec, requestRelationshipSpecCore, entity.requestRelationshipSpec, 0);
    }
    requestRelationshipSpecDetail.singleFieldSelect = true;
    attributes.push(requestRelationshipSpecDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
