import {SORespRelationshipStoreRelationshipSpecAssociation} from '../../core/relationship-store-relationship-spec-association/so-resp-relationship-store-relationship-spec-association';
import {RelationshipStoreRelationshipSpecAssociationCore} from './relationship-store-relationship-spec-association.core';
import { ListEntry, ListEntryFactory, ListEntryFactoryProtocol} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';


export class RelationshipStoreRelationshipSpecAssociationListEntryFactory  extends ListEntryFactory implements ListEntryFactoryProtocol {

  makeListEntry(parentCoreViewSpec, entityCore: EntityCoreProtocol, entity: SORespRelationshipStoreRelationshipSpecAssociation, section: number = 0): ListEntry {
    const resp = super.makeListEntry(parentCoreViewSpec, entityCore, entity, section);
    return resp;
  }
}
