import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const attributeField = new FormField(
  'attribute',
  'Attribute',
  'attribute',
  FormFieldType.pushedSelectionList,
  false,
)
const requestField = new FormField(
  'request',
  'Request',
  'requestAttributeSpec',
  FormFieldType.pushedSelectionList,
  false,
)


export const putAttributeRequestAttributeSpecAssociationFormFieldOptions: {[key: string]: FormField} = {
  attribute: attributeField,
  request: requestField,
};