import { Type } from 'class-transformer';
import { SORespResponseResponseAttributeSpecAssociation } from '../response-response-attribute-spec-association/so-resp-response-response-attribute-spec-association';
import { SORespAttributeResponseAttributeSpecAssociation } from '../attribute-response-attribute-spec-association/so-resp-attribute-response-attribute-spec-association';
import { SORespOrganization } from '../organization/so-resp-organization';
import { SORespApp } from '../app/so-resp-app';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespResponseAttributeSpec implements ObjectiveProtocol {

  entityType: string = 'responseAttributeSpec';
  uid: string;
  isRequired: boolean;
  defaultValue: string = null;
  @Type(() => SORespResponseResponseAttributeSpecAssociation)
  response: SORespResponseResponseAttributeSpecAssociation[];
  @Type(() => SORespAttributeResponseAttributeSpecAssociation)
  attribute: SORespAttributeResponseAttributeSpecAssociation[];
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
  @Type(() => SORespApp)
  app: SORespApp;
}