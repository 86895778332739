import { SOGetAttribute } from '../attribute/so-get-attribute';
import { SOGetStoreAttributeSpec } from '../store-attribute-spec/so-get-store-attribute-spec';
import { SOGetCommit } from '../commit/so-get-commit';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostAttributeStoreAttributeSpecAssociation {

  constructor(
    public attribute: SOGetAttribute,
    public store: SOGetStoreAttributeSpec,
    public commit: SOGetCommit,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostAttributeStoreAttributeSpecAssociation(
      'attribute' in params ? params.attribute : null,
      'store' in params ? params.store : null,
      'commit' in params ? params.commit : null,
      'organization' in params ? params.organization : null,
    );
  }
}
