import {SOPutViewGrant} from '../../core/view-grant/so-put-view-grant';
import {ViewGrantCore} from './view-grant.core';
import {SOGetViewGrant} from '../../core/view-grant/so-get-view-grant';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putViewGrantFormFieldOptions} from './put-view-grant-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';

export class RAPutViewGrant extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putViewGrantFormFieldOptions);

  RequestClass = SOPutViewGrant

  constructor(
    public entityCore: ViewGrantCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}