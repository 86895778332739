import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const relationshipField = new FormField(
  'relationship',
  'Relationship',
  'relationship',
  FormFieldType.pushedSelectionList,
  false,
)
const requestField = new FormField(
  'request',
  'Request',
  'requestRelationshipSpec',
  FormFieldType.pushedSelectionList,
  false,
)


export const putRelationshipRequestRelationshipSpecAssociationFormFieldOptions: {[key: string]: FormField} = {
  relationship: relationshipField,
  request: requestField,
};