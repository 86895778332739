import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostBranch } from './so-post-branch';
import { SOGetBranch } from './so-get-branch';
import { SOPutBranch } from './so-put-branch';
import { SODeleteBranch } from './so-delete-branch';
import {SORespBranch} from './so-resp-branch';
import {SOPluralBranch} from './so-plural-branch';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOBranchService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'branch',
      entityCore
    );
  }

  /** GET Branch by id. Will 404 if id not found */
  get(request: SOGetBranch): Observable<SOPluralBranch> {
    return this.httpClient.get<SOPluralBranch>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralBranch, resp) }),
      tap(_ => this.log(`fetched Branch`)),
      catchError(this.handleError<SOPluralBranch>(`get Branch`))
    );
  }

  /** POST: add a new Branch to the server */
  post(request: SOPostBranch): Observable<SORespBranch> {
    return this.httpClient.post<SORespBranch>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespBranch, resp) }),
      tap((response: SORespBranch) => this.log(`added Branch w/ id=${response.uid}`)),
      catchError(this.handleError<SORespBranch>('Branch post'))
    );
  }

  /** PUT: update Branch  */
  put(request: SOPutBranch): Observable<SORespBranch> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespBranch>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespBranch, resp) }),
      tap((response: SORespBranch) => this.log(`edited Branch w/ uid=${uid}`)),
      catchError(this.handleError<SORespBranch>('Branch put'))
    );
  }

  /** DELETE: delete the Branch from the server */
  delete(request: SODeleteBranch): Observable<SORespBranch> {
    return this.httpClient.delete<SORespBranch>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Branch uid=${request.uid}`)),
      catchError(this.handleError<SORespBranch>('delete Branch'))
    );
  }
}
