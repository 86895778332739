import {SOPostRequestRelationshipSpec} from '../../core/request-relationship-spec/so-post-request-relationship-spec';
import {RequestRelationshipSpecCore} from './request-relationship-spec.core';
import {SOGetRequestRelationshipSpec} from '../../core/request-relationship-spec/so-get-request-relationship-spec';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postRequestRelationshipSpecFormFieldOptions} from './post-request-relationship-spec-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {RequestRequestRelationshipSpecAssociationCore} from '../bv-request-request-relationship-spec-association/request-request-relationship-spec-association.core';
import {SOGetRequestRequestRelationshipSpecAssociation} from '../../core/request-request-relationship-spec-association/so-get-request-request-relationship-spec-association';
import {RelationshipRequestRelationshipSpecAssociationCore} from '../bv-relationship-request-relationship-spec-association/relationship-request-relationship-spec-association.core';
import {SOGetRelationshipRequestRelationshipSpecAssociation} from '../../core/relationship-request-relationship-spec-association/so-get-relationship-request-relationship-spec-association';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {AppCore} from '../bv-app/app.core';
import {SOGetApp} from '../../core/app/so-get-app';

export class RAPostRequestRelationshipSpec extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postRequestRelationshipSpecFormFieldOptions);

  RequestClass = SOPostRequestRelationshipSpec

  constructor(
    public entityCore: RequestRelationshipSpecCore
  ) {
    super();
    if (postRequestRelationshipSpecFormFieldOptions.request) {
      postRequestRelationshipSpecFormFieldOptions.request.makeCore = true;
    }
    if (postRequestRelationshipSpecFormFieldOptions.relationship) {
      postRequestRelationshipSpecFormFieldOptions.relationship.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('isRequired'),
      [this.getFormValueFromIdentifier('request', SOGetRequestRequestRelationshipSpecAssociation)],
      [this.getFormValueFromIdentifier('relationship', SOGetRelationshipRequestRelationshipSpecAssociation)],
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier(
        'app',
        SOGetApp,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}