import { SOGetEntityRelationshipAssociation } from '../entity-relationship-association/so-get-entity-relationship-association';
import { SOGetOrganization } from '../organization/so-get-organization';
import { SOGetApp } from '../app/so-get-app';
import {ENMultiplicity} from '../../enums';


export class SOPostRelationship {

  constructor(
    public entity: SOGetEntityRelationshipAssociation[],
    public name: string,
    public multiplicity: ENMultiplicity,
    public organization: SOGetOrganization,
    public app: SOGetApp,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostRelationship(
      'entity' in params ? params.entity : null,
      'name' in params ? params.name : null,
      'multiplicity' in params ? params.multiplicity : null,
      'organization' in params ? params.organization : null,
      'app' in params ? params.app : null,
    );
  }
}
