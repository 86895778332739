import {SOPutRequestRequestAttributeSpecAssociation} from '../../core/request-request-attribute-spec-association/so-put-request-request-attribute-spec-association';
import {RequestRequestAttributeSpecAssociationCore} from './request-request-attribute-spec-association.core';
import {SOGetRequestRequestAttributeSpecAssociation} from '../../core/request-request-attribute-spec-association/so-get-request-request-attribute-spec-association';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putRequestRequestAttributeSpecAssociationFormFieldOptions} from './put-request-request-attribute-spec-association-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {RequestCore} from '../bv-request/request.core';
import {SOGetRequest} from '../../core/request/so-get-request';
import {RequestAttributeSpecCore} from '../bv-request-attribute-spec/request-attribute-spec.core';
import {SOGetRequestAttributeSpec} from '../../core/request-attribute-spec/so-get-request-attribute-spec';

export class RAPutRequestRequestAttributeSpecAssociation extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putRequestRequestAttributeSpecAssociationFormFieldOptions);

  RequestClass = SOPutRequestRequestAttributeSpecAssociation

  constructor(
    public entityCore: RequestRequestAttributeSpecAssociationCore
  ) {
    super();
    if (putRequestRequestAttributeSpecAssociationFormFieldOptions.request) {
      putRequestRequestAttributeSpecAssociationFormFieldOptions.request.makeCore = true;
    }
    if (putRequestRequestAttributeSpecAssociationFormFieldOptions.requestAttributeSpec) {
      putRequestRequestAttributeSpecAssociationFormFieldOptions.requestAttributeSpec.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier(
        'request',
        SOGetRequest,
      ),
      this.getFormValueFromIdentifier(
        'requestAttributeSpec',
        SOGetRequestAttributeSpec,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}