import {SORespConstraint} from '../../core/constraint/so-resp-constraint';
import {ConstraintCore} from './constraint.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putConstraintFormFieldOptions} from './put-constraint-form-fields';
import {SOGetConstraint} from '../../core/constraint/so-get-constraint';
import {SOGetAppliedConstraint} from '../../core/applied-constraint/so-get-applied-constraint';


export class ConstraintDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespConstraint = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const nameDetail = new DetailEntry(
        'Name',
        entity.name,
        'name',
        BaseDetailType.text,
        null,
        EditableType.always,
        putConstraintFormFieldOptions.name
    )
    attributes.push(nameDetail)

    const identifierDetail = new DetailEntry(
        'Identifier',
        entity.identifier,
        'identifier',
        BaseDetailType.text,
        null,
        EditableType.always,
        putConstraintFormFieldOptions.identifier
    )
    attributes.push(identifierDetail)

    const descriptionDetail = new DetailEntry(
        'Description',
        entity.description,
        'description',
        BaseDetailType.text,
        null,
        EditableType.always,
        putConstraintFormFieldOptions.description
    )
    attributes.push(descriptionDetail)


    const appliedConstraintsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'appliedConstraint');
    appliedConstraintsCore.soGet = SOGetAppliedConstraint.construct({
      constraint: new SOGetConstraint(entity.uid),
    });
    heading = getHeading(entityViewSpec.entityCore, appliedConstraintsCore)
    appliedConstraintsCore.title = heading ? heading : appliedConstraintsCore.title
    appliedConstraintsCore.listTitle = 'Applied constraints'

    appliedConstraintsCore.configureForDisplay(entityViewSpec)

    const appliedConstraintsDetail = new DetailEntry(
      heading ? heading : appliedConstraintsCore.listTitle,
      null,
      'appliedConstraints',
      BaseDetailType.embeddedList,
      appliedConstraintsCore,
      EditableType.never
    )
    attributes.push(appliedConstraintsDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
