import { SOGetRelationship } from '../relationship/so-get-relationship';
import { SOGetStoreRelationshipSpec } from '../store-relationship-spec/so-get-store-relationship-spec';
import { SOGetCommit } from '../commit/so-get-commit';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostRelationshipStoreRelationshipSpecAssociation {

  constructor(
    public relationship: SOGetRelationship,
    public store: SOGetStoreRelationshipSpec,
    public commit: SOGetCommit,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostRelationshipStoreRelationshipSpecAssociation(
      'relationship' in params ? params.relationship : null,
      'store' in params ? params.store : null,
      'commit' in params ? params.commit : null,
      'organization' in params ? params.organization : null,
    );
  }
}
