import {MMQueryOptions} from 'ngx-lionheart-material';
import {QueryProtocol} from 'ngx-lionheart-material';


export class SOGetProduct implements QueryProtocol {

  constructor(
    public uid: string = null,
    public name: string = null,
    public sku: string = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetProduct(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'sku' in params ? params.sku : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
