import {SORespRelationship} from '../../core/relationship/so-resp-relationship';
import {RelationshipCore} from './relationship.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putRelationshipFormFieldOptions} from './put-relationship-form-fields';
import {SOGetRelationship} from '../../core/relationship/so-get-relationship';
import {SOGetEntityRelationshipAssociation} from '../../core/entity-relationship-association/so-get-entity-relationship-association';
import {SOGetRelationshipLink} from '../../core/relationship-link/so-get-relationship-link';
import {SOGetRelationshipWidgetRelationshipSpecAssociation} from '../../core/relationship-widget-relationship-spec-association/so-get-relationship-widget-relationship-spec-association';
import {SOGetRelationshipRequestRelationshipSpecAssociation} from '../../core/relationship-request-relationship-spec-association/so-get-relationship-request-relationship-spec-association';
import {SOGetRelationshipResponseRelationshipSpecAssociation} from '../../core/relationship-response-relationship-spec-association/so-get-relationship-response-relationship-spec-association';
import {SOGetRelationshipStoreRelationshipSpecAssociation} from '../../core/relationship-store-relationship-spec-association/so-get-relationship-store-relationship-spec-association';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetApp} from '../../core/app/so-get-app';


export class RelationshipDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespRelationship = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const nameDetail = new DetailEntry(
        'Name',
        entity.name,
        'name',
        BaseDetailType.text,
        null,
        EditableType.always,
        putRelationshipFormFieldOptions.name
    )
    attributes.push(nameDetail)

    const multiplicityDetail = new DetailEntry(
        'Multiplicity',
        entity.multiplicity,
        'multiplicity',
        BaseDetailType.toggleGroup,
        null,
        EditableType.always,
        putRelationshipFormFieldOptions.multiplicity
    )
    attributes.push(multiplicityDetail)


    const entityCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'entityRelationshipAssociation');
    entityCore.soGet = SOGetEntityRelationshipAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, entityCore)
    entityCore.title = heading ? heading : entityCore.title
    entityCore.listTitle = 'Entity'

    entityCore.configureForDisplay(entityViewSpec)

    const entityDetail = new DetailEntry(
      heading ? heading : entityCore.listTitle,
      null,
      'entity',
      BaseDetailType.embeddedList,
      entityCore,
      EditableType.always,
      putRelationshipFormFieldOptions.entity
    )
    attributes.push(entityDetail);

    const relationshipLinkCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'relationshipLink');
    relationshipLinkCore.soGet = SOGetRelationshipLink.construct({
      app: new SOGetApp(activeUserService.getScope('app').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, relationshipLinkCore)
    relationshipLinkCore.title = heading ? heading : relationshipLinkCore.title
    relationshipLinkCore.listTitle = 'Relationship link'

    if (entity.relationshipLink) {
        displayStringFactory.reconfigure(entity.relationshipLink, entityViewSpec.entityCore.type)
    }
    relationshipLinkCore.configureForDisplay(entityViewSpec)

    const relationshipLinkDetail = new DetailEntry(
      heading ? heading : relationshipLinkCore.listTitle,
      entity.relationshipLink ? displayStringFactory.primaryString : null,
      'relationshipLink',
      BaseDetailType.pushedDetail,
      relationshipLinkCore,
      EditableType.always,
      putRelationshipFormFieldOptions.relationshipLink
    )
    if (entity.relationshipLink) {
      relationshipLinkDetail.cachedListEntry = relationshipLinkCore.makeListEntry(entityViewSpec, relationshipLinkCore, entity.relationshipLink, 0)
    }
    relationshipLinkDetail.singleFieldSelect = true
    attributes.push(relationshipLinkDetail);

    const widgetRelationshipSpecsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'relationshipWidgetRelationshipSpecAssociation');
    widgetRelationshipSpecsCore.soGet = SOGetRelationshipWidgetRelationshipSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, widgetRelationshipSpecsCore)
    widgetRelationshipSpecsCore.title = heading ? heading : widgetRelationshipSpecsCore.title
    widgetRelationshipSpecsCore.listTitle = 'Widget relationship specs'

    widgetRelationshipSpecsCore.configureForDisplay(entityViewSpec)

    const widgetRelationshipSpecsDetail = new DetailEntry(
      heading ? heading : widgetRelationshipSpecsCore.listTitle,
      null,
      'widgetRelationshipSpecs',
      BaseDetailType.embeddedList,
      widgetRelationshipSpecsCore,
      EditableType.never
    )
    attributes.push(widgetRelationshipSpecsDetail);

    const requestRelationshipSpecsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'relationshipRequestRelationshipSpecAssociation');
    requestRelationshipSpecsCore.soGet = SOGetRelationshipRequestRelationshipSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, requestRelationshipSpecsCore)
    requestRelationshipSpecsCore.title = heading ? heading : requestRelationshipSpecsCore.title
    requestRelationshipSpecsCore.listTitle = 'Request relationship specs'

    requestRelationshipSpecsCore.configureForDisplay(entityViewSpec)

    const requestRelationshipSpecsDetail = new DetailEntry(
      heading ? heading : requestRelationshipSpecsCore.listTitle,
      null,
      'requestRelationshipSpecs',
      BaseDetailType.embeddedList,
      requestRelationshipSpecsCore,
      EditableType.never
    )
    attributes.push(requestRelationshipSpecsDetail);

    const responseRelationshipSpecsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'relationshipResponseRelationshipSpecAssociation');
    responseRelationshipSpecsCore.soGet = SOGetRelationshipResponseRelationshipSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, responseRelationshipSpecsCore)
    responseRelationshipSpecsCore.title = heading ? heading : responseRelationshipSpecsCore.title
    responseRelationshipSpecsCore.listTitle = 'Response relationship specs'

    responseRelationshipSpecsCore.configureForDisplay(entityViewSpec)

    const responseRelationshipSpecsDetail = new DetailEntry(
      heading ? heading : responseRelationshipSpecsCore.listTitle,
      null,
      'responseRelationshipSpecs',
      BaseDetailType.embeddedList,
      responseRelationshipSpecsCore,
      EditableType.never
    )
    attributes.push(responseRelationshipSpecsDetail);

    const storeRelationshipSpecsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'relationshipStoreRelationshipSpecAssociation');
    storeRelationshipSpecsCore.soGet = SOGetRelationshipStoreRelationshipSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, storeRelationshipSpecsCore)
    storeRelationshipSpecsCore.title = heading ? heading : storeRelationshipSpecsCore.title
    storeRelationshipSpecsCore.listTitle = 'Store relationship specs'

    storeRelationshipSpecsCore.configureForDisplay(entityViewSpec)

    const storeRelationshipSpecsDetail = new DetailEntry(
      heading ? heading : storeRelationshipSpecsCore.listTitle,
      null,
      'storeRelationshipSpecs',
      BaseDetailType.embeddedList,
      storeRelationshipSpecsCore,
      EditableType.never
    )
    attributes.push(storeRelationshipSpecsDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
