import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const storeField = new FormField(
  'store',
  'Store',
  'store',
  FormFieldType.pushedSelectionList,
  true,
)
const storeRelationshipSpecField = new FormField(
  'storeRelationshipSpec',
  'Store relationship spec',
  'storeRelationshipSpec',
  FormFieldType.pushedSelectionList,
  true,
)


export const postStoreStoreRelationshipSpecAssociationFormFieldOptions: {[key: string]: FormField} = {
  store: storeField,
  storeRelationshipSpec: storeRelationshipSpecField,
};