import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EORequestService} from '../../core/request/eo-request';
import {RequestListEntryFactory} from './request-list-entry-factory';
import {RequestDetailFactory} from './request-detail-factory';
import {SOGetRequest} from '../../core/request/so-get-request';
import {RAPutRequest} from './ra-put-request';
import {RAPostRequest} from './ra-post-request';
import {RADeleteRequest} from './ra-delete-request';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import {SOGetApp} from '../../core/app/so-get-app'


export class RequestCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'request';
  title = 'Request';
  listEntryFactory = new RequestListEntryFactory();
  detailFactory = new RequestDetailFactory();

  entityService: EORequestService;
  soGetClass = SOGetRequest;
  soGet: SOGetRequest;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EORequestService
    );
    this.adapters = [
      new RAPostRequest(this),
      new RAPutRequest(this),
      new RADeleteRequest(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetRequest();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.app = new SOGetApp(
      this.activeUserService.getScope('app').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
