import {SOPutAttributeResponseAttributeSpecAssociation} from '../../core/attribute-response-attribute-spec-association/so-put-attribute-response-attribute-spec-association';
import {AttributeResponseAttributeSpecAssociationCore} from './attribute-response-attribute-spec-association.core';
import {SOGetAttributeResponseAttributeSpecAssociation} from '../../core/attribute-response-attribute-spec-association/so-get-attribute-response-attribute-spec-association';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putAttributeResponseAttributeSpecAssociationFormFieldOptions} from './put-attribute-response-attribute-spec-association-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {AttributeCore} from '../bv-attribute/attribute.core';
import {SOGetAttribute} from '../../core/attribute/so-get-attribute';
import {ResponseAttributeSpecCore} from '../bv-response-attribute-spec/response-attribute-spec.core';
import {SOGetResponseAttributeSpec} from '../../core/response-attribute-spec/so-get-response-attribute-spec';
import {CommitCore} from '../bv-commit/commit.core';
import {SOGetCommit} from '../../core/commit/so-get-commit';

export class RAPutAttributeResponseAttributeSpecAssociation extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putAttributeResponseAttributeSpecAssociationFormFieldOptions);

  RequestClass = SOPutAttributeResponseAttributeSpecAssociation

  constructor(
    public entityCore: AttributeResponseAttributeSpecAssociationCore
  ) {
    super();
    if (putAttributeResponseAttributeSpecAssociationFormFieldOptions.attribute) {
      putAttributeResponseAttributeSpecAssociationFormFieldOptions.attribute.makeCore = true;
    }
    if (putAttributeResponseAttributeSpecAssociationFormFieldOptions.response) {
      putAttributeResponseAttributeSpecAssociationFormFieldOptions.response.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier(
        'attribute',
        SOGetAttribute,
      ),
      this.getFormValueFromIdentifier(
        'response',
        SOGetResponseAttributeSpec,
      ),
      this.getFormValueFromIdentifier(
        'commit',
        SOGetCommit,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}