import {SORespWidgetWidgetRelationshipSpecAssociation} from '../../core/widget-widget-relationship-spec-association/so-resp-widget-widget-relationship-spec-association';
import {WidgetWidgetRelationshipSpecAssociationCore} from './widget-widget-relationship-spec-association.core';
import { ListEntry, ListEntryFactory, ListEntryFactoryProtocol} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';


export class WidgetWidgetRelationshipSpecAssociationListEntryFactory  extends ListEntryFactory implements ListEntryFactoryProtocol {

  makeListEntry(parentCoreViewSpec, entityCore: EntityCoreProtocol, entity: SORespWidgetWidgetRelationshipSpecAssociation, section: number = 0): ListEntry {
    const resp = super.makeListEntry(parentCoreViewSpec, entityCore, entity, section);
    return resp;
  }
}
