import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostRequestRequestRelationshipSpecAssociation } from './so-post-request-request-relationship-spec-association';
import { SOGetRequestRequestRelationshipSpecAssociation } from './so-get-request-request-relationship-spec-association';
import { SOPutRequestRequestRelationshipSpecAssociation } from './so-put-request-request-relationship-spec-association';
import { SODeleteRequestRequestRelationshipSpecAssociation } from './so-delete-request-request-relationship-spec-association';
import {SORespRequestRequestRelationshipSpecAssociation} from './so-resp-request-request-relationship-spec-association';
import {SOPluralRequestRequestRelationshipSpecAssociation} from './so-plural-request-request-relationship-spec-association';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EORequestRequestRelationshipSpecAssociationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'request_request_relationship_spec_association',
      entityCore
    );
  }

  /** GET RequestRequestRelationshipSpecAssociation by id. Will 404 if id not found */
  get(request: SOGetRequestRequestRelationshipSpecAssociation): Observable<SOPluralRequestRequestRelationshipSpecAssociation> {
    return this.httpClient.get<SOPluralRequestRequestRelationshipSpecAssociation>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralRequestRequestRelationshipSpecAssociation, resp) }),
      tap(_ => this.log(`fetched RequestRequestRelationshipSpecAssociation`)),
      catchError(this.handleError<SOPluralRequestRequestRelationshipSpecAssociation>(`get RequestRequestRelationshipSpecAssociation`))
    );
  }

  /** POST: add a new RequestRequestRelationshipSpecAssociation to the server */
  post(request: SOPostRequestRequestRelationshipSpecAssociation): Observable<SORespRequestRequestRelationshipSpecAssociation> {
    return this.httpClient.post<SORespRequestRequestRelationshipSpecAssociation>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespRequestRequestRelationshipSpecAssociation, resp) }),
      tap((response: SORespRequestRequestRelationshipSpecAssociation) => this.log(`added RequestRequestRelationshipSpecAssociation w/ id=${response.uid}`)),
      catchError(this.handleError<SORespRequestRequestRelationshipSpecAssociation>('RequestRequestRelationshipSpecAssociation post'))
    );
  }

  /** PUT: update RequestRequestRelationshipSpecAssociation  */
  put(request: SOPutRequestRequestRelationshipSpecAssociation): Observable<SORespRequestRequestRelationshipSpecAssociation> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespRequestRequestRelationshipSpecAssociation>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespRequestRequestRelationshipSpecAssociation, resp) }),
      tap((response: SORespRequestRequestRelationshipSpecAssociation) => this.log(`edited RequestRequestRelationshipSpecAssociation w/ uid=${uid}`)),
      catchError(this.handleError<SORespRequestRequestRelationshipSpecAssociation>('RequestRequestRelationshipSpecAssociation put'))
    );
  }

  /** DELETE: delete the RequestRequestRelationshipSpecAssociation from the server */
  delete(request: SODeleteRequestRequestRelationshipSpecAssociation): Observable<SORespRequestRequestRelationshipSpecAssociation> {
    return this.httpClient.delete<SORespRequestRequestRelationshipSpecAssociation>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted RequestRequestRelationshipSpecAssociation uid=${request.uid}`)),
      catchError(this.handleError<SORespRequestRequestRelationshipSpecAssociation>('delete RequestRequestRelationshipSpecAssociation'))
    );
  }
}
