import { SOGetEnumerator } from '../enumerator/so-get-enumerator';
import { SOGetEnumeral } from '../enumeral/so-get-enumeral';
import { SOGetCommit } from '../commit/so-get-commit';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostEnumeratorEnumeralAssociation {

  constructor(
    public enumerator: SOGetEnumerator,
    public enumeral: SOGetEnumeral,
    public commit: SOGetCommit,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostEnumeratorEnumeralAssociation(
      'enumerator' in params ? params.enumerator : null,
      'enumeral' in params ? params.enumeral : null,
      'commit' in params ? params.commit : null,
      'organization' in params ? params.organization : null,
    );
  }
}
