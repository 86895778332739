import {SORespHello} from '../../core/hello/so-resp-hello';
import {HelloCore} from './hello.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putHelloFormFieldOptions} from './put-hello-form-fields';
import {SOGetHello} from '../../core/hello/so-get-hello';


export class HelloDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespHello = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const loginStyleDetail = new DetailEntry(
        'Login style',
        entity.loginStyle,
        'loginStyle',
        BaseDetailType.text,
        null,
        EditableType.always,
        putHelloFormFieldOptions.loginStyle
    )
    attributes.push(loginStyleDetail)


    return new EntityAttributesForDisplay(attributes);

  }
}
