import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostRelationshipRequestRelationshipSpecAssociation } from './so-post-relationship-request-relationship-spec-association';
import { SOGetRelationshipRequestRelationshipSpecAssociation } from './so-get-relationship-request-relationship-spec-association';
import { SOPutRelationshipRequestRelationshipSpecAssociation } from './so-put-relationship-request-relationship-spec-association';
import { SODeleteRelationshipRequestRelationshipSpecAssociation } from './so-delete-relationship-request-relationship-spec-association';
import {SORespRelationshipRequestRelationshipSpecAssociation} from './so-resp-relationship-request-relationship-spec-association';
import {SOPluralRelationshipRequestRelationshipSpecAssociation} from './so-plural-relationship-request-relationship-spec-association';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EORelationshipRequestRelationshipSpecAssociationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'relationship_request_relationship_spec_association',
      entityCore
    );
  }

  /** GET RelationshipRequestRelationshipSpecAssociation by id. Will 404 if id not found */
  get(request: SOGetRelationshipRequestRelationshipSpecAssociation): Observable<SOPluralRelationshipRequestRelationshipSpecAssociation> {
    return this.httpClient.get<SOPluralRelationshipRequestRelationshipSpecAssociation>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralRelationshipRequestRelationshipSpecAssociation, resp) }),
      tap(_ => this.log(`fetched RelationshipRequestRelationshipSpecAssociation`)),
      catchError(this.handleError<SOPluralRelationshipRequestRelationshipSpecAssociation>(`get RelationshipRequestRelationshipSpecAssociation`))
    );
  }

  /** POST: add a new RelationshipRequestRelationshipSpecAssociation to the server */
  post(request: SOPostRelationshipRequestRelationshipSpecAssociation): Observable<SORespRelationshipRequestRelationshipSpecAssociation> {
    return this.httpClient.post<SORespRelationshipRequestRelationshipSpecAssociation>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespRelationshipRequestRelationshipSpecAssociation, resp) }),
      tap((response: SORespRelationshipRequestRelationshipSpecAssociation) => this.log(`added RelationshipRequestRelationshipSpecAssociation w/ id=${response.uid}`)),
      catchError(this.handleError<SORespRelationshipRequestRelationshipSpecAssociation>('RelationshipRequestRelationshipSpecAssociation post'))
    );
  }

  /** PUT: update RelationshipRequestRelationshipSpecAssociation  */
  put(request: SOPutRelationshipRequestRelationshipSpecAssociation): Observable<SORespRelationshipRequestRelationshipSpecAssociation> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespRelationshipRequestRelationshipSpecAssociation>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespRelationshipRequestRelationshipSpecAssociation, resp) }),
      tap((response: SORespRelationshipRequestRelationshipSpecAssociation) => this.log(`edited RelationshipRequestRelationshipSpecAssociation w/ uid=${uid}`)),
      catchError(this.handleError<SORespRelationshipRequestRelationshipSpecAssociation>('RelationshipRequestRelationshipSpecAssociation put'))
    );
  }

  /** DELETE: delete the RelationshipRequestRelationshipSpecAssociation from the server */
  delete(request: SODeleteRelationshipRequestRelationshipSpecAssociation): Observable<SORespRelationshipRequestRelationshipSpecAssociation> {
    return this.httpClient.delete<SORespRelationshipRequestRelationshipSpecAssociation>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted RelationshipRequestRelationshipSpecAssociation uid=${request.uid}`)),
      catchError(this.handleError<SORespRelationshipRequestRelationshipSpecAssociation>('delete RelationshipRequestRelationshipSpecAssociation'))
    );
  }
}
