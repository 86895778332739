import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const widgetField = new FormField(
  'widget',
  'Widget',
  'widget',
  FormFieldType.pushedSelectionList,
  true,
)
const widgetRelationshipSpecField = new FormField(
  'widgetRelationshipSpec',
  'Widget relationship spec',
  'widgetRelationshipSpec',
  FormFieldType.pushedSelectionList,
  true,
)


export const postWidgetWidgetRelationshipSpecAssociationFormFieldOptions: {[key: string]: FormField} = {
  widget: widgetField,
  widgetRelationshipSpec: widgetRelationshipSpecField,
};