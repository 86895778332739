import {SORespAttributeRequestAttributeSpecAssociation} from '../../core/attribute-request-attribute-spec-association/so-resp-attribute-request-attribute-spec-association';
import {AttributeRequestAttributeSpecAssociationCore} from './attribute-request-attribute-spec-association.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putAttributeRequestAttributeSpecAssociationFormFieldOptions} from './put-attribute-request-attribute-spec-association-form-fields';
import {SOGetAttributeRequestAttributeSpecAssociation} from '../../core/attribute-request-attribute-spec-association/so-get-attribute-request-attribute-spec-association';
import {SOGetAttribute} from '../../core/attribute/so-get-attribute';
import {SOGetRequestAttributeSpec} from '../../core/request-attribute-spec/so-get-request-attribute-spec';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetApp} from '../../core/app/so-get-app';


export class AttributeRequestAttributeSpecAssociationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespAttributeRequestAttributeSpecAssociation = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = [];


    const attributeCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'attribute');
    attributeCore.soGet = SOGetAttribute.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, attributeCore);
    attributeCore.title = heading ? heading : attributeCore.title;
    attributeCore.listTitle = 'Attribute';

    if (entity.attribute) {
        displayStringFactory.reconfigure(entity.attribute, entityViewSpec.entityCore.type);
    }
    attributeCore.configureForDisplay(entityViewSpec);

    const attributeDetail = new DetailEntry(
      heading ? heading : attributeCore.listTitle,
      entity.attribute ? displayStringFactory.primaryString : null,
      'attribute',
      BaseDetailType.pushedDetail,
      attributeCore,
      EditableType.always,
      putAttributeRequestAttributeSpecAssociationFormFieldOptions.attribute
    );
    if (entity.attribute) {
      attributeDetail.cachedListEntry = attributeCore.makeListEntry(entityViewSpec, attributeCore, entity.attribute, 0);
    }
    attributeDetail.singleFieldSelect = true;
    attributes.push(attributeDetail);

    const requestCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'requestAttributeSpec');
    requestCore.soGet = SOGetRequestAttributeSpec.construct({
      attribute: new SOGetAttributeRequestAttributeSpecAssociation(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, requestCore);
    requestCore.title = heading ? heading : requestCore.title;
    requestCore.listTitle = 'Request';

    if (entity.request) {
        displayStringFactory.reconfigure(entity.request, entityViewSpec.entityCore.type);
    }
    requestCore.configureForDisplay(entityViewSpec);

    const requestDetail = new DetailEntry(
      heading ? heading : requestCore.listTitle,
      entity.request ? displayStringFactory.primaryString : null,
      'request',
      BaseDetailType.pushedDetail,
      requestCore,
      EditableType.always,
      putAttributeRequestAttributeSpecAssociationFormFieldOptions.request
    );
    if (entity.request) {
      requestDetail.cachedListEntry = requestCore.makeListEntry(entityViewSpec, requestCore, entity.request, 0);
    }
    requestDetail.singleFieldSelect = true;
    attributes.push(requestDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
