import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const quantityField = new FormField(
  'quantity',
  'Quantity',
  null,
  FormFieldType.text,
  false,
)


export const putAppliedConstraintFormFieldOptions: {[key: string]: FormField} = {
  quantity: quantityField,
};