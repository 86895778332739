import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const constraintField = new FormField(
  'constraint',
  'Constraint',
  'constraint',
  FormFieldType.pushedSelectionList,
  true,
)
const productField = new FormField(
  'product',
  'Product',
  'product',
  FormFieldType.pushedSelectionList,
  true,
)
const quantityField = new FormField(
  'quantity',
  'Quantity',
  null,
  FormFieldType.text,
  true,
)


export const postAppliedConstraintFormFieldOptions: {[key: string]: FormField} = {
  constraint: constraintField,
  product: productField,
  quantity: quantityField,
};