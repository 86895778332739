import { Type } from 'class-transformer';
import { SORespApp } from '../app/so-resp-app';
import { SORespOrganization } from '../organization/so-resp-organization';
import { SORespCommitRelationshipLinkAssociation } from '../commit-relationship-link-association/so-resp-commit-relationship-link-association';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespRelationshipLink implements ObjectiveProtocol {

  entityType: string = 'relationshipLink';
  uid: string;
  @Type(() => SORespApp)
  app: SORespApp;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
  @Type(() => SORespCommitRelationshipLinkAssociation)
  commitRelationshipLinkAssociations: SORespCommitRelationshipLinkAssociation[];
}