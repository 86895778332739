import { Type } from 'class-transformer';
import { SORespResponseResponseRelationshipSpecAssociation } from '../response-response-relationship-spec-association/so-resp-response-response-relationship-spec-association';
import { SORespRelationshipResponseRelationshipSpecAssociation } from '../relationship-response-relationship-spec-association/so-resp-relationship-response-relationship-spec-association';
import { SORespOrganization } from '../organization/so-resp-organization';
import { SORespApp } from '../app/so-resp-app';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespResponseRelationshipSpec implements ObjectiveProtocol {

  entityType: string = 'responseRelationshipSpec';
  uid: string;
  isRequired: boolean;
  @Type(() => SORespResponseResponseRelationshipSpecAssociation)
  response: SORespResponseResponseRelationshipSpecAssociation[];
  @Type(() => SORespRelationshipResponseRelationshipSpecAssociation)
  relationship: SORespRelationshipResponseRelationshipSpecAssociation[];
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
  @Type(() => SORespApp)
  app: SORespApp;
}