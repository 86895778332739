import { SOGetCommit } from '../commit/so-get-commit';
import { SOGetCodable } from '../codable/so-get-codable';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostCommitCodableAssociation {

  constructor(
    public commit: SOGetCommit,
    public codable: SOGetCodable,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostCommitCodableAssociation(
      'commit' in params ? params.commit : null,
      'codable' in params ? params.codable : null,
      'organization' in params ? params.organization : null,
    );
  }
}
