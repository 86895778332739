import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostAttributeResponseAttributeSpecAssociation } from './so-post-attribute-response-attribute-spec-association';
import { SOGetAttributeResponseAttributeSpecAssociation } from './so-get-attribute-response-attribute-spec-association';
import { SOPutAttributeResponseAttributeSpecAssociation } from './so-put-attribute-response-attribute-spec-association';
import { SODeleteAttributeResponseAttributeSpecAssociation } from './so-delete-attribute-response-attribute-spec-association';
import {SORespAttributeResponseAttributeSpecAssociation} from './so-resp-attribute-response-attribute-spec-association';
import {SOPluralAttributeResponseAttributeSpecAssociation} from './so-plural-attribute-response-attribute-spec-association';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOAttributeResponseAttributeSpecAssociationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'attribute_response_attribute_spec_association',
      entityCore
    );
  }

  /** GET AttributeResponseAttributeSpecAssociation by id. Will 404 if id not found */
  get(request: SOGetAttributeResponseAttributeSpecAssociation): Observable<SOPluralAttributeResponseAttributeSpecAssociation> {
    return this.httpClient.get<SOPluralAttributeResponseAttributeSpecAssociation>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralAttributeResponseAttributeSpecAssociation, resp) }),
      tap(_ => this.log(`fetched AttributeResponseAttributeSpecAssociation`)),
      catchError(this.handleError<SOPluralAttributeResponseAttributeSpecAssociation>(`get AttributeResponseAttributeSpecAssociation`))
    );
  }

  /** POST: add a new AttributeResponseAttributeSpecAssociation to the server */
  post(request: SOPostAttributeResponseAttributeSpecAssociation): Observable<SORespAttributeResponseAttributeSpecAssociation> {
    return this.httpClient.post<SORespAttributeResponseAttributeSpecAssociation>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAttributeResponseAttributeSpecAssociation, resp) }),
      tap((response: SORespAttributeResponseAttributeSpecAssociation) => this.log(`added AttributeResponseAttributeSpecAssociation w/ id=${response.uid}`)),
      catchError(this.handleError<SORespAttributeResponseAttributeSpecAssociation>('AttributeResponseAttributeSpecAssociation post'))
    );
  }

  /** PUT: update AttributeResponseAttributeSpecAssociation  */
  put(request: SOPutAttributeResponseAttributeSpecAssociation): Observable<SORespAttributeResponseAttributeSpecAssociation> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespAttributeResponseAttributeSpecAssociation>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAttributeResponseAttributeSpecAssociation, resp) }),
      tap((response: SORespAttributeResponseAttributeSpecAssociation) => this.log(`edited AttributeResponseAttributeSpecAssociation w/ uid=${uid}`)),
      catchError(this.handleError<SORespAttributeResponseAttributeSpecAssociation>('AttributeResponseAttributeSpecAssociation put'))
    );
  }

  /** DELETE: delete the AttributeResponseAttributeSpecAssociation from the server */
  delete(request: SODeleteAttributeResponseAttributeSpecAssociation): Observable<SORespAttributeResponseAttributeSpecAssociation> {
    return this.httpClient.delete<SORespAttributeResponseAttributeSpecAssociation>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted AttributeResponseAttributeSpecAssociation uid=${request.uid}`)),
      catchError(this.handleError<SORespAttributeResponseAttributeSpecAssociation>('delete AttributeResponseAttributeSpecAssociation'))
    );
  }
}
