import { Type } from 'class-transformer';
import { SORespUser } from '../user/so-resp-user';
import { SORespRole } from '../role/so-resp-role';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ENInviteStatus} from '../../enums';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespOrganizationInvite implements ObjectiveProtocol {

  entityType: string = 'organizationInvite';
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  email: string;
  status: ENInviteStatus = ENInviteStatus.sent;
  @Type(() => SORespUser)
  user: SORespUser = null;
  @Type(() => SORespRole)
  role: SORespRole;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
}