import {SORespPaymentIntent} from '../../core/payment-intent/so-resp-payment-intent';
import {PaymentIntentCore} from './payment-intent.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putPaymentIntentFormFieldOptions} from './put-payment-intent-form-fields';
import {SOGetPaymentIntent} from '../../core/payment-intent/so-get-payment-intent';
import {SOGetInvoice} from '../../core/invoice/so-get-invoice';


export class PaymentIntentDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespPaymentIntent = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const amountDetail = new DetailEntry(
        'Amount',
        entity.amount,
        'amount',
        BaseDetailType.text,
        null,
        EditableType.always,
        putPaymentIntentFormFieldOptions.amount
    )
    attributes.push(amountDetail)

    const dateCreatedDetail = new DetailEntry(
        'Date created',
        activeUserService.time.niceDate(entity.dateCreated),
        'dateCreated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateCreatedDetail)

    const statusDetail = new DetailEntry(
        'Status',
        entity.status,
        'status',
        BaseDetailType.toggleGroup,
        null,
        EditableType.always,
        putPaymentIntentFormFieldOptions.status
    )
    attributes.push(statusDetail)


    const invoiceCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'invoice');
    invoiceCore.soGet = SOGetInvoice.construct({
    });
    heading = getHeading(entityViewSpec.entityCore, invoiceCore)
    invoiceCore.title = heading ? heading : invoiceCore.title
    invoiceCore.listTitle = 'Invoice'

    if (entity.invoice) {
        displayStringFactory.reconfigure(entity.invoice, entityViewSpec.entityCore.type)
    }
    invoiceCore.configureForDisplay(entityViewSpec)

    const invoiceDetail = new DetailEntry(
      heading ? heading : invoiceCore.listTitle,
      entity.invoice ? displayStringFactory.primaryString : null,
      'invoice',
      BaseDetailType.pushedDetail,
      invoiceCore,
      EditableType.always,
      putPaymentIntentFormFieldOptions.invoice
    )
    if (entity.invoice) {
      invoiceDetail.cachedListEntry = invoiceCore.makeListEntry(entityViewSpec, invoiceCore, entity.invoice, 0)
    }
    invoiceDetail.singleFieldSelect = true
    attributes.push(invoiceDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
