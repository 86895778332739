import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const nameField = new FormField(
  'name',
  'Name',
  null,
  FormFieldType.text,
  false,
)


export const putEntityFormFieldOptions: {[key: string]: FormField} = {
  name: nameField,
};