import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const userField = new FormField(
  'user',
  'User',
  'user',
  FormFieldType.pushedSelectionList,
  false,
)
const credentialField = new FormField(
  'credential',
  'Credential',
  null,
  FormFieldType.text,
  false,
)
const tokensField = new FormField(
  'tokens',
  'Tokens',
  'token',
  FormFieldType.pushedSelectionList,
  false,
)


export const putCertificateFormFieldOptions: {[key: string]: FormField} = {
  user: userField,
  credential: credentialField,
  tokens: tokensField,
};