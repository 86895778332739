import {SORespEntityResponseAssociation} from '../../core/entity-response-association/so-resp-entity-response-association';
import {EntityResponseAssociationCore} from './entity-response-association.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putEntityResponseAssociationFormFieldOptions} from './put-entity-response-association-form-fields';
import {SOGetEntityResponseAssociation} from '../../core/entity-response-association/so-get-entity-response-association';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {SOGetResponse} from '../../core/response/so-get-response';
import {SOGetApp} from '../../core/app/so-get-app';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class EntityResponseAssociationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespEntityResponseAssociation = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []


    const entityCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'entity');
    entityCore.soGet = SOGetEntity.construct({
      app: new SOGetApp(activeUserService.getScope('app').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, entityCore)
    entityCore.title = heading ? heading : entityCore.title
    entityCore.listTitle = 'Entity'

    if (entity.entity) {
        displayStringFactory.reconfigure(entity.entity, entityViewSpec.entityCore.type)
    }
    entityCore.configureForDisplay(entityViewSpec)

    const entityDetail = new DetailEntry(
      heading ? heading : entityCore.listTitle,
      entity.entity ? displayStringFactory.primaryString : null,
      'entity',
      BaseDetailType.pushedDetail,
      entityCore,
      EditableType.always,
      putEntityResponseAssociationFormFieldOptions.entity
    )
    if (entity.entity) {
      entityDetail.cachedListEntry = entityCore.makeListEntry(entityViewSpec, entityCore, entity.entity, 0)
    }
    entityDetail.singleFieldSelect = true
    attributes.push(entityDetail);

    const responseCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'response');
    responseCore.soGet = SOGetResponse.construct({
      entity: new SOGetEntityResponseAssociation(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, responseCore)
    responseCore.title = heading ? heading : responseCore.title
    responseCore.listTitle = 'Response'

    if (entity.response) {
        displayStringFactory.reconfigure(entity.response, entityViewSpec.entityCore.type)
    }
    responseCore.configureForDisplay(entityViewSpec)

    const responseDetail = new DetailEntry(
      heading ? heading : responseCore.listTitle,
      entity.response ? displayStringFactory.primaryString : null,
      'response',
      BaseDetailType.pushedDetail,
      responseCore,
      EditableType.always,
      putEntityResponseAssociationFormFieldOptions.response
    )
    if (entity.response) {
      responseDetail.cachedListEntry = responseCore.makeListEntry(entityViewSpec, responseCore, entity.response, 0)
    }
    responseDetail.singleFieldSelect = true
    attributes.push(responseDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
