import {SORespWidgetRelationshipSpec} from '../../core/widget-relationship-spec/so-resp-widget-relationship-spec';
import {WidgetRelationshipSpecCore} from './widget-relationship-spec.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putWidgetRelationshipSpecFormFieldOptions} from './put-widget-relationship-spec-form-fields';
import {SOGetWidgetRelationshipSpec} from '../../core/widget-relationship-spec/so-get-widget-relationship-spec';
import {SOGetWidgetWidgetRelationshipSpecAssociation} from '../../core/widget-widget-relationship-spec-association/so-get-widget-widget-relationship-spec-association';
import {SOGetRelationshipWidgetRelationshipSpecAssociation} from '../../core/relationship-widget-relationship-spec-association/so-get-relationship-widget-relationship-spec-association';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class WidgetRelationshipSpecDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespWidgetRelationshipSpec = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const isRequiredDetail = new DetailEntry(
        'Is required',
        entity.isRequired,
        'isRequired',
        BaseDetailType.toggle,
        null,
        EditableType.always,
        putWidgetRelationshipSpecFormFieldOptions.isRequired
    )
    attributes.push(isRequiredDetail)


    const widgetCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'widgetWidgetRelationshipSpecAssociation');
    widgetCore.soGet = SOGetWidgetWidgetRelationshipSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, widgetCore)
    widgetCore.title = heading ? heading : widgetCore.title
    widgetCore.listTitle = 'Widget'

    widgetCore.configureForDisplay(entityViewSpec)

    const widgetDetail = new DetailEntry(
      heading ? heading : widgetCore.listTitle,
      null,
      'widget',
      BaseDetailType.embeddedList,
      widgetCore,
      EditableType.always,
      putWidgetRelationshipSpecFormFieldOptions.widget
    )
    attributes.push(widgetDetail);

    const relationshipCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'relationshipWidgetRelationshipSpecAssociation');
    relationshipCore.soGet = SOGetRelationshipWidgetRelationshipSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, relationshipCore)
    relationshipCore.title = heading ? heading : relationshipCore.title
    relationshipCore.listTitle = 'Relationship'

    relationshipCore.configureForDisplay(entityViewSpec)

    const relationshipDetail = new DetailEntry(
      heading ? heading : relationshipCore.listTitle,
      null,
      'relationship',
      BaseDetailType.embeddedList,
      relationshipCore,
      EditableType.always,
      putWidgetRelationshipSpecFormFieldOptions.relationship
    )
    attributes.push(relationshipDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
