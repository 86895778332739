import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOWidgetService} from '../../core/widget/eo-widget';
import {WidgetListEntryFactory} from './widget-list-entry-factory';
import {WidgetDetailFactory} from './widget-detail-factory';
import {SOGetWidget} from '../../core/widget/so-get-widget';
import {RAPutWidget} from './ra-put-widget';
import {RAPostWidget} from './ra-post-widget';
import {RADeleteWidget} from './ra-delete-widget';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import {SOGetApp} from '../../core/app/so-get-app'


export class WidgetCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'widget';
  title = 'Widget';
  listEntryFactory = new WidgetListEntryFactory();
  detailFactory = new WidgetDetailFactory();

  entityService: EOWidgetService;
  soGetClass = SOGetWidget;
  soGet: SOGetWidget;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOWidgetService
    );
    this.adapters = [
      new RAPostWidget(this),
      new RAPutWidget(this),
      new RADeleteWidget(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetWidget();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.app = new SOGetApp(
      this.activeUserService.getScope('app').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
