import { Type } from 'class-transformer';
import { SORespUser } from '../user/so-resp-user';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespIdentity implements ObjectiveProtocol {

  entityType: string = 'identity';
  uid: string;
  provider: string;
  @Type(() => SORespUser)
  user: SORespUser;
}