import { SOGetBranch } from '../branch/so-get-branch';
import { SOGetCommit } from '../commit/so-get-commit';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPutBranchCommitAssociation {

  constructor(
    public uid: string,
    public branch: SOGetBranch = null,
    public commit: SOGetCommit = null,
    public organization: SOGetOrganization = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutBranchCommitAssociation(
      'uid' in params ? params.uid : null,
      'branch' in params ? params.branch : null,
      'commit' in params ? params.commit : null,
      'organization' in params ? params.organization : null,
    );
  }
}
