import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostEnumeratorEnumeralAssociation } from './so-post-enumerator-enumeral-association';
import { SOGetEnumeratorEnumeralAssociation } from './so-get-enumerator-enumeral-association';
import { SOPutEnumeratorEnumeralAssociation } from './so-put-enumerator-enumeral-association';
import { SODeleteEnumeratorEnumeralAssociation } from './so-delete-enumerator-enumeral-association';
import {SORespEnumeratorEnumeralAssociation} from './so-resp-enumerator-enumeral-association';
import {SOPluralEnumeratorEnumeralAssociation} from './so-plural-enumerator-enumeral-association';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOEnumeratorEnumeralAssociationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'enumerator_enumeral_association',
      entityCore
    );
  }

  /** GET EnumeratorEnumeralAssociation by id. Will 404 if id not found */
  get(request: SOGetEnumeratorEnumeralAssociation): Observable<SOPluralEnumeratorEnumeralAssociation> {
    return this.httpClient.get<SOPluralEnumeratorEnumeralAssociation>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralEnumeratorEnumeralAssociation, resp) }),
      tap(_ => this.log(`fetched EnumeratorEnumeralAssociation`)),
      catchError(this.handleError<SOPluralEnumeratorEnumeralAssociation>(`get EnumeratorEnumeralAssociation`))
    );
  }

  /** POST: add a new EnumeratorEnumeralAssociation to the server */
  post(request: SOPostEnumeratorEnumeralAssociation): Observable<SORespEnumeratorEnumeralAssociation> {
    return this.httpClient.post<SORespEnumeratorEnumeralAssociation>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespEnumeratorEnumeralAssociation, resp) }),
      tap((response: SORespEnumeratorEnumeralAssociation) => this.log(`added EnumeratorEnumeralAssociation w/ id=${response.uid}`)),
      catchError(this.handleError<SORespEnumeratorEnumeralAssociation>('EnumeratorEnumeralAssociation post'))
    );
  }

  /** PUT: update EnumeratorEnumeralAssociation  */
  put(request: SOPutEnumeratorEnumeralAssociation): Observable<SORespEnumeratorEnumeralAssociation> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespEnumeratorEnumeralAssociation>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespEnumeratorEnumeralAssociation, resp) }),
      tap((response: SORespEnumeratorEnumeralAssociation) => this.log(`edited EnumeratorEnumeralAssociation w/ uid=${uid}`)),
      catchError(this.handleError<SORespEnumeratorEnumeralAssociation>('EnumeratorEnumeralAssociation put'))
    );
  }

  /** DELETE: delete the EnumeratorEnumeralAssociation from the server */
  delete(request: SODeleteEnumeratorEnumeralAssociation): Observable<SORespEnumeratorEnumeralAssociation> {
    return this.httpClient.delete<SORespEnumeratorEnumeralAssociation>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted EnumeratorEnumeralAssociation uid=${request.uid}`)),
      catchError(this.handleError<SORespEnumeratorEnumeralAssociation>('delete EnumeratorEnumeralAssociation'))
    );
  }
}
