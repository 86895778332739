import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOCommitCodableAssociationService} from '../../core/commit-codable-association/eo-commit-codable-association';
import {CommitCodableAssociationListEntryFactory} from './commit-codable-association-list-entry-factory';
import {CommitCodableAssociationDetailFactory} from './commit-codable-association-detail-factory';
import {SOGetCommitCodableAssociation} from '../../core/commit-codable-association/so-get-commit-codable-association';
import {RAPutCommitCodableAssociation} from './ra-put-commit-codable-association';
import {RAPostCommitCodableAssociation} from './ra-post-commit-codable-association';
import {RADeleteCommitCodableAssociation} from './ra-delete-commit-codable-association';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetOrganization} from '../../core/organization/so-get-organization'


export class CommitCodableAssociationCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'commitCodableAssociation';
  title = 'Commit codable association';
  listEntryFactory = new CommitCodableAssociationListEntryFactory();
  detailFactory = new CommitCodableAssociationDetailFactory();

  entityService: EOCommitCodableAssociationService;
  soGetClass = SOGetCommitCodableAssociation;
  soGet: SOGetCommitCodableAssociation;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOCommitCodableAssociationService
    );
    this.adapters = [
      new RAPostCommitCodableAssociation(this),
      new RAPutCommitCodableAssociation(this),
      new RADeleteCommitCodableAssociation(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetCommitCodableAssociation();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
