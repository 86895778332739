import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const nameField = new FormField(
  'name',
  'Name',
  null,
  FormFieldType.text,
  true,
)
const skuField = new FormField(
  'sku',
  'Sku',
  null,
  FormFieldType.text,
  true,
  true,
  ''
)
const exRefField = new FormField(
  'exRef',
  'Ex ref',
  null,
  FormFieldType.text,
  false,
)
const activeField = new FormField(
  'active',
  'Active',
  null,
  FormFieldType.boolean,
  true,
  true,
  true
)


export const postProductFormFieldOptions: {[key: string]: FormField} = {
  name: nameField,
  sku: skuField,
  exRef: exRefField,
  active: activeField,
};