import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOEnumeratorEnumeralAssociationService} from '../../core/enumerator-enumeral-association/eo-enumerator-enumeral-association';
import {EnumeratorEnumeralAssociationListEntryFactory} from './enumerator-enumeral-association-list-entry-factory';
import {EnumeratorEnumeralAssociationDetailFactory} from './enumerator-enumeral-association-detail-factory';
import {SOGetEnumeratorEnumeralAssociation} from '../../core/enumerator-enumeral-association/so-get-enumerator-enumeral-association';
import {RAPutEnumeratorEnumeralAssociation} from './ra-put-enumerator-enumeral-association';
import {RAPostEnumeratorEnumeralAssociation} from './ra-post-enumerator-enumeral-association';
import {RADeleteEnumeratorEnumeralAssociation} from './ra-delete-enumerator-enumeral-association';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetCommit} from '../../core/commit/so-get-commit'
import {SOGetOrganization} from '../../core/organization/so-get-organization'


export class EnumeratorEnumeralAssociationCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'enumeratorEnumeralAssociation';
  title = 'Enumerator enumeral association';
  listEntryFactory = new EnumeratorEnumeralAssociationListEntryFactory();
  detailFactory = new EnumeratorEnumeralAssociationDetailFactory();

  entityService: EOEnumeratorEnumeralAssociationService;
  soGetClass = SOGetEnumeratorEnumeralAssociation;
  soGet: SOGetEnumeratorEnumeralAssociation;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOEnumeratorEnumeralAssociationService
    );
    this.adapters = [
      new RAPostEnumeratorEnumeralAssociation(this),
      new RAPutEnumeratorEnumeralAssociation(this),
      new RADeleteEnumeratorEnumeralAssociation(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetEnumeratorEnumeralAssociation();
    this.soGet.commit = new SOGetCommit(
      this.activeUserService.getScope('commit').uid
    );
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
