import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EORequestRelationshipSpecService} from '../../core/request-relationship-spec/eo-request-relationship-spec';
import {RequestRelationshipSpecListEntryFactory} from './request-relationship-spec-list-entry-factory';
import {RequestRelationshipSpecDetailFactory} from './request-relationship-spec-detail-factory';
import {SOGetRequestRelationshipSpec} from '../../core/request-relationship-spec/so-get-request-relationship-spec';
import {RAPutRequestRelationshipSpec} from './ra-put-request-relationship-spec';
import {RAPostRequestRelationshipSpec} from './ra-post-request-relationship-spec';
import {RADeleteRequestRelationshipSpec} from './ra-delete-request-relationship-spec';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import {SOGetApp} from '../../core/app/so-get-app'


export class RequestRelationshipSpecCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'requestRelationshipSpec';
  title = 'Request relationship spec';
  listEntryFactory = new RequestRelationshipSpecListEntryFactory();
  detailFactory = new RequestRelationshipSpecDetailFactory();

  entityService: EORequestRelationshipSpecService;
  soGetClass = SOGetRequestRelationshipSpec;
  soGet: SOGetRequestRelationshipSpec;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EORequestRelationshipSpecService
    );
    this.adapters = [
      new RAPostRequestRelationshipSpec(this),
      new RAPutRequestRelationshipSpec(this),
      new RADeleteRequestRelationshipSpec(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetRequestRelationshipSpec();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.app = new SOGetApp(
      this.activeUserService.getScope('app').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
