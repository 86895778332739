import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostWidgetRelationshipSpec } from './so-post-widget-relationship-spec';
import { SOGetWidgetRelationshipSpec } from './so-get-widget-relationship-spec';
import { SOPutWidgetRelationshipSpec } from './so-put-widget-relationship-spec';
import { SODeleteWidgetRelationshipSpec } from './so-delete-widget-relationship-spec';
import {SORespWidgetRelationshipSpec} from './so-resp-widget-relationship-spec';
import {SOPluralWidgetRelationshipSpec} from './so-plural-widget-relationship-spec';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOWidgetRelationshipSpecService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'widget_relationship_spec',
      entityCore
    );
  }

  /** GET WidgetRelationshipSpec by id. Will 404 if id not found */
  get(request: SOGetWidgetRelationshipSpec): Observable<SOPluralWidgetRelationshipSpec> {
    return this.httpClient.get<SOPluralWidgetRelationshipSpec>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralWidgetRelationshipSpec, resp) }),
      tap(_ => this.log(`fetched WidgetRelationshipSpec`)),
      catchError(this.handleError<SOPluralWidgetRelationshipSpec>(`get WidgetRelationshipSpec`))
    );
  }

  /** POST: add a new WidgetRelationshipSpec to the server */
  post(request: SOPostWidgetRelationshipSpec): Observable<SORespWidgetRelationshipSpec> {
    return this.httpClient.post<SORespWidgetRelationshipSpec>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespWidgetRelationshipSpec, resp) }),
      tap((response: SORespWidgetRelationshipSpec) => this.log(`added WidgetRelationshipSpec w/ id=${response.uid}`)),
      catchError(this.handleError<SORespWidgetRelationshipSpec>('WidgetRelationshipSpec post'))
    );
  }

  /** PUT: update WidgetRelationshipSpec  */
  put(request: SOPutWidgetRelationshipSpec): Observable<SORespWidgetRelationshipSpec> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespWidgetRelationshipSpec>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespWidgetRelationshipSpec, resp) }),
      tap((response: SORespWidgetRelationshipSpec) => this.log(`edited WidgetRelationshipSpec w/ uid=${uid}`)),
      catchError(this.handleError<SORespWidgetRelationshipSpec>('WidgetRelationshipSpec put'))
    );
  }

  /** DELETE: delete the WidgetRelationshipSpec from the server */
  delete(request: SODeleteWidgetRelationshipSpec): Observable<SORespWidgetRelationshipSpec> {
    return this.httpClient.delete<SORespWidgetRelationshipSpec>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted WidgetRelationshipSpec uid=${request.uid}`)),
      catchError(this.handleError<SORespWidgetRelationshipSpec>('delete WidgetRelationshipSpec'))
    );
  }
}
