import {SOPutUser} from '../../core/user/so-put-user';
import {UserCore} from './user.core';
import {SOGetUser} from '../../core/user/so-get-user';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putUserFormFieldOptions} from './put-user-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';

export class RAPutUser extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putUserFormFieldOptions);

  RequestClass = SOPutUser

  constructor(
    public entityCore: UserCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('password'),
      this.getFormValueFromIdentifier('givenName'),
      this.getFormValueFromIdentifier('surname'),
      this.getFormValueFromIdentifier('email'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}