import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const attributeField = new FormField(
  'attribute',
  'Attribute',
  'attribute',
  FormFieldType.pushedSelectionList,
  true,
)
const storeField = new FormField(
  'store',
  'Store',
  'storeAttributeSpec',
  FormFieldType.pushedSelectionList,
  true,
)


export const postAttributeStoreAttributeSpecAssociationFormFieldOptions: {[key: string]: FormField} = {
  attribute: attributeField,
  store: storeField,
};