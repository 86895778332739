import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const entityField = new FormField(
  'entity',
  'Entity',
  'entity',
  FormFieldType.pushedSelectionList,
  true,
)
const storeField = new FormField(
  'store',
  'Store',
  'store',
  FormFieldType.pushedSelectionList,
  true,
)


export const postEntityStoreAssociationFormFieldOptions: {[key: string]: FormField} = {
  entity: entityField,
  store: storeField,
};