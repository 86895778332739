import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOUserService} from '../../core/user/eo-user';
import {UserListEntryFactory} from './user-list-entry-factory';
import {UserDetailFactory} from './user-detail-factory';
import {SOGetUser} from '../../core/user/so-get-user';
import {RAPutUser} from './ra-put-user';
import {RAPostUser} from './ra-post-user';
import {RADeleteUser} from './ra-delete-user';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';


export class UserCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'user';
  title = 'User';
  listEntryFactory = new UserListEntryFactory();
  detailFactory = new UserDetailFactory();

  entityService: EOUserService;
  soGetClass = SOGetUser;
  soGet: SOGetUser;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOUserService
    );
    this.adapters = [
      new RAPostUser(this),
      new RAPutUser(this),
      new RADeleteUser(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetUser();
    this.soGet.queryOptions.limit = this.limit;
  }


}
