import {SORespAttributeResponseAttributeSpecAssociation} from '../../core/attribute-response-attribute-spec-association/so-resp-attribute-response-attribute-spec-association';
import {AttributeResponseAttributeSpecAssociationCore} from './attribute-response-attribute-spec-association.core';
import { ListEntry, ListEntryFactory, ListEntryFactoryProtocol} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';


export class AttributeResponseAttributeSpecAssociationListEntryFactory  extends ListEntryFactory implements ListEntryFactoryProtocol {

  makeListEntry(parentCoreViewSpec, entityCore: EntityCoreProtocol, entity: SORespAttributeResponseAttributeSpecAssociation, section: number = 0): ListEntry {
    const resp = super.makeListEntry(parentCoreViewSpec, entityCore, entity, section);
    return resp;
  }
}
