import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOAttributeStoreAttributeSpecAssociationService} from '../../core/attribute-store-attribute-spec-association/eo-attribute-store-attribute-spec-association';
import {AttributeStoreAttributeSpecAssociationListEntryFactory} from './attribute-store-attribute-spec-association-list-entry-factory';
import {AttributeStoreAttributeSpecAssociationDetailFactory} from './attribute-store-attribute-spec-association-detail-factory';
import {SOGetAttributeStoreAttributeSpecAssociation} from '../../core/attribute-store-attribute-spec-association/so-get-attribute-store-attribute-spec-association';
import {RAPutAttributeStoreAttributeSpecAssociation} from './ra-put-attribute-store-attribute-spec-association';
import {RAPostAttributeStoreAttributeSpecAssociation} from './ra-post-attribute-store-attribute-spec-association';
import {RADeleteAttributeStoreAttributeSpecAssociation} from './ra-delete-attribute-store-attribute-spec-association';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetCommit} from '../../core/commit/so-get-commit'
import {SOGetOrganization} from '../../core/organization/so-get-organization'


export class AttributeStoreAttributeSpecAssociationCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'attributeStoreAttributeSpecAssociation';
  title = 'Attribute store attribute spec association';
  listEntryFactory = new AttributeStoreAttributeSpecAssociationListEntryFactory();
  detailFactory = new AttributeStoreAttributeSpecAssociationDetailFactory();

  entityService: EOAttributeStoreAttributeSpecAssociationService;
  soGetClass = SOGetAttributeStoreAttributeSpecAssociation;
  soGet: SOGetAttributeStoreAttributeSpecAssociation;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOAttributeStoreAttributeSpecAssociationService
    );
    this.adapters = [
      new RAPostAttributeStoreAttributeSpecAssociation(this),
      new RAPutAttributeStoreAttributeSpecAssociation(this),
      new RADeleteAttributeStoreAttributeSpecAssociation(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetAttributeStoreAttributeSpecAssociation();
    this.soGet.commit = new SOGetCommit(
      this.activeUserService.getScope('commit').uid
    );
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
