import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOEntityResponseAssociationService} from '../../core/entity-response-association/eo-entity-response-association';
import {EntityResponseAssociationListEntryFactory} from './entity-response-association-list-entry-factory';
import {EntityResponseAssociationDetailFactory} from './entity-response-association-detail-factory';
import {SOGetEntityResponseAssociation} from '../../core/entity-response-association/so-get-entity-response-association';
import {RAPutEntityResponseAssociation} from './ra-put-entity-response-association';
import {RAPostEntityResponseAssociation} from './ra-post-entity-response-association';
import {RADeleteEntityResponseAssociation} from './ra-delete-entity-response-association';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetCommit} from '../../core/commit/so-get-commit'
import {SOGetOrganization} from '../../core/organization/so-get-organization'


export class EntityResponseAssociationCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'entityResponseAssociation';
  title = 'Entity response association';
  listEntryFactory = new EntityResponseAssociationListEntryFactory();
  detailFactory = new EntityResponseAssociationDetailFactory();

  entityService: EOEntityResponseAssociationService;
  soGetClass = SOGetEntityResponseAssociation;
  soGet: SOGetEntityResponseAssociation;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOEntityResponseAssociationService
    );
    this.adapters = [
      new RAPostEntityResponseAssociation(this),
      new RAPutEntityResponseAssociation(this),
      new RADeleteEntityResponseAssociation(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetEntityResponseAssociation();
    this.soGet.commit = new SOGetCommit(
      this.activeUserService.getScope('commit').uid
    );
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
