import { SOGetRelationship } from '../relationship/so-get-relationship';
import { SOGetStoreRelationshipSpec } from '../store-relationship-spec/so-get-store-relationship-spec';
import { SOGetCommit } from '../commit/so-get-commit';


export class SOPutRelationshipStoreRelationshipSpecAssociation {

  constructor(
    public uid: string,
    public relationship: SOGetRelationship = null,
    public store: SOGetStoreRelationshipSpec = null,
    public commit: SOGetCommit = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutRelationshipStoreRelationshipSpecAssociation(
      'uid' in params ? params.uid : null,
      'relationship' in params ? params.relationship : null,
      'store' in params ? params.store : null,
      'commit' in params ? params.commit : null,
    );
  }
}
