import { Type } from 'class-transformer';
import { SORespRelationship } from '../relationship/so-resp-relationship';
import { SORespResponseRelationshipSpec } from '../response-relationship-spec/so-resp-response-relationship-spec';
import { SORespCommit } from '../commit/so-resp-commit';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespRelationshipResponseRelationshipSpecAssociation implements ObjectiveProtocol {

  entityType: string = 'relationshipResponseRelationshipSpecAssociation';
  uid: string;
  @Type(() => SORespRelationship)
  relationship: SORespRelationship;
  @Type(() => SORespResponseRelationshipSpec)
  response: SORespResponseRelationshipSpec;
  @Type(() => SORespCommit)
  commit: SORespCommit;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
}