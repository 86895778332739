import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const refField = new FormField(
  'ref',
  'Ref',
  null,
  FormFieldType.text,
  false,
)
const exRefField = new FormField(
  'exRef',
  'Ex ref',
  null,
  FormFieldType.text,
  false,
)
const amountDueField = new FormField(
  'amountDue',
  'Amount due',
  null,
  FormFieldType.text,
  false,
)
const amountPaidField = new FormField(
  'amountPaid',
  'Amount paid',
  null,
  FormFieldType.text,
  false,
)
const amountRemainingField = new FormField(
  'amountRemaining',
  'Amount remaining',
  null,
  FormFieldType.text,
  false,
)
const paidField = new FormField(
  'paid',
  'Paid',
  null,
  FormFieldType.boolean,
  false,
  true,
  false
)
const dateDueField = new FormField(
  'dateDue',
  'Date due',
  null,
  FormFieldType.datetime,
  false,
)
const periodStartField = new FormField(
  'periodStart',
  'Period start',
  null,
  FormFieldType.datetime,
  false,
)
const periodEndField = new FormField(
  'periodEnd',
  'Period end',
  null,
  FormFieldType.datetime,
  false,
)
const subscriptionField = new FormField(
  'subscription',
  'Subscription',
  'subscription',
  FormFieldType.pushedSelectionList,
  false,
)


export const putInvoiceFormFieldOptions: {[key: string]: FormField} = {
  ref: refField,
  exRef: exRefField,
  amountDue: amountDueField,
  amountPaid: amountPaidField,
  amountRemaining: amountRemainingField,
  paid: paidField,
  dateDue: dateDueField,
  periodStart: periodStartField,
  periodEnd: periodEndField,
  subscription: subscriptionField,
};