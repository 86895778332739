import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostAttribute } from './so-post-attribute';
import { SOGetAttribute } from './so-get-attribute';
import { SOPutAttribute } from './so-put-attribute';
import { SODeleteAttribute } from './so-delete-attribute';
import {SORespAttribute} from './so-resp-attribute';
import {SOPluralAttribute} from './so-plural-attribute';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOAttributeService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'attribute',
      entityCore
    );
  }

  /** GET Attribute by id. Will 404 if id not found */
  get(request: SOGetAttribute): Observable<SOPluralAttribute> {
    return this.httpClient.get<SOPluralAttribute>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralAttribute, resp) }),
      tap(_ => this.log(`fetched Attribute`)),
      catchError(this.handleError<SOPluralAttribute>(`get Attribute`))
    );
  }

  /** POST: add a new Attribute to the server */
  post(request: SOPostAttribute): Observable<SORespAttribute> {
    return this.httpClient.post<SORespAttribute>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAttribute, resp) }),
      tap((response: SORespAttribute) => this.log(`added Attribute w/ id=${response.uid}`)),
      catchError(this.handleError<SORespAttribute>('Attribute post'))
    );
  }

  /** PUT: update Attribute  */
  put(request: SOPutAttribute): Observable<SORespAttribute> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespAttribute>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAttribute, resp) }),
      tap((response: SORespAttribute) => this.log(`edited Attribute w/ uid=${uid}`)),
      catchError(this.handleError<SORespAttribute>('Attribute put'))
    );
  }

  /** DELETE: delete the Attribute from the server */
  delete(request: SODeleteAttribute): Observable<SORespAttribute> {
    return this.httpClient.delete<SORespAttribute>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Attribute uid=${request.uid}`)),
      catchError(this.handleError<SORespAttribute>('delete Attribute'))
    );
  }
}
