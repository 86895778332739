import {SOPostCommitCodableAssociation} from '../../core/commit-codable-association/so-post-commit-codable-association';
import {CommitCodableAssociationCore} from './commit-codable-association.core';
import {SOGetCommitCodableAssociation} from '../../core/commit-codable-association/so-get-commit-codable-association';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postCommitCodableAssociationFormFieldOptions} from './post-commit-codable-association-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {CommitCore} from '../bv-commit/commit.core';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {CodableCore} from '../bv-codable/codable.core';
import {SOGetCodable} from '../../core/codable/so-get-codable';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostCommitCodableAssociation extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postCommitCodableAssociationFormFieldOptions);

  RequestClass = SOPostCommitCodableAssociation

  constructor(
    public entityCore: CommitCodableAssociationCore
  ) {
    super();
    if (postCommitCodableAssociationFormFieldOptions.commit) {
      postCommitCodableAssociationFormFieldOptions.commit.makeCore = true;
    }
    if (postCommitCodableAssociationFormFieldOptions.codable) {
      postCommitCodableAssociationFormFieldOptions.codable.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'commit',
        SOGetCommit,
      ),
      this.getFormValueFromIdentifier(
        'codable',
        SOGetCodable,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}