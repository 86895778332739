import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const responseField = new FormField(
  'response',
  'Response',
  'response',
  FormFieldType.pushedSelectionList,
  false,
)
const responseRelationshipSpecField = new FormField(
  'responseRelationshipSpec',
  'Response relationship spec',
  'responseRelationshipSpec',
  FormFieldType.pushedSelectionList,
  false,
)


export const putResponseResponseRelationshipSpecAssociationFormFieldOptions: {[key: string]: FormField} = {
  response: responseField,
  responseRelationshipSpec: responseRelationshipSpecField,
};