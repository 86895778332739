import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOOrganizationInviteService} from '../../core/organization-invite/eo-organization-invite';
import {OrganizationInviteListEntryFactory} from './organization-invite-list-entry-factory';
import {OrganizationInviteDetailFactory} from './organization-invite-detail-factory';
import {SOGetOrganizationInvite} from '../../core/organization-invite/so-get-organization-invite';
import {RAPutOrganizationInvite} from './ra-put-organization-invite';
import {RAPostOrganizationInvite} from './ra-post-organization-invite';
import {RADeleteOrganizationInvite} from './ra-delete-organization-invite';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';


export class OrganizationInviteCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'organizationInvite';
  title = 'Organization invite';
  listEntryFactory = new OrganizationInviteListEntryFactory();
  detailFactory = new OrganizationInviteDetailFactory();

  entityService: EOOrganizationInviteService;
  soGetClass = SOGetOrganizationInvite;
  soGet: SOGetOrganizationInvite;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOOrganizationInviteService
    );
    this.adapters = [
      new RAPostOrganizationInvite(this),
      new RAPutOrganizationInvite(this),
      new RADeleteOrganizationInvite(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetOrganizationInvite();
    this.soGet.queryOptions.limit = this.limit;
  }


}
