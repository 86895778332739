import {SORespWidgetRelationshipSpec} from '../../core/widget-relationship-spec/so-resp-widget-relationship-spec';
import {WidgetRelationshipSpecCore} from './widget-relationship-spec.core';
import { ListEntry, ListEntryFactory, ListEntryFactoryProtocol} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';


export class WidgetRelationshipSpecListEntryFactory  extends ListEntryFactory implements ListEntryFactoryProtocol {

  makeListEntry(parentCoreViewSpec, entityCore: EntityCoreProtocol, entity: SORespWidgetRelationshipSpec, section: number = 0): ListEntry {
    const resp = super.makeListEntry(parentCoreViewSpec, entityCore, entity, section)
    return resp
  }
}
