import {SORespRequestAttributeSpec} from '../../core/request-attribute-spec/so-resp-request-attribute-spec';
import {RequestAttributeSpecCore} from './request-attribute-spec.core';
import { ListEntry, ListEntryFactory, ListEntryFactoryProtocol} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';


export class RequestAttributeSpecListEntryFactory  extends ListEntryFactory implements ListEntryFactoryProtocol {

  makeListEntry(parentCoreViewSpec, entityCore: EntityCoreProtocol, entity: SORespRequestAttributeSpec, section: number = 0): ListEntry {
    const resp = super.makeListEntry(parentCoreViewSpec, entityCore, entity, section)
    return resp
  }
}
