import { SOGetEnumeratorEnumeralAssociation } from '../enumerator-enumeral-association/so-get-enumerator-enumeral-association';


export class SOPutEnumeral {

  constructor(
    public uid: string,
    public name: string = null,
    public enumerators: SOGetEnumeratorEnumeralAssociation[] = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutEnumeral(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'enumerators' in params ? params.enumerators : null,
    );
  }
}
