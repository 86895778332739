import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOHelloService} from '../../core/hello/eo-hello';
import {HelloListEntryFactory} from './hello-list-entry-factory';
import {HelloDetailFactory} from './hello-detail-factory';
import {SOGetHello} from '../../core/hello/so-get-hello';
import {RAPutHello} from './ra-put-hello';
import {RAPostHello} from './ra-post-hello';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';


export class HelloCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'hello';
  title = 'Hello';
  listEntryFactory = new HelloListEntryFactory();
  detailFactory = new HelloDetailFactory();

  entityService: EOHelloService;
  soGetClass = SOGetHello;
  soGet: SOGetHello;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOHelloService
    );
    this.adapters = [
      new RAPostHello(this),
      new RAPutHello(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetHello();
    this.soGet.queryOptions.limit = this.limit;
  }


}
