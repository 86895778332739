import {SOPutWidgetWidgetRelationshipSpecAssociation} from '../../core/widget-widget-relationship-spec-association/so-put-widget-widget-relationship-spec-association';
import {WidgetWidgetRelationshipSpecAssociationCore} from './widget-widget-relationship-spec-association.core';
import {SOGetWidgetWidgetRelationshipSpecAssociation} from '../../core/widget-widget-relationship-spec-association/so-get-widget-widget-relationship-spec-association';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putWidgetWidgetRelationshipSpecAssociationFormFieldOptions} from './put-widget-widget-relationship-spec-association-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {WidgetCore} from '../bv-widget/widget.core';
import {SOGetWidget} from '../../core/widget/so-get-widget';
import {WidgetRelationshipSpecCore} from '../bv-widget-relationship-spec/widget-relationship-spec.core';
import {SOGetWidgetRelationshipSpec} from '../../core/widget-relationship-spec/so-get-widget-relationship-spec';

export class RAPutWidgetWidgetRelationshipSpecAssociation extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putWidgetWidgetRelationshipSpecAssociationFormFieldOptions);

  RequestClass = SOPutWidgetWidgetRelationshipSpecAssociation

  constructor(
    public entityCore: WidgetWidgetRelationshipSpecAssociationCore
  ) {
    super();
    if (putWidgetWidgetRelationshipSpecAssociationFormFieldOptions.widget) {
      putWidgetWidgetRelationshipSpecAssociationFormFieldOptions.widget.makeCore = true;
    }
    if (putWidgetWidgetRelationshipSpecAssociationFormFieldOptions.widgetRelationshipSpec) {
      putWidgetWidgetRelationshipSpecAssociationFormFieldOptions.widgetRelationshipSpec.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier(
        'widget',
        SOGetWidget,
      ),
      this.getFormValueFromIdentifier(
        'widgetRelationshipSpec',
        SOGetWidgetRelationshipSpec,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}