import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOWidgetWidgetAttributeSpecAssociationService} from '../../core/widget-widget-attribute-spec-association/eo-widget-widget-attribute-spec-association';
import {WidgetWidgetAttributeSpecAssociationListEntryFactory} from './widget-widget-attribute-spec-association-list-entry-factory';
import {WidgetWidgetAttributeSpecAssociationDetailFactory} from './widget-widget-attribute-spec-association-detail-factory';
import {SOGetWidgetWidgetAttributeSpecAssociation} from '../../core/widget-widget-attribute-spec-association/so-get-widget-widget-attribute-spec-association';
import {RAPutWidgetWidgetAttributeSpecAssociation} from './ra-put-widget-widget-attribute-spec-association';
import {RAPostWidgetWidgetAttributeSpecAssociation} from './ra-post-widget-widget-attribute-spec-association';
import {RADeleteWidgetWidgetAttributeSpecAssociation} from './ra-delete-widget-widget-attribute-spec-association';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetCommit} from '../../core/commit/so-get-commit'
import {SOGetOrganization} from '../../core/organization/so-get-organization'


export class WidgetWidgetAttributeSpecAssociationCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'widgetWidgetAttributeSpecAssociation';
  title = 'Widget widget attribute spec association';
  listEntryFactory = new WidgetWidgetAttributeSpecAssociationListEntryFactory();
  detailFactory = new WidgetWidgetAttributeSpecAssociationDetailFactory();

  entityService: EOWidgetWidgetAttributeSpecAssociationService;
  soGetClass = SOGetWidgetWidgetAttributeSpecAssociation;
  soGet: SOGetWidgetWidgetAttributeSpecAssociation;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOWidgetWidgetAttributeSpecAssociationService
    );
    this.adapters = [
      new RAPostWidgetWidgetAttributeSpecAssociation(this),
      new RAPutWidgetWidgetAttributeSpecAssociation(this),
      new RADeleteWidgetWidgetAttributeSpecAssociation(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetWidgetWidgetAttributeSpecAssociation();
    this.soGet.commit = new SOGetCommit(
      this.activeUserService.getScope('commit').uid
    );
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
