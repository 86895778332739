import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOMediaService} from '../../core/media/eo-media';
import {MediaListEntryFactory} from './media-list-entry-factory';
import {MediaDetailFactory} from './media-detail-factory';
import {SOGetMedia} from '../../core/media/so-get-media';
import {RAPutMedia} from './ra-put-media';
import {RAPostMedia} from './ra-post-media';
import {RADeleteMedia} from './ra-delete-media';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetOrganization} from '../../core/organization/so-get-organization'


export class MediaCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'media';
  title = 'Media';
  listEntryFactory = new MediaListEntryFactory();
  detailFactory = new MediaDetailFactory();

  entityService: EOMediaService;
  soGetClass = SOGetMedia;
  soGet: SOGetMedia;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOMediaService
    );
    this.adapters = [
      new RAPostMedia(this),
      new RAPutMedia(this),
      new RADeleteMedia(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetMedia();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}
