import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostSubscription } from './so-post-subscription';
import { SOGetSubscription } from './so-get-subscription';
import { SOPutSubscription } from './so-put-subscription';
import { SODeleteSubscription } from './so-delete-subscription';
import {SORespSubscription} from './so-resp-subscription';
import {SOPluralSubscription} from './so-plural-subscription';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOSubscriptionService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'subscription',
      entityCore
    );
  }

  /** GET Subscription by id. Will 404 if id not found */
  get(request: SOGetSubscription): Observable<SOPluralSubscription> {
    return this.httpClient.get<SOPluralSubscription>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralSubscription, resp) }),
      tap(_ => this.log(`fetched Subscription`)),
      catchError(this.handleError<SOPluralSubscription>(`get Subscription`))
    );
  }

  /** POST: add a new Subscription to the server */
  post(request: SOPostSubscription): Observable<SORespSubscription> {
    return this.httpClient.post<SORespSubscription>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespSubscription, resp) }),
      tap((response: SORespSubscription) => this.log(`added Subscription w/ id=${response.uid}`)),
      catchError(this.handleError<SORespSubscription>('Subscription post'))
    );
  }

  /** PUT: update Subscription  */
  put(request: SOPutSubscription): Observable<SORespSubscription> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespSubscription>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespSubscription, resp) }),
      tap((response: SORespSubscription) => this.log(`edited Subscription w/ uid=${uid}`)),
      catchError(this.handleError<SORespSubscription>('Subscription put'))
    );
  }

  /** DELETE: delete the Subscription from the server */
  delete(request: SODeleteSubscription): Observable<SORespSubscription> {
    return this.httpClient.delete<SORespSubscription>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Subscription uid=${request.uid}`)),
      catchError(this.handleError<SORespSubscription>('delete Subscription'))
    );
  }
}
