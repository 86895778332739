import {SOPostAttribute} from '../../core/attribute/so-post-attribute';
import {AttributeCore} from './attribute.core';
import {SOGetAttribute} from '../../core/attribute/so-get-attribute';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postAttributeFormFieldOptions} from './post-attribute-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {AppCore} from '../bv-app/app.core';
import {SOGetApp} from '../../core/app/so-get-app';

export class RAPostAttribute extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postAttributeFormFieldOptions);

  RequestClass = SOPostAttribute

  constructor(
    public entityCore: AttributeCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier(
        'app',
        SOGetApp,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}