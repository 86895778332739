import { SOGetSubscription } from '../subscription/so-get-subscription';


export class SOPostInvoice {

  constructor(
    public ref: string,
    public amountDue: number,
    public amountPaid: number,
    public amountRemaining: number,
    public dateDue: Date,
    public periodStart: Date,
    public periodEnd: Date,
    public subscription: SOGetSubscription,
    public exRef: string = null,
    public paid: boolean = false,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostInvoice(
      'ref' in params ? params.ref : null,
      'exRef' in params ? params.exRef : null,
      'amountDue' in params ? params.amountDue : null,
      'amountPaid' in params ? params.amountPaid : null,
      'amountRemaining' in params ? params.amountRemaining : null,
      'paid' in params ? params.paid : null,
      'dateDue' in params ? params.dateDue : null,
      'periodStart' in params ? params.periodStart : null,
      'periodEnd' in params ? params.periodEnd : null,
      'subscription' in params ? params.subscription : null,
    );
  }
}
