import {SOPostStoreRelationshipSpec} from '../../core/store-relationship-spec/so-post-store-relationship-spec';
import {StoreRelationshipSpecCore} from './store-relationship-spec.core';
import {SOGetStoreRelationshipSpec} from '../../core/store-relationship-spec/so-get-store-relationship-spec';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postStoreRelationshipSpecFormFieldOptions} from './post-store-relationship-spec-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {StoreStoreRelationshipSpecAssociationCore} from '../bv-store-store-relationship-spec-association/store-store-relationship-spec-association.core';
import {SOGetStoreStoreRelationshipSpecAssociation} from '../../core/store-store-relationship-spec-association/so-get-store-store-relationship-spec-association';
import {RelationshipStoreRelationshipSpecAssociationCore} from '../bv-relationship-store-relationship-spec-association/relationship-store-relationship-spec-association.core';
import {SOGetRelationshipStoreRelationshipSpecAssociation} from '../../core/relationship-store-relationship-spec-association/so-get-relationship-store-relationship-spec-association';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {AppCore} from '../bv-app/app.core';
import {SOGetApp} from '../../core/app/so-get-app';

export class RAPostStoreRelationshipSpec extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postStoreRelationshipSpecFormFieldOptions);

  RequestClass = SOPostStoreRelationshipSpec

  constructor(
    public entityCore: StoreRelationshipSpecCore
  ) {
    super();
    if (postStoreRelationshipSpecFormFieldOptions.store) {
      postStoreRelationshipSpecFormFieldOptions.store.makeCore = true;
    }
    if (postStoreRelationshipSpecFormFieldOptions.relationship) {
      postStoreRelationshipSpecFormFieldOptions.relationship.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('isRequired'),
      [this.getFormValueFromIdentifier('store', SOGetStoreStoreRelationshipSpecAssociation)],
      [this.getFormValueFromIdentifier('relationship', SOGetRelationshipStoreRelationshipSpecAssociation)],
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier(
        'app',
        SOGetApp,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}