import {SORespAppliedConstraint} from '../../core/applied-constraint/so-resp-applied-constraint';
import {AppliedConstraintCore} from './applied-constraint.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putAppliedConstraintFormFieldOptions} from './put-applied-constraint-form-fields';
import {SOGetAppliedConstraint} from '../../core/applied-constraint/so-get-applied-constraint';
import {SOGetConstraint} from '../../core/constraint/so-get-constraint';
import {SOGetProduct} from '../../core/product/so-get-product';


export class AppliedConstraintDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespAppliedConstraint = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const quantityDetail = new DetailEntry(
        'Quantity',
        entity.quantity,
        'quantity',
        BaseDetailType.text,
        null,
        EditableType.always,
        putAppliedConstraintFormFieldOptions.quantity
    )
    attributes.push(quantityDetail)


    const constraintCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'constraint');
    constraintCore.soGet = SOGetConstraint.construct({
    });
    heading = getHeading(entityViewSpec.entityCore, constraintCore)
    constraintCore.title = heading ? heading : constraintCore.title
    constraintCore.listTitle = 'Constraint'

    if (entity.constraint) {
        displayStringFactory.reconfigure(entity.constraint, entityViewSpec.entityCore.type)
    }
    constraintCore.configureForDisplay(entityViewSpec)

    const constraintDetail = new DetailEntry(
      heading ? heading : constraintCore.listTitle,
      entity.constraint ? displayStringFactory.primaryString : null,
      'constraint',
      BaseDetailType.pushedDetail,
      constraintCore,
      EditableType.never
    )
    if (entity.constraint) {
      constraintDetail.cachedListEntry = constraintCore.makeListEntry(entityViewSpec, constraintCore, entity.constraint, 0)
    }
    constraintDetail.singleFieldSelect = true
    attributes.push(constraintDetail);

    const productCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'product');
    productCore.soGet = SOGetProduct.construct({
    });
    heading = getHeading(entityViewSpec.entityCore, productCore)
    productCore.title = heading ? heading : productCore.title
    productCore.listTitle = 'Product'

    if (entity.product) {
        displayStringFactory.reconfigure(entity.product, entityViewSpec.entityCore.type)
    }
    productCore.configureForDisplay(entityViewSpec)

    const productDetail = new DetailEntry(
      heading ? heading : productCore.listTitle,
      entity.product ? displayStringFactory.primaryString : null,
      'product',
      BaseDetailType.pushedDetail,
      productCore,
      EditableType.never
    )
    if (entity.product) {
      productDetail.cachedListEntry = productCore.makeListEntry(entityViewSpec, productCore, entity.product, 0)
    }
    productDetail.singleFieldSelect = true
    attributes.push(productDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
