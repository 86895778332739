import { SOGetEnumerator } from '../enumerator/so-get-enumerator';
import { SOGetEnumeral } from '../enumeral/so-get-enumeral';
import { SOGetCommit } from '../commit/so-get-commit';


export class SOPutEnumeratorEnumeralAssociation {

  constructor(
    public uid: string,
    public enumerator: SOGetEnumerator = null,
    public enumeral: SOGetEnumeral = null,
    public commit: SOGetCommit = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutEnumeratorEnumeralAssociation(
      'uid' in params ? params.uid : null,
      'enumerator' in params ? params.enumerator : null,
      'enumeral' in params ? params.enumeral : null,
      'commit' in params ? params.commit : null,
    );
  }
}
