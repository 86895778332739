import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const requestField = new FormField(
  'request',
  'Request',
  'request',
  FormFieldType.pushedSelectionList,
  true,
)
const requestAttributeSpecField = new FormField(
  'requestAttributeSpec',
  'Request attribute spec',
  'requestAttributeSpec',
  FormFieldType.pushedSelectionList,
  true,
)


export const postRequestRequestAttributeSpecAssociationFormFieldOptions: {[key: string]: FormField} = {
  request: requestField,
  requestAttributeSpec: requestAttributeSpecField,
};