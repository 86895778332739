import {SORespView} from '../../core/view/so-resp-view';
import {ViewCore} from './view.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putViewFormFieldOptions} from './put-view-form-fields';
import {SOGetView} from '../../core/view/so-get-view';
import {SOGetViewGrant} from '../../core/view-grant/so-get-view-grant';


export class ViewDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespView = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const nameDetail = new DetailEntry(
        'Name',
        entity.name,
        'name',
        BaseDetailType.text,
        null,
        EditableType.always,
        putViewFormFieldOptions.name
    )
    attributes.push(nameDetail)

    const identifierDetail = new DetailEntry(
        'Identifier',
        entity.identifier,
        'identifier',
        BaseDetailType.text,
        null,
        EditableType.always,
        putViewFormFieldOptions.identifier
    )
    attributes.push(identifierDetail)

    const typeDetail = new DetailEntry(
        'Type',
        entity.type,
        'type',
        BaseDetailType.toggleGroup,
        null,
        EditableType.always,
        putViewFormFieldOptions.type
    )
    attributes.push(typeDetail)


    const viewGrantsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'viewGrant');
    viewGrantsCore.soGet = SOGetViewGrant.construct({
      view: new SOGetView(entity.uid),
    });
    heading = getHeading(entityViewSpec.entityCore, viewGrantsCore)
    viewGrantsCore.title = heading ? heading : viewGrantsCore.title
    viewGrantsCore.listTitle = 'View grants'

    viewGrantsCore.configureForDisplay(entityViewSpec)

    const viewGrantsDetail = new DetailEntry(
      heading ? heading : viewGrantsCore.listTitle,
      null,
      'viewGrants',
      BaseDetailType.embeddedList,
      viewGrantsCore,
      EditableType.never
    )
    attributes.push(viewGrantsDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
