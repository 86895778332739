import {SOPostOrganizationInvite} from '../../core/organization-invite/so-post-organization-invite';
import {OrganizationInviteCore} from './organization-invite.core';
import {SOGetOrganizationInvite} from '../../core/organization-invite/so-get-organization-invite';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postOrganizationInviteFormFieldOptions} from './post-organization-invite-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {RoleCore} from '../bv-role/role.core';
import {SOGetRole} from '../../core/role/so-get-role';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostOrganizationInvite extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postOrganizationInviteFormFieldOptions);

  RequestClass = SOPostOrganizationInvite

  constructor(
    public entityCore: OrganizationInviteCore
  ) {
    super();
    if (postOrganizationInviteFormFieldOptions.role) {
      postOrganizationInviteFormFieldOptions.role.makeCore = true;
    }
    if (postOrganizationInviteFormFieldOptions.organization) {
      postOrganizationInviteFormFieldOptions.organization.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('email'),
      this.getFormValueFromIdentifier(
        'role',
        SOGetRole,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}