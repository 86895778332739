import { SOGetApp } from '../app/so-get-app';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostCommit {

  constructor(
    public description: string,
    public app: SOGetApp,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostCommit(
      'description' in params ? params.description : null,
      'app' in params ? params.app : null,
      'organization' in params ? params.organization : null,
    );
  }
}
