export enum ENWidgetType {
  entity = 'entity',
}


export enum ENRequestType {
  get = 'get',
  post = 'post',
  put = 'put',
  delete = 'delete',
}


export enum ENResponseType {
  objective = 'objective',
}


export enum ENStoreType {
  persistent = 'persistent',
}


export enum ENCodableType {
  enumeral = 'enumeral',
  codable = 'codable',
}


export enum ENAttributeLocation {
  path = 'path',
  header = 'header',
  body = 'body',
  query = 'query',
}


export enum ENAttributeSpecType {
  storage = 'storage',
  http = 'http',
  gui = 'gui',
}


export enum ENAttributeType {
  simpleAttribute = 'simpleAttribute',
  relationship = 'relationship',
}


export enum ENSimpleAttributeType {
  string = 'string',
  int = 'int',
}


export enum ENMultiplicity {
  zeroToOne = 'zeroToOne',
  zeroToMany = 'zeroToMany',
  one = 'one',
  oneToMany = 'oneToMany',
}


export enum ENMethodType {
  get = 'get',
  post = 'post',
  put = 'put',
  delete = 'delete',
}


export enum ENBaseType {
  entity = 'entity',
  widget = 'widget',
  app = 'app',
}


export enum ENTargetType {
  serverPython = 'serverPython',
  clientTypeScriptAngular = 'clientTypeScriptAngular',
  clientSwift = 'clientSwift',
}


export enum ENMediaStatus {
  pending = 'pending',
  uploaded = 'uploaded',
}


export enum ENMediaTypePrimary {
  img = 'img',
  video = 'video',
  pdf = 'pdf',
}


export enum ENMediaTypeSecondary {
  jpeg = 'jpeg',
  png = 'png',
}


export enum ENInviteStatus {
  sent = 'sent',
  accepted = 'accepted',
  denied = 'denied',
  ignored = 'ignored',
}


export enum ENSubscriptionStatus {
  active = 'active',
  trialing = 'trialing',
  canceled = 'canceled',
  pastDue = 'pastDue',
  unpaid = 'unpaid',
}


export enum ENInterval {
  month = 'month',
  year = 'year',
  eon = 'eon',
}


export enum ENPaymentIntentStatus {
  complete = 'complete',
}


export enum ENRoleGrantStatus {
  active = 'active',
  inActive = 'inActive',
  deleted = 'deleted',
}


export enum ENTokenStatus {
  available = 'available',
  deleted = 'deleted',
}


export enum ENSesMessageType {
  bounce = 'bounce',
  complaint = 'complaint',
  delivery = 'delivery',
}


export enum ENDuration {
  month = 'month',
  eon = 'eon',
}


export enum ENViewType {
  primary = 'primary',
  secondary = 'secondary',
}


