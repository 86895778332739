import { Type } from 'class-transformer';
import { SORespUser } from '../user/so-resp-user';
import { MMCredential } from 'ngx-lionheart-material';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespCertificate implements ObjectiveProtocol {

  entityType: string = 'certificate';
  uid: string;
  @Type(() => SORespUser)
  user: SORespUser;
  @Type(() => Date)
  dateCreated: Date;
  credential: MMCredential = null;
}