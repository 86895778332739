import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespRole implements ObjectiveProtocol {

  entityType: string = 'role';
  uid: string;
  name: string;
  identifier: string;
}