import { Type } from 'class-transformer';
import { SORespEntity } from '../entity/so-resp-entity';
import { SORespWidget } from '../widget/so-resp-widget';
import { SORespCommit } from '../commit/so-resp-commit';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespEntityWidgetAssociation implements ObjectiveProtocol {

  entityType: string = 'entityWidgetAssociation';
  uid: string;
  @Type(() => SORespEntity)
  entity: SORespEntity;
  @Type(() => SORespWidget)
  widget: SORespWidget;
  @Type(() => SORespCommit)
  commit: SORespCommit;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
}