import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';
import {ENInterval} from '../../enums';


const nameField = new FormField(
  'name',
  'Name',
  null,
  FormFieldType.text,
  true,
)
const skuField = new FormField(
  'sku',
  'Sku',
  null,
  FormFieldType.text,
  true,
)
const trailDaysField = new FormField(
  'trailDays',
  'Trail days',
  null,
  FormFieldType.text,
  true,
)
const exRefField = new FormField(
  'exRef',
  'Ex ref',
  null,
  FormFieldType.text,
  false,
)
const amountField = new FormField(
  'amount',
  'Amount',
  null,
  FormFieldType.text,
  true,
)
const intervalField = new FormField(
  'interval',
  'Interval',
  null,
  FormFieldType.enumeration,
  true,
)
intervalField.enumeration = ENInterval
const activeField = new FormField(
  'active',
  'Active',
  null,
  FormFieldType.boolean,
  true,
  true,
  true
)
const productField = new FormField(
  'product',
  'Product',
  'product',
  FormFieldType.pushedSelectionList,
  true,
)


export const postPriceFormFieldOptions: {[key: string]: FormField} = {
  name: nameField,
  sku: skuField,
  trailDays: trailDaysField,
  exRef: exRefField,
  amount: amountField,
  interval: intervalField,
  active: activeField,
  product: productField,
};