import {SOPutResponseResponseAttributeSpecAssociation} from '../../core/response-response-attribute-spec-association/so-put-response-response-attribute-spec-association';
import {ResponseResponseAttributeSpecAssociationCore} from './response-response-attribute-spec-association.core';
import {SOGetResponseResponseAttributeSpecAssociation} from '../../core/response-response-attribute-spec-association/so-get-response-response-attribute-spec-association';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putResponseResponseAttributeSpecAssociationFormFieldOptions} from './put-response-response-attribute-spec-association-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {ResponseCore} from '../bv-response/response.core';
import {SOGetResponse} from '../../core/response/so-get-response';
import {ResponseAttributeSpecCore} from '../bv-response-attribute-spec/response-attribute-spec.core';
import {SOGetResponseAttributeSpec} from '../../core/response-attribute-spec/so-get-response-attribute-spec';

export class RAPutResponseResponseAttributeSpecAssociation extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putResponseResponseAttributeSpecAssociationFormFieldOptions);

  RequestClass = SOPutResponseResponseAttributeSpecAssociation

  constructor(
    public entityCore: ResponseResponseAttributeSpecAssociationCore
  ) {
    super();
    if (putResponseResponseAttributeSpecAssociationFormFieldOptions.response) {
      putResponseResponseAttributeSpecAssociationFormFieldOptions.response.makeCore = true;
    }
    if (putResponseResponseAttributeSpecAssociationFormFieldOptions.responseAttributeSpec) {
      putResponseResponseAttributeSpecAssociationFormFieldOptions.responseAttributeSpec.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier(
        'response',
        SOGetResponse,
      ),
      this.getFormValueFromIdentifier(
        'responseAttributeSpec',
        SOGetResponseAttributeSpec,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}