import { SOGetRelationship } from '../relationship/so-get-relationship';
import { SOGetWidgetRelationshipSpec } from '../widget-relationship-spec/so-get-widget-relationship-spec';
import { SOGetCommit } from '../commit/so-get-commit';


export class SOPutRelationshipWidgetRelationshipSpecAssociation {

  constructor(
    public uid: string,
    public relationship: SOGetRelationship = null,
    public widget: SOGetWidgetRelationshipSpec = null,
    public commit: SOGetCommit = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutRelationshipWidgetRelationshipSpecAssociation(
      'uid' in params ? params.uid : null,
      'relationship' in params ? params.relationship : null,
      'widget' in params ? params.widget : null,
      'commit' in params ? params.commit : null,
    );
  }
}
