import { SOGetWidget } from '../widget/so-get-widget';
import { SOGetWidgetAttributeSpec } from '../widget-attribute-spec/so-get-widget-attribute-spec';


export class SOPutWidgetWidgetAttributeSpecAssociation {

  constructor(
    public uid: string,
    public widget: SOGetWidget = null,
    public widgetAttributeSpec: SOGetWidgetAttributeSpec = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutWidgetWidgetAttributeSpecAssociation(
      'uid' in params ? params.uid : null,
      'widget' in params ? params.widget : null,
      'widgetAttributeSpec' in params ? params.widgetAttributeSpec : null,
    );
  }
}
