import { SOGetRequestRequestRelationshipSpecAssociation } from '../request-request-relationship-spec-association/so-get-request-request-relationship-spec-association';
import { SOGetRelationshipRequestRelationshipSpecAssociation } from '../relationship-request-relationship-spec-association/so-get-relationship-request-relationship-spec-association';


export class SOPutRequestRelationshipSpec {

  constructor(
    public uid: string,
    public isRequired: boolean = null,
    public request: SOGetRequestRequestRelationshipSpecAssociation[] = null,
    public relationship: SOGetRelationshipRequestRelationshipSpecAssociation[] = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutRequestRelationshipSpec(
      'uid' in params ? params.uid : null,
      'isRequired' in params ? params.isRequired : null,
      'request' in params ? params.request : null,
      'relationship' in params ? params.relationship : null,
    );
  }
}
