import { SOGetEntity } from '../entity/so-get-entity';
import { SOGetRelationship } from '../relationship/so-get-relationship';
import { SOGetCommit } from '../commit/so-get-commit';


export class SOPutEntityRelationshipAssociation {

  constructor(
    public uid: string,
    public entity: SOGetEntity = null,
    public relationship: SOGetRelationship = null,
    public commit: SOGetCommit = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutEntityRelationshipAssociation(
      'uid' in params ? params.uid : null,
      'entity' in params ? params.entity : null,
      'relationship' in params ? params.relationship : null,
      'commit' in params ? params.commit : null,
    );
  }
}
