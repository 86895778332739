import {SORespRequestRelationshipSpec} from '../../core/request-relationship-spec/so-resp-request-relationship-spec';
import {RequestRelationshipSpecCore} from './request-relationship-spec.core';
import { ListEntry, ListEntryFactory, ListEntryFactoryProtocol} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';


export class RequestRelationshipSpecListEntryFactory  extends ListEntryFactory implements ListEntryFactoryProtocol {

  makeListEntry(parentCoreViewSpec, entityCore: EntityCoreProtocol, entity: SORespRequestRelationshipSpec, section: number = 0): ListEntry {
    const resp = super.makeListEntry(parentCoreViewSpec, entityCore, entity, section)
    return resp
  }
}
