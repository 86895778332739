import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EORelationshipWidgetRelationshipSpecAssociationService} from '../../core/relationship-widget-relationship-spec-association/eo-relationship-widget-relationship-spec-association';
import {RelationshipWidgetRelationshipSpecAssociationListEntryFactory} from './relationship-widget-relationship-spec-association-list-entry-factory';
import {RelationshipWidgetRelationshipSpecAssociationDetailFactory} from './relationship-widget-relationship-spec-association-detail-factory';
import {SOGetRelationshipWidgetRelationshipSpecAssociation} from '../../core/relationship-widget-relationship-spec-association/so-get-relationship-widget-relationship-spec-association';
import {RAPutRelationshipWidgetRelationshipSpecAssociation} from './ra-put-relationship-widget-relationship-spec-association';
import {RAPostRelationshipWidgetRelationshipSpecAssociation} from './ra-post-relationship-widget-relationship-spec-association';
import {RADeleteRelationshipWidgetRelationshipSpecAssociation} from './ra-delete-relationship-widget-relationship-spec-association';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class RelationshipWidgetRelationshipSpecAssociationCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'relationshipWidgetRelationshipSpecAssociation';
  title = 'Relationship widget relationship spec association';
  listEntryFactory = new RelationshipWidgetRelationshipSpecAssociationListEntryFactory();
  detailFactory = new RelationshipWidgetRelationshipSpecAssociationDetailFactory();

  entityService: EORelationshipWidgetRelationshipSpecAssociationService;
  soGetClass = SOGetRelationshipWidgetRelationshipSpecAssociation;
  soGet: SOGetRelationshipWidgetRelationshipSpecAssociation;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EORelationshipWidgetRelationshipSpecAssociationService
    );
    this.adapters = [
      new RAPostRelationshipWidgetRelationshipSpecAssociation(this),
      new RAPutRelationshipWidgetRelationshipSpecAssociation(this),
      new RADeleteRelationshipWidgetRelationshipSpecAssociation(this),
    ];
    this.resetSoGet();
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetRelationshipWidgetRelationshipSpecAssociation();
    this.soGet.commit = new SOGetCommit(
      this.activeUserService.getScope('commit').uid
    );
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
