import { SOGetProduct } from '../product/so-get-product';
import {MMQueryOptions} from 'ngx-lionheart-material';
import {QueryProtocol} from 'ngx-lionheart-material';


export class SOGetPrice implements QueryProtocol {

  constructor(
    public uid: string = null,
    public sku: string = null,
    public exRef: string = null,
    public product: SOGetProduct = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetPrice(
      'uid' in params ? params.uid : null,
      'sku' in params ? params.sku : null,
      'exRef' in params ? params.exRef : null,
      'product' in params ? params.product : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
