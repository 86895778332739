import {SORespRelationshipResponseRelationshipSpecAssociation} from '../../core/relationship-response-relationship-spec-association/so-resp-relationship-response-relationship-spec-association';
import {RelationshipResponseRelationshipSpecAssociationCore} from './relationship-response-relationship-spec-association.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putRelationshipResponseRelationshipSpecAssociationFormFieldOptions} from './put-relationship-response-relationship-spec-association-form-fields';
import {SOGetRelationshipResponseRelationshipSpecAssociation} from '../../core/relationship-response-relationship-spec-association/so-get-relationship-response-relationship-spec-association';
import {SOGetRelationship} from '../../core/relationship/so-get-relationship';
import {SOGetResponseRelationshipSpec} from '../../core/response-relationship-spec/so-get-response-relationship-spec';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetApp} from '../../core/app/so-get-app';


export class RelationshipResponseRelationshipSpecAssociationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespRelationshipResponseRelationshipSpecAssociation = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = [];


    const relationshipCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'relationship');
    relationshipCore.soGet = SOGetRelationship.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, relationshipCore);
    relationshipCore.title = heading ? heading : relationshipCore.title;
    relationshipCore.listTitle = 'Relationship';

    if (entity.relationship) {
        displayStringFactory.reconfigure(entity.relationship, entityViewSpec.entityCore.type);
    }
    relationshipCore.configureForDisplay(entityViewSpec);

    const relationshipDetail = new DetailEntry(
      heading ? heading : relationshipCore.listTitle,
      entity.relationship ? displayStringFactory.primaryString : null,
      'relationship',
      BaseDetailType.pushedDetail,
      relationshipCore,
      EditableType.always,
      putRelationshipResponseRelationshipSpecAssociationFormFieldOptions.relationship
    );
    if (entity.relationship) {
      relationshipDetail.cachedListEntry = relationshipCore.makeListEntry(entityViewSpec, relationshipCore, entity.relationship, 0);
    }
    relationshipDetail.singleFieldSelect = true;
    attributes.push(relationshipDetail);

    const responseCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'responseRelationshipSpec');
    responseCore.soGet = SOGetResponseRelationshipSpec.construct({
      relationship: new SOGetRelationshipResponseRelationshipSpecAssociation(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, responseCore);
    responseCore.title = heading ? heading : responseCore.title;
    responseCore.listTitle = 'Response';

    if (entity.response) {
        displayStringFactory.reconfigure(entity.response, entityViewSpec.entityCore.type);
    }
    responseCore.configureForDisplay(entityViewSpec);

    const responseDetail = new DetailEntry(
      heading ? heading : responseCore.listTitle,
      entity.response ? displayStringFactory.primaryString : null,
      'response',
      BaseDetailType.pushedDetail,
      responseCore,
      EditableType.always,
      putRelationshipResponseRelationshipSpecAssociationFormFieldOptions.response
    );
    if (entity.response) {
      responseDetail.cachedListEntry = responseCore.makeListEntry(entityViewSpec, responseCore, entity.response, 0);
    }
    responseDetail.singleFieldSelect = true;
    attributes.push(responseDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
