import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostInvoiceLine } from './so-post-invoice-line';
import { SOGetInvoiceLine } from './so-get-invoice-line';
import { SOPutInvoiceLine } from './so-put-invoice-line';
import { SODeleteInvoiceLine } from './so-delete-invoice-line';
import {SORespInvoiceLine} from './so-resp-invoice-line';
import {SOPluralInvoiceLine} from './so-plural-invoice-line';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOInvoiceLineService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'invoice_line',
      entityCore
    );
  }

  /** GET InvoiceLine by id. Will 404 if id not found */
  get(request: SOGetInvoiceLine): Observable<SOPluralInvoiceLine> {
    return this.httpClient.get<SOPluralInvoiceLine>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralInvoiceLine, resp) }),
      tap(_ => this.log(`fetched InvoiceLine`)),
      catchError(this.handleError<SOPluralInvoiceLine>(`get InvoiceLine`))
    );
  }

  /** POST: add a new InvoiceLine to the server */
  post(request: SOPostInvoiceLine): Observable<SORespInvoiceLine> {
    return this.httpClient.post<SORespInvoiceLine>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespInvoiceLine, resp) }),
      tap((response: SORespInvoiceLine) => this.log(`added InvoiceLine w/ id=${response.uid}`)),
      catchError(this.handleError<SORespInvoiceLine>('InvoiceLine post'))
    );
  }

  /** PUT: update InvoiceLine  */
  put(request: SOPutInvoiceLine): Observable<SORespInvoiceLine> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespInvoiceLine>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespInvoiceLine, resp) }),
      tap((response: SORespInvoiceLine) => this.log(`edited InvoiceLine w/ uid=${uid}`)),
      catchError(this.handleError<SORespInvoiceLine>('InvoiceLine put'))
    );
  }

  /** DELETE: delete the InvoiceLine from the server */
  delete(request: SODeleteInvoiceLine): Observable<SORespInvoiceLine> {
    return this.httpClient.delete<SORespInvoiceLine>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted InvoiceLine uid=${request.uid}`)),
      catchError(this.handleError<SORespInvoiceLine>('delete InvoiceLine'))
    );
  }
}
