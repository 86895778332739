import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOEntityAttributeAssociationService} from '../../core/entity-attribute-association/eo-entity-attribute-association';
import {EntityAttributeAssociationListEntryFactory} from './entity-attribute-association-list-entry-factory';
import {EntityAttributeAssociationDetailFactory} from './entity-attribute-association-detail-factory';
import {SOGetEntityAttributeAssociation} from '../../core/entity-attribute-association/so-get-entity-attribute-association';
import {RAPutEntityAttributeAssociation} from './ra-put-entity-attribute-association';
import {RAPostEntityAttributeAssociation} from './ra-post-entity-attribute-association';
import {RADeleteEntityAttributeAssociation} from './ra-delete-entity-attribute-association';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetCommit} from '../../core/commit/so-get-commit'
import {SOGetOrganization} from '../../core/organization/so-get-organization'


export class EntityAttributeAssociationCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'entityAttributeAssociation';
  title = 'Entity attribute association';
  listEntryFactory = new EntityAttributeAssociationListEntryFactory();
  detailFactory = new EntityAttributeAssociationDetailFactory();

  entityService: EOEntityAttributeAssociationService;
  soGetClass = SOGetEntityAttributeAssociation;
  soGet: SOGetEntityAttributeAssociation;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOEntityAttributeAssociationService
    );
    this.adapters = [
      new RAPostEntityAttributeAssociation(this),
      new RAPutEntityAttributeAssociation(this),
      new RADeleteEntityAttributeAssociation(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetEntityAttributeAssociation();
    this.soGet.commit = new SOGetCommit(
      this.activeUserService.getScope('commit').uid
    );
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
