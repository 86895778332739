import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const descriptionField = new FormField(
  'description',
  'Description',
  null,
  FormFieldType.text,
  true,
)


export const postCommitFormFieldOptions: {[key: string]: FormField} = {
  description: descriptionField,
};