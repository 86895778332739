import {SOPutInvoice} from '../../core/invoice/so-put-invoice';
import {InvoiceCore} from './invoice.core';
import {SOGetInvoice} from '../../core/invoice/so-get-invoice';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putInvoiceFormFieldOptions} from './put-invoice-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {SubscriptionCore} from '../bv-subscription/subscription.core';
import {SOGetSubscription} from '../../core/subscription/so-get-subscription';

export class RAPutInvoice extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putInvoiceFormFieldOptions);

  RequestClass = SOPutInvoice

  constructor(
    public entityCore: InvoiceCore
  ) {
    super();
    if (putInvoiceFormFieldOptions.subscription) {
      putInvoiceFormFieldOptions.subscription.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('ref'),
      this.getFormValueFromIdentifier('exRef'),
      this.getFormValueFromIdentifier('amountDue'),
      this.getFormValueFromIdentifier('amountPaid'),
      this.getFormValueFromIdentifier('amountRemaining'),
      this.getFormValueFromIdentifier('paid'),
      this.getFormValueFromIdentifier('dateDue'),
      this.getFormValueFromIdentifier('periodStart'),
      this.getFormValueFromIdentifier('periodEnd'),
      this.getFormValueFromIdentifier(
        'subscription',
        SOGetSubscription,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}