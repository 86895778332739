import {SbNavItem} from 'ngx-lionheart-material';

export const navItems = [
  new SbNavItem('app', 'app', '/app', 'View your apps', false),
  new SbNavItem('media', 'media', '/media', 'View your medias', false),
  new SbNavItem('organization', 'organization', '/organization', 'View your organizations', false),
  new SbNavItem('product', 'product', '/product', 'View your products', false),
  new SbNavItem('constraint', 'constraint', '/constraint', 'View your constraints', false),
  new SbNavItem('appliedConstraint', 'appliedConstraint', '/applied_constraint', 'View your applied_constraints', false),
  new SbNavItem('role', 'role', '/role', 'View your roles', false),
  new SbNavItem('view', 'view', '/view', 'View your views', false),
  new SbNavItem('sesMessage', 'sesMessage', '/ses_message', 'View your ses_messages', false),
];
