import {SORespResponseRelationshipSpec} from '../../core/response-relationship-spec/so-resp-response-relationship-spec';
import {ResponseRelationshipSpecCore} from './response-relationship-spec.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putResponseRelationshipSpecFormFieldOptions} from './put-response-relationship-spec-form-fields';
import {SOGetResponseRelationshipSpec} from '../../core/response-relationship-spec/so-get-response-relationship-spec';
import {SOGetResponseResponseRelationshipSpecAssociation} from '../../core/response-response-relationship-spec-association/so-get-response-response-relationship-spec-association';
import {SOGetRelationshipResponseRelationshipSpecAssociation} from '../../core/relationship-response-relationship-spec-association/so-get-relationship-response-relationship-spec-association';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class ResponseRelationshipSpecDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespResponseRelationshipSpec = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const isRequiredDetail = new DetailEntry(
        'Is required',
        entity.isRequired,
        'isRequired',
        BaseDetailType.toggle,
        null,
        EditableType.always,
        putResponseRelationshipSpecFormFieldOptions.isRequired
    )
    attributes.push(isRequiredDetail)


    const responseCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'responseResponseRelationshipSpecAssociation');
    responseCore.soGet = SOGetResponseResponseRelationshipSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, responseCore)
    responseCore.title = heading ? heading : responseCore.title
    responseCore.listTitle = 'Response'

    responseCore.configureForDisplay(entityViewSpec)

    const responseDetail = new DetailEntry(
      heading ? heading : responseCore.listTitle,
      null,
      'response',
      BaseDetailType.embeddedList,
      responseCore,
      EditableType.always,
      putResponseRelationshipSpecFormFieldOptions.response
    )
    attributes.push(responseDetail);

    const relationshipCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'relationshipResponseRelationshipSpecAssociation');
    relationshipCore.soGet = SOGetRelationshipResponseRelationshipSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, relationshipCore)
    relationshipCore.title = heading ? heading : relationshipCore.title
    relationshipCore.listTitle = 'Relationship'

    relationshipCore.configureForDisplay(entityViewSpec)

    const relationshipDetail = new DetailEntry(
      heading ? heading : relationshipCore.listTitle,
      null,
      'relationship',
      BaseDetailType.embeddedList,
      relationshipCore,
      EditableType.always,
      putResponseRelationshipSpecFormFieldOptions.relationship
    )
    attributes.push(relationshipDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
