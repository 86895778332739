import {SORespRelationshipWidgetRelationshipSpecAssociation} from '../../core/relationship-widget-relationship-spec-association/so-resp-relationship-widget-relationship-spec-association';
import {RelationshipWidgetRelationshipSpecAssociationCore} from './relationship-widget-relationship-spec-association.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putRelationshipWidgetRelationshipSpecAssociationFormFieldOptions} from './put-relationship-widget-relationship-spec-association-form-fields';
import {SOGetRelationshipWidgetRelationshipSpecAssociation} from '../../core/relationship-widget-relationship-spec-association/so-get-relationship-widget-relationship-spec-association';
import {SOGetRelationship} from '../../core/relationship/so-get-relationship';
import {SOGetWidgetRelationshipSpec} from '../../core/widget-relationship-spec/so-get-widget-relationship-spec';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetApp} from '../../core/app/so-get-app';


export class RelationshipWidgetRelationshipSpecAssociationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespRelationshipWidgetRelationshipSpecAssociation = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = [];


    const relationshipCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'relationship');
    relationshipCore.soGet = SOGetRelationship.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, relationshipCore);
    relationshipCore.title = heading ? heading : relationshipCore.title;
    relationshipCore.listTitle = 'Relationship';

    if (entity.relationship) {
        displayStringFactory.reconfigure(entity.relationship, entityViewSpec.entityCore.type);
    }
    relationshipCore.configureForDisplay(entityViewSpec);

    const relationshipDetail = new DetailEntry(
      heading ? heading : relationshipCore.listTitle,
      entity.relationship ? displayStringFactory.primaryString : null,
      'relationship',
      BaseDetailType.pushedDetail,
      relationshipCore,
      EditableType.always,
      putRelationshipWidgetRelationshipSpecAssociationFormFieldOptions.relationship
    );
    if (entity.relationship) {
      relationshipDetail.cachedListEntry = relationshipCore.makeListEntry(entityViewSpec, relationshipCore, entity.relationship, 0);
    }
    relationshipDetail.singleFieldSelect = true;
    attributes.push(relationshipDetail);

    const widgetCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'widgetRelationshipSpec');
    widgetCore.soGet = SOGetWidgetRelationshipSpec.construct({
      relationship: new SOGetRelationshipWidgetRelationshipSpecAssociation(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, widgetCore);
    widgetCore.title = heading ? heading : widgetCore.title;
    widgetCore.listTitle = 'Widget';

    if (entity.widget) {
        displayStringFactory.reconfigure(entity.widget, entityViewSpec.entityCore.type);
    }
    widgetCore.configureForDisplay(entityViewSpec);

    const widgetDetail = new DetailEntry(
      heading ? heading : widgetCore.listTitle,
      entity.widget ? displayStringFactory.primaryString : null,
      'widget',
      BaseDetailType.pushedDetail,
      widgetCore,
      EditableType.always,
      putRelationshipWidgetRelationshipSpecAssociationFormFieldOptions.widget
    );
    if (entity.widget) {
      widgetDetail.cachedListEntry = widgetCore.makeListEntry(entityViewSpec, widgetCore, entity.widget, 0);
    }
    widgetDetail.singleFieldSelect = true;
    attributes.push(widgetDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
