import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const commitField = new FormField(
  'commit',
  'Commit',
  'commit',
  FormFieldType.pushedSelectionList,
  true,
)
const entityField = new FormField(
  'entity',
  'Entity',
  'relationshipLink',
  FormFieldType.pushedSelectionList,
  true,
)


export const postCommitRelationshipLinkAssociationFormFieldOptions: {[key: string]: FormField} = {
  commit: commitField,
  entity: entityField,
};