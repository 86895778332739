import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const attributeField = new FormField(
  'attribute',
  'Attribute',
  'attribute',
  FormFieldType.pushedSelectionList,
  false,
)
const responseField = new FormField(
  'response',
  'Response',
  'responseAttributeSpec',
  FormFieldType.pushedSelectionList,
  false,
)


export const putAttributeResponseAttributeSpecAssociationFormFieldOptions: {[key: string]: FormField} = {
  attribute: attributeField,
  response: responseField,
};