import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ALL_ROUTES, AuthGuard, SvNavigatorComponent } from 'ngx-lionheart-material';

import {AppCore} from './entities/bv-app/app.core';
import {BranchCore} from './entities/bv-branch/branch.core';
import {BranchCommitAssociationCore} from './entities/bv-branch-commit-association/branch-commit-association.core';
import {CommitCore} from './entities/bv-commit/commit.core';
import {CommitEntityAssociationCore} from './entities/bv-commit-entity-association/commit-entity-association.core';
import {CommitRelationshipLinkAssociationCore} from './entities/bv-commit-relationship-link-association/commit-relationship-link-association.core';
import {CommitCodableAssociationCore} from './entities/bv-commit-codable-association/commit-codable-association.core';
import {EntityCore} from './entities/bv-entity/entity.core';
import {EntityChildrenAssociationCore} from './entities/bv-entity-children-association/entity-children-association.core';
import {RelationshipLinkCore} from './entities/bv-relationship-link/relationship-link.core';
import {CodableCore} from './entities/bv-codable/codable.core';
import {EnumeratorCore} from './entities/bv-enumerator/enumerator.core';
import {EnumeratorEnumeralAssociationCore} from './entities/bv-enumerator-enumeral-association/enumerator-enumeral-association.core';
import {EnumeralCore} from './entities/bv-enumeral/enumeral.core';
import {EntityAttributeAssociationCore} from './entities/bv-entity-attribute-association/entity-attribute-association.core';
import {EntityRelationshipAssociationCore} from './entities/bv-entity-relationship-association/entity-relationship-association.core';
import {AttributeCore} from './entities/bv-attribute/attribute.core';
import {AttributeCodableAssociationCore} from './entities/bv-attribute-codable-association/attribute-codable-association.core';
import {AttributeWidgetAttributeSpecAssociationCore} from './entities/bv-attribute-widget-attribute-spec-association/attribute-widget-attribute-spec-association.core';
import {AttributeRequestAttributeSpecAssociationCore} from './entities/bv-attribute-request-attribute-spec-association/attribute-request-attribute-spec-association.core';
import {AttributeStoreAttributeSpecAssociationCore} from './entities/bv-attribute-store-attribute-spec-association/attribute-store-attribute-spec-association.core';
import {AttributeResponseAttributeSpecAssociationCore} from './entities/bv-attribute-response-attribute-spec-association/attribute-response-attribute-spec-association.core';
import {RelationshipCore} from './entities/bv-relationship/relationship.core';
import {RelationshipWidgetRelationshipSpecAssociationCore} from './entities/bv-relationship-widget-relationship-spec-association/relationship-widget-relationship-spec-association.core';
import {RelationshipRequestRelationshipSpecAssociationCore} from './entities/bv-relationship-request-relationship-spec-association/relationship-request-relationship-spec-association.core';
import {RelationshipStoreRelationshipSpecAssociationCore} from './entities/bv-relationship-store-relationship-spec-association/relationship-store-relationship-spec-association.core';
import {RelationshipResponseRelationshipSpecAssociationCore} from './entities/bv-relationship-response-relationship-spec-association/relationship-response-relationship-spec-association.core';
import {EntityWidgetAssociationCore} from './entities/bv-entity-widget-association/entity-widget-association.core';
import {WidgetCore} from './entities/bv-widget/widget.core';
import {WidgetWidgetAttributeSpecAssociationCore} from './entities/bv-widget-widget-attribute-spec-association/widget-widget-attribute-spec-association.core';
import {WidgetWidgetRelationshipSpecAssociationCore} from './entities/bv-widget-widget-relationship-spec-association/widget-widget-relationship-spec-association.core';
import {WidgetAttributeSpecCore} from './entities/bv-widget-attribute-spec/widget-attribute-spec.core';
import {WidgetRelationshipSpecCore} from './entities/bv-widget-relationship-spec/widget-relationship-spec.core';
import {EntityRequestAssociationCore} from './entities/bv-entity-request-association/entity-request-association.core';
import {RequestCore} from './entities/bv-request/request.core';
import {RequestRequestAttributeSpecAssociationCore} from './entities/bv-request-request-attribute-spec-association/request-request-attribute-spec-association.core';
import {RequestRequestRelationshipSpecAssociationCore} from './entities/bv-request-request-relationship-spec-association/request-request-relationship-spec-association.core';
import {RequestAttributeSpecCore} from './entities/bv-request-attribute-spec/request-attribute-spec.core';
import {RequestRelationshipSpecCore} from './entities/bv-request-relationship-spec/request-relationship-spec.core';
import {EntityResponseAssociationCore} from './entities/bv-entity-response-association/entity-response-association.core';
import {ResponseCore} from './entities/bv-response/response.core';
import {ResponseResponseAttributeSpecAssociationCore} from './entities/bv-response-response-attribute-spec-association/response-response-attribute-spec-association.core';
import {ResponseResponseRelationshipSpecAssociationCore} from './entities/bv-response-response-relationship-spec-association/response-response-relationship-spec-association.core';
import {ResponseAttributeSpecCore} from './entities/bv-response-attribute-spec/response-attribute-spec.core';
import {ResponseRelationshipSpecCore} from './entities/bv-response-relationship-spec/response-relationship-spec.core';
import {EntityStoreAssociationCore} from './entities/bv-entity-store-association/entity-store-association.core';
import {StoreCore} from './entities/bv-store/store.core';
import {StoreStoreAttributeSpecAssociationCore} from './entities/bv-store-store-attribute-spec-association/store-store-attribute-spec-association.core';
import {StoreStoreRelationshipSpecAssociationCore} from './entities/bv-store-store-relationship-spec-association/store-store-relationship-spec-association.core';
import {StoreAttributeSpecCore} from './entities/bv-store-attribute-spec/store-attribute-spec.core';
import {StoreRelationshipSpecCore} from './entities/bv-store-relationship-spec/store-relationship-spec.core';
import {MediaCore} from './entities/bv-media/media.core';
import {AttachmentCore} from './entities/bv-attachment/attachment.core';
import {OrganizationCore} from './entities/bv-organization/organization.core';
import {ProductCore} from './entities/bv-product/product.core';
import {PriceCore} from './entities/bv-price/price.core';
import {InvoiceCore} from './entities/bv-invoice/invoice.core';
import {InvoiceLineCore} from './entities/bv-invoice-line/invoice-line.core';
import {PaymentIntentCore} from './entities/bv-payment-intent/payment-intent.core';
import {SubscriptionCore} from './entities/bv-subscription/subscription.core';
import {ConstraintCore} from './entities/bv-constraint/constraint.core';
import {AppliedConstraintCore} from './entities/bv-applied-constraint/applied-constraint.core';
import {UserCore} from './entities/bv-user/user.core';
import {OrganizationInviteCore} from './entities/bv-organization-invite/organization-invite.core';
import {RoleGrantCore} from './entities/bv-role-grant/role-grant.core';
import {CertificateCore} from './entities/bv-certificate/certificate.core';
import {IdentityCore} from './entities/bv-identity/identity.core';
import {TokenCore} from './entities/bv-token/token.core';
import {RoleCore} from './entities/bv-role/role.core';
import {ViewCore} from './entities/bv-view/view.core';
import {ViewGrantCore} from './entities/bv-view-grant/view-grant.core';
import {HelloCore} from './entities/bv-hello/hello.core';
import {SesMessageCore} from './entities/bv-ses-message/ses-message.core';
import {IngestCore} from './entities/bv-ingest/ingest.core';

ALL_ROUTES.push(
  { path: 'app', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AppCore, identifier: 'app'} },
  { path: 'app/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AppCore, identifier: 'app'} },
  { path: 'branch', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: BranchCore, identifier: 'branch'} },
  { path: 'branch/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: BranchCore, identifier: 'branch'} },
  { path: 'branch_commit_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: BranchCommitAssociationCore, identifier: 'branchCommitAssociation'} },
  { path: 'branch_commit_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: BranchCommitAssociationCore, identifier: 'branchCommitAssociation'} },
  { path: 'commit', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: CommitCore, identifier: 'commit'} },
  { path: 'commit/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: CommitCore, identifier: 'commit'} },
  { path: 'commit_entity_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: CommitEntityAssociationCore, identifier: 'commitEntityAssociation'} },
  { path: 'commit_entity_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: CommitEntityAssociationCore, identifier: 'commitEntityAssociation'} },
  { path: 'commit_relationship_link_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: CommitRelationshipLinkAssociationCore, identifier: 'commitRelationshipLinkAssociation'} },
  { path: 'commit_relationship_link_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: CommitRelationshipLinkAssociationCore, identifier: 'commitRelationshipLinkAssociation'} },
  { path: 'commit_codable_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: CommitCodableAssociationCore, identifier: 'commitCodableAssociation'} },
  { path: 'commit_codable_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: CommitCodableAssociationCore, identifier: 'commitCodableAssociation'} },
  { path: 'entity', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: EntityCore, identifier: 'entity'} },
  { path: 'entity/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: EntityCore, identifier: 'entity'} },
  { path: 'entity_children_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: EntityChildrenAssociationCore, identifier: 'entityChildrenAssociation'} },
  { path: 'entity_children_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: EntityChildrenAssociationCore, identifier: 'entityChildrenAssociation'} },
  { path: 'relationship_link', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RelationshipLinkCore, identifier: 'relationshipLink'} },
  { path: 'relationship_link/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RelationshipLinkCore, identifier: 'relationshipLink'} },
  { path: 'codable', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: CodableCore, identifier: 'codable'} },
  { path: 'codable/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: CodableCore, identifier: 'codable'} },
  { path: 'enumerator', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: EnumeratorCore, identifier: 'enumerator'} },
  { path: 'enumerator/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: EnumeratorCore, identifier: 'enumerator'} },
  { path: 'enumerator_enumeral_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: EnumeratorEnumeralAssociationCore, identifier: 'enumeratorEnumeralAssociation'} },
  { path: 'enumerator_enumeral_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: EnumeratorEnumeralAssociationCore, identifier: 'enumeratorEnumeralAssociation'} },
  { path: 'enumeral', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: EnumeralCore, identifier: 'enumeral'} },
  { path: 'enumeral/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: EnumeralCore, identifier: 'enumeral'} },
  { path: 'entity_attribute_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: EntityAttributeAssociationCore, identifier: 'entityAttributeAssociation'} },
  { path: 'entity_attribute_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: EntityAttributeAssociationCore, identifier: 'entityAttributeAssociation'} },
  { path: 'entity_relationship_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: EntityRelationshipAssociationCore, identifier: 'entityRelationshipAssociation'} },
  { path: 'entity_relationship_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: EntityRelationshipAssociationCore, identifier: 'entityRelationshipAssociation'} },
  { path: 'attribute', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AttributeCore, identifier: 'attribute'} },
  { path: 'attribute/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AttributeCore, identifier: 'attribute'} },
  { path: 'attribute_codable_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AttributeCodableAssociationCore, identifier: 'attributeCodableAssociation'} },
  { path: 'attribute_codable_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AttributeCodableAssociationCore, identifier: 'attributeCodableAssociation'} },
  { path: 'attribute_widget_attribute_spec_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AttributeWidgetAttributeSpecAssociationCore, identifier: 'attributeWidgetAttributeSpecAssociation'} },
  { path: 'attribute_widget_attribute_spec_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AttributeWidgetAttributeSpecAssociationCore, identifier: 'attributeWidgetAttributeSpecAssociation'} },
  { path: 'attribute_request_attribute_spec_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AttributeRequestAttributeSpecAssociationCore, identifier: 'attributeRequestAttributeSpecAssociation'} },
  { path: 'attribute_request_attribute_spec_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AttributeRequestAttributeSpecAssociationCore, identifier: 'attributeRequestAttributeSpecAssociation'} },
  { path: 'attribute_store_attribute_spec_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AttributeStoreAttributeSpecAssociationCore, identifier: 'attributeStoreAttributeSpecAssociation'} },
  { path: 'attribute_store_attribute_spec_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AttributeStoreAttributeSpecAssociationCore, identifier: 'attributeStoreAttributeSpecAssociation'} },
  { path: 'attribute_response_attribute_spec_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AttributeResponseAttributeSpecAssociationCore, identifier: 'attributeResponseAttributeSpecAssociation'} },
  { path: 'attribute_response_attribute_spec_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AttributeResponseAttributeSpecAssociationCore, identifier: 'attributeResponseAttributeSpecAssociation'} },
  { path: 'relationship', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RelationshipCore, identifier: 'relationship'} },
  { path: 'relationship/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RelationshipCore, identifier: 'relationship'} },
  { path: 'relationship_widget_relationship_spec_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RelationshipWidgetRelationshipSpecAssociationCore, identifier: 'relationshipWidgetRelationshipSpecAssociation'} },
  { path: 'relationship_widget_relationship_spec_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RelationshipWidgetRelationshipSpecAssociationCore, identifier: 'relationshipWidgetRelationshipSpecAssociation'} },
  { path: 'relationship_request_relationship_spec_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RelationshipRequestRelationshipSpecAssociationCore, identifier: 'relationshipRequestRelationshipSpecAssociation'} },
  { path: 'relationship_request_relationship_spec_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RelationshipRequestRelationshipSpecAssociationCore, identifier: 'relationshipRequestRelationshipSpecAssociation'} },
  { path: 'relationship_store_relationship_spec_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RelationshipStoreRelationshipSpecAssociationCore, identifier: 'relationshipStoreRelationshipSpecAssociation'} },
  { path: 'relationship_store_relationship_spec_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RelationshipStoreRelationshipSpecAssociationCore, identifier: 'relationshipStoreRelationshipSpecAssociation'} },
  { path: 'relationship_response_relationship_spec_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RelationshipResponseRelationshipSpecAssociationCore, identifier: 'relationshipResponseRelationshipSpecAssociation'} },
  { path: 'relationship_response_relationship_spec_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RelationshipResponseRelationshipSpecAssociationCore, identifier: 'relationshipResponseRelationshipSpecAssociation'} },
  { path: 'entity_widget_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: EntityWidgetAssociationCore, identifier: 'entityWidgetAssociation'} },
  { path: 'entity_widget_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: EntityWidgetAssociationCore, identifier: 'entityWidgetAssociation'} },
  { path: 'widget', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: WidgetCore, identifier: 'widget'} },
  { path: 'widget/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: WidgetCore, identifier: 'widget'} },
  { path: 'widget_widget_attribute_spec_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: WidgetWidgetAttributeSpecAssociationCore, identifier: 'widgetWidgetAttributeSpecAssociation'} },
  { path: 'widget_widget_attribute_spec_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: WidgetWidgetAttributeSpecAssociationCore, identifier: 'widgetWidgetAttributeSpecAssociation'} },
  { path: 'widget_widget_relationship_spec_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: WidgetWidgetRelationshipSpecAssociationCore, identifier: 'widgetWidgetRelationshipSpecAssociation'} },
  { path: 'widget_widget_relationship_spec_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: WidgetWidgetRelationshipSpecAssociationCore, identifier: 'widgetWidgetRelationshipSpecAssociation'} },
  { path: 'widget_attribute_spec', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: WidgetAttributeSpecCore, identifier: 'widgetAttributeSpec'} },
  { path: 'widget_attribute_spec/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: WidgetAttributeSpecCore, identifier: 'widgetAttributeSpec'} },
  { path: 'widget_relationship_spec', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: WidgetRelationshipSpecCore, identifier: 'widgetRelationshipSpec'} },
  { path: 'widget_relationship_spec/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: WidgetRelationshipSpecCore, identifier: 'widgetRelationshipSpec'} },
  { path: 'entity_request_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: EntityRequestAssociationCore, identifier: 'entityRequestAssociation'} },
  { path: 'entity_request_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: EntityRequestAssociationCore, identifier: 'entityRequestAssociation'} },
  { path: 'request', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RequestCore, identifier: 'request'} },
  { path: 'request/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RequestCore, identifier: 'request'} },
  { path: 'request_request_attribute_spec_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RequestRequestAttributeSpecAssociationCore, identifier: 'requestRequestAttributeSpecAssociation'} },
  { path: 'request_request_attribute_spec_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RequestRequestAttributeSpecAssociationCore, identifier: 'requestRequestAttributeSpecAssociation'} },
  { path: 'request_request_relationship_spec_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RequestRequestRelationshipSpecAssociationCore, identifier: 'requestRequestRelationshipSpecAssociation'} },
  { path: 'request_request_relationship_spec_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RequestRequestRelationshipSpecAssociationCore, identifier: 'requestRequestRelationshipSpecAssociation'} },
  { path: 'request_attribute_spec', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RequestAttributeSpecCore, identifier: 'requestAttributeSpec'} },
  { path: 'request_attribute_spec/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RequestAttributeSpecCore, identifier: 'requestAttributeSpec'} },
  { path: 'request_relationship_spec', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RequestRelationshipSpecCore, identifier: 'requestRelationshipSpec'} },
  { path: 'request_relationship_spec/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RequestRelationshipSpecCore, identifier: 'requestRelationshipSpec'} },
  { path: 'entity_response_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: EntityResponseAssociationCore, identifier: 'entityResponseAssociation'} },
  { path: 'entity_response_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: EntityResponseAssociationCore, identifier: 'entityResponseAssociation'} },
  { path: 'response', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ResponseCore, identifier: 'response'} },
  { path: 'response/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ResponseCore, identifier: 'response'} },
  { path: 'response_response_attribute_spec_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ResponseResponseAttributeSpecAssociationCore, identifier: 'responseResponseAttributeSpecAssociation'} },
  { path: 'response_response_attribute_spec_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ResponseResponseAttributeSpecAssociationCore, identifier: 'responseResponseAttributeSpecAssociation'} },
  { path: 'response_response_relationship_spec_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ResponseResponseRelationshipSpecAssociationCore, identifier: 'responseResponseRelationshipSpecAssociation'} },
  { path: 'response_response_relationship_spec_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ResponseResponseRelationshipSpecAssociationCore, identifier: 'responseResponseRelationshipSpecAssociation'} },
  { path: 'response_attribute_spec', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ResponseAttributeSpecCore, identifier: 'responseAttributeSpec'} },
  { path: 'response_attribute_spec/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ResponseAttributeSpecCore, identifier: 'responseAttributeSpec'} },
  { path: 'response_relationship_spec', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ResponseRelationshipSpecCore, identifier: 'responseRelationshipSpec'} },
  { path: 'response_relationship_spec/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ResponseRelationshipSpecCore, identifier: 'responseRelationshipSpec'} },
  { path: 'entity_store_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: EntityStoreAssociationCore, identifier: 'entityStoreAssociation'} },
  { path: 'entity_store_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: EntityStoreAssociationCore, identifier: 'entityStoreAssociation'} },
  { path: 'store', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: StoreCore, identifier: 'store'} },
  { path: 'store/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: StoreCore, identifier: 'store'} },
  { path: 'store_store_attribute_spec_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: StoreStoreAttributeSpecAssociationCore, identifier: 'storeStoreAttributeSpecAssociation'} },
  { path: 'store_store_attribute_spec_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: StoreStoreAttributeSpecAssociationCore, identifier: 'storeStoreAttributeSpecAssociation'} },
  { path: 'store_store_relationship_spec_association', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: StoreStoreRelationshipSpecAssociationCore, identifier: 'storeStoreRelationshipSpecAssociation'} },
  { path: 'store_store_relationship_spec_association/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: StoreStoreRelationshipSpecAssociationCore, identifier: 'storeStoreRelationshipSpecAssociation'} },
  { path: 'store_attribute_spec', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: StoreAttributeSpecCore, identifier: 'storeAttributeSpec'} },
  { path: 'store_attribute_spec/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: StoreAttributeSpecCore, identifier: 'storeAttributeSpec'} },
  { path: 'store_relationship_spec', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: StoreRelationshipSpecCore, identifier: 'storeRelationshipSpec'} },
  { path: 'store_relationship_spec/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: StoreRelationshipSpecCore, identifier: 'storeRelationshipSpec'} },
  { path: 'media', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: MediaCore, identifier: 'media'} },
  { path: 'media/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: MediaCore, identifier: 'media'} },
  { path: 'attachment', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AttachmentCore, identifier: 'attachment'} },
  { path: 'attachment/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AttachmentCore, identifier: 'attachment'} },
  { path: 'organization', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: OrganizationCore, identifier: 'organization'} },
  { path: 'organization/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: OrganizationCore, identifier: 'organization'} },
  { path: 'product', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ProductCore, identifier: 'product'} },
  { path: 'product/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ProductCore, identifier: 'product'} },
  { path: 'price', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: PriceCore, identifier: 'price'} },
  { path: 'price/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: PriceCore, identifier: 'price'} },
  { path: 'invoice', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: InvoiceCore, identifier: 'invoice'} },
  { path: 'invoice/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: InvoiceCore, identifier: 'invoice'} },
  { path: 'invoice_line', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: InvoiceLineCore, identifier: 'invoiceLine'} },
  { path: 'invoice_line/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: InvoiceLineCore, identifier: 'invoiceLine'} },
  { path: 'payment_intent', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: PaymentIntentCore, identifier: 'paymentIntent'} },
  { path: 'payment_intent/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: PaymentIntentCore, identifier: 'paymentIntent'} },
  { path: 'subscription', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SubscriptionCore, identifier: 'subscription'} },
  { path: 'subscription/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SubscriptionCore, identifier: 'subscription'} },
  { path: 'constraint', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ConstraintCore, identifier: 'constraint'} },
  { path: 'constraint/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ConstraintCore, identifier: 'constraint'} },
  { path: 'applied_constraint', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AppliedConstraintCore, identifier: 'appliedConstraint'} },
  { path: 'applied_constraint/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AppliedConstraintCore, identifier: 'appliedConstraint'} },
  { path: 'user', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: UserCore, identifier: 'user'} },
  { path: 'user/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: UserCore, identifier: 'user'} },
  { path: 'organization_invite', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: OrganizationInviteCore, identifier: 'organizationInvite'} },
  { path: 'organization_invite/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: OrganizationInviteCore, identifier: 'organizationInvite'} },
  { path: 'role_grant', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RoleGrantCore, identifier: 'roleGrant'} },
  { path: 'role_grant/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RoleGrantCore, identifier: 'roleGrant'} },
  { path: 'certificate', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: CertificateCore, identifier: 'certificate'} },
  { path: 'certificate/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: CertificateCore, identifier: 'certificate'} },
  { path: 'identity', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: IdentityCore, identifier: 'identity'} },
  { path: 'identity/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: IdentityCore, identifier: 'identity'} },
  { path: 'token', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TokenCore, identifier: 'token'} },
  { path: 'token/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TokenCore, identifier: 'token'} },
  { path: 'role', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RoleCore, identifier: 'role'} },
  { path: 'role/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RoleCore, identifier: 'role'} },
  { path: 'view', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ViewCore, identifier: 'view'} },
  { path: 'view/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ViewCore, identifier: 'view'} },
  { path: 'view_grant', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ViewGrantCore, identifier: 'viewGrant'} },
  { path: 'view_grant/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ViewGrantCore, identifier: 'viewGrant'} },
  { path: 'hello', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: HelloCore, identifier: 'hello'} },
  { path: 'hello/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: HelloCore, identifier: 'hello'} },
  { path: 'ses_message', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SesMessageCore, identifier: 'sesMessage'} },
  { path: 'ses_message/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SesMessageCore, identifier: 'sesMessage'} },
  { path: 'ingest', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: IngestCore, identifier: 'ingest'} },
  { path: 'ingest/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: IngestCore, identifier: 'ingest'} },
)

@NgModule({
  imports: [RouterModule.forRoot(ALL_ROUTES, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }