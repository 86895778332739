import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostRelationshipLink } from './so-post-relationship-link';
import { SOGetRelationshipLink } from './so-get-relationship-link';
import { SOPutRelationshipLink } from './so-put-relationship-link';
import { SODeleteRelationshipLink } from './so-delete-relationship-link';
import {SORespRelationshipLink} from './so-resp-relationship-link';
import {SOPluralRelationshipLink} from './so-plural-relationship-link';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EORelationshipLinkService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'relationship_link',
      entityCore
    );
  }

  /** GET RelationshipLink by id. Will 404 if id not found */
  get(request: SOGetRelationshipLink): Observable<SOPluralRelationshipLink> {
    return this.httpClient.get<SOPluralRelationshipLink>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralRelationshipLink, resp) }),
      tap(_ => this.log(`fetched RelationshipLink`)),
      catchError(this.handleError<SOPluralRelationshipLink>(`get RelationshipLink`))
    );
  }

  /** POST: add a new RelationshipLink to the server */
  post(request: SOPostRelationshipLink): Observable<SORespRelationshipLink> {
    return this.httpClient.post<SORespRelationshipLink>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespRelationshipLink, resp) }),
      tap((response: SORespRelationshipLink) => this.log(`added RelationshipLink w/ id=${response.uid}`)),
      catchError(this.handleError<SORespRelationshipLink>('RelationshipLink post'))
    );
  }

  /** PUT: update RelationshipLink  */
  put(request: SOPutRelationshipLink): Observable<SORespRelationshipLink> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespRelationshipLink>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespRelationshipLink, resp) }),
      tap((response: SORespRelationshipLink) => this.log(`edited RelationshipLink w/ uid=${uid}`)),
      catchError(this.handleError<SORespRelationshipLink>('RelationshipLink put'))
    );
  }

  /** DELETE: delete the RelationshipLink from the server */
  delete(request: SODeleteRelationshipLink): Observable<SORespRelationshipLink> {
    return this.httpClient.delete<SORespRelationshipLink>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted RelationshipLink uid=${request.uid}`)),
      catchError(this.handleError<SORespRelationshipLink>('delete RelationshipLink'))
    );
  }
}
