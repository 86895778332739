import { SOGetWidgetWidgetRelationshipSpecAssociation } from '../widget-widget-relationship-spec-association/so-get-widget-widget-relationship-spec-association';
import { SOGetRelationshipWidgetRelationshipSpecAssociation } from '../relationship-widget-relationship-spec-association/so-get-relationship-widget-relationship-spec-association';
import { SOGetOrganization } from '../organization/so-get-organization';
import { SOGetApp } from '../app/so-get-app';


export class SOPostWidgetRelationshipSpec {

  constructor(
    public isRequired: boolean,
    public widget: SOGetWidgetWidgetRelationshipSpecAssociation[],
    public relationship: SOGetRelationshipWidgetRelationshipSpecAssociation[],
    public organization: SOGetOrganization,
    public app: SOGetApp,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostWidgetRelationshipSpec(
      'isRequired' in params ? params.isRequired : null,
      'widget' in params ? params.widget : null,
      'relationship' in params ? params.relationship : null,
      'organization' in params ? params.organization : null,
      'app' in params ? params.app : null,
    );
  }
}
