import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOAttributeResponseAttributeSpecAssociationService} from '../../core/attribute-response-attribute-spec-association/eo-attribute-response-attribute-spec-association';
import {AttributeResponseAttributeSpecAssociationListEntryFactory} from './attribute-response-attribute-spec-association-list-entry-factory';
import {AttributeResponseAttributeSpecAssociationDetailFactory} from './attribute-response-attribute-spec-association-detail-factory';
import {SOGetAttributeResponseAttributeSpecAssociation} from '../../core/attribute-response-attribute-spec-association/so-get-attribute-response-attribute-spec-association';
import {RAPutAttributeResponseAttributeSpecAssociation} from './ra-put-attribute-response-attribute-spec-association';
import {RAPostAttributeResponseAttributeSpecAssociation} from './ra-post-attribute-response-attribute-spec-association';
import {RADeleteAttributeResponseAttributeSpecAssociation} from './ra-delete-attribute-response-attribute-spec-association';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class AttributeResponseAttributeSpecAssociationCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'attributeResponseAttributeSpecAssociation';
  title = 'Attribute response attribute spec association';
  listEntryFactory = new AttributeResponseAttributeSpecAssociationListEntryFactory();
  detailFactory = new AttributeResponseAttributeSpecAssociationDetailFactory();

  entityService: EOAttributeResponseAttributeSpecAssociationService;
  soGetClass = SOGetAttributeResponseAttributeSpecAssociation;
  soGet: SOGetAttributeResponseAttributeSpecAssociation;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOAttributeResponseAttributeSpecAssociationService
    );
    this.adapters = [
      new RAPostAttributeResponseAttributeSpecAssociation(this),
      new RAPutAttributeResponseAttributeSpecAssociation(this),
      new RADeleteAttributeResponseAttributeSpecAssociation(this),
    ];
    this.resetSoGet();
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetAttributeResponseAttributeSpecAssociation();
    this.soGet.commit = new SOGetCommit(
      this.activeUserService.getScope('commit').uid
    );
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
