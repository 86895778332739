import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostEntityAttributeAssociation } from './so-post-entity-attribute-association';
import { SOGetEntityAttributeAssociation } from './so-get-entity-attribute-association';
import { SOPutEntityAttributeAssociation } from './so-put-entity-attribute-association';
import { SODeleteEntityAttributeAssociation } from './so-delete-entity-attribute-association';
import {SORespEntityAttributeAssociation} from './so-resp-entity-attribute-association';
import {SOPluralEntityAttributeAssociation} from './so-plural-entity-attribute-association';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOEntityAttributeAssociationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'entity_attribute_association',
      entityCore
    );
  }

  /** GET EntityAttributeAssociation by id. Will 404 if id not found */
  get(request: SOGetEntityAttributeAssociation): Observable<SOPluralEntityAttributeAssociation> {
    return this.httpClient.get<SOPluralEntityAttributeAssociation>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralEntityAttributeAssociation, resp) }),
      tap(_ => this.log(`fetched EntityAttributeAssociation`)),
      catchError(this.handleError<SOPluralEntityAttributeAssociation>(`get EntityAttributeAssociation`))
    );
  }

  /** POST: add a new EntityAttributeAssociation to the server */
  post(request: SOPostEntityAttributeAssociation): Observable<SORespEntityAttributeAssociation> {
    return this.httpClient.post<SORespEntityAttributeAssociation>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespEntityAttributeAssociation, resp) }),
      tap((response: SORespEntityAttributeAssociation) => this.log(`added EntityAttributeAssociation w/ id=${response.uid}`)),
      catchError(this.handleError<SORespEntityAttributeAssociation>('EntityAttributeAssociation post'))
    );
  }

  /** PUT: update EntityAttributeAssociation  */
  put(request: SOPutEntityAttributeAssociation): Observable<SORespEntityAttributeAssociation> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespEntityAttributeAssociation>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespEntityAttributeAssociation, resp) }),
      tap((response: SORespEntityAttributeAssociation) => this.log(`edited EntityAttributeAssociation w/ uid=${uid}`)),
      catchError(this.handleError<SORespEntityAttributeAssociation>('EntityAttributeAssociation put'))
    );
  }

  /** DELETE: delete the EntityAttributeAssociation from the server */
  delete(request: SODeleteEntityAttributeAssociation): Observable<SORespEntityAttributeAssociation> {
    return this.httpClient.delete<SORespEntityAttributeAssociation>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted EntityAttributeAssociation uid=${request.uid}`)),
      catchError(this.handleError<SORespEntityAttributeAssociation>('delete EntityAttributeAssociation'))
    );
  }
}
