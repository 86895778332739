import {SOPostBranch} from '../../core/branch/so-post-branch';
import {BranchCore} from './branch.core';
import {SOGetBranch} from '../../core/branch/so-get-branch';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postBranchFormFieldOptions} from './post-branch-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {AppCore} from '../bv-app/app.core';
import {SOGetApp} from '../../core/app/so-get-app';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostBranch extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postBranchFormFieldOptions);

  RequestClass = SOPostBranch

  constructor(
    public entityCore: BranchCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier(
        'app',
        SOGetApp,
        true,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}