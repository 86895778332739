import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostStoreAttributeSpec } from './so-post-store-attribute-spec';
import { SOGetStoreAttributeSpec } from './so-get-store-attribute-spec';
import { SOPutStoreAttributeSpec } from './so-put-store-attribute-spec';
import { SODeleteStoreAttributeSpec } from './so-delete-store-attribute-spec';
import {SORespStoreAttributeSpec} from './so-resp-store-attribute-spec';
import {SOPluralStoreAttributeSpec} from './so-plural-store-attribute-spec';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOStoreAttributeSpecService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'store_attribute_spec',
      entityCore
    );
  }

  /** GET StoreAttributeSpec by id. Will 404 if id not found */
  get(request: SOGetStoreAttributeSpec): Observable<SOPluralStoreAttributeSpec> {
    return this.httpClient.get<SOPluralStoreAttributeSpec>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralStoreAttributeSpec, resp) }),
      tap(_ => this.log(`fetched StoreAttributeSpec`)),
      catchError(this.handleError<SOPluralStoreAttributeSpec>(`get StoreAttributeSpec`))
    );
  }

  /** POST: add a new StoreAttributeSpec to the server */
  post(request: SOPostStoreAttributeSpec): Observable<SORespStoreAttributeSpec> {
    return this.httpClient.post<SORespStoreAttributeSpec>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespStoreAttributeSpec, resp) }),
      tap((response: SORespStoreAttributeSpec) => this.log(`added StoreAttributeSpec w/ id=${response.uid}`)),
      catchError(this.handleError<SORespStoreAttributeSpec>('StoreAttributeSpec post'))
    );
  }

  /** PUT: update StoreAttributeSpec  */
  put(request: SOPutStoreAttributeSpec): Observable<SORespStoreAttributeSpec> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespStoreAttributeSpec>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespStoreAttributeSpec, resp) }),
      tap((response: SORespStoreAttributeSpec) => this.log(`edited StoreAttributeSpec w/ uid=${uid}`)),
      catchError(this.handleError<SORespStoreAttributeSpec>('StoreAttributeSpec put'))
    );
  }

  /** DELETE: delete the StoreAttributeSpec from the server */
  delete(request: SODeleteStoreAttributeSpec): Observable<SORespStoreAttributeSpec> {
    return this.httpClient.delete<SORespStoreAttributeSpec>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted StoreAttributeSpec uid=${request.uid}`)),
      catchError(this.handleError<SORespStoreAttributeSpec>('delete StoreAttributeSpec'))
    );
  }
}
