import {SORespOrganization} from '../../core/organization/so-resp-organization';
import {OrganizationCore} from './organization.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putOrganizationFormFieldOptions} from './put-organization-form-fields';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetSubscription} from '../../core/subscription/so-get-subscription';
import {SOGetRoleGrant} from '../../core/role-grant/so-get-role-grant';
import {SOGetOrganizationInvite} from '../../core/organization-invite/so-get-organization-invite';


export class OrganizationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespOrganization = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const nameDetail = new DetailEntry(
        'Name',
        entity.name,
        'name',
        BaseDetailType.text,
        null,
        EditableType.always,
        putOrganizationFormFieldOptions.name
    )
    attributes.push(nameDetail)

    const identifierDetail = new DetailEntry(
        'Identifier',
        entity.identifier,
        'identifier',
        BaseDetailType.text,
        null,
        EditableType.always,
        putOrganizationFormFieldOptions.identifier
    )
    attributes.push(identifierDetail)

    const dateCreatedDetail = new DetailEntry(
        'Date created',
        activeUserService.time.niceDate(entity.dateCreated),
        'dateCreated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateCreatedDetail)

    const exRefPaymentsLinkDetail = new DetailEntry(
        'Manage billing and payments',
        entity.exRefPaymentsLink,
        'exRefPaymentsLink',
        BaseDetailType.link,
        null,
        EditableType.never,
    )
    attributes.push(exRefPaymentsLinkDetail)


    const subscriptionsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'subscription');
    subscriptionsCore.soGet = SOGetSubscription.construct({
      organization: new SOGetOrganization(entity.uid),
    });
    heading = getHeading(entityViewSpec.entityCore, subscriptionsCore)
    subscriptionsCore.title = heading ? heading : subscriptionsCore.title
    subscriptionsCore.listTitle = 'Subscriptions'

    subscriptionsCore.configureForDisplay(entityViewSpec)

    const subscriptionsDetail = new DetailEntry(
      heading ? heading : subscriptionsCore.listTitle,
      null,
      'subscriptions',
      BaseDetailType.embeddedList,
      subscriptionsCore,
      EditableType.never
    )
    attributes.push(subscriptionsDetail);

    const roleGrantsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'roleGrant');
    roleGrantsCore.soGet = SOGetRoleGrant.construct({
      organization: new SOGetOrganization(entity.uid),
    });
    heading = getHeading(entityViewSpec.entityCore, roleGrantsCore)
    roleGrantsCore.title = heading ? heading : roleGrantsCore.title
    roleGrantsCore.listTitle = 'Role grants'

    roleGrantsCore.configureForDisplay(entityViewSpec)

    const roleGrantsDetail = new DetailEntry(
      heading ? heading : roleGrantsCore.listTitle,
      null,
      'roleGrants',
      BaseDetailType.embeddedList,
      roleGrantsCore,
      EditableType.never
    )
    attributes.push(roleGrantsDetail);

    const invitesCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'organizationInvite');
    invitesCore.soGet = SOGetOrganizationInvite.construct({
      organization: new SOGetOrganization(entity.uid),
    });
    heading = getHeading(entityViewSpec.entityCore, invitesCore)
    invitesCore.title = heading ? heading : invitesCore.title
    invitesCore.listTitle = 'Invites'

    invitesCore.configureForDisplay(entityViewSpec)

    const invitesDetail = new DetailEntry(
      heading ? heading : invitesCore.listTitle,
      null,
      'invites',
      BaseDetailType.embeddedList,
      invitesCore,
      EditableType.never
    )
    attributes.push(invitesDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
