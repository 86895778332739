import { Type } from 'class-transformer';
import { SORespConstraint } from '../constraint/so-resp-constraint';
import { SORespProduct } from '../product/so-resp-product';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespAppliedConstraint implements ObjectiveProtocol {

  entityType: string = 'appliedConstraint';
  uid: string;
  @Type(() => SORespConstraint)
  constraint: SORespConstraint;
  @Type(() => SORespProduct)
  product: SORespProduct;
  quantity: number;
}