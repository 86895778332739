import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const attributeField = new FormField(
  'attribute',
  'Attribute',
  'attribute',
  FormFieldType.pushedSelectionList,
  false,
)
const codableField = new FormField(
  'codable',
  'Codable',
  'codable',
  FormFieldType.pushedSelectionList,
  false,
)


export const putAttributeCodableAssociationFormFieldOptions: {[key: string]: FormField} = {
  attribute: attributeField,
  codable: codableField,
};