import {SORespEntity} from '../../core/entity/so-resp-entity';
import {EntityCore} from './entity.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putEntityFormFieldOptions} from './put-entity-form-fields';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {SOGetEntityAttributeAssociation} from '../../core/entity-attribute-association/so-get-entity-attribute-association';
import {SOGetEntityRelationshipAssociation} from '../../core/entity-relationship-association/so-get-entity-relationship-association';
import {SOGetEntityWidgetAssociation} from '../../core/entity-widget-association/so-get-entity-widget-association';
import {SOGetEntityRequestAssociation} from '../../core/entity-request-association/so-get-entity-request-association';
import {SOGetEntityResponseAssociation} from '../../core/entity-response-association/so-get-entity-response-association';
import {SOGetEntityStoreAssociation} from '../../core/entity-store-association/so-get-entity-store-association';
import {SOGetEntityChildrenAssociation} from '../../core/entity-children-association/so-get-entity-children-association';
import {SOGetCommitEntityAssociation} from '../../core/commit-entity-association/so-get-commit-entity-association';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class EntityDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespEntity = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const nameDetail = new DetailEntry(
        'Name',
        entity.name,
        'name',
        BaseDetailType.text,
        null,
        EditableType.always,
        putEntityFormFieldOptions.name
    )
    attributes.push(nameDetail)


    const attributesCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'entityAttributeAssociation');
    attributesCore.soGet = SOGetEntityAttributeAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, attributesCore)
    attributesCore.title = heading ? heading : attributesCore.title
    attributesCore.listTitle = 'Attributes'

    attributesCore.configureForDisplay(entityViewSpec)

    const attributesDetail = new DetailEntry(
      heading ? heading : attributesCore.listTitle,
      null,
      'attributes',
      BaseDetailType.embeddedList,
      attributesCore,
      EditableType.never
    )
    attributes.push(attributesDetail);

    const relationshipsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'entityRelationshipAssociation');
    relationshipsCore.soGet = SOGetEntityRelationshipAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, relationshipsCore)
    relationshipsCore.title = heading ? heading : relationshipsCore.title
    relationshipsCore.listTitle = 'Relationships'

    relationshipsCore.configureForDisplay(entityViewSpec)

    const relationshipsDetail = new DetailEntry(
      heading ? heading : relationshipsCore.listTitle,
      null,
      'relationships',
      BaseDetailType.embeddedList,
      relationshipsCore,
      EditableType.never
    )
    attributes.push(relationshipsDetail);

    const widgetsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'entityWidgetAssociation');
    widgetsCore.soGet = SOGetEntityWidgetAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, widgetsCore)
    widgetsCore.title = heading ? heading : widgetsCore.title
    widgetsCore.listTitle = 'Widgets'

    widgetsCore.configureForDisplay(entityViewSpec)

    const widgetsDetail = new DetailEntry(
      heading ? heading : widgetsCore.listTitle,
      null,
      'widgets',
      BaseDetailType.embeddedList,
      widgetsCore,
      EditableType.never
    )
    attributes.push(widgetsDetail);

    const requestsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'entityRequestAssociation');
    requestsCore.soGet = SOGetEntityRequestAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, requestsCore)
    requestsCore.title = heading ? heading : requestsCore.title
    requestsCore.listTitle = 'Requests'

    requestsCore.configureForDisplay(entityViewSpec)

    const requestsDetail = new DetailEntry(
      heading ? heading : requestsCore.listTitle,
      null,
      'requests',
      BaseDetailType.embeddedList,
      requestsCore,
      EditableType.never
    )
    attributes.push(requestsDetail);

    const responsesCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'entityResponseAssociation');
    responsesCore.soGet = SOGetEntityResponseAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, responsesCore)
    responsesCore.title = heading ? heading : responsesCore.title
    responsesCore.listTitle = 'Responses'

    responsesCore.configureForDisplay(entityViewSpec)

    const responsesDetail = new DetailEntry(
      heading ? heading : responsesCore.listTitle,
      null,
      'responses',
      BaseDetailType.embeddedList,
      responsesCore,
      EditableType.never
    )
    attributes.push(responsesDetail);

    const storesCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'entityStoreAssociation');
    storesCore.soGet = SOGetEntityStoreAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, storesCore)
    storesCore.title = heading ? heading : storesCore.title
    storesCore.listTitle = 'Stores'

    storesCore.configureForDisplay(entityViewSpec)

    const storesDetail = new DetailEntry(
      heading ? heading : storesCore.listTitle,
      null,
      'stores',
      BaseDetailType.embeddedList,
      storesCore,
      EditableType.never
    )
    attributes.push(storesDetail);

    const parentCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'entityChildrenAssociation');
    parentCore.soGet = SOGetEntityChildrenAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, parentCore)
    parentCore.title = heading ? heading : parentCore.title
    parentCore.listTitle = 'Parent'

    parentCore.configureForDisplay(entityViewSpec)

    const parentDetail = new DetailEntry(
      heading ? heading : parentCore.listTitle,
      null,
      'parent',
      BaseDetailType.embeddedList,
      parentCore,
      EditableType.never
    )
    attributes.push(parentDetail);

    const childrenCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'entityChildrenAssociation');
    childrenCore.soGet = SOGetEntityChildrenAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, childrenCore)
    childrenCore.title = heading ? heading : childrenCore.title
    childrenCore.listTitle = 'Children'

    childrenCore.configureForDisplay(entityViewSpec)

    const childrenDetail = new DetailEntry(
      heading ? heading : childrenCore.listTitle,
      null,
      'children',
      BaseDetailType.embeddedList,
      childrenCore,
      EditableType.never
    )
    attributes.push(childrenDetail);

    const commitEntityAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'commitEntityAssociation');
    commitEntityAssociationsCore.soGet = SOGetCommitEntityAssociation.construct({
      entity: new SOGetEntity(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, commitEntityAssociationsCore)
    commitEntityAssociationsCore.title = heading ? heading : commitEntityAssociationsCore.title
    commitEntityAssociationsCore.listTitle = 'Commit entity associations'

    commitEntityAssociationsCore.configureForDisplay(entityViewSpec)

    const commitEntityAssociationsDetail = new DetailEntry(
      heading ? heading : commitEntityAssociationsCore.listTitle,
      null,
      'commitEntityAssociations',
      BaseDetailType.embeddedList,
      commitEntityAssociationsCore,
      EditableType.never
    )
    attributes.push(commitEntityAssociationsDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
