import { Type } from 'class-transformer';
import { SORespEntityRequestAssociation } from '../entity-request-association/so-resp-entity-request-association';
import { SORespOrganization } from '../organization/so-resp-organization';
import { SORespApp } from '../app/so-resp-app';
import {ENRequestType} from '../../enums';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespRequest implements ObjectiveProtocol {

  entityType: string = 'request';
  uid: string;
  name: string = "";
  requestType: ENRequestType;
  isDefault: boolean;
  @Type(() => SORespEntityRequestAssociation)
  entity: SORespEntityRequestAssociation[];
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
  @Type(() => SORespApp)
  app: SORespApp;
}