import { Type } from 'class-transformer';
import { SORespWidget } from '../widget/so-resp-widget';
import { SORespWidgetRelationshipSpec } from '../widget-relationship-spec/so-resp-widget-relationship-spec';
import { SORespCommit } from '../commit/so-resp-commit';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespWidgetWidgetRelationshipSpecAssociation implements ObjectiveProtocol {

  entityType: string = 'widgetWidgetRelationshipSpecAssociation';
  uid: string;
  @Type(() => SORespWidget)
  widget: SORespWidget;
  @Type(() => SORespWidgetRelationshipSpec)
  widgetRelationshipSpec: SORespWidgetRelationshipSpec;
  @Type(() => SORespCommit)
  commit: SORespCommit;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
}