import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const nameField = new FormField(
  'name',
  'Name',
  null,
  FormFieldType.text,
  false,
)
const identifierField = new FormField(
  'identifier',
  'Identifier',
  null,
  FormFieldType.text,
  false,
)


export const putOrganizationFormFieldOptions: {[key: string]: FormField} = {
  name: nameField,
  identifier: identifierField,
};