import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const entityField = new FormField(
  'entity',
  'Entity',
  'entity',
  FormFieldType.pushedSelectionList,
  false,
)
const relationshipField = new FormField(
  'relationship',
  'Relationship',
  'relationship',
  FormFieldType.pushedSelectionList,
  false,
)


export const putEntityRelationshipAssociationFormFieldOptions: {[key: string]: FormField} = {
  entity: entityField,
  relationship: relationshipField,
};