

export class SOPutUser {

  constructor(
    public uid: string,
    public password: string = null,
    public givenName: string = null,
    public surname: string = null,
    public email: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutUser(
      'uid' in params ? params.uid : null,
      'password' in params ? params.password : null,
      'givenName' in params ? params.givenName : null,
      'surname' in params ? params.surname : null,
      'email' in params ? params.email : null,
    );
  }
}
