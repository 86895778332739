import {SORespUser} from '../../core/user/so-resp-user';
import {UserCore} from './user.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putUserFormFieldOptions} from './put-user-form-fields';
import {SOGetUser} from '../../core/user/so-get-user';
import {SOGetRoleGrant} from '../../core/role-grant/so-get-role-grant';
import {SOGetOrganizationInvite} from '../../core/organization-invite/so-get-organization-invite';


export class UserDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespUser = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const usernameDetail = new DetailEntry(
        'Username',
        entity.username,
        'username',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(usernameDetail)

    const givenNameDetail = new DetailEntry(
        'Given name',
        entity.givenName,
        'givenName',
        BaseDetailType.text,
        null,
        EditableType.always,
        putUserFormFieldOptions.givenName
    )
    attributes.push(givenNameDetail)

    const surnameDetail = new DetailEntry(
        'Surname',
        entity.surname,
        'surname',
        BaseDetailType.text,
        null,
        EditableType.always,
        putUserFormFieldOptions.surname
    )
    attributes.push(surnameDetail)

    const emailDetail = new DetailEntry(
        'Email',
        entity.email,
        'email',
        BaseDetailType.text,
        null,
        EditableType.always,
        putUserFormFieldOptions.email
    )
    attributes.push(emailDetail)

    const emailPendingDetail = new DetailEntry(
        'Email pending',
        entity.emailPending,
        'emailPending',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(emailPendingDetail)

    const emailVerifiedDetail = new DetailEntry(
        'Email verified',
        entity.emailVerified,
        'emailVerified',
        BaseDetailType.toggle,
        null,
        EditableType.never,
    )
    attributes.push(emailVerifiedDetail)

    const dateCreatedDetail = new DetailEntry(
        'Date created',
        activeUserService.time.niceDate(entity.dateCreated),
        'dateCreated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateCreatedDetail)


    const roleGrantsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'roleGrant');
    roleGrantsCore.soGet = SOGetRoleGrant.construct({
      user: new SOGetUser(entity.uid),
    });
    heading = getHeading(entityViewSpec.entityCore, roleGrantsCore)
    roleGrantsCore.title = heading ? heading : roleGrantsCore.title
    roleGrantsCore.listTitle = 'Role grants'

    roleGrantsCore.configureForDisplay(entityViewSpec)

    const roleGrantsDetail = new DetailEntry(
      heading ? heading : roleGrantsCore.listTitle,
      null,
      'roleGrants',
      BaseDetailType.embeddedList,
      roleGrantsCore,
      EditableType.never
    )
    attributes.push(roleGrantsDetail);

    const invitesCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'organizationInvite');
    invitesCore.soGet = SOGetOrganizationInvite.construct({
      user: new SOGetUser(entity.uid),
    });
    heading = getHeading(entityViewSpec.entityCore, invitesCore)
    invitesCore.title = heading ? heading : invitesCore.title
    invitesCore.listTitle = 'Invites'

    invitesCore.configureForDisplay(entityViewSpec)

    const invitesDetail = new DetailEntry(
      heading ? heading : invitesCore.listTitle,
      null,
      'invites',
      BaseDetailType.embeddedList,
      invitesCore,
      EditableType.never
    )
    attributes.push(invitesDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
