import {SORespEnumeratorEnumeralAssociation} from '../../core/enumerator-enumeral-association/so-resp-enumerator-enumeral-association';
import {EnumeratorEnumeralAssociationCore} from './enumerator-enumeral-association.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putEnumeratorEnumeralAssociationFormFieldOptions} from './put-enumerator-enumeral-association-form-fields';
import {SOGetEnumeratorEnumeralAssociation} from '../../core/enumerator-enumeral-association/so-get-enumerator-enumeral-association';
import {SOGetEnumerator} from '../../core/enumerator/so-get-enumerator';
import {SOGetEnumeral} from '../../core/enumeral/so-get-enumeral';
import {SOGetApp} from '../../core/app/so-get-app';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class EnumeratorEnumeralAssociationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespEnumeratorEnumeralAssociation = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []


    const enumeratorCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'enumerator');
    enumeratorCore.soGet = SOGetEnumerator.construct({
      app: new SOGetApp(activeUserService.getScope('app').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, enumeratorCore)
    enumeratorCore.title = heading ? heading : enumeratorCore.title
    enumeratorCore.listTitle = 'Enumerator'

    if (entity.enumerator) {
        displayStringFactory.reconfigure(entity.enumerator, entityViewSpec.entityCore.type)
    }
    enumeratorCore.configureForDisplay(entityViewSpec)

    const enumeratorDetail = new DetailEntry(
      heading ? heading : enumeratorCore.listTitle,
      entity.enumerator ? displayStringFactory.primaryString : null,
      'enumerator',
      BaseDetailType.pushedDetail,
      enumeratorCore,
      EditableType.always,
      putEnumeratorEnumeralAssociationFormFieldOptions.enumerator
    )
    if (entity.enumerator) {
      enumeratorDetail.cachedListEntry = enumeratorCore.makeListEntry(entityViewSpec, enumeratorCore, entity.enumerator, 0)
    }
    enumeratorDetail.singleFieldSelect = true
    attributes.push(enumeratorDetail);

    const enumeralCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'enumeral');
    enumeralCore.soGet = SOGetEnumeral.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, enumeralCore)
    enumeralCore.title = heading ? heading : enumeralCore.title
    enumeralCore.listTitle = 'Enumeral'

    if (entity.enumeral) {
        displayStringFactory.reconfigure(entity.enumeral, entityViewSpec.entityCore.type)
    }
    enumeralCore.configureForDisplay(entityViewSpec)

    const enumeralDetail = new DetailEntry(
      heading ? heading : enumeralCore.listTitle,
      entity.enumeral ? displayStringFactory.primaryString : null,
      'enumeral',
      BaseDetailType.pushedDetail,
      enumeralCore,
      EditableType.always,
      putEnumeratorEnumeralAssociationFormFieldOptions.enumeral
    )
    if (entity.enumeral) {
      enumeralDetail.cachedListEntry = enumeralCore.makeListEntry(entityViewSpec, enumeralCore, entity.enumeral, 0)
    }
    enumeralDetail.singleFieldSelect = true
    attributes.push(enumeralDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
