import {SORespWidgetWidgetRelationshipSpecAssociation} from '../../core/widget-widget-relationship-spec-association/so-resp-widget-widget-relationship-spec-association';
import {WidgetWidgetRelationshipSpecAssociationCore} from './widget-widget-relationship-spec-association.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putWidgetWidgetRelationshipSpecAssociationFormFieldOptions} from './put-widget-widget-relationship-spec-association-form-fields';
import {SOGetWidgetWidgetRelationshipSpecAssociation} from '../../core/widget-widget-relationship-spec-association/so-get-widget-widget-relationship-spec-association';
import {SOGetWidget} from '../../core/widget/so-get-widget';
import {SOGetWidgetRelationshipSpec} from '../../core/widget-relationship-spec/so-get-widget-relationship-spec';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetApp} from '../../core/app/so-get-app';


export class WidgetWidgetRelationshipSpecAssociationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespWidgetWidgetRelationshipSpecAssociation = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = [];


    const widgetCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'widget');
    widgetCore.soGet = SOGetWidget.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, widgetCore);
    widgetCore.title = heading ? heading : widgetCore.title;
    widgetCore.listTitle = 'Widget';

    if (entity.widget) {
        displayStringFactory.reconfigure(entity.widget, entityViewSpec.entityCore.type);
    }
    widgetCore.configureForDisplay(entityViewSpec);

    const widgetDetail = new DetailEntry(
      heading ? heading : widgetCore.listTitle,
      entity.widget ? displayStringFactory.primaryString : null,
      'widget',
      BaseDetailType.pushedDetail,
      widgetCore,
      EditableType.always,
      putWidgetWidgetRelationshipSpecAssociationFormFieldOptions.widget
    );
    if (entity.widget) {
      widgetDetail.cachedListEntry = widgetCore.makeListEntry(entityViewSpec, widgetCore, entity.widget, 0);
    }
    widgetDetail.singleFieldSelect = true;
    attributes.push(widgetDetail);

    const widgetRelationshipSpecCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'widgetRelationshipSpec');
    widgetRelationshipSpecCore.soGet = SOGetWidgetRelationshipSpec.construct({
      widget: new SOGetWidgetWidgetRelationshipSpecAssociation(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, widgetRelationshipSpecCore);
    widgetRelationshipSpecCore.title = heading ? heading : widgetRelationshipSpecCore.title;
    widgetRelationshipSpecCore.listTitle = 'Widget relationship spec';

    if (entity.widgetRelationshipSpec) {
        displayStringFactory.reconfigure(entity.widgetRelationshipSpec, entityViewSpec.entityCore.type);
    }
    widgetRelationshipSpecCore.configureForDisplay(entityViewSpec);

    const widgetRelationshipSpecDetail = new DetailEntry(
      heading ? heading : widgetRelationshipSpecCore.listTitle,
      entity.widgetRelationshipSpec ? displayStringFactory.primaryString : null,
      'widgetRelationshipSpec',
      BaseDetailType.pushedDetail,
      widgetRelationshipSpecCore,
      EditableType.always,
      putWidgetWidgetRelationshipSpecAssociationFormFieldOptions.widgetRelationshipSpec
    );
    if (entity.widgetRelationshipSpec) {
      widgetRelationshipSpecDetail.cachedListEntry = widgetRelationshipSpecCore.makeListEntry(entityViewSpec, widgetRelationshipSpecCore, entity.widgetRelationshipSpec, 0);
    }
    widgetRelationshipSpecDetail.singleFieldSelect = true;
    attributes.push(widgetRelationshipSpecDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
