import { Type } from 'class-transformer';
import { SORespRelationship } from '../relationship/so-resp-relationship';
import { SORespWidgetRelationshipSpec } from '../widget-relationship-spec/so-resp-widget-relationship-spec';
import { SORespCommit } from '../commit/so-resp-commit';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespRelationshipWidgetRelationshipSpecAssociation implements ObjectiveProtocol {

  entityType: string = 'relationshipWidgetRelationshipSpecAssociation';
  uid: string;
  @Type(() => SORespRelationship)
  relationship: SORespRelationship;
  @Type(() => SORespWidgetRelationshipSpec)
  widget: SORespWidgetRelationshipSpec;
  @Type(() => SORespCommit)
  commit: SORespCommit;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
}