import {SORespEntityRelationshipAssociation} from '../../core/entity-relationship-association/so-resp-entity-relationship-association';
import {EntityRelationshipAssociationCore} from './entity-relationship-association.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putEntityRelationshipAssociationFormFieldOptions} from './put-entity-relationship-association-form-fields';
import {SOGetEntityRelationshipAssociation} from '../../core/entity-relationship-association/so-get-entity-relationship-association';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {SOGetRelationship} from '../../core/relationship/so-get-relationship';
import {SOGetApp} from '../../core/app/so-get-app';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class EntityRelationshipAssociationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespEntityRelationshipAssociation = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []


    const entityCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'entity');
    entityCore.soGet = SOGetEntity.construct({
      app: new SOGetApp(activeUserService.getScope('app').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, entityCore)
    entityCore.title = heading ? heading : entityCore.title
    entityCore.listTitle = 'Entity'

    if (entity.entity) {
        displayStringFactory.reconfigure(entity.entity, entityViewSpec.entityCore.type)
    }
    entityCore.configureForDisplay(entityViewSpec)

    const entityDetail = new DetailEntry(
      heading ? heading : entityCore.listTitle,
      entity.entity ? displayStringFactory.primaryString : null,
      'entity',
      BaseDetailType.pushedDetail,
      entityCore,
      EditableType.always,
      putEntityRelationshipAssociationFormFieldOptions.entity
    )
    if (entity.entity) {
      entityDetail.cachedListEntry = entityCore.makeListEntry(entityViewSpec, entityCore, entity.entity, 0)
    }
    entityDetail.singleFieldSelect = true
    attributes.push(entityDetail);

    const relationshipCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'relationship');
    relationshipCore.soGet = SOGetRelationship.construct({
      entity: new SOGetEntityRelationshipAssociation(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, relationshipCore)
    relationshipCore.title = heading ? heading : relationshipCore.title
    relationshipCore.listTitle = 'Relationship'

    if (entity.relationship) {
        displayStringFactory.reconfigure(entity.relationship, entityViewSpec.entityCore.type)
    }
    relationshipCore.configureForDisplay(entityViewSpec)

    const relationshipDetail = new DetailEntry(
      heading ? heading : relationshipCore.listTitle,
      entity.relationship ? displayStringFactory.primaryString : null,
      'relationship',
      BaseDetailType.pushedDetail,
      relationshipCore,
      EditableType.always,
      putEntityRelationshipAssociationFormFieldOptions.relationship
    )
    if (entity.relationship) {
      relationshipDetail.cachedListEntry = relationshipCore.makeListEntry(entityViewSpec, relationshipCore, entity.relationship, 0)
    }
    relationshipDetail.singleFieldSelect = true
    attributes.push(relationshipDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
