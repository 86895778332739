import {SOPutResponseAttributeSpec} from '../../core/response-attribute-spec/so-put-response-attribute-spec';
import {ResponseAttributeSpecCore} from './response-attribute-spec.core';
import {SOGetResponseAttributeSpec} from '../../core/response-attribute-spec/so-get-response-attribute-spec';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putResponseAttributeSpecFormFieldOptions} from './put-response-attribute-spec-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {ResponseResponseAttributeSpecAssociationCore} from '../bv-response-response-attribute-spec-association/response-response-attribute-spec-association.core';
import {SOGetResponseResponseAttributeSpecAssociation} from '../../core/response-response-attribute-spec-association/so-get-response-response-attribute-spec-association';
import {AttributeResponseAttributeSpecAssociationCore} from '../bv-attribute-response-attribute-spec-association/attribute-response-attribute-spec-association.core';
import {SOGetAttributeResponseAttributeSpecAssociation} from '../../core/attribute-response-attribute-spec-association/so-get-attribute-response-attribute-spec-association';

export class RAPutResponseAttributeSpec extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putResponseAttributeSpecFormFieldOptions);

  RequestClass = SOPutResponseAttributeSpec

  constructor(
    public entityCore: ResponseAttributeSpecCore
  ) {
    super();
    if (putResponseAttributeSpecFormFieldOptions.response) {
      putResponseAttributeSpecFormFieldOptions.response.makeCore = true;
    }
    if (putResponseAttributeSpecFormFieldOptions.attribute) {
      putResponseAttributeSpecFormFieldOptions.attribute.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('isRequired'),
      this.getFormValueFromIdentifier('defaultValue'),
      [this.getFormValueFromIdentifier('response', SOGetResponseResponseAttributeSpecAssociation)],
      [this.getFormValueFromIdentifier('attribute', SOGetAttributeResponseAttributeSpecAssociation)],
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}