import {SORespWidget} from '../../core/widget/so-resp-widget';
import {WidgetCore} from './widget.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putWidgetFormFieldOptions} from './put-widget-form-fields';
import {SOGetWidget} from '../../core/widget/so-get-widget';
import {SOGetWidgetWidgetAttributeSpecAssociation} from '../../core/widget-widget-attribute-spec-association/so-get-widget-widget-attribute-spec-association';
import {SOGetWidgetWidgetRelationshipSpecAssociation} from '../../core/widget-widget-relationship-spec-association/so-get-widget-widget-relationship-spec-association';
import {SOGetEntityWidgetAssociation} from '../../core/entity-widget-association/so-get-entity-widget-association';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class WidgetDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespWidget = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const nameDetail = new DetailEntry(
        'Name',
        entity.name,
        'name',
        BaseDetailType.text,
        null,
        EditableType.always,
        putWidgetFormFieldOptions.name
    )
    attributes.push(nameDetail)

    const widgetTypeDetail = new DetailEntry(
        'Widget type',
        entity.widgetType,
        'widgetType',
        BaseDetailType.toggleGroup,
        null,
        EditableType.always,
        putWidgetFormFieldOptions.widgetType
    )
    attributes.push(widgetTypeDetail)

    const isDefaultDetail = new DetailEntry(
        'Is default',
        entity.isDefault,
        'isDefault',
        BaseDetailType.toggle,
        null,
        EditableType.always,
        putWidgetFormFieldOptions.isDefault
    )
    attributes.push(isDefaultDetail)


    const attributeSpecsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'widgetWidgetAttributeSpecAssociation');
    attributeSpecsCore.soGet = SOGetWidgetWidgetAttributeSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, attributeSpecsCore)
    attributeSpecsCore.title = heading ? heading : attributeSpecsCore.title
    attributeSpecsCore.listTitle = 'Attribute specs'

    attributeSpecsCore.configureForDisplay(entityViewSpec)

    const attributeSpecsDetail = new DetailEntry(
      heading ? heading : attributeSpecsCore.listTitle,
      null,
      'attributeSpecs',
      BaseDetailType.embeddedList,
      attributeSpecsCore,
      EditableType.never
    )
    attributes.push(attributeSpecsDetail);

    const relationshipSpecsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'widgetWidgetRelationshipSpecAssociation');
    relationshipSpecsCore.soGet = SOGetWidgetWidgetRelationshipSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, relationshipSpecsCore)
    relationshipSpecsCore.title = heading ? heading : relationshipSpecsCore.title
    relationshipSpecsCore.listTitle = 'Relationship specs'

    relationshipSpecsCore.configureForDisplay(entityViewSpec)

    const relationshipSpecsDetail = new DetailEntry(
      heading ? heading : relationshipSpecsCore.listTitle,
      null,
      'relationshipSpecs',
      BaseDetailType.embeddedList,
      relationshipSpecsCore,
      EditableType.never
    )
    attributes.push(relationshipSpecsDetail);

    const entityCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'entityWidgetAssociation');
    entityCore.soGet = SOGetEntityWidgetAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, entityCore)
    entityCore.title = heading ? heading : entityCore.title
    entityCore.listTitle = 'Entity'

    entityCore.configureForDisplay(entityViewSpec)

    const entityDetail = new DetailEntry(
      heading ? heading : entityCore.listTitle,
      null,
      'entity',
      BaseDetailType.embeddedList,
      entityCore,
      EditableType.always,
      putWidgetFormFieldOptions.entity
    )
    attributes.push(entityDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
