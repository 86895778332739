import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const nameField = new FormField(
  'name',
  'Name',
  null,
  FormFieldType.text,
  false,
  true,
  ''
)
const descriptionField = new FormField(
  'description',
  'Description',
  null,
  FormFieldType.text,
  false,
  true,
  ''
)


export const putMediaFormFieldOptions: {[key: string]: FormField} = {
  name: nameField,
  description: descriptionField,
};