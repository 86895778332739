import { Type } from 'class-transformer';
import { SORespEntityWidgetAssociation } from '../entity-widget-association/so-resp-entity-widget-association';
import { SORespOrganization } from '../organization/so-resp-organization';
import { SORespApp } from '../app/so-resp-app';
import {ENWidgetType} from '../../enums';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespWidget implements ObjectiveProtocol {

  entityType: string = 'widget';
  uid: string;
  name: string = "";
  widgetType: ENWidgetType;
  isDefault: boolean;
  @Type(() => SORespEntityWidgetAssociation)
  entity: SORespEntityWidgetAssociation[];
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
  @Type(() => SORespApp)
  app: SORespApp;
}