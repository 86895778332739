import {SOPutEnumeratorEnumeralAssociation} from '../../core/enumerator-enumeral-association/so-put-enumerator-enumeral-association';
import {EnumeratorEnumeralAssociationCore} from './enumerator-enumeral-association.core';
import {SOGetEnumeratorEnumeralAssociation} from '../../core/enumerator-enumeral-association/so-get-enumerator-enumeral-association';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putEnumeratorEnumeralAssociationFormFieldOptions} from './put-enumerator-enumeral-association-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {EnumeratorCore} from '../bv-enumerator/enumerator.core';
import {SOGetEnumerator} from '../../core/enumerator/so-get-enumerator';
import {EnumeralCore} from '../bv-enumeral/enumeral.core';
import {SOGetEnumeral} from '../../core/enumeral/so-get-enumeral';
import {CommitCore} from '../bv-commit/commit.core';
import {SOGetCommit} from '../../core/commit/so-get-commit';

export class RAPutEnumeratorEnumeralAssociation extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putEnumeratorEnumeralAssociationFormFieldOptions);

  RequestClass = SOPutEnumeratorEnumeralAssociation

  constructor(
    public entityCore: EnumeratorEnumeralAssociationCore
  ) {
    super();
    if (putEnumeratorEnumeralAssociationFormFieldOptions.enumerator) {
      putEnumeratorEnumeralAssociationFormFieldOptions.enumerator.makeCore = true;
    }
    if (putEnumeratorEnumeralAssociationFormFieldOptions.enumeral) {
      putEnumeratorEnumeralAssociationFormFieldOptions.enumeral.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier(
        'enumerator',
        SOGetEnumerator,
      ),
      this.getFormValueFromIdentifier(
        'enumeral',
        SOGetEnumeral,
      ),
      this.getFormValueFromIdentifier(
        'commit',
        SOGetCommit,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}