import { SOGetRequestRequestRelationshipSpecAssociation } from '../request-request-relationship-spec-association/so-get-request-request-relationship-spec-association';
import { SOGetRelationshipRequestRelationshipSpecAssociation } from '../relationship-request-relationship-spec-association/so-get-relationship-request-relationship-spec-association';
import { SOGetOrganization } from '../organization/so-get-organization';
import { SOGetApp } from '../app/so-get-app';


export class SOPostRequestRelationshipSpec {

  constructor(
    public isRequired: boolean,
    public request: SOGetRequestRequestRelationshipSpecAssociation[],
    public relationship: SOGetRelationshipRequestRelationshipSpecAssociation[],
    public organization: SOGetOrganization,
    public app: SOGetApp,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostRequestRelationshipSpec(
      'isRequired' in params ? params.isRequired : null,
      'request' in params ? params.request : null,
      'relationship' in params ? params.relationship : null,
      'organization' in params ? params.organization : null,
      'app' in params ? params.app : null,
    );
  }
}
