import {SOPutEntityRequestAssociation} from '../../core/entity-request-association/so-put-entity-request-association';
import {EntityRequestAssociationCore} from './entity-request-association.core';
import {SOGetEntityRequestAssociation} from '../../core/entity-request-association/so-get-entity-request-association';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putEntityRequestAssociationFormFieldOptions} from './put-entity-request-association-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {EntityCore} from '../bv-entity/entity.core';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {RequestCore} from '../bv-request/request.core';
import {SOGetRequest} from '../../core/request/so-get-request';
import {CommitCore} from '../bv-commit/commit.core';
import {SOGetCommit} from '../../core/commit/so-get-commit';

export class RAPutEntityRequestAssociation extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putEntityRequestAssociationFormFieldOptions);

  RequestClass = SOPutEntityRequestAssociation

  constructor(
    public entityCore: EntityRequestAssociationCore
  ) {
    super();
    if (putEntityRequestAssociationFormFieldOptions.entity) {
      putEntityRequestAssociationFormFieldOptions.entity.makeCore = true;
    }
    if (putEntityRequestAssociationFormFieldOptions.request) {
      putEntityRequestAssociationFormFieldOptions.request.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier(
        'entity',
        SOGetEntity,
      ),
      this.getFormValueFromIdentifier(
        'request',
        SOGetRequest,
      ),
      this.getFormValueFromIdentifier(
        'commit',
        SOGetCommit,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}