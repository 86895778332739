import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOEntityChildrenAssociationService} from '../../core/entity-children-association/eo-entity-children-association';
import {EntityChildrenAssociationListEntryFactory} from './entity-children-association-list-entry-factory';
import {EntityChildrenAssociationDetailFactory} from './entity-children-association-detail-factory';
import {SOGetEntityChildrenAssociation} from '../../core/entity-children-association/so-get-entity-children-association';
import {RAPutEntityChildrenAssociation} from './ra-put-entity-children-association';
import {RAPostEntityChildrenAssociation} from './ra-post-entity-children-association';
import {RADeleteEntityChildrenAssociation} from './ra-delete-entity-children-association';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetCommit} from '../../core/commit/so-get-commit'
import {SOGetOrganization} from '../../core/organization/so-get-organization'


export class EntityChildrenAssociationCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'entityChildrenAssociation';
  title = 'Entity children association';
  listEntryFactory = new EntityChildrenAssociationListEntryFactory();
  detailFactory = new EntityChildrenAssociationDetailFactory();

  entityService: EOEntityChildrenAssociationService;
  soGetClass = SOGetEntityChildrenAssociation;
  soGet: SOGetEntityChildrenAssociation;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOEntityChildrenAssociationService
    );
    this.adapters = [
      new RAPostEntityChildrenAssociation(this),
      new RAPutEntityChildrenAssociation(this),
      new RADeleteEntityChildrenAssociation(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetEntityChildrenAssociation();
    this.soGet.commit = new SOGetCommit(
      this.activeUserService.getScope('commit').uid
    );
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
