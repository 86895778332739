import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostRelationship } from './so-post-relationship';
import { SOGetRelationship } from './so-get-relationship';
import { SOPutRelationship } from './so-put-relationship';
import { SODeleteRelationship } from './so-delete-relationship';
import {SORespRelationship} from './so-resp-relationship';
import {SOPluralRelationship} from './so-plural-relationship';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EORelationshipService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'relationship',
      entityCore
    );
  }

  /** GET Relationship by id. Will 404 if id not found */
  get(request: SOGetRelationship): Observable<SOPluralRelationship> {
    return this.httpClient.get<SOPluralRelationship>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralRelationship, resp) }),
      tap(_ => this.log(`fetched Relationship`)),
      catchError(this.handleError<SOPluralRelationship>(`get Relationship`))
    );
  }

  /** POST: add a new Relationship to the server */
  post(request: SOPostRelationship): Observable<SORespRelationship> {
    return this.httpClient.post<SORespRelationship>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespRelationship, resp) }),
      tap((response: SORespRelationship) => this.log(`added Relationship w/ id=${response.uid}`)),
      catchError(this.handleError<SORespRelationship>('Relationship post'))
    );
  }

  /** PUT: update Relationship  */
  put(request: SOPutRelationship): Observable<SORespRelationship> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespRelationship>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespRelationship, resp) }),
      tap((response: SORespRelationship) => this.log(`edited Relationship w/ uid=${uid}`)),
      catchError(this.handleError<SORespRelationship>('Relationship put'))
    );
  }

  /** DELETE: delete the Relationship from the server */
  delete(request: SODeleteRelationship): Observable<SORespRelationship> {
    return this.httpClient.delete<SORespRelationship>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Relationship uid=${request.uid}`)),
      catchError(this.handleError<SORespRelationship>('delete Relationship'))
    );
  }
}
