import { SOGetEntity } from '../entity/so-get-entity';
import { SOGetCommit } from '../commit/so-get-commit';


export class SOPutEntityChildrenAssociation {

  constructor(
    public uid: string,
    public parent: SOGetEntity = null,
    public child: SOGetEntity[] = null,
    public commit: SOGetCommit = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutEntityChildrenAssociation(
      'uid' in params ? params.uid : null,
      'parent' in params ? params.parent : null,
      'child' in params ? params.child : null,
      'commit' in params ? params.commit : null,
    );
  }
}
