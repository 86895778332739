import {SOPostWidgetAttributeSpec} from '../../core/widget-attribute-spec/so-post-widget-attribute-spec';
import {WidgetAttributeSpecCore} from './widget-attribute-spec.core';
import {SOGetWidgetAttributeSpec} from '../../core/widget-attribute-spec/so-get-widget-attribute-spec';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postWidgetAttributeSpecFormFieldOptions} from './post-widget-attribute-spec-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {WidgetWidgetAttributeSpecAssociationCore} from '../bv-widget-widget-attribute-spec-association/widget-widget-attribute-spec-association.core';
import {SOGetWidgetWidgetAttributeSpecAssociation} from '../../core/widget-widget-attribute-spec-association/so-get-widget-widget-attribute-spec-association';
import {AttributeWidgetAttributeSpecAssociationCore} from '../bv-attribute-widget-attribute-spec-association/attribute-widget-attribute-spec-association.core';
import {SOGetAttributeWidgetAttributeSpecAssociation} from '../../core/attribute-widget-attribute-spec-association/so-get-attribute-widget-attribute-spec-association';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {AppCore} from '../bv-app/app.core';
import {SOGetApp} from '../../core/app/so-get-app';

export class RAPostWidgetAttributeSpec extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postWidgetAttributeSpecFormFieldOptions);

  RequestClass = SOPostWidgetAttributeSpec

  constructor(
    public entityCore: WidgetAttributeSpecCore
  ) {
    super();
    if (postWidgetAttributeSpecFormFieldOptions.widget) {
      postWidgetAttributeSpecFormFieldOptions.widget.makeCore = true;
    }
    if (postWidgetAttributeSpecFormFieldOptions.attribute) {
      postWidgetAttributeSpecFormFieldOptions.attribute.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      [this.getFormValueFromIdentifier('widget', SOGetWidgetWidgetAttributeSpecAssociation)],
      [this.getFormValueFromIdentifier('attribute', SOGetAttributeWidgetAttributeSpecAssociation)],
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier(
        'app',
        SOGetApp,
        true,
      ),
      this.getFormValueFromIdentifier('isRequired'),
      this.getFormValueFromIdentifier('defaultValue'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}