import { Type } from 'class-transformer';
import { SORespEntityRelationshipAssociation } from '../entity-relationship-association/so-resp-entity-relationship-association';
import { SORespRelationshipLink } from '../relationship-link/so-resp-relationship-link';
import { SORespOrganization } from '../organization/so-resp-organization';
import { SORespApp } from '../app/so-resp-app';
import {ENMultiplicity} from '../../enums';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespRelationship implements ObjectiveProtocol {

  entityType: string = 'relationship';
  uid: string;
  @Type(() => SORespEntityRelationshipAssociation)
  entity: SORespEntityRelationshipAssociation[];
  name: string;
  multiplicity: ENMultiplicity;
  @Type(() => SORespRelationshipLink)
  relationshipLink: SORespRelationshipLink = null;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
  @Type(() => SORespApp)
  app: SORespApp;
}