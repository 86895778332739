import {SOPostRequest} from '../../core/request/so-post-request';
import {RequestCore} from './request.core';
import {SOGetRequest} from '../../core/request/so-get-request';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postRequestFormFieldOptions} from './post-request-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {EntityRequestAssociationCore} from '../bv-entity-request-association/entity-request-association.core';
import {SOGetEntityRequestAssociation} from '../../core/entity-request-association/so-get-entity-request-association';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {AppCore} from '../bv-app/app.core';
import {SOGetApp} from '../../core/app/so-get-app';

export class RAPostRequest extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postRequestFormFieldOptions);

  RequestClass = SOPostRequest

  constructor(
    public entityCore: RequestCore
  ) {
    super();
    if (postRequestFormFieldOptions.entity) {
      postRequestFormFieldOptions.entity.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('requestType'),
      [this.getFormValueFromIdentifier('entity', SOGetEntityRequestAssociation)],
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier(
        'app',
        SOGetApp,
        true,
      ),
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('isDefault'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}