import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const emailField = new FormField(
  'email',
  'Email',
  null,
  FormFieldType.text,
  true,
)
const roleField = new FormField(
  'role',
  'Role',
  'role',
  FormFieldType.pushedSelectionList,
  true,
)
const organizationField = new FormField(
  'organization',
  'Organization',
  'organization',
  FormFieldType.pushedSelectionList,
  true,
)


export const postOrganizationInviteFormFieldOptions: {[key: string]: FormField} = {
  email: emailField,
  role: roleField,
  organization: organizationField,
};