import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const nameField = new FormField(
  'name',
  'Name',
  null,
  FormFieldType.text,
  false,
)
const commitCodableAssociationsField = new FormField(
  'commitCodableAssociations',
  'Commit codable associations',
  'commitCodableAssociation',
  FormFieldType.pushedSelectionList,
  false,
)


export const putEnumeratorFormFieldOptions: {[key: string]: FormField} = {
  name: nameField,
  commitCodableAssociations: commitCodableAssociationsField,
};