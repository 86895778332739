import { SOGetApp } from '../app/so-get-app';
import { SOGetOrganization } from '../organization/so-get-organization';
import { SOGetCommitRelationshipLinkAssociation } from '../commit-relationship-link-association/so-get-commit-relationship-link-association';


export class SOPostRelationshipLink {

  constructor(
    public app: SOGetApp,
    public organization: SOGetOrganization,
    public commitRelationshipLinkAssociations: SOGetCommitRelationshipLinkAssociation[],
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostRelationshipLink(
      'app' in params ? params.app : null,
      'organization' in params ? params.organization : null,
      'commitRelationshipLinkAssociations' in params ? params.commitRelationshipLinkAssociations : null,
    );
  }
}
