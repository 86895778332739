import {SORespRoleGrant} from '../../core/role-grant/so-resp-role-grant';
import {RoleGrantCore} from './role-grant.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putRoleGrantFormFieldOptions} from './put-role-grant-form-fields';
import {SOGetRoleGrant} from '../../core/role-grant/so-get-role-grant';
import {SOGetUser} from '../../core/user/so-get-user';
import {SOGetRole} from '../../core/role/so-get-role';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetToken} from '../../core/token/so-get-token';


export class RoleGrantDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespRoleGrant = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const dateCreatedDetail = new DetailEntry(
        'Date created',
        activeUserService.time.niceDate(entity.dateCreated),
        'dateCreated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateCreatedDetail)

    const isDefaultDetail = new DetailEntry(
        'Is default',
        entity.isDefault,
        'isDefault',
        BaseDetailType.toggle,
        null,
        EditableType.always,
        putRoleGrantFormFieldOptions.isDefault
    )
    attributes.push(isDefaultDetail)

    const statusDetail = new DetailEntry(
        'Status',
        entity.status,
        'status',
        BaseDetailType.toggleGroup,
        null,
        EditableType.always,
        putRoleGrantFormFieldOptions.status
    )
    attributes.push(statusDetail)


    const userCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'user');
    userCore.soGet = SOGetUser.construct({
    });
    heading = getHeading(entityViewSpec.entityCore, userCore)
    userCore.title = heading ? heading : userCore.title
    userCore.listTitle = 'User'

    if (entity.user) {
        displayStringFactory.reconfigure(entity.user, entityViewSpec.entityCore.type)
    }
    userCore.configureForDisplay(entityViewSpec)

    const userDetail = new DetailEntry(
      heading ? heading : userCore.listTitle,
      entity.user ? displayStringFactory.primaryString : null,
      'user',
      BaseDetailType.pushedDetail,
      userCore,
      EditableType.never
    )
    if (entity.user) {
      userDetail.cachedListEntry = userCore.makeListEntry(entityViewSpec, userCore, entity.user, 0)
    }
    userDetail.singleFieldSelect = true
    attributes.push(userDetail);

    const roleCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'role');
    roleCore.soGet = SOGetRole.construct({
    });
    heading = getHeading(entityViewSpec.entityCore, roleCore)
    roleCore.title = heading ? heading : roleCore.title
    roleCore.listTitle = 'Role'

    if (entity.role) {
        displayStringFactory.reconfigure(entity.role, entityViewSpec.entityCore.type)
    }
    roleCore.configureForDisplay(entityViewSpec)

    const roleDetail = new DetailEntry(
      heading ? heading : roleCore.listTitle,
      entity.role ? displayStringFactory.primaryString : null,
      'role',
      BaseDetailType.pushedDetail,
      roleCore,
      EditableType.never
    )
    if (entity.role) {
      roleDetail.cachedListEntry = roleCore.makeListEntry(entityViewSpec, roleCore, entity.role, 0)
    }
    roleDetail.singleFieldSelect = true
    attributes.push(roleDetail);

    const organizationCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'organization');
    organizationCore.soGet = SOGetOrganization.construct({
    });
    heading = getHeading(entityViewSpec.entityCore, organizationCore)
    organizationCore.title = heading ? heading : organizationCore.title
    organizationCore.listTitle = 'Organization'

    if (entity.organization) {
        displayStringFactory.reconfigure(entity.organization, entityViewSpec.entityCore.type)
    }
    organizationCore.configureForDisplay(entityViewSpec)

    const organizationDetail = new DetailEntry(
      heading ? heading : organizationCore.listTitle,
      entity.organization ? displayStringFactory.primaryString : null,
      'organization',
      BaseDetailType.pushedDetail,
      organizationCore,
      EditableType.never
    )
    if (entity.organization) {
      organizationDetail.cachedListEntry = organizationCore.makeListEntry(entityViewSpec, organizationCore, entity.organization, 0)
    }
    organizationDetail.singleFieldSelect = true
    attributes.push(organizationDetail);

    const tokensCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'token');
    tokensCore.soGet = SOGetToken.construct({
      roleGrant: new SOGetRoleGrant(entity.uid),
    });
    heading = getHeading(entityViewSpec.entityCore, tokensCore)
    tokensCore.title = heading ? heading : tokensCore.title
    tokensCore.listTitle = 'Tokens'

    tokensCore.configureForDisplay(entityViewSpec)

    const tokensDetail = new DetailEntry(
      heading ? heading : tokensCore.listTitle,
      null,
      'tokens',
      BaseDetailType.pushedList,
      tokensCore,
      EditableType.never
    )
    attributes.push(tokensDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
