import { SOGetStoreStoreRelationshipSpecAssociation } from '../store-store-relationship-spec-association/so-get-store-store-relationship-spec-association';
import { SOGetRelationshipStoreRelationshipSpecAssociation } from '../relationship-store-relationship-spec-association/so-get-relationship-store-relationship-spec-association';


export class SOPutStoreRelationshipSpec {

  constructor(
    public uid: string,
    public isRequired: boolean = null,
    public store: SOGetStoreStoreRelationshipSpecAssociation[] = null,
    public relationship: SOGetRelationshipStoreRelationshipSpecAssociation[] = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutStoreRelationshipSpec(
      'uid' in params ? params.uid : null,
      'isRequired' in params ? params.isRequired : null,
      'store' in params ? params.store : null,
      'relationship' in params ? params.relationship : null,
    );
  }
}
