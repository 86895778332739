import {SORespStoreStoreAttributeSpecAssociation} from '../../core/store-store-attribute-spec-association/so-resp-store-store-attribute-spec-association';
import {StoreStoreAttributeSpecAssociationCore} from './store-store-attribute-spec-association.core';
import { ListEntry, ListEntryFactory, ListEntryFactoryProtocol} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';


export class StoreStoreAttributeSpecAssociationListEntryFactory  extends ListEntryFactory implements ListEntryFactoryProtocol {

  makeListEntry(parentCoreViewSpec, entityCore: EntityCoreProtocol, entity: SORespStoreStoreAttributeSpecAssociation, section: number = 0): ListEntry {
    const resp = super.makeListEntry(parentCoreViewSpec, entityCore, entity, section)
    return resp
  }
}
