import {SORespRequestRequestRelationshipSpecAssociation} from '../../core/request-request-relationship-spec-association/so-resp-request-request-relationship-spec-association';
import {RequestRequestRelationshipSpecAssociationCore} from './request-request-relationship-spec-association.core';
import { ListEntry, ListEntryFactory, ListEntryFactoryProtocol} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';


export class RequestRequestRelationshipSpecAssociationListEntryFactory  extends ListEntryFactory implements ListEntryFactoryProtocol {

  makeListEntry(parentCoreViewSpec, entityCore: EntityCoreProtocol, entity: SORespRequestRequestRelationshipSpecAssociation, section: number = 0): ListEntry {
    const resp = super.makeListEntry(parentCoreViewSpec, entityCore, entity, section);
    return resp;
  }
}
