import { SOGetStoreStoreRelationshipSpecAssociation } from '../store-store-relationship-spec-association/so-get-store-store-relationship-spec-association';
import { SOGetRelationshipStoreRelationshipSpecAssociation } from '../relationship-store-relationship-spec-association/so-get-relationship-store-relationship-spec-association';
import { SOGetOrganization } from '../organization/so-get-organization';
import { SOGetApp } from '../app/so-get-app';


export class SOPostStoreRelationshipSpec {

  constructor(
    public isRequired: boolean,
    public store: SOGetStoreStoreRelationshipSpecAssociation[],
    public relationship: SOGetRelationshipStoreRelationshipSpecAssociation[],
    public organization: SOGetOrganization,
    public app: SOGetApp,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostStoreRelationshipSpec(
      'isRequired' in params ? params.isRequired : null,
      'store' in params ? params.store : null,
      'relationship' in params ? params.relationship : null,
      'organization' in params ? params.organization : null,
      'app' in params ? params.app : null,
    );
  }
}
