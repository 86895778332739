import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOIdentityService} from '../../core/identity/eo-identity';
import {IdentityListEntryFactory} from './identity-list-entry-factory';
import {IdentityDetailFactory} from './identity-detail-factory';
import {SOGetIdentity} from '../../core/identity/so-get-identity';
import {RAPutIdentity} from './ra-put-identity';
import {RAPostIdentity} from './ra-post-identity';
import {RADeleteIdentity} from './ra-delete-identity';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';


export class IdentityCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'identity';
  title = 'Identity';
  listEntryFactory = new IdentityListEntryFactory();
  detailFactory = new IdentityDetailFactory();

  entityService: EOIdentityService;
  soGetClass = SOGetIdentity;
  soGet: SOGetIdentity;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOIdentityService
    );
    this.adapters = [
      new RAPostIdentity(this),
      new RAPutIdentity(this),
      new RADeleteIdentity(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetIdentity();
    this.soGet.queryOptions.limit = this.limit;
  }


}
