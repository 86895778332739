import {SOPutEnumeral} from '../../core/enumeral/so-put-enumeral';
import {EnumeralCore} from './enumeral.core';
import {SOGetEnumeral} from '../../core/enumeral/so-get-enumeral';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putEnumeralFormFieldOptions} from './put-enumeral-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {EnumeratorEnumeralAssociationCore} from '../bv-enumerator-enumeral-association/enumerator-enumeral-association.core';
import {SOGetEnumeratorEnumeralAssociation} from '../../core/enumerator-enumeral-association/so-get-enumerator-enumeral-association';

export class RAPutEnumeral extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putEnumeralFormFieldOptions);

  RequestClass = SOPutEnumeral

  constructor(
    public entityCore: EnumeralCore
  ) {
    super();
    if (putEnumeralFormFieldOptions.enumerators) {
      putEnumeralFormFieldOptions.enumerators.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      [this.getFormValueFromIdentifier('enumerators', SOGetEnumeratorEnumeralAssociation)],
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}