import {SORespResponseAttributeSpec} from '../../core/response-attribute-spec/so-resp-response-attribute-spec';
import {ResponseAttributeSpecCore} from './response-attribute-spec.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putResponseAttributeSpecFormFieldOptions} from './put-response-attribute-spec-form-fields';
import {SOGetResponseAttributeSpec} from '../../core/response-attribute-spec/so-get-response-attribute-spec';
import {SOGetResponseResponseAttributeSpecAssociation} from '../../core/response-response-attribute-spec-association/so-get-response-response-attribute-spec-association';
import {SOGetAttributeResponseAttributeSpecAssociation} from '../../core/attribute-response-attribute-spec-association/so-get-attribute-response-attribute-spec-association';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class ResponseAttributeSpecDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespResponseAttributeSpec = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const isRequiredDetail = new DetailEntry(
        'Is required',
        entity.isRequired,
        'isRequired',
        BaseDetailType.toggle,
        null,
        EditableType.always,
        putResponseAttributeSpecFormFieldOptions.isRequired
    )
    attributes.push(isRequiredDetail)

    const defaultValueDetail = new DetailEntry(
        'Default value',
        entity.defaultValue,
        'defaultValue',
        BaseDetailType.text,
        null,
        EditableType.always,
        putResponseAttributeSpecFormFieldOptions.defaultValue
    )
    attributes.push(defaultValueDetail)


    const responseCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'responseResponseAttributeSpecAssociation');
    responseCore.soGet = SOGetResponseResponseAttributeSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, responseCore)
    responseCore.title = heading ? heading : responseCore.title
    responseCore.listTitle = 'Response'

    responseCore.configureForDisplay(entityViewSpec)

    const responseDetail = new DetailEntry(
      heading ? heading : responseCore.listTitle,
      null,
      'response',
      BaseDetailType.embeddedList,
      responseCore,
      EditableType.always,
      putResponseAttributeSpecFormFieldOptions.response
    )
    attributes.push(responseDetail);

    const attributeCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'attributeResponseAttributeSpecAssociation');
    attributeCore.soGet = SOGetAttributeResponseAttributeSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, attributeCore)
    attributeCore.title = heading ? heading : attributeCore.title
    attributeCore.listTitle = 'Attribute'

    attributeCore.configureForDisplay(entityViewSpec)

    const attributeDetail = new DetailEntry(
      heading ? heading : attributeCore.listTitle,
      null,
      'attribute',
      BaseDetailType.embeddedList,
      attributeCore,
      EditableType.always,
      putResponseAttributeSpecFormFieldOptions.attribute
    )
    attributes.push(attributeDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
