import { SOGetEntityStoreAssociation } from '../entity-store-association/so-get-entity-store-association';
import {ENStoreType} from '../../enums';


export class SOPutStore {

  constructor(
    public uid: string,
    public name: string = null,
    public storeType: ENStoreType = null,
    public isDefault: boolean = null,
    public entity: SOGetEntityStoreAssociation[] = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutStore(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'storeType' in params ? params.storeType : null,
      'isDefault' in params ? params.isDefault : null,
      'entity' in params ? params.entity : null,
    );
  }
}
