import {MMQueryOptions} from 'ngx-lionheart-material';
import {QueryProtocol} from 'ngx-lionheart-material';


export class SOGetUser implements QueryProtocol {

  constructor(
    public uid: string = null,
    public username: string = null,
    public password: string = null,
    public email: string = null,
    public emailVerificationToken: string = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetUser(
      'uid' in params ? params.uid : null,
      'username' in params ? params.username : null,
      'password' in params ? params.password : null,
      'email' in params ? params.email : null,
      'emailVerificationToken' in params ? params.emailVerificationToken : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
