import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOCertificateService} from '../../core/certificate/eo-certificate';
import {CertificateListEntryFactory} from './certificate-list-entry-factory';
import {CertificateDetailFactory} from './certificate-detail-factory';
import {SOGetCertificate} from '../../core/certificate/so-get-certificate';
import {RAPutCertificate} from './ra-put-certificate';
import {RAPostCertificate} from './ra-post-certificate';
import {RADeleteCertificate} from './ra-delete-certificate';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';


export class CertificateCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'certificate';
  title = 'Certificate';
  listEntryFactory = new CertificateListEntryFactory();
  detailFactory = new CertificateDetailFactory();

  entityService: EOCertificateService;
  soGetClass = SOGetCertificate;
  soGet: SOGetCertificate;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOCertificateService
    );
    this.adapters = [
      new RAPostCertificate(this),
      new RAPutCertificate(this),
      new RADeleteCertificate(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetCertificate();
    this.soGet.queryOptions.limit = this.limit;
  }


}
