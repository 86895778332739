import { Type } from 'class-transformer';
import { SORespStore } from '../store/so-resp-store';
import { SORespStoreRelationshipSpec } from '../store-relationship-spec/so-resp-store-relationship-spec';
import { SORespCommit } from '../commit/so-resp-commit';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespStoreStoreRelationshipSpecAssociation implements ObjectiveProtocol {

  entityType: string = 'storeStoreRelationshipSpecAssociation';
  uid: string;
  @Type(() => SORespStore)
  store: SORespStore;
  @Type(() => SORespStoreRelationshipSpec)
  storeRelationshipSpec: SORespStoreRelationshipSpec;
  @Type(() => SORespCommit)
  commit: SORespCommit;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
}