import { Type } from 'class-transformer';
import { SORespCommit } from '../commit/so-resp-commit';
import { SORespRelationshipLink } from '../relationship-link/so-resp-relationship-link';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespCommitRelationshipLinkAssociation implements ObjectiveProtocol {

  entityType: string = 'commitRelationshipLinkAssociation';
  uid: string;
  @Type(() => SORespCommit)
  commit: SORespCommit;
  @Type(() => SORespRelationshipLink)
  entity: SORespRelationshipLink;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
}