import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const certificateField = new FormField(
  'certificate',
  'Certificate',
  'certificate',
  FormFieldType.pushedSelectionList,
  false,
)
const roleGrantField = new FormField(
  'roleGrant',
  'Role grant',
  'roleGrant',
  FormFieldType.pushedSelectionList,
  false,
)
const identityTokenField = new FormField(
  'identityToken',
  'Identity token',
  null,
  FormFieldType.text,
  false,
)


export const postTokenFormFieldOptions: {[key: string]: FormField} = {
  certificate: certificateField,
  roleGrant: roleGrantField,
  identityToken: identityTokenField,
};