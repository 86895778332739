import {SOPostStoreAttributeSpec} from '../../core/store-attribute-spec/so-post-store-attribute-spec';
import {StoreAttributeSpecCore} from './store-attribute-spec.core';
import {SOGetStoreAttributeSpec} from '../../core/store-attribute-spec/so-get-store-attribute-spec';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postStoreAttributeSpecFormFieldOptions} from './post-store-attribute-spec-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {StoreStoreAttributeSpecAssociationCore} from '../bv-store-store-attribute-spec-association/store-store-attribute-spec-association.core';
import {SOGetStoreStoreAttributeSpecAssociation} from '../../core/store-store-attribute-spec-association/so-get-store-store-attribute-spec-association';
import {AttributeStoreAttributeSpecAssociationCore} from '../bv-attribute-store-attribute-spec-association/attribute-store-attribute-spec-association.core';
import {SOGetAttributeStoreAttributeSpecAssociation} from '../../core/attribute-store-attribute-spec-association/so-get-attribute-store-attribute-spec-association';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {AppCore} from '../bv-app/app.core';
import {SOGetApp} from '../../core/app/so-get-app';

export class RAPostStoreAttributeSpec extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postStoreAttributeSpecFormFieldOptions);

  RequestClass = SOPostStoreAttributeSpec

  constructor(
    public entityCore: StoreAttributeSpecCore
  ) {
    super();
    if (postStoreAttributeSpecFormFieldOptions.store) {
      postStoreAttributeSpecFormFieldOptions.store.makeCore = true;
    }
    if (postStoreAttributeSpecFormFieldOptions.attribute) {
      postStoreAttributeSpecFormFieldOptions.attribute.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('isRequired'),
      [this.getFormValueFromIdentifier('store', SOGetStoreStoreAttributeSpecAssociation)],
      [this.getFormValueFromIdentifier('attribute', SOGetAttributeStoreAttributeSpecAssociation)],
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier(
        'app',
        SOGetApp,
        true,
      ),
      this.getFormValueFromIdentifier('defaultValue'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}