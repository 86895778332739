import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostWidgetAttributeSpec } from './so-post-widget-attribute-spec';
import { SOGetWidgetAttributeSpec } from './so-get-widget-attribute-spec';
import { SOPutWidgetAttributeSpec } from './so-put-widget-attribute-spec';
import { SODeleteWidgetAttributeSpec } from './so-delete-widget-attribute-spec';
import {SORespWidgetAttributeSpec} from './so-resp-widget-attribute-spec';
import {SOPluralWidgetAttributeSpec} from './so-plural-widget-attribute-spec';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOWidgetAttributeSpecService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'widget_attribute_spec',
      entityCore
    );
  }

  /** GET WidgetAttributeSpec by id. Will 404 if id not found */
  get(request: SOGetWidgetAttributeSpec): Observable<SOPluralWidgetAttributeSpec> {
    return this.httpClient.get<SOPluralWidgetAttributeSpec>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralWidgetAttributeSpec, resp) }),
      tap(_ => this.log(`fetched WidgetAttributeSpec`)),
      catchError(this.handleError<SOPluralWidgetAttributeSpec>(`get WidgetAttributeSpec`))
    );
  }

  /** POST: add a new WidgetAttributeSpec to the server */
  post(request: SOPostWidgetAttributeSpec): Observable<SORespWidgetAttributeSpec> {
    return this.httpClient.post<SORespWidgetAttributeSpec>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespWidgetAttributeSpec, resp) }),
      tap((response: SORespWidgetAttributeSpec) => this.log(`added WidgetAttributeSpec w/ id=${response.uid}`)),
      catchError(this.handleError<SORespWidgetAttributeSpec>('WidgetAttributeSpec post'))
    );
  }

  /** PUT: update WidgetAttributeSpec  */
  put(request: SOPutWidgetAttributeSpec): Observable<SORespWidgetAttributeSpec> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespWidgetAttributeSpec>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespWidgetAttributeSpec, resp) }),
      tap((response: SORespWidgetAttributeSpec) => this.log(`edited WidgetAttributeSpec w/ uid=${uid}`)),
      catchError(this.handleError<SORespWidgetAttributeSpec>('WidgetAttributeSpec put'))
    );
  }

  /** DELETE: delete the WidgetAttributeSpec from the server */
  delete(request: SODeleteWidgetAttributeSpec): Observable<SORespWidgetAttributeSpec> {
    return this.httpClient.delete<SORespWidgetAttributeSpec>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted WidgetAttributeSpec uid=${request.uid}`)),
      catchError(this.handleError<SORespWidgetAttributeSpec>('delete WidgetAttributeSpec'))
    );
  }
}
