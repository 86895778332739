import {SOPutStore} from '../../core/store/so-put-store';
import {StoreCore} from './store.core';
import {SOGetStore} from '../../core/store/so-get-store';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putStoreFormFieldOptions} from './put-store-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {EntityStoreAssociationCore} from '../bv-entity-store-association/entity-store-association.core';
import {SOGetEntityStoreAssociation} from '../../core/entity-store-association/so-get-entity-store-association';

export class RAPutStore extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putStoreFormFieldOptions);

  RequestClass = SOPutStore

  constructor(
    public entityCore: StoreCore
  ) {
    super();
    if (putStoreFormFieldOptions.entity) {
      putStoreFormFieldOptions.entity.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('storeType'),
      this.getFormValueFromIdentifier('isDefault'),
      [this.getFormValueFromIdentifier('entity', SOGetEntityStoreAssociation)],
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}