import {SORespToken} from '../../core/token/so-resp-token';
import {TokenCore} from './token.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putTokenFormFieldOptions} from './put-token-form-fields';
import {SOGetToken} from '../../core/token/so-get-token';
import {SOGetCertificate} from '../../core/certificate/so-get-certificate';
import {SOGetRoleGrant} from '../../core/role-grant/so-get-role-grant';


export class TokenDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespToken = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const dateCreatedDetail = new DetailEntry(
        'Date created',
        activeUserService.time.niceDate(entity.dateCreated),
        'dateCreated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateCreatedDetail)

    const statusDetail = new DetailEntry(
        'Status',
        entity.status,
        'status',
        BaseDetailType.toggleGroup,
        null,
        EditableType.always,
        putTokenFormFieldOptions.status
    )
    attributes.push(statusDetail)


    const certificateCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'certificate');
    certificateCore.soGet = SOGetCertificate.construct({
    });
    heading = getHeading(entityViewSpec.entityCore, certificateCore)
    certificateCore.title = heading ? heading : certificateCore.title
    certificateCore.listTitle = 'Certificate'

    certificateCore.configureForDisplay(entityViewSpec)

    const certificateDetail = new DetailEntry(
      heading ? heading : certificateCore.listTitle,
      null,
      'certificate',
      BaseDetailType.pushedDetail,
      certificateCore,
      EditableType.never
    )
    certificateDetail.singleFieldSelect = true
    attributes.push(certificateDetail);

    const roleGrantCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'roleGrant');
    roleGrantCore.soGet = SOGetRoleGrant.construct({
    });
    heading = getHeading(entityViewSpec.entityCore, roleGrantCore)
    roleGrantCore.title = heading ? heading : roleGrantCore.title
    roleGrantCore.listTitle = 'Role grant'

    if (entity.roleGrant) {
        displayStringFactory.reconfigure(entity.roleGrant, entityViewSpec.entityCore.type)
    }
    roleGrantCore.configureForDisplay(entityViewSpec)

    const roleGrantDetail = new DetailEntry(
      heading ? heading : roleGrantCore.listTitle,
      entity.roleGrant ? displayStringFactory.primaryString : null,
      'roleGrant',
      BaseDetailType.pushedDetail,
      roleGrantCore,
      EditableType.never
    )
    if (entity.roleGrant) {
      roleGrantDetail.cachedListEntry = roleGrantCore.makeListEntry(entityViewSpec, roleGrantCore, entity.roleGrant, 0)
    }
    roleGrantDetail.singleFieldSelect = true
    attributes.push(roleGrantDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
