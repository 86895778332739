import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EORequestAttributeSpecService} from '../../core/request-attribute-spec/eo-request-attribute-spec';
import {RequestAttributeSpecListEntryFactory} from './request-attribute-spec-list-entry-factory';
import {RequestAttributeSpecDetailFactory} from './request-attribute-spec-detail-factory';
import {SOGetRequestAttributeSpec} from '../../core/request-attribute-spec/so-get-request-attribute-spec';
import {RAPutRequestAttributeSpec} from './ra-put-request-attribute-spec';
import {RAPostRequestAttributeSpec} from './ra-post-request-attribute-spec';
import {RADeleteRequestAttributeSpec} from './ra-delete-request-attribute-spec';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import {SOGetApp} from '../../core/app/so-get-app'


export class RequestAttributeSpecCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'requestAttributeSpec';
  title = 'Request attribute spec';
  listEntryFactory = new RequestAttributeSpecListEntryFactory();
  detailFactory = new RequestAttributeSpecDetailFactory();

  entityService: EORequestAttributeSpecService;
  soGetClass = SOGetRequestAttributeSpec;
  soGet: SOGetRequestAttributeSpec;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EORequestAttributeSpecService
    );
    this.adapters = [
      new RAPostRequestAttributeSpec(this),
      new RAPutRequestAttributeSpec(this),
      new RADeleteRequestAttributeSpec(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetRequestAttributeSpec();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.app = new SOGetApp(
      this.activeUserService.getScope('app').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
