import {SOPutRelationship} from '../../core/relationship/so-put-relationship';
import {RelationshipCore} from './relationship.core';
import {SOGetRelationship} from '../../core/relationship/so-get-relationship';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putRelationshipFormFieldOptions} from './put-relationship-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {EntityRelationshipAssociationCore} from '../bv-entity-relationship-association/entity-relationship-association.core';
import {SOGetEntityRelationshipAssociation} from '../../core/entity-relationship-association/so-get-entity-relationship-association';
import {RelationshipLinkCore} from '../bv-relationship-link/relationship-link.core';
import {SOGetRelationshipLink} from '../../core/relationship-link/so-get-relationship-link';

export class RAPutRelationship extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putRelationshipFormFieldOptions);

  RequestClass = SOPutRelationship

  constructor(
    public entityCore: RelationshipCore
  ) {
    super();
    if (putRelationshipFormFieldOptions.entity) {
      putRelationshipFormFieldOptions.entity.makeCore = true;
    }
    if (putRelationshipFormFieldOptions.relationshipLink) {
      putRelationshipFormFieldOptions.relationshipLink.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      [this.getFormValueFromIdentifier('entity', SOGetEntityRelationshipAssociation)],
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('multiplicity'),
      this.getFormValueFromIdentifier(
        'relationshipLink',
        SOGetRelationshipLink,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}