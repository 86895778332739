import {SORespBranch} from '../../core/branch/so-resp-branch';
import {BranchCore} from './branch.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putBranchFormFieldOptions} from './put-branch-form-fields';
import {SOGetBranch} from '../../core/branch/so-get-branch';
import {SOGetBranchCommitAssociation} from '../../core/branch-commit-association/so-get-branch-commit-association';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class BranchDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespBranch = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const nameDetail = new DetailEntry(
        'Name',
        entity.name,
        'name',
        BaseDetailType.text,
        null,
        EditableType.always,
        putBranchFormFieldOptions.name
    )
    attributes.push(nameDetail)


    const branchCommitAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'branchCommitAssociation');
    branchCommitAssociationsCore.soGet = SOGetBranchCommitAssociation.construct({
      branch: new SOGetBranch(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, branchCommitAssociationsCore)
    branchCommitAssociationsCore.title = heading ? heading : branchCommitAssociationsCore.title
    branchCommitAssociationsCore.listTitle = 'Branch commit associations'

    branchCommitAssociationsCore.configureForDisplay(entityViewSpec)

    const branchCommitAssociationsDetail = new DetailEntry(
      heading ? heading : branchCommitAssociationsCore.listTitle,
      null,
      'branchCommitAssociations',
      BaseDetailType.embeddedList,
      branchCommitAssociationsCore,
      EditableType.never
    )
    attributes.push(branchCommitAssociationsDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
