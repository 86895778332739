import {SORespAttributeRequestAttributeSpecAssociation} from '../../core/attribute-request-attribute-spec-association/so-resp-attribute-request-attribute-spec-association';
import {AttributeRequestAttributeSpecAssociationCore} from './attribute-request-attribute-spec-association.core';
import { ListEntry, ListEntryFactory, ListEntryFactoryProtocol} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';


export class AttributeRequestAttributeSpecAssociationListEntryFactory  extends ListEntryFactory implements ListEntryFactoryProtocol {

  makeListEntry(parentCoreViewSpec, entityCore: EntityCoreProtocol, entity: SORespAttributeRequestAttributeSpecAssociation, section: number = 0): ListEntry {
    const resp = super.makeListEntry(parentCoreViewSpec, entityCore, entity, section);
    return resp;
  }
}
