import {SOPostHello} from '../../core/hello/so-post-hello';
import {HelloCore} from './hello.core';
import {SOGetHello} from '../../core/hello/so-get-hello';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postHelloFormFieldOptions} from './post-hello-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';

export class RAPostHello extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postHelloFormFieldOptions);

  RequestClass = SOPostHello

  constructor(
    public entityCore: HelloCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('loginStyle'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}