import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostStoreRelationshipSpec } from './so-post-store-relationship-spec';
import { SOGetStoreRelationshipSpec } from './so-get-store-relationship-spec';
import { SOPutStoreRelationshipSpec } from './so-put-store-relationship-spec';
import { SODeleteStoreRelationshipSpec } from './so-delete-store-relationship-spec';
import {SORespStoreRelationshipSpec} from './so-resp-store-relationship-spec';
import {SOPluralStoreRelationshipSpec} from './so-plural-store-relationship-spec';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOStoreRelationshipSpecService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'store_relationship_spec',
      entityCore
    );
  }

  /** GET StoreRelationshipSpec by id. Will 404 if id not found */
  get(request: SOGetStoreRelationshipSpec): Observable<SOPluralStoreRelationshipSpec> {
    return this.httpClient.get<SOPluralStoreRelationshipSpec>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralStoreRelationshipSpec, resp) }),
      tap(_ => this.log(`fetched StoreRelationshipSpec`)),
      catchError(this.handleError<SOPluralStoreRelationshipSpec>(`get StoreRelationshipSpec`))
    );
  }

  /** POST: add a new StoreRelationshipSpec to the server */
  post(request: SOPostStoreRelationshipSpec): Observable<SORespStoreRelationshipSpec> {
    return this.httpClient.post<SORespStoreRelationshipSpec>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespStoreRelationshipSpec, resp) }),
      tap((response: SORespStoreRelationshipSpec) => this.log(`added StoreRelationshipSpec w/ id=${response.uid}`)),
      catchError(this.handleError<SORespStoreRelationshipSpec>('StoreRelationshipSpec post'))
    );
  }

  /** PUT: update StoreRelationshipSpec  */
  put(request: SOPutStoreRelationshipSpec): Observable<SORespStoreRelationshipSpec> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespStoreRelationshipSpec>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespStoreRelationshipSpec, resp) }),
      tap((response: SORespStoreRelationshipSpec) => this.log(`edited StoreRelationshipSpec w/ uid=${uid}`)),
      catchError(this.handleError<SORespStoreRelationshipSpec>('StoreRelationshipSpec put'))
    );
  }

  /** DELETE: delete the StoreRelationshipSpec from the server */
  delete(request: SODeleteStoreRelationshipSpec): Observable<SORespStoreRelationshipSpec> {
    return this.httpClient.delete<SORespStoreRelationshipSpec>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted StoreRelationshipSpec uid=${request.uid}`)),
      catchError(this.handleError<SORespStoreRelationshipSpec>('delete StoreRelationshipSpec'))
    );
  }
}
