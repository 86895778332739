import {Component} from '@angular/core';
import {ActiveUserService, MaterialAppSpec, UserLoaded} from 'ngx-lionheart-material';
import 'reflect-metadata';
import {environment} from "../environments/environment";
import {navItems} from "./navi-items";
import {entityCoreMap} from "./entity-core-map";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements UserLoaded {

  id = 'appComponent';

  constructor(
    public activeUserService: ActiveUserService,
  ) {
    const appSpec = new MaterialAppSpec();
    appSpec.home = 'app';
    appSpec.navItems = navItems;
    appSpec.coreApiUrl = environment.coreApiUrl;
    appSpec.title = 'Lionheart Builder';
    appSpec.entityCoreMap = entityCoreMap;
    activeUserService.initialize(appSpec);
    this.activeUserService.registerUserLoadedView(this);
  }

  userLoaded() {
  }

  userUnloaded() {
  }
}
