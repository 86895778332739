import {SOPutResponseRelationshipSpec} from '../../core/response-relationship-spec/so-put-response-relationship-spec';
import {ResponseRelationshipSpecCore} from './response-relationship-spec.core';
import {SOGetResponseRelationshipSpec} from '../../core/response-relationship-spec/so-get-response-relationship-spec';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putResponseRelationshipSpecFormFieldOptions} from './put-response-relationship-spec-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {ResponseResponseRelationshipSpecAssociationCore} from '../bv-response-response-relationship-spec-association/response-response-relationship-spec-association.core';
import {SOGetResponseResponseRelationshipSpecAssociation} from '../../core/response-response-relationship-spec-association/so-get-response-response-relationship-spec-association';
import {RelationshipResponseRelationshipSpecAssociationCore} from '../bv-relationship-response-relationship-spec-association/relationship-response-relationship-spec-association.core';
import {SOGetRelationshipResponseRelationshipSpecAssociation} from '../../core/relationship-response-relationship-spec-association/so-get-relationship-response-relationship-spec-association';

export class RAPutResponseRelationshipSpec extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putResponseRelationshipSpecFormFieldOptions);

  RequestClass = SOPutResponseRelationshipSpec

  constructor(
    public entityCore: ResponseRelationshipSpecCore
  ) {
    super();
    if (putResponseRelationshipSpecFormFieldOptions.response) {
      putResponseRelationshipSpecFormFieldOptions.response.makeCore = true;
    }
    if (putResponseRelationshipSpecFormFieldOptions.relationship) {
      putResponseRelationshipSpecFormFieldOptions.relationship.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('isRequired'),
      [this.getFormValueFromIdentifier('response', SOGetResponseResponseRelationshipSpecAssociation)],
      [this.getFormValueFromIdentifier('relationship', SOGetRelationshipResponseRelationshipSpecAssociation)],
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}