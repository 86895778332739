import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const entityField = new FormField(
  'entity',
  'Entity',
  'entity',
  FormFieldType.pushedSelectionList,
  false,
)
const attributeField = new FormField(
  'attribute',
  'Attribute',
  'attribute',
  FormFieldType.pushedSelectionList,
  false,
)


export const putEntityAttributeAssociationFormFieldOptions: {[key: string]: FormField} = {
  entity: entityField,
  attribute: attributeField,
};