import { SOGetMedia } from '../media/so-get-media';
import { SOGetOrganization } from '../organization/so-get-organization';
import {MMQueryOptions} from 'ngx-lionheart-material';
import {QueryProtocol} from 'ngx-lionheart-material';


export class SOGetAttachment implements QueryProtocol {

  constructor(
    public uid: string = null,
    public media: SOGetMedia = null,
    public organization: SOGetOrganization = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetAttachment(
      'uid' in params ? params.uid : null,
      'media' in params ? params.media : null,
      'organization' in params ? params.organization : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
