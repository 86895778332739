import { SOGetRelationship } from '../relationship/so-get-relationship';
import { SOGetRequestRelationshipSpec } from '../request-relationship-spec/so-get-request-relationship-spec';
import { SOGetCommit } from '../commit/so-get-commit';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostRelationshipRequestRelationshipSpecAssociation {

  constructor(
    public relationship: SOGetRelationship,
    public request: SOGetRequestRelationshipSpec,
    public commit: SOGetCommit,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostRelationshipRequestRelationshipSpecAssociation(
      'relationship' in params ? params.relationship : null,
      'request' in params ? params.request : null,
      'commit' in params ? params.commit : null,
      'organization' in params ? params.organization : null,
    );
  }
}
