import { Type } from 'class-transformer';
import { SORespStoreStoreAttributeSpecAssociation } from '../store-store-attribute-spec-association/so-resp-store-store-attribute-spec-association';
import { SORespAttributeStoreAttributeSpecAssociation } from '../attribute-store-attribute-spec-association/so-resp-attribute-store-attribute-spec-association';
import { SORespOrganization } from '../organization/so-resp-organization';
import { SORespApp } from '../app/so-resp-app';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespStoreAttributeSpec implements ObjectiveProtocol {

  entityType: string = 'storeAttributeSpec';
  uid: string;
  isRequired: boolean;
  defaultValue: string = null;
  @Type(() => SORespStoreStoreAttributeSpecAssociation)
  store: SORespStoreStoreAttributeSpecAssociation[];
  @Type(() => SORespAttributeStoreAttributeSpecAssociation)
  attribute: SORespAttributeStoreAttributeSpecAssociation[];
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
  @Type(() => SORespApp)
  app: SORespApp;
}