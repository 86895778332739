import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostResponseResponseAttributeSpecAssociation } from './so-post-response-response-attribute-spec-association';
import { SOGetResponseResponseAttributeSpecAssociation } from './so-get-response-response-attribute-spec-association';
import { SOPutResponseResponseAttributeSpecAssociation } from './so-put-response-response-attribute-spec-association';
import { SODeleteResponseResponseAttributeSpecAssociation } from './so-delete-response-response-attribute-spec-association';
import {SORespResponseResponseAttributeSpecAssociation} from './so-resp-response-response-attribute-spec-association';
import {SOPluralResponseResponseAttributeSpecAssociation} from './so-plural-response-response-attribute-spec-association';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOResponseResponseAttributeSpecAssociationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'response_response_attribute_spec_association',
      entityCore
    );
  }

  /** GET ResponseResponseAttributeSpecAssociation by id. Will 404 if id not found */
  get(request: SOGetResponseResponseAttributeSpecAssociation): Observable<SOPluralResponseResponseAttributeSpecAssociation> {
    return this.httpClient.get<SOPluralResponseResponseAttributeSpecAssociation>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralResponseResponseAttributeSpecAssociation, resp) }),
      tap(_ => this.log(`fetched ResponseResponseAttributeSpecAssociation`)),
      catchError(this.handleError<SOPluralResponseResponseAttributeSpecAssociation>(`get ResponseResponseAttributeSpecAssociation`))
    );
  }

  /** POST: add a new ResponseResponseAttributeSpecAssociation to the server */
  post(request: SOPostResponseResponseAttributeSpecAssociation): Observable<SORespResponseResponseAttributeSpecAssociation> {
    return this.httpClient.post<SORespResponseResponseAttributeSpecAssociation>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespResponseResponseAttributeSpecAssociation, resp) }),
      tap((response: SORespResponseResponseAttributeSpecAssociation) => this.log(`added ResponseResponseAttributeSpecAssociation w/ id=${response.uid}`)),
      catchError(this.handleError<SORespResponseResponseAttributeSpecAssociation>('ResponseResponseAttributeSpecAssociation post'))
    );
  }

  /** PUT: update ResponseResponseAttributeSpecAssociation  */
  put(request: SOPutResponseResponseAttributeSpecAssociation): Observable<SORespResponseResponseAttributeSpecAssociation> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespResponseResponseAttributeSpecAssociation>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespResponseResponseAttributeSpecAssociation, resp) }),
      tap((response: SORespResponseResponseAttributeSpecAssociation) => this.log(`edited ResponseResponseAttributeSpecAssociation w/ uid=${uid}`)),
      catchError(this.handleError<SORespResponseResponseAttributeSpecAssociation>('ResponseResponseAttributeSpecAssociation put'))
    );
  }

  /** DELETE: delete the ResponseResponseAttributeSpecAssociation from the server */
  delete(request: SODeleteResponseResponseAttributeSpecAssociation): Observable<SORespResponseResponseAttributeSpecAssociation> {
    return this.httpClient.delete<SORespResponseResponseAttributeSpecAssociation>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted ResponseResponseAttributeSpecAssociation uid=${request.uid}`)),
      catchError(this.handleError<SORespResponseResponseAttributeSpecAssociation>('delete ResponseResponseAttributeSpecAssociation'))
    );
  }
}
