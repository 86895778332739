import { SOGetEntityWidgetAssociation } from '../entity-widget-association/so-get-entity-widget-association';
import { SOGetOrganization } from '../organization/so-get-organization';
import { SOGetApp } from '../app/so-get-app';
import {ENWidgetType} from '../../enums';


export class SOPostWidget {

  constructor(
    public widgetType: ENWidgetType,
    public isDefault: boolean,
    public entity: SOGetEntityWidgetAssociation[],
    public organization: SOGetOrganization,
    public app: SOGetApp,
    public name: string = "",
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostWidget(
      'name' in params ? params.name : null,
      'widgetType' in params ? params.widgetType : null,
      'isDefault' in params ? params.isDefault : null,
      'entity' in params ? params.entity : null,
      'organization' in params ? params.organization : null,
      'app' in params ? params.app : null,
    );
  }
}
