import {SORespStoreAttributeSpec} from '../../core/store-attribute-spec/so-resp-store-attribute-spec';
import {StoreAttributeSpecCore} from './store-attribute-spec.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putStoreAttributeSpecFormFieldOptions} from './put-store-attribute-spec-form-fields';
import {SOGetStoreAttributeSpec} from '../../core/store-attribute-spec/so-get-store-attribute-spec';
import {SOGetStoreStoreAttributeSpecAssociation} from '../../core/store-store-attribute-spec-association/so-get-store-store-attribute-spec-association';
import {SOGetAttributeStoreAttributeSpecAssociation} from '../../core/attribute-store-attribute-spec-association/so-get-attribute-store-attribute-spec-association';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class StoreAttributeSpecDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespStoreAttributeSpec = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const isRequiredDetail = new DetailEntry(
        'Is required',
        entity.isRequired,
        'isRequired',
        BaseDetailType.toggle,
        null,
        EditableType.always,
        putStoreAttributeSpecFormFieldOptions.isRequired
    )
    attributes.push(isRequiredDetail)

    const defaultValueDetail = new DetailEntry(
        'Default value',
        entity.defaultValue,
        'defaultValue',
        BaseDetailType.text,
        null,
        EditableType.always,
        putStoreAttributeSpecFormFieldOptions.defaultValue
    )
    attributes.push(defaultValueDetail)


    const storeCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'storeStoreAttributeSpecAssociation');
    storeCore.soGet = SOGetStoreStoreAttributeSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, storeCore)
    storeCore.title = heading ? heading : storeCore.title
    storeCore.listTitle = 'Store'

    storeCore.configureForDisplay(entityViewSpec)

    const storeDetail = new DetailEntry(
      heading ? heading : storeCore.listTitle,
      null,
      'store',
      BaseDetailType.embeddedList,
      storeCore,
      EditableType.always,
      putStoreAttributeSpecFormFieldOptions.store
    )
    attributes.push(storeDetail);

    const attributeCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'attributeStoreAttributeSpecAssociation');
    attributeCore.soGet = SOGetAttributeStoreAttributeSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, attributeCore)
    attributeCore.title = heading ? heading : attributeCore.title
    attributeCore.listTitle = 'Attribute'

    attributeCore.configureForDisplay(entityViewSpec)

    const attributeDetail = new DetailEntry(
      heading ? heading : attributeCore.listTitle,
      null,
      'attribute',
      BaseDetailType.embeddedList,
      attributeCore,
      EditableType.always,
      putStoreAttributeSpecFormFieldOptions.attribute
    )
    attributes.push(attributeDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
