import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespOrganization implements ObjectiveProtocol {

  entityType: string = 'organization';
  uid: string;
  name: string;
  identifier: string;
  @Type(() => Date)
  dateCreated: Date;
  exRefPaymentsLink: string = null;
}