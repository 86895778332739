import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const storeField = new FormField(
  'store',
  'Store',
  'store',
  FormFieldType.pushedSelectionList,
  true,
)
const storeAttributeSpecField = new FormField(
  'storeAttributeSpec',
  'Store attribute spec',
  'storeAttributeSpec',
  FormFieldType.pushedSelectionList,
  true,
)


export const postStoreStoreAttributeSpecAssociationFormFieldOptions: {[key: string]: FormField} = {
  store: storeField,
  storeAttributeSpec: storeAttributeSpecField,
};