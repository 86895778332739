import { SOGetCommit } from '../commit/so-get-commit';
import { SOGetRelationshipLink } from '../relationship-link/so-get-relationship-link';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostCommitRelationshipLinkAssociation {

  constructor(
    public commit: SOGetCommit,
    public entity: SOGetRelationshipLink,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostCommitRelationshipLinkAssociation(
      'commit' in params ? params.commit : null,
      'entity' in params ? params.entity : null,
      'organization' in params ? params.organization : null,
    );
  }
}
