import {SORespRequest} from '../../core/request/so-resp-request';
import {RequestCore} from './request.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putRequestFormFieldOptions} from './put-request-form-fields';
import {SOGetRequest} from '../../core/request/so-get-request';
import {SOGetRequestRequestAttributeSpecAssociation} from '../../core/request-request-attribute-spec-association/so-get-request-request-attribute-spec-association';
import {SOGetRequestRequestRelationshipSpecAssociation} from '../../core/request-request-relationship-spec-association/so-get-request-request-relationship-spec-association';
import {SOGetEntityRequestAssociation} from '../../core/entity-request-association/so-get-entity-request-association';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class RequestDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespRequest = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const nameDetail = new DetailEntry(
        'Name',
        entity.name,
        'name',
        BaseDetailType.text,
        null,
        EditableType.always,
        putRequestFormFieldOptions.name
    )
    attributes.push(nameDetail)

    const requestTypeDetail = new DetailEntry(
        'Request type',
        entity.requestType,
        'requestType',
        BaseDetailType.toggleGroup,
        null,
        EditableType.always,
        putRequestFormFieldOptions.requestType
    )
    attributes.push(requestTypeDetail)

    const isDefaultDetail = new DetailEntry(
        'Is default',
        entity.isDefault,
        'isDefault',
        BaseDetailType.toggle,
        null,
        EditableType.always,
        putRequestFormFieldOptions.isDefault
    )
    attributes.push(isDefaultDetail)


    const attributeSpecsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'requestRequestAttributeSpecAssociation');
    attributeSpecsCore.soGet = SOGetRequestRequestAttributeSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, attributeSpecsCore)
    attributeSpecsCore.title = heading ? heading : attributeSpecsCore.title
    attributeSpecsCore.listTitle = 'Attribute specs'

    attributeSpecsCore.configureForDisplay(entityViewSpec)

    const attributeSpecsDetail = new DetailEntry(
      heading ? heading : attributeSpecsCore.listTitle,
      null,
      'attributeSpecs',
      BaseDetailType.embeddedList,
      attributeSpecsCore,
      EditableType.never
    )
    attributes.push(attributeSpecsDetail);

    const relationshipSpecsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'requestRequestRelationshipSpecAssociation');
    relationshipSpecsCore.soGet = SOGetRequestRequestRelationshipSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, relationshipSpecsCore)
    relationshipSpecsCore.title = heading ? heading : relationshipSpecsCore.title
    relationshipSpecsCore.listTitle = 'Relationship specs'

    relationshipSpecsCore.configureForDisplay(entityViewSpec)

    const relationshipSpecsDetail = new DetailEntry(
      heading ? heading : relationshipSpecsCore.listTitle,
      null,
      'relationshipSpecs',
      BaseDetailType.embeddedList,
      relationshipSpecsCore,
      EditableType.never
    )
    attributes.push(relationshipSpecsDetail);

    const entityCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'entityRequestAssociation');
    entityCore.soGet = SOGetEntityRequestAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, entityCore)
    entityCore.title = heading ? heading : entityCore.title
    entityCore.listTitle = 'Entity'

    entityCore.configureForDisplay(entityViewSpec)

    const entityDetail = new DetailEntry(
      heading ? heading : entityCore.listTitle,
      null,
      'entity',
      BaseDetailType.embeddedList,
      entityCore,
      EditableType.always,
      putRequestFormFieldOptions.entity
    )
    attributes.push(entityDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
