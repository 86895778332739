import {SOPutAttributeStoreAttributeSpecAssociation} from '../../core/attribute-store-attribute-spec-association/so-put-attribute-store-attribute-spec-association';
import {AttributeStoreAttributeSpecAssociationCore} from './attribute-store-attribute-spec-association.core';
import {SOGetAttributeStoreAttributeSpecAssociation} from '../../core/attribute-store-attribute-spec-association/so-get-attribute-store-attribute-spec-association';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putAttributeStoreAttributeSpecAssociationFormFieldOptions} from './put-attribute-store-attribute-spec-association-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {AttributeCore} from '../bv-attribute/attribute.core';
import {SOGetAttribute} from '../../core/attribute/so-get-attribute';
import {StoreAttributeSpecCore} from '../bv-store-attribute-spec/store-attribute-spec.core';
import {SOGetStoreAttributeSpec} from '../../core/store-attribute-spec/so-get-store-attribute-spec';
import {CommitCore} from '../bv-commit/commit.core';
import {SOGetCommit} from '../../core/commit/so-get-commit';

export class RAPutAttributeStoreAttributeSpecAssociation extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putAttributeStoreAttributeSpecAssociationFormFieldOptions);

  RequestClass = SOPutAttributeStoreAttributeSpecAssociation

  constructor(
    public entityCore: AttributeStoreAttributeSpecAssociationCore
  ) {
    super();
    if (putAttributeStoreAttributeSpecAssociationFormFieldOptions.attribute) {
      putAttributeStoreAttributeSpecAssociationFormFieldOptions.attribute.makeCore = true;
    }
    if (putAttributeStoreAttributeSpecAssociationFormFieldOptions.store) {
      putAttributeStoreAttributeSpecAssociationFormFieldOptions.store.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier(
        'attribute',
        SOGetAttribute,
      ),
      this.getFormValueFromIdentifier(
        'store',
        SOGetStoreAttributeSpec,
      ),
      this.getFormValueFromIdentifier(
        'commit',
        SOGetCommit,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}