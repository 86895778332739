import {SOPostSesMessage} from '../../core/ses-message/so-post-ses-message';
import {SesMessageCore} from './ses-message.core';
import {SOGetSesMessage} from '../../core/ses-message/so-get-ses-message';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postSesMessageFormFieldOptions} from './post-ses-message-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';

export class RAPostSesMessage extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postSesMessageFormFieldOptions);

  RequestClass = SOPostSesMessage

  constructor(
    public entityCore: SesMessageCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('data'),
      this.getFormValueFromIdentifier('messageType'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}