import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOEntityService} from '../../core/entity/eo-entity';
import {EntityListEntryFactory} from './entity-list-entry-factory';
import {EntityDetailFactory} from './entity-detail-factory';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {RAPutEntity} from './ra-put-entity';
import {RAPostEntity} from './ra-post-entity';
import {RADeleteEntity} from './ra-delete-entity';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetApp} from '../../core/app/so-get-app'
import {SOGetOrganization} from '../../core/organization/so-get-organization'


export class EntityCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'entity';
  title = 'Entity';
  listEntryFactory = new EntityListEntryFactory();
  detailFactory = new EntityDetailFactory();

  entityService: EOEntityService;
  soGetClass = SOGetEntity;
  soGet: SOGetEntity;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOEntityService
    );
    this.adapters = [
      new RAPostEntity(this),
      new RAPutEntity(this),
      new RADeleteEntity(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetEntity();
    this.soGet.app = new SOGetApp(
      this.activeUserService.getScope('app').uid
    );
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
