import {SORespWidgetAttributeSpec} from '../../core/widget-attribute-spec/so-resp-widget-attribute-spec';
import {WidgetAttributeSpecCore} from './widget-attribute-spec.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putWidgetAttributeSpecFormFieldOptions} from './put-widget-attribute-spec-form-fields';
import {SOGetWidgetAttributeSpec} from '../../core/widget-attribute-spec/so-get-widget-attribute-spec';
import {SOGetWidgetWidgetAttributeSpecAssociation} from '../../core/widget-widget-attribute-spec-association/so-get-widget-widget-attribute-spec-association';
import {SOGetAttributeWidgetAttributeSpecAssociation} from '../../core/attribute-widget-attribute-spec-association/so-get-attribute-widget-attribute-spec-association';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class WidgetAttributeSpecDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespWidgetAttributeSpec = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const isRequiredDetail = new DetailEntry(
        'Is required',
        entity.isRequired,
        'isRequired',
        BaseDetailType.toggle,
        null,
        EditableType.always,
        putWidgetAttributeSpecFormFieldOptions.isRequired
    )
    attributes.push(isRequiredDetail)

    const defaultValueDetail = new DetailEntry(
        'Default value',
        entity.defaultValue,
        'defaultValue',
        BaseDetailType.text,
        null,
        EditableType.always,
        putWidgetAttributeSpecFormFieldOptions.defaultValue
    )
    attributes.push(defaultValueDetail)


    const widgetCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'widgetWidgetAttributeSpecAssociation');
    widgetCore.soGet = SOGetWidgetWidgetAttributeSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, widgetCore)
    widgetCore.title = heading ? heading : widgetCore.title
    widgetCore.listTitle = 'Widget'

    widgetCore.configureForDisplay(entityViewSpec)

    const widgetDetail = new DetailEntry(
      heading ? heading : widgetCore.listTitle,
      null,
      'widget',
      BaseDetailType.embeddedList,
      widgetCore,
      EditableType.always,
      putWidgetAttributeSpecFormFieldOptions.widget
    )
    attributes.push(widgetDetail);

    const attributeCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'attributeWidgetAttributeSpecAssociation');
    attributeCore.soGet = SOGetAttributeWidgetAttributeSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, attributeCore)
    attributeCore.title = heading ? heading : attributeCore.title
    attributeCore.listTitle = 'Attribute'

    attributeCore.configureForDisplay(entityViewSpec)

    const attributeDetail = new DetailEntry(
      heading ? heading : attributeCore.listTitle,
      null,
      'attribute',
      BaseDetailType.embeddedList,
      attributeCore,
      EditableType.always,
      putWidgetAttributeSpecFormFieldOptions.attribute
    )
    attributes.push(attributeDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
