import {SOPutRequestRelationshipSpec} from '../../core/request-relationship-spec/so-put-request-relationship-spec';
import {RequestRelationshipSpecCore} from './request-relationship-spec.core';
import {SOGetRequestRelationshipSpec} from '../../core/request-relationship-spec/so-get-request-relationship-spec';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putRequestRelationshipSpecFormFieldOptions} from './put-request-relationship-spec-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {RequestRequestRelationshipSpecAssociationCore} from '../bv-request-request-relationship-spec-association/request-request-relationship-spec-association.core';
import {SOGetRequestRequestRelationshipSpecAssociation} from '../../core/request-request-relationship-spec-association/so-get-request-request-relationship-spec-association';
import {RelationshipRequestRelationshipSpecAssociationCore} from '../bv-relationship-request-relationship-spec-association/relationship-request-relationship-spec-association.core';
import {SOGetRelationshipRequestRelationshipSpecAssociation} from '../../core/relationship-request-relationship-spec-association/so-get-relationship-request-relationship-spec-association';

export class RAPutRequestRelationshipSpec extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putRequestRelationshipSpecFormFieldOptions);

  RequestClass = SOPutRequestRelationshipSpec

  constructor(
    public entityCore: RequestRelationshipSpecCore
  ) {
    super();
    if (putRequestRelationshipSpecFormFieldOptions.request) {
      putRequestRelationshipSpecFormFieldOptions.request.makeCore = true;
    }
    if (putRequestRelationshipSpecFormFieldOptions.relationship) {
      putRequestRelationshipSpecFormFieldOptions.relationship.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('isRequired'),
      [this.getFormValueFromIdentifier('request', SOGetRequestRequestRelationshipSpecAssociation)],
      [this.getFormValueFromIdentifier('relationship', SOGetRelationshipRequestRelationshipSpecAssociation)],
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}