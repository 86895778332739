import {SORespRelationshipRequestRelationshipSpecAssociation} from '../../core/relationship-request-relationship-spec-association/so-resp-relationship-request-relationship-spec-association';
import {RelationshipRequestRelationshipSpecAssociationCore} from './relationship-request-relationship-spec-association.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putRelationshipRequestRelationshipSpecAssociationFormFieldOptions} from './put-relationship-request-relationship-spec-association-form-fields';
import {SOGetRelationshipRequestRelationshipSpecAssociation} from '../../core/relationship-request-relationship-spec-association/so-get-relationship-request-relationship-spec-association';
import {SOGetRelationship} from '../../core/relationship/so-get-relationship';
import {SOGetRequestRelationshipSpec} from '../../core/request-relationship-spec/so-get-request-relationship-spec';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetApp} from '../../core/app/so-get-app';


export class RelationshipRequestRelationshipSpecAssociationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespRelationshipRequestRelationshipSpecAssociation = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = [];


    const relationshipCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'relationship');
    relationshipCore.soGet = SOGetRelationship.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, relationshipCore);
    relationshipCore.title = heading ? heading : relationshipCore.title;
    relationshipCore.listTitle = 'Relationship';

    if (entity.relationship) {
        displayStringFactory.reconfigure(entity.relationship, entityViewSpec.entityCore.type);
    }
    relationshipCore.configureForDisplay(entityViewSpec);

    const relationshipDetail = new DetailEntry(
      heading ? heading : relationshipCore.listTitle,
      entity.relationship ? displayStringFactory.primaryString : null,
      'relationship',
      BaseDetailType.pushedDetail,
      relationshipCore,
      EditableType.always,
      putRelationshipRequestRelationshipSpecAssociationFormFieldOptions.relationship
    );
    if (entity.relationship) {
      relationshipDetail.cachedListEntry = relationshipCore.makeListEntry(entityViewSpec, relationshipCore, entity.relationship, 0);
    }
    relationshipDetail.singleFieldSelect = true;
    attributes.push(relationshipDetail);

    const requestCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'requestRelationshipSpec');
    requestCore.soGet = SOGetRequestRelationshipSpec.construct({
      relationship: new SOGetRelationshipRequestRelationshipSpecAssociation(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, requestCore);
    requestCore.title = heading ? heading : requestCore.title;
    requestCore.listTitle = 'Request';

    if (entity.request) {
        displayStringFactory.reconfigure(entity.request, entityViewSpec.entityCore.type);
    }
    requestCore.configureForDisplay(entityViewSpec);

    const requestDetail = new DetailEntry(
      heading ? heading : requestCore.listTitle,
      entity.request ? displayStringFactory.primaryString : null,
      'request',
      BaseDetailType.pushedDetail,
      requestCore,
      EditableType.always,
      putRelationshipRequestRelationshipSpecAssociationFormFieldOptions.request
    );
    if (entity.request) {
      requestDetail.cachedListEntry = requestCore.makeListEntry(entityViewSpec, requestCore, entity.request, 0);
    }
    requestDetail.singleFieldSelect = true;
    attributes.push(requestDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
