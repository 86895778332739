import {SORespViewGrant} from '../../core/view-grant/so-resp-view-grant';
import {ViewGrantCore} from './view-grant.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putViewGrantFormFieldOptions} from './put-view-grant-form-fields';
import {SOGetViewGrant} from '../../core/view-grant/so-get-view-grant';
import {SOGetRole} from '../../core/role/so-get-role';
import {SOGetView} from '../../core/view/so-get-view';


export class ViewGrantDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespViewGrant = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []


    const roleCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'role');
    roleCore.soGet = SOGetRole.construct({
    });
    heading = getHeading(entityViewSpec.entityCore, roleCore)
    roleCore.title = heading ? heading : roleCore.title
    roleCore.listTitle = 'Role'

    if (entity.role) {
        displayStringFactory.reconfigure(entity.role, entityViewSpec.entityCore.type)
    }
    roleCore.configureForDisplay(entityViewSpec)

    const roleDetail = new DetailEntry(
      heading ? heading : roleCore.listTitle,
      entity.role ? displayStringFactory.primaryString : null,
      'role',
      BaseDetailType.pushedDetail,
      roleCore,
      EditableType.never
    )
    if (entity.role) {
      roleDetail.cachedListEntry = roleCore.makeListEntry(entityViewSpec, roleCore, entity.role, 0)
    }
    roleDetail.singleFieldSelect = true
    attributes.push(roleDetail);

    const viewCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'view');
    viewCore.soGet = SOGetView.construct({
    });
    heading = getHeading(entityViewSpec.entityCore, viewCore)
    viewCore.title = heading ? heading : viewCore.title
    viewCore.listTitle = 'View'

    if (entity.view) {
        displayStringFactory.reconfigure(entity.view, entityViewSpec.entityCore.type)
    }
    viewCore.configureForDisplay(entityViewSpec)

    const viewDetail = new DetailEntry(
      heading ? heading : viewCore.listTitle,
      entity.view ? displayStringFactory.primaryString : null,
      'view',
      BaseDetailType.pushedDetail,
      viewCore,
      EditableType.never
    )
    if (entity.view) {
      viewDetail.cachedListEntry = viewCore.makeListEntry(entityViewSpec, viewCore, entity.view, 0)
    }
    viewDetail.singleFieldSelect = true
    attributes.push(viewDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
