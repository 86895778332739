import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostEnumerator } from './so-post-enumerator';
import { SOGetEnumerator } from './so-get-enumerator';
import { SOPutEnumerator } from './so-put-enumerator';
import { SODeleteEnumerator } from './so-delete-enumerator';
import {SORespEnumerator} from './so-resp-enumerator';
import {SOPluralEnumerator} from './so-plural-enumerator';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOEnumeratorService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'enumerator',
      entityCore
    );
  }

  /** GET Enumerator by id. Will 404 if id not found */
  get(request: SOGetEnumerator): Observable<SOPluralEnumerator> {
    return this.httpClient.get<SOPluralEnumerator>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralEnumerator, resp) }),
      tap(_ => this.log(`fetched Enumerator`)),
      catchError(this.handleError<SOPluralEnumerator>(`get Enumerator`))
    );
  }

  /** POST: add a new Enumerator to the server */
  post(request: SOPostEnumerator): Observable<SORespEnumerator> {
    return this.httpClient.post<SORespEnumerator>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespEnumerator, resp) }),
      tap((response: SORespEnumerator) => this.log(`added Enumerator w/ id=${response.uid}`)),
      catchError(this.handleError<SORespEnumerator>('Enumerator post'))
    );
  }

  /** PUT: update Enumerator  */
  put(request: SOPutEnumerator): Observable<SORespEnumerator> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespEnumerator>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespEnumerator, resp) }),
      tap((response: SORespEnumerator) => this.log(`edited Enumerator w/ uid=${uid}`)),
      catchError(this.handleError<SORespEnumerator>('Enumerator put'))
    );
  }

  /** DELETE: delete the Enumerator from the server */
  delete(request: SODeleteEnumerator): Observable<SORespEnumerator> {
    return this.httpClient.delete<SORespEnumerator>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Enumerator uid=${request.uid}`)),
      catchError(this.handleError<SORespEnumerator>('delete Enumerator'))
    );
  }
}
