import { SOGetAttribute } from '../attribute/so-get-attribute';
import { SOGetCodable } from '../codable/so-get-codable';
import { SOGetCommit } from '../commit/so-get-commit';


export class SOPutAttributeCodableAssociation {

  constructor(
    public uid: string,
    public attribute: SOGetAttribute = null,
    public codable: SOGetCodable = null,
    public commit: SOGetCommit = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutAttributeCodableAssociation(
      'uid' in params ? params.uid : null,
      'attribute' in params ? params.attribute : null,
      'codable' in params ? params.codable : null,
      'commit' in params ? params.commit : null,
    );
  }
}
