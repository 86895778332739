import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EORequestRequestRelationshipSpecAssociationService} from '../../core/request-request-relationship-spec-association/eo-request-request-relationship-spec-association';
import {RequestRequestRelationshipSpecAssociationListEntryFactory} from './request-request-relationship-spec-association-list-entry-factory';
import {RequestRequestRelationshipSpecAssociationDetailFactory} from './request-request-relationship-spec-association-detail-factory';
import {SOGetRequestRequestRelationshipSpecAssociation} from '../../core/request-request-relationship-spec-association/so-get-request-request-relationship-spec-association';
import {RAPutRequestRequestRelationshipSpecAssociation} from './ra-put-request-request-relationship-spec-association';
import {RAPostRequestRequestRelationshipSpecAssociation} from './ra-post-request-request-relationship-spec-association';
import {RADeleteRequestRequestRelationshipSpecAssociation} from './ra-delete-request-request-relationship-spec-association';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class RequestRequestRelationshipSpecAssociationCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'requestRequestRelationshipSpecAssociation';
  title = 'Request request relationship spec association';
  listEntryFactory = new RequestRequestRelationshipSpecAssociationListEntryFactory();
  detailFactory = new RequestRequestRelationshipSpecAssociationDetailFactory();

  entityService: EORequestRequestRelationshipSpecAssociationService;
  soGetClass = SOGetRequestRequestRelationshipSpecAssociation;
  soGet: SOGetRequestRequestRelationshipSpecAssociation;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EORequestRequestRelationshipSpecAssociationService
    );
    this.adapters = [
      new RAPostRequestRequestRelationshipSpecAssociation(this),
      new RAPutRequestRequestRelationshipSpecAssociation(this),
      new RADeleteRequestRequestRelationshipSpecAssociation(this),
    ];
    this.resetSoGet();
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetRequestRequestRelationshipSpecAssociation();
    this.soGet.commit = new SOGetCommit(
      this.activeUserService.getScope('commit').uid
    );
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
