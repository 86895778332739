import { Type } from 'class-transformer';
import { SORespAttributeCodableAssociation } from '../attribute-codable-association/so-resp-attribute-codable-association';
import { SORespCommitCodableAssociation } from '../commit-codable-association/so-resp-commit-codable-association';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespEnumerator implements ObjectiveProtocol {

  entityType: string = 'enumerator';
  uid: string;
  name: string;
  @Type(() => SORespAttributeCodableAssociation)
  attributes: SORespAttributeCodableAssociation[];
  @Type(() => SORespCommitCodableAssociation)
  commitCodableAssociations: SORespCommitCodableAssociation[];
}