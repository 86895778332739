import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostCommitRelationshipLinkAssociation } from './so-post-commit-relationship-link-association';
import { SOGetCommitRelationshipLinkAssociation } from './so-get-commit-relationship-link-association';
import { SOPutCommitRelationshipLinkAssociation } from './so-put-commit-relationship-link-association';
import { SODeleteCommitRelationshipLinkAssociation } from './so-delete-commit-relationship-link-association';
import {SORespCommitRelationshipLinkAssociation} from './so-resp-commit-relationship-link-association';
import {SOPluralCommitRelationshipLinkAssociation} from './so-plural-commit-relationship-link-association';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOCommitRelationshipLinkAssociationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'commit_relationship_link_association',
      entityCore
    );
  }

  /** GET CommitRelationshipLinkAssociation by id. Will 404 if id not found */
  get(request: SOGetCommitRelationshipLinkAssociation): Observable<SOPluralCommitRelationshipLinkAssociation> {
    return this.httpClient.get<SOPluralCommitRelationshipLinkAssociation>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralCommitRelationshipLinkAssociation, resp) }),
      tap(_ => this.log(`fetched CommitRelationshipLinkAssociation`)),
      catchError(this.handleError<SOPluralCommitRelationshipLinkAssociation>(`get CommitRelationshipLinkAssociation`))
    );
  }

  /** POST: add a new CommitRelationshipLinkAssociation to the server */
  post(request: SOPostCommitRelationshipLinkAssociation): Observable<SORespCommitRelationshipLinkAssociation> {
    return this.httpClient.post<SORespCommitRelationshipLinkAssociation>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespCommitRelationshipLinkAssociation, resp) }),
      tap((response: SORespCommitRelationshipLinkAssociation) => this.log(`added CommitRelationshipLinkAssociation w/ id=${response.uid}`)),
      catchError(this.handleError<SORespCommitRelationshipLinkAssociation>('CommitRelationshipLinkAssociation post'))
    );
  }

  /** PUT: update CommitRelationshipLinkAssociation  */
  put(request: SOPutCommitRelationshipLinkAssociation): Observable<SORespCommitRelationshipLinkAssociation> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespCommitRelationshipLinkAssociation>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespCommitRelationshipLinkAssociation, resp) }),
      tap((response: SORespCommitRelationshipLinkAssociation) => this.log(`edited CommitRelationshipLinkAssociation w/ uid=${uid}`)),
      catchError(this.handleError<SORespCommitRelationshipLinkAssociation>('CommitRelationshipLinkAssociation put'))
    );
  }

  /** DELETE: delete the CommitRelationshipLinkAssociation from the server */
  delete(request: SODeleteCommitRelationshipLinkAssociation): Observable<SORespCommitRelationshipLinkAssociation> {
    return this.httpClient.delete<SORespCommitRelationshipLinkAssociation>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted CommitRelationshipLinkAssociation uid=${request.uid}`)),
      catchError(this.handleError<SORespCommitRelationshipLinkAssociation>('delete CommitRelationshipLinkAssociation'))
    );
  }
}
