import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostRequestAttributeSpec } from './so-post-request-attribute-spec';
import { SOGetRequestAttributeSpec } from './so-get-request-attribute-spec';
import { SOPutRequestAttributeSpec } from './so-put-request-attribute-spec';
import { SODeleteRequestAttributeSpec } from './so-delete-request-attribute-spec';
import {SORespRequestAttributeSpec} from './so-resp-request-attribute-spec';
import {SOPluralRequestAttributeSpec} from './so-plural-request-attribute-spec';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EORequestAttributeSpecService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'request_attribute_spec',
      entityCore
    );
  }

  /** GET RequestAttributeSpec by id. Will 404 if id not found */
  get(request: SOGetRequestAttributeSpec): Observable<SOPluralRequestAttributeSpec> {
    return this.httpClient.get<SOPluralRequestAttributeSpec>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralRequestAttributeSpec, resp) }),
      tap(_ => this.log(`fetched RequestAttributeSpec`)),
      catchError(this.handleError<SOPluralRequestAttributeSpec>(`get RequestAttributeSpec`))
    );
  }

  /** POST: add a new RequestAttributeSpec to the server */
  post(request: SOPostRequestAttributeSpec): Observable<SORespRequestAttributeSpec> {
    return this.httpClient.post<SORespRequestAttributeSpec>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespRequestAttributeSpec, resp) }),
      tap((response: SORespRequestAttributeSpec) => this.log(`added RequestAttributeSpec w/ id=${response.uid}`)),
      catchError(this.handleError<SORespRequestAttributeSpec>('RequestAttributeSpec post'))
    );
  }

  /** PUT: update RequestAttributeSpec  */
  put(request: SOPutRequestAttributeSpec): Observable<SORespRequestAttributeSpec> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespRequestAttributeSpec>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespRequestAttributeSpec, resp) }),
      tap((response: SORespRequestAttributeSpec) => this.log(`edited RequestAttributeSpec w/ uid=${uid}`)),
      catchError(this.handleError<SORespRequestAttributeSpec>('RequestAttributeSpec put'))
    );
  }

  /** DELETE: delete the RequestAttributeSpec from the server */
  delete(request: SODeleteRequestAttributeSpec): Observable<SORespRequestAttributeSpec> {
    return this.httpClient.delete<SORespRequestAttributeSpec>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted RequestAttributeSpec uid=${request.uid}`)),
      catchError(this.handleError<SORespRequestAttributeSpec>('delete RequestAttributeSpec'))
    );
  }
}
