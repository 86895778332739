import { SOGetStore } from '../store/so-get-store';
import { SOGetStoreRelationshipSpec } from '../store-relationship-spec/so-get-store-relationship-spec';
import { SOGetCommit } from '../commit/so-get-commit';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostStoreStoreRelationshipSpecAssociation {

  constructor(
    public store: SOGetStore,
    public storeRelationshipSpec: SOGetStoreRelationshipSpec,
    public commit: SOGetCommit,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostStoreStoreRelationshipSpecAssociation(
      'store' in params ? params.store : null,
      'storeRelationshipSpec' in params ? params.storeRelationshipSpec : null,
      'commit' in params ? params.commit : null,
      'organization' in params ? params.organization : null,
    );
  }
}
