import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostStoreStoreRelationshipSpecAssociation } from './so-post-store-store-relationship-spec-association';
import { SOGetStoreStoreRelationshipSpecAssociation } from './so-get-store-store-relationship-spec-association';
import { SOPutStoreStoreRelationshipSpecAssociation } from './so-put-store-store-relationship-spec-association';
import { SODeleteStoreStoreRelationshipSpecAssociation } from './so-delete-store-store-relationship-spec-association';
import {SORespStoreStoreRelationshipSpecAssociation} from './so-resp-store-store-relationship-spec-association';
import {SOPluralStoreStoreRelationshipSpecAssociation} from './so-plural-store-store-relationship-spec-association';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOStoreStoreRelationshipSpecAssociationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'store_store_relationship_spec_association',
      entityCore
    );
  }

  /** GET StoreStoreRelationshipSpecAssociation by id. Will 404 if id not found */
  get(request: SOGetStoreStoreRelationshipSpecAssociation): Observable<SOPluralStoreStoreRelationshipSpecAssociation> {
    return this.httpClient.get<SOPluralStoreStoreRelationshipSpecAssociation>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralStoreStoreRelationshipSpecAssociation, resp) }),
      tap(_ => this.log(`fetched StoreStoreRelationshipSpecAssociation`)),
      catchError(this.handleError<SOPluralStoreStoreRelationshipSpecAssociation>(`get StoreStoreRelationshipSpecAssociation`))
    );
  }

  /** POST: add a new StoreStoreRelationshipSpecAssociation to the server */
  post(request: SOPostStoreStoreRelationshipSpecAssociation): Observable<SORespStoreStoreRelationshipSpecAssociation> {
    return this.httpClient.post<SORespStoreStoreRelationshipSpecAssociation>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespStoreStoreRelationshipSpecAssociation, resp) }),
      tap((response: SORespStoreStoreRelationshipSpecAssociation) => this.log(`added StoreStoreRelationshipSpecAssociation w/ id=${response.uid}`)),
      catchError(this.handleError<SORespStoreStoreRelationshipSpecAssociation>('StoreStoreRelationshipSpecAssociation post'))
    );
  }

  /** PUT: update StoreStoreRelationshipSpecAssociation  */
  put(request: SOPutStoreStoreRelationshipSpecAssociation): Observable<SORespStoreStoreRelationshipSpecAssociation> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespStoreStoreRelationshipSpecAssociation>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespStoreStoreRelationshipSpecAssociation, resp) }),
      tap((response: SORespStoreStoreRelationshipSpecAssociation) => this.log(`edited StoreStoreRelationshipSpecAssociation w/ uid=${uid}`)),
      catchError(this.handleError<SORespStoreStoreRelationshipSpecAssociation>('StoreStoreRelationshipSpecAssociation put'))
    );
  }

  /** DELETE: delete the StoreStoreRelationshipSpecAssociation from the server */
  delete(request: SODeleteStoreStoreRelationshipSpecAssociation): Observable<SORespStoreStoreRelationshipSpecAssociation> {
    return this.httpClient.delete<SORespStoreStoreRelationshipSpecAssociation>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted StoreStoreRelationshipSpecAssociation uid=${request.uid}`)),
      catchError(this.handleError<SORespStoreStoreRelationshipSpecAssociation>('delete StoreStoreRelationshipSpecAssociation'))
    );
  }
}
