import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostResponse } from './so-post-response';
import { SOGetResponse } from './so-get-response';
import { SOPutResponse } from './so-put-response';
import { SODeleteResponse } from './so-delete-response';
import {SORespResponse} from './so-resp-response';
import {SOPluralResponse} from './so-plural-response';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOResponseService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'response',
      entityCore
    );
  }

  /** GET Response by id. Will 404 if id not found */
  get(request: SOGetResponse): Observable<SOPluralResponse> {
    return this.httpClient.get<SOPluralResponse>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralResponse, resp) }),
      tap(_ => this.log(`fetched Response`)),
      catchError(this.handleError<SOPluralResponse>(`get Response`))
    );
  }

  /** POST: add a new Response to the server */
  post(request: SOPostResponse): Observable<SORespResponse> {
    return this.httpClient.post<SORespResponse>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespResponse, resp) }),
      tap((response: SORespResponse) => this.log(`added Response w/ id=${response.uid}`)),
      catchError(this.handleError<SORespResponse>('Response post'))
    );
  }

  /** PUT: update Response  */
  put(request: SOPutResponse): Observable<SORespResponse> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespResponse>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespResponse, resp) }),
      tap((response: SORespResponse) => this.log(`edited Response w/ uid=${uid}`)),
      catchError(this.handleError<SORespResponse>('Response put'))
    );
  }

  /** DELETE: delete the Response from the server */
  delete(request: SODeleteResponse): Observable<SORespResponse> {
    return this.httpClient.delete<SORespResponse>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Response uid=${request.uid}`)),
      catchError(this.handleError<SORespResponse>('delete Response'))
    );
  }
}
