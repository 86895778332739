import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOStoreAttributeSpecService} from '../../core/store-attribute-spec/eo-store-attribute-spec';
import {StoreAttributeSpecListEntryFactory} from './store-attribute-spec-list-entry-factory';
import {StoreAttributeSpecDetailFactory} from './store-attribute-spec-detail-factory';
import {SOGetStoreAttributeSpec} from '../../core/store-attribute-spec/so-get-store-attribute-spec';
import {RAPutStoreAttributeSpec} from './ra-put-store-attribute-spec';
import {RAPostStoreAttributeSpec} from './ra-post-store-attribute-spec';
import {RADeleteStoreAttributeSpec} from './ra-delete-store-attribute-spec';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import {SOGetApp} from '../../core/app/so-get-app'


export class StoreAttributeSpecCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'storeAttributeSpec';
  title = 'Store attribute spec';
  listEntryFactory = new StoreAttributeSpecListEntryFactory();
  detailFactory = new StoreAttributeSpecDetailFactory();

  entityService: EOStoreAttributeSpecService;
  soGetClass = SOGetStoreAttributeSpec;
  soGet: SOGetStoreAttributeSpec;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOStoreAttributeSpecService
    );
    this.adapters = [
      new RAPostStoreAttributeSpec(this),
      new RAPutStoreAttributeSpec(this),
      new RADeleteStoreAttributeSpec(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetStoreAttributeSpec();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.app = new SOGetApp(
      this.activeUserService.getScope('app').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
