import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOCommitService} from '../../core/commit/eo-commit';
import {CommitListEntryFactory} from './commit-list-entry-factory';
import {CommitDetailFactory} from './commit-detail-factory';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {RAPutCommit} from './ra-put-commit';
import {RAPostCommit} from './ra-post-commit';
import {RADeleteCommit} from './ra-delete-commit';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetApp} from '../../core/app/so-get-app'
import {SOGetOrganization} from '../../core/organization/so-get-organization'


export class CommitCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'commit';
  title = 'Commit';
  listEntryFactory = new CommitListEntryFactory();
  detailFactory = new CommitDetailFactory();

  entityService: EOCommitService;
  soGetClass = SOGetCommit;
  soGet: SOGetCommit;

  isScope = true;

  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOCommitService
    );
    this.adapters = [
      new RAPostCommit(this),
      new RAPutCommit(this),
      new RADeleteCommit(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetCommit();
    this.soGet.app = new SOGetApp(
      this.activeUserService.getScope('app').uid
    );
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
