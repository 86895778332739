import {SOPutResponseResponseRelationshipSpecAssociation} from '../../core/response-response-relationship-spec-association/so-put-response-response-relationship-spec-association';
import {ResponseResponseRelationshipSpecAssociationCore} from './response-response-relationship-spec-association.core';
import {SOGetResponseResponseRelationshipSpecAssociation} from '../../core/response-response-relationship-spec-association/so-get-response-response-relationship-spec-association';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putResponseResponseRelationshipSpecAssociationFormFieldOptions} from './put-response-response-relationship-spec-association-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {ResponseCore} from '../bv-response/response.core';
import {SOGetResponse} from '../../core/response/so-get-response';
import {ResponseRelationshipSpecCore} from '../bv-response-relationship-spec/response-relationship-spec.core';
import {SOGetResponseRelationshipSpec} from '../../core/response-relationship-spec/so-get-response-relationship-spec';

export class RAPutResponseResponseRelationshipSpecAssociation extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putResponseResponseRelationshipSpecAssociationFormFieldOptions);

  RequestClass = SOPutResponseResponseRelationshipSpecAssociation

  constructor(
    public entityCore: ResponseResponseRelationshipSpecAssociationCore
  ) {
    super();
    if (putResponseResponseRelationshipSpecAssociationFormFieldOptions.response) {
      putResponseResponseRelationshipSpecAssociationFormFieldOptions.response.makeCore = true;
    }
    if (putResponseResponseRelationshipSpecAssociationFormFieldOptions.responseRelationshipSpec) {
      putResponseResponseRelationshipSpecAssociationFormFieldOptions.responseRelationshipSpec.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier(
        'response',
        SOGetResponse,
      ),
      this.getFormValueFromIdentifier(
        'responseRelationshipSpec',
        SOGetResponseRelationshipSpec,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}