import {SORespEnumeratorEnumeralAssociation} from '../../core/enumerator-enumeral-association/so-resp-enumerator-enumeral-association';
import {EnumeratorEnumeralAssociationCore} from './enumerator-enumeral-association.core';
import { ListEntry, ListEntryFactory, ListEntryFactoryProtocol} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';


export class EnumeratorEnumeralAssociationListEntryFactory  extends ListEntryFactory implements ListEntryFactoryProtocol {

  makeListEntry(parentCoreViewSpec, entityCore: EntityCoreProtocol, entity: SORespEnumeratorEnumeralAssociation, section: number = 0): ListEntry {
    const resp = super.makeListEntry(parentCoreViewSpec, entityCore, entity, section)
    return resp
  }
}
