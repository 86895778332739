import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOWidgetRelationshipSpecService} from '../../core/widget-relationship-spec/eo-widget-relationship-spec';
import {WidgetRelationshipSpecListEntryFactory} from './widget-relationship-spec-list-entry-factory';
import {WidgetRelationshipSpecDetailFactory} from './widget-relationship-spec-detail-factory';
import {SOGetWidgetRelationshipSpec} from '../../core/widget-relationship-spec/so-get-widget-relationship-spec';
import {RAPutWidgetRelationshipSpec} from './ra-put-widget-relationship-spec';
import {RAPostWidgetRelationshipSpec} from './ra-post-widget-relationship-spec';
import {RADeleteWidgetRelationshipSpec} from './ra-delete-widget-relationship-spec';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import {SOGetApp} from '../../core/app/so-get-app'


export class WidgetRelationshipSpecCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'widgetRelationshipSpec';
  title = 'Widget relationship spec';
  listEntryFactory = new WidgetRelationshipSpecListEntryFactory();
  detailFactory = new WidgetRelationshipSpecDetailFactory();

  entityService: EOWidgetRelationshipSpecService;
  soGetClass = SOGetWidgetRelationshipSpec;
  soGet: SOGetWidgetRelationshipSpec;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOWidgetRelationshipSpecService
    );
    this.adapters = [
      new RAPostWidgetRelationshipSpec(this),
      new RAPutWidgetRelationshipSpec(this),
      new RADeleteWidgetRelationshipSpec(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetWidgetRelationshipSpec();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.app = new SOGetApp(
      this.activeUserService.getScope('app').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
