import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostApp } from './so-post-app';
import { SOGetApp } from './so-get-app';
import { SOPutApp } from './so-put-app';
import { SODeleteApp } from './so-delete-app';
import {SORespApp} from './so-resp-app';
import {SOPluralApp} from './so-plural-app';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOAppService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'app',
      entityCore
    );
  }

  /** GET App by id. Will 404 if id not found */
  get(request: SOGetApp): Observable<SOPluralApp> {
    return this.httpClient.get<SOPluralApp>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralApp, resp) }),
      tap(_ => this.log(`fetched App`)),
      catchError(this.handleError<SOPluralApp>(`get App`))
    );
  }

  /** POST: add a new App to the server */
  post(request: SOPostApp): Observable<SORespApp> {
    return this.httpClient.post<SORespApp>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespApp, resp) }),
      tap((response: SORespApp) => this.log(`added App w/ id=${response.uid}`)),
      catchError(this.handleError<SORespApp>('App post'))
    );
  }

  /** PUT: update App  */
  put(request: SOPutApp): Observable<SORespApp> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespApp>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespApp, resp) }),
      tap((response: SORespApp) => this.log(`edited App w/ uid=${uid}`)),
      catchError(this.handleError<SORespApp>('App put'))
    );
  }

  /** DELETE: delete the App from the server */
  delete(request: SODeleteApp): Observable<SORespApp> {
    return this.httpClient.delete<SORespApp>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted App uid=${request.uid}`)),
      catchError(this.handleError<SORespApp>('delete App'))
    );
  }
}
