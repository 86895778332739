import { Type } from 'class-transformer';
import {ENViewType} from '../../enums';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespView implements ObjectiveProtocol {

  entityType: string = 'view';
  uid: string;
  name: string;
  identifier: string;
  type: ENViewType = null;
}