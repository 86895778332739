import {SORespAttributeCodableAssociation} from '../../core/attribute-codable-association/so-resp-attribute-codable-association';
import {AttributeCodableAssociationCore} from './attribute-codable-association.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putAttributeCodableAssociationFormFieldOptions} from './put-attribute-codable-association-form-fields';
import {SOGetAttributeCodableAssociation} from '../../core/attribute-codable-association/so-get-attribute-codable-association';
import {SOGetAttribute} from '../../core/attribute/so-get-attribute';
import {SOGetCodable} from '../../core/codable/so-get-codable';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetApp} from '../../core/app/so-get-app';


export class AttributeCodableAssociationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespAttributeCodableAssociation = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []


    const attributeCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'attribute');
    attributeCore.soGet = SOGetAttribute.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, attributeCore)
    attributeCore.title = heading ? heading : attributeCore.title
    attributeCore.listTitle = 'Attribute'

    if (entity.attribute) {
        displayStringFactory.reconfigure(entity.attribute, entityViewSpec.entityCore.type)
    }
    attributeCore.configureForDisplay(entityViewSpec)

    const attributeDetail = new DetailEntry(
      heading ? heading : attributeCore.listTitle,
      entity.attribute ? displayStringFactory.primaryString : null,
      'attribute',
      BaseDetailType.pushedDetail,
      attributeCore,
      EditableType.always,
      putAttributeCodableAssociationFormFieldOptions.attribute
    )
    if (entity.attribute) {
      attributeDetail.cachedListEntry = attributeCore.makeListEntry(entityViewSpec, attributeCore, entity.attribute, 0)
    }
    attributeDetail.singleFieldSelect = true
    attributes.push(attributeDetail);

    const codableCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'codable');
    codableCore.soGet = SOGetCodable.construct({
      app: new SOGetApp(activeUserService.getScope('app').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, codableCore)
    codableCore.title = heading ? heading : codableCore.title
    codableCore.listTitle = 'Codable'

    if (entity.codable) {
        displayStringFactory.reconfigure(entity.codable, entityViewSpec.entityCore.type)
    }
    codableCore.configureForDisplay(entityViewSpec)

    const codableDetail = new DetailEntry(
      heading ? heading : codableCore.listTitle,
      entity.codable ? displayStringFactory.primaryString : null,
      'codable',
      BaseDetailType.pushedDetail,
      codableCore,
      EditableType.always,
      putAttributeCodableAssociationFormFieldOptions.codable
    )
    if (entity.codable) {
      codableDetail.cachedListEntry = codableCore.makeListEntry(entityViewSpec, codableCore, entity.codable, 0)
    }
    codableDetail.singleFieldSelect = true
    attributes.push(codableDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
