import {SORespRelationshipLink} from '../../core/relationship-link/so-resp-relationship-link';
import {RelationshipLinkCore} from './relationship-link.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putRelationshipLinkFormFieldOptions} from './put-relationship-link-form-fields';
import {SOGetRelationshipLink} from '../../core/relationship-link/so-get-relationship-link';
import {SOGetRelationship} from '../../core/relationship/so-get-relationship';
import {SOGetCommitRelationshipLinkAssociation} from '../../core/commit-relationship-link-association/so-get-commit-relationship-link-association';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetApp} from '../../core/app/so-get-app';


export class RelationshipLinkDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespRelationshipLink = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []


    const relationshipsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'relationship');
    relationshipsCore.soGet = SOGetRelationship.construct({
      relationshipLink: new SOGetRelationshipLink(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, relationshipsCore)
    relationshipsCore.title = heading ? heading : relationshipsCore.title
    relationshipsCore.listTitle = 'Relationships'

    const relationshipsSelectCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'relationship');
    relationshipsSelectCore.soGet = SOGetRelationship.construct({
      relationshipLink: new SOGetRelationshipLink(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    relationshipsSelectCore.soGet.predicate = 'listSelect'
    heading = getHeading(entityViewSpec.entityCore, relationshipsSelectCore)
    relationshipsSelectCore.title = heading ? heading : relationshipsSelectCore.title
    relationshipsSelectCore.listTitle = 'Relationships'
    relationshipsSelectCore.selectItemFromCoreParentGet = new SOGetRelationshipLink(entity.uid)
    relationshipsSelectCore.selectItemFromCoreParentGetIdentifier = 'relationshipLink'
    relationshipsSelectCore.checklistAttributeName = 'relationshipLink'
    relationshipsCore.selectItemFromCore = relationshipsSelectCore
    relationshipsCore.configureForDisplay(entityViewSpec)

    const relationshipsDetail = new DetailEntry(
      heading ? heading : relationshipsCore.listTitle,
      null,
      'relationships',
      BaseDetailType.embeddedList,
      relationshipsCore,
      EditableType.never
    )
    attributes.push(relationshipsDetail);

    const commitRelationshipLinkAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'commitRelationshipLinkAssociation');
    commitRelationshipLinkAssociationsCore.soGet = SOGetCommitRelationshipLinkAssociation.construct({
      entity: new SOGetRelationshipLink(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, commitRelationshipLinkAssociationsCore)
    commitRelationshipLinkAssociationsCore.title = heading ? heading : commitRelationshipLinkAssociationsCore.title
    commitRelationshipLinkAssociationsCore.listTitle = 'Commit relationship link associations'

    commitRelationshipLinkAssociationsCore.configureForDisplay(entityViewSpec)

    const commitRelationshipLinkAssociationsDetail = new DetailEntry(
      heading ? heading : commitRelationshipLinkAssociationsCore.listTitle,
      null,
      'commitRelationshipLinkAssociations',
      BaseDetailType.embeddedList,
      commitRelationshipLinkAssociationsCore,
      EditableType.always,
      putRelationshipLinkFormFieldOptions.commitRelationshipLinkAssociations
    )
    attributes.push(commitRelationshipLinkAssociationsDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
