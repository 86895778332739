import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOAttachmentService} from '../../core/attachment/eo-attachment';
import {AttachmentListEntryFactory} from './attachment-list-entry-factory';
import {AttachmentDetailFactory} from './attachment-detail-factory';
import {SOGetAttachment} from '../../core/attachment/so-get-attachment';
import {RAPutAttachment} from './ra-put-attachment';
import {RAPostAttachment} from './ra-post-attachment';
import {RADeleteAttachment} from './ra-delete-attachment';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetOrganization} from '../../core/organization/so-get-organization'


export class AttachmentCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'attachment';
  title = 'Attachment';
  listEntryFactory = new AttachmentListEntryFactory();
  detailFactory = new AttachmentDetailFactory();

  entityService: EOAttachmentService;
  soGetClass = SOGetAttachment;
  soGet: SOGetAttachment;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOAttachmentService
    );
    this.adapters = [
      new RAPostAttachment(this),
      new RAPutAttachment(this),
      new RADeleteAttachment(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetAttachment();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
