import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostCommitCodableAssociation } from './so-post-commit-codable-association';
import { SOGetCommitCodableAssociation } from './so-get-commit-codable-association';
import { SOPutCommitCodableAssociation } from './so-put-commit-codable-association';
import { SODeleteCommitCodableAssociation } from './so-delete-commit-codable-association';
import {SORespCommitCodableAssociation} from './so-resp-commit-codable-association';
import {SOPluralCommitCodableAssociation} from './so-plural-commit-codable-association';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOCommitCodableAssociationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'commit_codable_association',
      entityCore
    );
  }

  /** GET CommitCodableAssociation by id. Will 404 if id not found */
  get(request: SOGetCommitCodableAssociation): Observable<SOPluralCommitCodableAssociation> {
    return this.httpClient.get<SOPluralCommitCodableAssociation>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralCommitCodableAssociation, resp) }),
      tap(_ => this.log(`fetched CommitCodableAssociation`)),
      catchError(this.handleError<SOPluralCommitCodableAssociation>(`get CommitCodableAssociation`))
    );
  }

  /** POST: add a new CommitCodableAssociation to the server */
  post(request: SOPostCommitCodableAssociation): Observable<SORespCommitCodableAssociation> {
    return this.httpClient.post<SORespCommitCodableAssociation>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespCommitCodableAssociation, resp) }),
      tap((response: SORespCommitCodableAssociation) => this.log(`added CommitCodableAssociation w/ id=${response.uid}`)),
      catchError(this.handleError<SORespCommitCodableAssociation>('CommitCodableAssociation post'))
    );
  }

  /** PUT: update CommitCodableAssociation  */
  put(request: SOPutCommitCodableAssociation): Observable<SORespCommitCodableAssociation> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespCommitCodableAssociation>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespCommitCodableAssociation, resp) }),
      tap((response: SORespCommitCodableAssociation) => this.log(`edited CommitCodableAssociation w/ uid=${uid}`)),
      catchError(this.handleError<SORespCommitCodableAssociation>('CommitCodableAssociation put'))
    );
  }

  /** DELETE: delete the CommitCodableAssociation from the server */
  delete(request: SODeleteCommitCodableAssociation): Observable<SORespCommitCodableAssociation> {
    return this.httpClient.delete<SORespCommitCodableAssociation>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted CommitCodableAssociation uid=${request.uid}`)),
      catchError(this.handleError<SORespCommitCodableAssociation>('delete CommitCodableAssociation'))
    );
  }
}
