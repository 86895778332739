import { Type } from 'class-transformer';
import {ENSesMessageType} from '../../enums';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespSesMessage implements ObjectiveProtocol {

  entityType: string = 'sesMessage';
  uid: string;
  data: string;
  @Type(() => Date)
  dateCreated: Date;
  messageType: ENSesMessageType;
}