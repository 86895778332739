import {SOPutWidgetAttributeSpec} from '../../core/widget-attribute-spec/so-put-widget-attribute-spec';
import {WidgetAttributeSpecCore} from './widget-attribute-spec.core';
import {SOGetWidgetAttributeSpec} from '../../core/widget-attribute-spec/so-get-widget-attribute-spec';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putWidgetAttributeSpecFormFieldOptions} from './put-widget-attribute-spec-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {WidgetWidgetAttributeSpecAssociationCore} from '../bv-widget-widget-attribute-spec-association/widget-widget-attribute-spec-association.core';
import {SOGetWidgetWidgetAttributeSpecAssociation} from '../../core/widget-widget-attribute-spec-association/so-get-widget-widget-attribute-spec-association';
import {AttributeWidgetAttributeSpecAssociationCore} from '../bv-attribute-widget-attribute-spec-association/attribute-widget-attribute-spec-association.core';
import {SOGetAttributeWidgetAttributeSpecAssociation} from '../../core/attribute-widget-attribute-spec-association/so-get-attribute-widget-attribute-spec-association';

export class RAPutWidgetAttributeSpec extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putWidgetAttributeSpecFormFieldOptions);

  RequestClass = SOPutWidgetAttributeSpec

  constructor(
    public entityCore: WidgetAttributeSpecCore
  ) {
    super();
    if (putWidgetAttributeSpecFormFieldOptions.widget) {
      putWidgetAttributeSpecFormFieldOptions.widget.makeCore = true;
    }
    if (putWidgetAttributeSpecFormFieldOptions.attribute) {
      putWidgetAttributeSpecFormFieldOptions.attribute.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('isRequired'),
      this.getFormValueFromIdentifier('defaultValue'),
      [this.getFormValueFromIdentifier('widget', SOGetWidgetWidgetAttributeSpecAssociation)],
      [this.getFormValueFromIdentifier('attribute', SOGetAttributeWidgetAttributeSpecAssociation)],
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}