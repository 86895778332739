import { SOGetResponse } from '../response/so-get-response';
import { SOGetResponseAttributeSpec } from '../response-attribute-spec/so-get-response-attribute-spec';
import { SOGetCommit } from '../commit/so-get-commit';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostResponseResponseAttributeSpecAssociation {

  constructor(
    public response: SOGetResponse,
    public responseAttributeSpec: SOGetResponseAttributeSpec,
    public commit: SOGetCommit,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostResponseResponseAttributeSpecAssociation(
      'response' in params ? params.response : null,
      'responseAttributeSpec' in params ? params.responseAttributeSpec : null,
      'commit' in params ? params.commit : null,
      'organization' in params ? params.organization : null,
    );
  }
}
