import { Type } from 'class-transformer';
import { SORespOrganization } from '../organization/so-resp-organization';
import {SOMediaSpec} from 'ngx-lionheart-material';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespMedia implements ObjectiveProtocol {

  entityType: string = 'media';
  uid: string;
  @Type(() => SOMediaSpec)
  links: SOMediaSpec[];
  name: string = '';
  description: string = '';
  size: number;
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
}