import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOEntityStoreAssociationService} from '../../core/entity-store-association/eo-entity-store-association';
import {EntityStoreAssociationListEntryFactory} from './entity-store-association-list-entry-factory';
import {EntityStoreAssociationDetailFactory} from './entity-store-association-detail-factory';
import {SOGetEntityStoreAssociation} from '../../core/entity-store-association/so-get-entity-store-association';
import {RAPutEntityStoreAssociation} from './ra-put-entity-store-association';
import {RAPostEntityStoreAssociation} from './ra-post-entity-store-association';
import {RADeleteEntityStoreAssociation} from './ra-delete-entity-store-association';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetCommit} from '../../core/commit/so-get-commit'
import {SOGetOrganization} from '../../core/organization/so-get-organization'


export class EntityStoreAssociationCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'entityStoreAssociation';
  title = 'Entity store association';
  listEntryFactory = new EntityStoreAssociationListEntryFactory();
  detailFactory = new EntityStoreAssociationDetailFactory();

  entityService: EOEntityStoreAssociationService;
  soGetClass = SOGetEntityStoreAssociation;
  soGet: SOGetEntityStoreAssociation;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOEntityStoreAssociationService
    );
    this.adapters = [
      new RAPostEntityStoreAssociation(this),
      new RAPutEntityStoreAssociation(this),
      new RADeleteEntityStoreAssociation(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetEntityStoreAssociation();
    this.soGet.commit = new SOGetCommit(
      this.activeUserService.getScope('commit').uid
    );
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
