import { SOGetCommitCodableAssociation } from '../commit-codable-association/so-get-commit-codable-association';


export class SOPutEnumerator {

  constructor(
    public uid: string,
    public name: string = null,
    public commitCodableAssociations: SOGetCommitCodableAssociation[] = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutEnumerator(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'commitCodableAssociations' in params ? params.commitCodableAssociations : null,
    );
  }
}
