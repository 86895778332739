import { SOGetRequestRequestAttributeSpecAssociation } from '../request-request-attribute-spec-association/so-get-request-request-attribute-spec-association';
import { SOGetAttributeRequestAttributeSpecAssociation } from '../attribute-request-attribute-spec-association/so-get-attribute-request-attribute-spec-association';


export class SOPutRequestAttributeSpec {

  constructor(
    public uid: string,
    public isRequired: boolean = null,
    public defaultValue: string = null,
    public request: SOGetRequestRequestAttributeSpecAssociation[] = null,
    public attribute: SOGetAttributeRequestAttributeSpecAssociation[] = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutRequestAttributeSpec(
      'uid' in params ? params.uid : null,
      'isRequired' in params ? params.isRequired : null,
      'defaultValue' in params ? params.defaultValue : null,
      'request' in params ? params.request : null,
      'attribute' in params ? params.attribute : null,
    );
  }
}
