import { SOGetRequest } from '../request/so-get-request';
import { SOGetRequestAttributeSpec } from '../request-attribute-spec/so-get-request-attribute-spec';
import { SOGetCommit } from '../commit/so-get-commit';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostRequestRequestAttributeSpecAssociation {

  constructor(
    public request: SOGetRequest,
    public requestAttributeSpec: SOGetRequestAttributeSpec,
    public commit: SOGetCommit,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostRequestRequestAttributeSpecAssociation(
      'request' in params ? params.request : null,
      'requestAttributeSpec' in params ? params.requestAttributeSpec : null,
      'commit' in params ? params.commit : null,
      'organization' in params ? params.organization : null,
    );
  }
}
