import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostRoleGrant } from './so-post-role-grant';
import { SOGetRoleGrant } from './so-get-role-grant';
import { SOPutRoleGrant } from './so-put-role-grant';
import { SODeleteRoleGrant } from './so-delete-role-grant';
import {SORespRoleGrant} from './so-resp-role-grant';
import {SOPluralRoleGrant} from './so-plural-role-grant';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EORoleGrantService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'role_grant',
      entityCore
    );
  }

  /** GET RoleGrant by id. Will 404 if id not found */
  get(request: SOGetRoleGrant): Observable<SOPluralRoleGrant> {
    return this.httpClient.get<SOPluralRoleGrant>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralRoleGrant, resp) }),
      tap(_ => this.log(`fetched RoleGrant`)),
      catchError(this.handleError<SOPluralRoleGrant>(`get RoleGrant`))
    );
  }

  /** POST: add a new RoleGrant to the server */
  post(request: SOPostRoleGrant): Observable<SORespRoleGrant> {
    return this.httpClient.post<SORespRoleGrant>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespRoleGrant, resp) }),
      tap((response: SORespRoleGrant) => this.log(`added RoleGrant w/ id=${response.uid}`)),
      catchError(this.handleError<SORespRoleGrant>('RoleGrant post'))
    );
  }

  /** PUT: update RoleGrant  */
  put(request: SOPutRoleGrant): Observable<SORespRoleGrant> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespRoleGrant>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespRoleGrant, resp) }),
      tap((response: SORespRoleGrant) => this.log(`edited RoleGrant w/ uid=${uid}`)),
      catchError(this.handleError<SORespRoleGrant>('RoleGrant put'))
    );
  }

  /** DELETE: delete the RoleGrant from the server */
  delete(request: SODeleteRoleGrant): Observable<SORespRoleGrant> {
    return this.httpClient.delete<SORespRoleGrant>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted RoleGrant uid=${request.uid}`)),
      catchError(this.handleError<SORespRoleGrant>('delete RoleGrant'))
    );
  }
}
