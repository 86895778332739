import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EORequestRequestAttributeSpecAssociationService} from '../../core/request-request-attribute-spec-association/eo-request-request-attribute-spec-association';
import {RequestRequestAttributeSpecAssociationListEntryFactory} from './request-request-attribute-spec-association-list-entry-factory';
import {RequestRequestAttributeSpecAssociationDetailFactory} from './request-request-attribute-spec-association-detail-factory';
import {SOGetRequestRequestAttributeSpecAssociation} from '../../core/request-request-attribute-spec-association/so-get-request-request-attribute-spec-association';
import {RAPutRequestRequestAttributeSpecAssociation} from './ra-put-request-request-attribute-spec-association';
import {RAPostRequestRequestAttributeSpecAssociation} from './ra-post-request-request-attribute-spec-association';
import {RADeleteRequestRequestAttributeSpecAssociation} from './ra-delete-request-request-attribute-spec-association';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetCommit} from '../../core/commit/so-get-commit'
import {SOGetOrganization} from '../../core/organization/so-get-organization'


export class RequestRequestAttributeSpecAssociationCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'requestRequestAttributeSpecAssociation';
  title = 'Request request attribute spec association';
  listEntryFactory = new RequestRequestAttributeSpecAssociationListEntryFactory();
  detailFactory = new RequestRequestAttributeSpecAssociationDetailFactory();

  entityService: EORequestRequestAttributeSpecAssociationService;
  soGetClass = SOGetRequestRequestAttributeSpecAssociation;
  soGet: SOGetRequestRequestAttributeSpecAssociation;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EORequestRequestAttributeSpecAssociationService
    );
    this.adapters = [
      new RAPostRequestRequestAttributeSpecAssociation(this),
      new RAPutRequestRequestAttributeSpecAssociation(this),
      new RADeleteRequestRequestAttributeSpecAssociation(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetRequestRequestAttributeSpecAssociation();
    this.soGet.commit = new SOGetCommit(
      this.activeUserService.getScope('commit').uid
    );
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
