import {SOPostEnumerator} from '../../core/enumerator/so-post-enumerator';
import {EnumeratorCore} from './enumerator.core';
import {SOGetEnumerator} from '../../core/enumerator/so-get-enumerator';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postEnumeratorFormFieldOptions} from './post-enumerator-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {AppCore} from '../bv-app/app.core';
import {SOGetApp} from '../../core/app/so-get-app';
import {CommitCodableAssociationCore} from '../bv-commit-codable-association/commit-codable-association.core';
import {SOGetCommitCodableAssociation} from '../../core/commit-codable-association/so-get-commit-codable-association';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostEnumerator extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postEnumeratorFormFieldOptions);

  RequestClass = SOPostEnumerator

  constructor(
    public entityCore: EnumeratorCore
  ) {
    super();
    if (postEnumeratorFormFieldOptions.commitCodableAssociations) {
      postEnumeratorFormFieldOptions.commitCodableAssociations.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'app',
        SOGetApp,
        true,
      ),
      this.getFormValueFromIdentifier('name'),
      [this.getFormValueFromIdentifier('commitCodableAssociations', SOGetCommitCodableAssociation)],
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}