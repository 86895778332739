import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const relationshipField = new FormField(
  'relationship',
  'Relationship',
  'relationship',
  FormFieldType.pushedSelectionList,
  false,
)
const storeField = new FormField(
  'store',
  'Store',
  'storeRelationshipSpec',
  FormFieldType.pushedSelectionList,
  false,
)


export const putRelationshipStoreRelationshipSpecAssociationFormFieldOptions: {[key: string]: FormField} = {
  relationship: relationshipField,
  store: storeField,
};