import { Type } from 'class-transformer';
import { SORespRequest } from '../request/so-resp-request';
import { SORespRequestRelationshipSpec } from '../request-relationship-spec/so-resp-request-relationship-spec';
import { SORespCommit } from '../commit/so-resp-commit';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespRequestRequestRelationshipSpecAssociation implements ObjectiveProtocol {

  entityType: string = 'requestRequestRelationshipSpecAssociation';
  uid: string;
  @Type(() => SORespRequest)
  request: SORespRequest;
  @Type(() => SORespRequestRelationshipSpec)
  requestRelationshipSpec: SORespRequestRelationshipSpec;
  @Type(() => SORespCommit)
  commit: SORespCommit;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
}