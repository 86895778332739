import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const commitRelationshipLinkAssociationsField = new FormField(
  'commitRelationshipLinkAssociations',
  'Commit relationship link associations',
  'commitRelationshipLinkAssociation',
  FormFieldType.pushedSelectionList,
  false,
)


export const putRelationshipLinkFormFieldOptions: {[key: string]: FormField} = {
  commitRelationshipLinkAssociations: commitRelationshipLinkAssociationsField,
};