import {SOPostRelationshipLink} from '../../core/relationship-link/so-post-relationship-link';
import {RelationshipLinkCore} from './relationship-link.core';
import {SOGetRelationshipLink} from '../../core/relationship-link/so-get-relationship-link';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postRelationshipLinkFormFieldOptions} from './post-relationship-link-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {AppCore} from '../bv-app/app.core';
import {SOGetApp} from '../../core/app/so-get-app';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {CommitRelationshipLinkAssociationCore} from '../bv-commit-relationship-link-association/commit-relationship-link-association.core';
import {SOGetCommitRelationshipLinkAssociation} from '../../core/commit-relationship-link-association/so-get-commit-relationship-link-association';

export class RAPostRelationshipLink extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postRelationshipLinkFormFieldOptions);

  RequestClass = SOPostRelationshipLink

  constructor(
    public entityCore: RelationshipLinkCore
  ) {
    super();
    if (postRelationshipLinkFormFieldOptions.commitRelationshipLinkAssociations) {
      postRelationshipLinkFormFieldOptions.commitRelationshipLinkAssociations.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'app',
        SOGetApp,
        true,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      [this.getFormValueFromIdentifier('commitRelationshipLinkAssociations', SOGetCommitRelationshipLinkAssociation)],
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}