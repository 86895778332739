import { SOGetResponseResponseRelationshipSpecAssociation } from '../response-response-relationship-spec-association/so-get-response-response-relationship-spec-association';
import { SOGetRelationshipResponseRelationshipSpecAssociation } from '../relationship-response-relationship-spec-association/so-get-relationship-response-relationship-spec-association';
import { SOGetOrganization } from '../organization/so-get-organization';
import { SOGetApp } from '../app/so-get-app';


export class SOPostResponseRelationshipSpec {

  constructor(
    public isRequired: boolean,
    public response: SOGetResponseResponseRelationshipSpecAssociation[],
    public relationship: SOGetRelationshipResponseRelationshipSpecAssociation[],
    public organization: SOGetOrganization,
    public app: SOGetApp,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostResponseRelationshipSpec(
      'isRequired' in params ? params.isRequired : null,
      'response' in params ? params.response : null,
      'relationship' in params ? params.relationship : null,
      'organization' in params ? params.organization : null,
      'app' in params ? params.app : null,
    );
  }
}
