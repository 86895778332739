

export class SOPostUser {

  constructor(
    public username: string,
    public password: string,
    public givenName: string,
    public surname: string,
    public email: string,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostUser(
      'username' in params ? params.username : null,
      'password' in params ? params.password : null,
      'givenName' in params ? params.givenName : null,
      'surname' in params ? params.surname : null,
      'email' in params ? params.email : null,
    );
  }
}
