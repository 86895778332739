import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostAttributeCodableAssociation } from './so-post-attribute-codable-association';
import { SOGetAttributeCodableAssociation } from './so-get-attribute-codable-association';
import { SOPutAttributeCodableAssociation } from './so-put-attribute-codable-association';
import { SODeleteAttributeCodableAssociation } from './so-delete-attribute-codable-association';
import {SORespAttributeCodableAssociation} from './so-resp-attribute-codable-association';
import {SOPluralAttributeCodableAssociation} from './so-plural-attribute-codable-association';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOAttributeCodableAssociationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'attribute_codable_association',
      entityCore
    );
  }

  /** GET AttributeCodableAssociation by id. Will 404 if id not found */
  get(request: SOGetAttributeCodableAssociation): Observable<SOPluralAttributeCodableAssociation> {
    return this.httpClient.get<SOPluralAttributeCodableAssociation>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralAttributeCodableAssociation, resp) }),
      tap(_ => this.log(`fetched AttributeCodableAssociation`)),
      catchError(this.handleError<SOPluralAttributeCodableAssociation>(`get AttributeCodableAssociation`))
    );
  }

  /** POST: add a new AttributeCodableAssociation to the server */
  post(request: SOPostAttributeCodableAssociation): Observable<SORespAttributeCodableAssociation> {
    return this.httpClient.post<SORespAttributeCodableAssociation>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAttributeCodableAssociation, resp) }),
      tap((response: SORespAttributeCodableAssociation) => this.log(`added AttributeCodableAssociation w/ id=${response.uid}`)),
      catchError(this.handleError<SORespAttributeCodableAssociation>('AttributeCodableAssociation post'))
    );
  }

  /** PUT: update AttributeCodableAssociation  */
  put(request: SOPutAttributeCodableAssociation): Observable<SORespAttributeCodableAssociation> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespAttributeCodableAssociation>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAttributeCodableAssociation, resp) }),
      tap((response: SORespAttributeCodableAssociation) => this.log(`edited AttributeCodableAssociation w/ uid=${uid}`)),
      catchError(this.handleError<SORespAttributeCodableAssociation>('AttributeCodableAssociation put'))
    );
  }

  /** DELETE: delete the AttributeCodableAssociation from the server */
  delete(request: SODeleteAttributeCodableAssociation): Observable<SORespAttributeCodableAssociation> {
    return this.httpClient.delete<SORespAttributeCodableAssociation>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted AttributeCodableAssociation uid=${request.uid}`)),
      catchError(this.handleError<SORespAttributeCodableAssociation>('delete AttributeCodableAssociation'))
    );
  }
}
