import { Type } from 'class-transformer';
import { SORespEntityStoreAssociation } from '../entity-store-association/so-resp-entity-store-association';
import { SORespOrganization } from '../organization/so-resp-organization';
import { SORespApp } from '../app/so-resp-app';
import {ENStoreType} from '../../enums';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespStore implements ObjectiveProtocol {

  entityType: string = 'store';
  uid: string;
  name: string = "";
  storeType: ENStoreType;
  isDefault: boolean;
  @Type(() => SORespEntityStoreAssociation)
  entity: SORespEntityStoreAssociation[];
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
  @Type(() => SORespApp)
  app: SORespApp;
}