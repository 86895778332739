import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespProduct implements ObjectiveProtocol {

  entityType: string = 'product';
  uid: string;
  name: string;
  sku: string = '';
  exRef: string = null;
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => Date)
  dateUpdated: Date;
  active: boolean = true;
}