import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const entityField = new FormField(
  'entity',
  'Entity',
  'entity',
  FormFieldType.pushedSelectionList,
  true,
)
const requestField = new FormField(
  'request',
  'Request',
  'request',
  FormFieldType.pushedSelectionList,
  true,
)


export const postEntityRequestAssociationFormFieldOptions: {[key: string]: FormField} = {
  entity: entityField,
  request: requestField,
};