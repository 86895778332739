import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const responseField = new FormField(
  'response',
  'Response',
  'response',
  FormFieldType.pushedSelectionList,
  false,
)
const responseAttributeSpecField = new FormField(
  'responseAttributeSpec',
  'Response attribute spec',
  'responseAttributeSpec',
  FormFieldType.pushedSelectionList,
  false,
)


export const putResponseResponseAttributeSpecAssociationFormFieldOptions: {[key: string]: FormField} = {
  response: responseField,
  responseAttributeSpec: responseAttributeSpecField,
};