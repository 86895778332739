import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const relationshipField = new FormField(
  'relationship',
  'Relationship',
  'relationship',
  FormFieldType.pushedSelectionList,
  false,
)
const widgetField = new FormField(
  'widget',
  'Widget',
  'widgetRelationshipSpec',
  FormFieldType.pushedSelectionList,
  false,
)


export const putRelationshipWidgetRelationshipSpecAssociationFormFieldOptions: {[key: string]: FormField} = {
  relationship: relationshipField,
  widget: widgetField,
};