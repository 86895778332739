import {SOPutEntity} from '../../core/entity/so-put-entity';
import {EntityCore} from './entity.core';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putEntityFormFieldOptions} from './put-entity-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';

export class RAPutEntity extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putEntityFormFieldOptions);

  RequestClass = SOPutEntity

  constructor(
    public entityCore: EntityCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}