import { SOGetWidget } from '../widget/so-get-widget';
import { SOGetWidgetAttributeSpec } from '../widget-attribute-spec/so-get-widget-attribute-spec';
import { SOGetCommit } from '../commit/so-get-commit';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostWidgetWidgetAttributeSpecAssociation {

  constructor(
    public widget: SOGetWidget,
    public widgetAttributeSpec: SOGetWidgetAttributeSpec,
    public commit: SOGetCommit,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostWidgetWidgetAttributeSpecAssociation(
      'widget' in params ? params.widget : null,
      'widgetAttributeSpec' in params ? params.widgetAttributeSpec : null,
      'commit' in params ? params.commit : null,
      'organization' in params ? params.organization : null,
    );
  }
}
