import { SOGetWidgetWidgetRelationshipSpecAssociation } from '../widget-widget-relationship-spec-association/so-get-widget-widget-relationship-spec-association';
import { SOGetRelationshipWidgetRelationshipSpecAssociation } from '../relationship-widget-relationship-spec-association/so-get-relationship-widget-relationship-spec-association';


export class SOPutWidgetRelationshipSpec {

  constructor(
    public uid: string,
    public isRequired: boolean = null,
    public widget: SOGetWidgetWidgetRelationshipSpecAssociation[] = null,
    public relationship: SOGetRelationshipWidgetRelationshipSpecAssociation[] = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutWidgetRelationshipSpec(
      'uid' in params ? params.uid : null,
      'isRequired' in params ? params.isRequired : null,
      'widget' in params ? params.widget : null,
      'relationship' in params ? params.relationship : null,
    );
  }
}
