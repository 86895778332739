import {SOPutAttribute} from '../../core/attribute/so-put-attribute';
import {AttributeCore} from './attribute.core';
import {SOGetAttribute} from '../../core/attribute/so-get-attribute';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putAttributeFormFieldOptions} from './put-attribute-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';

export class RAPutAttribute extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putAttributeFormFieldOptions);

  RequestClass = SOPutAttribute

  constructor(
    public entityCore: AttributeCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}