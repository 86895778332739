import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostCodable } from './so-post-codable';
import { SOGetCodable } from './so-get-codable';
import { SOPutCodable } from './so-put-codable';
import { SODeleteCodable } from './so-delete-codable';
import {SORespCodable} from './so-resp-codable';
import {SOPluralCodable} from './so-plural-codable';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOCodableService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'codable',
      entityCore
    );
  }

  /** GET Codable by id. Will 404 if id not found */
  get(request: SOGetCodable): Observable<SOPluralCodable> {
    return this.httpClient.get<SOPluralCodable>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralCodable, resp) }),
      tap(_ => this.log(`fetched Codable`)),
      catchError(this.handleError<SOPluralCodable>(`get Codable`))
    );
  }

  /** POST: add a new Codable to the server */
  post(request: SOPostCodable): Observable<SORespCodable> {
    return this.httpClient.post<SORespCodable>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespCodable, resp) }),
      tap((response: SORespCodable) => this.log(`added Codable w/ id=${response.uid}`)),
      catchError(this.handleError<SORespCodable>('Codable post'))
    );
  }

  /** PUT: update Codable  */
  put(request: SOPutCodable): Observable<SORespCodable> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespCodable>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespCodable, resp) }),
      tap((response: SORespCodable) => this.log(`edited Codable w/ uid=${uid}`)),
      catchError(this.handleError<SORespCodable>('Codable put'))
    );
  }

  /** DELETE: delete the Codable from the server */
  delete(request: SODeleteCodable): Observable<SORespCodable> {
    return this.httpClient.delete<SORespCodable>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Codable uid=${request.uid}`)),
      catchError(this.handleError<SORespCodable>('delete Codable'))
    );
  }
}
