import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOEnumeratorService} from '../../core/enumerator/eo-enumerator';
import {EnumeratorListEntryFactory} from './enumerator-list-entry-factory';
import {EnumeratorDetailFactory} from './enumerator-detail-factory';
import {SOGetEnumerator} from '../../core/enumerator/so-get-enumerator';
import {RAPutEnumerator} from './ra-put-enumerator';
import {RAPostEnumerator} from './ra-post-enumerator';
import {RADeleteEnumerator} from './ra-delete-enumerator';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetApp} from '../../core/app/so-get-app'
import {SOGetOrganization} from '../../core/organization/so-get-organization'


export class EnumeratorCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'enumerator';
  title = 'Enumerator';
  listEntryFactory = new EnumeratorListEntryFactory();
  detailFactory = new EnumeratorDetailFactory();

  entityService: EOEnumeratorService;
  soGetClass = SOGetEnumerator;
  soGet: SOGetEnumerator;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOEnumeratorService
    );
    this.adapters = [
      new RAPostEnumerator(this),
      new RAPutEnumerator(this),
      new RADeleteEnumerator(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetEnumerator();
    this.soGet.app = new SOGetApp(
      this.activeUserService.getScope('app').uid
    );
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
