import { Type } from 'class-transformer';
import { SORespWidgetWidgetAttributeSpecAssociation } from '../widget-widget-attribute-spec-association/so-resp-widget-widget-attribute-spec-association';
import { SORespAttributeWidgetAttributeSpecAssociation } from '../attribute-widget-attribute-spec-association/so-resp-attribute-widget-attribute-spec-association';
import { SORespOrganization } from '../organization/so-resp-organization';
import { SORespApp } from '../app/so-resp-app';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespWidgetAttributeSpec implements ObjectiveProtocol {

  entityType: string = 'widgetAttributeSpec';
  uid: string;
  isRequired: boolean;
  defaultValue: string = null;
  @Type(() => SORespWidgetWidgetAttributeSpecAssociation)
  widget: SORespWidgetWidgetAttributeSpecAssociation[];
  @Type(() => SORespAttributeWidgetAttributeSpecAssociation)
  attribute: SORespAttributeWidgetAttributeSpecAssociation[];
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
  @Type(() => SORespApp)
  app: SORespApp;
}