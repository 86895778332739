import { SOGetRequest } from '../request/so-get-request';
import { SOGetRequestAttributeSpec } from '../request-attribute-spec/so-get-request-attribute-spec';


export class SOPutRequestRequestAttributeSpecAssociation {

  constructor(
    public uid: string,
    public request: SOGetRequest = null,
    public requestAttributeSpec: SOGetRequestAttributeSpec = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutRequestRequestAttributeSpecAssociation(
      'uid' in params ? params.uid : null,
      'request' in params ? params.request : null,
      'requestAttributeSpec' in params ? params.requestAttributeSpec : null,
    );
  }
}
