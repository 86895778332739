import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutToken } from './so-put-token';
import { SOPostToken } from './so-post-token';
import { SOGetToken } from './so-get-token';
import { SODeleteToken } from './so-delete-token';
import {SORespToken} from './so-resp-token';
import {SOPluralToken} from './so-plural-token';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOTokenService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'token',
      entityCore
    );
  }

  /** GET Token by id. Will 404 if id not found */
  get(request: SOGetToken): Observable<SOPluralToken> {
    return this.httpClient.get<SOPluralToken>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralToken, resp) }),
      tap(_ => this.log(`fetched Token`)),
      catchError(this.handleError<SOPluralToken>(`get Token`))
    );
  }

  /** POST: add a new Token to the server */
  post(request: SOPostToken): Observable<SORespToken> {
    return this.httpClient.post<SORespToken>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespToken, resp) }),
      tap((response: SORespToken) => this.log(`added Token w/ id=${response.uid}`)),
      catchError(this.handleError<SORespToken>('Token post'))
    );
  }

  /** PUT: update Token  */
  put(request: SOPutToken): Observable<SORespToken> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespToken>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespToken, resp) }),
      tap((response: SORespToken) => this.log(`edited Token w/ uid=${uid}`)),
      catchError(this.handleError<SORespToken>('Token put'))
    );
  }

  /** DELETE: delete the Token from the server */
  delete(request: SODeleteToken): Observable<SORespToken> {
    return this.httpClient.delete<SORespToken>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Token uid=${request.uid}`)),
      catchError(this.handleError<SORespToken>('delete Token'))
    );
  }
}
