import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostEntityWidgetAssociation } from './so-post-entity-widget-association';
import { SOGetEntityWidgetAssociation } from './so-get-entity-widget-association';
import { SOPutEntityWidgetAssociation } from './so-put-entity-widget-association';
import { SODeleteEntityWidgetAssociation } from './so-delete-entity-widget-association';
import {SORespEntityWidgetAssociation} from './so-resp-entity-widget-association';
import {SOPluralEntityWidgetAssociation} from './so-plural-entity-widget-association';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOEntityWidgetAssociationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'entity_widget_association',
      entityCore
    );
  }

  /** GET EntityWidgetAssociation by id. Will 404 if id not found */
  get(request: SOGetEntityWidgetAssociation): Observable<SOPluralEntityWidgetAssociation> {
    return this.httpClient.get<SOPluralEntityWidgetAssociation>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralEntityWidgetAssociation, resp) }),
      tap(_ => this.log(`fetched EntityWidgetAssociation`)),
      catchError(this.handleError<SOPluralEntityWidgetAssociation>(`get EntityWidgetAssociation`))
    );
  }

  /** POST: add a new EntityWidgetAssociation to the server */
  post(request: SOPostEntityWidgetAssociation): Observable<SORespEntityWidgetAssociation> {
    return this.httpClient.post<SORespEntityWidgetAssociation>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespEntityWidgetAssociation, resp) }),
      tap((response: SORespEntityWidgetAssociation) => this.log(`added EntityWidgetAssociation w/ id=${response.uid}`)),
      catchError(this.handleError<SORespEntityWidgetAssociation>('EntityWidgetAssociation post'))
    );
  }

  /** PUT: update EntityWidgetAssociation  */
  put(request: SOPutEntityWidgetAssociation): Observable<SORespEntityWidgetAssociation> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespEntityWidgetAssociation>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespEntityWidgetAssociation, resp) }),
      tap((response: SORespEntityWidgetAssociation) => this.log(`edited EntityWidgetAssociation w/ uid=${uid}`)),
      catchError(this.handleError<SORespEntityWidgetAssociation>('EntityWidgetAssociation put'))
    );
  }

  /** DELETE: delete the EntityWidgetAssociation from the server */
  delete(request: SODeleteEntityWidgetAssociation): Observable<SORespEntityWidgetAssociation> {
    return this.httpClient.delete<SORespEntityWidgetAssociation>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted EntityWidgetAssociation uid=${request.uid}`)),
      catchError(this.handleError<SORespEntityWidgetAssociation>('delete EntityWidgetAssociation'))
    );
  }
}
