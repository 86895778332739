import {SORespEntityChildrenAssociation} from '../../core/entity-children-association/so-resp-entity-children-association';
import {EntityChildrenAssociationCore} from './entity-children-association.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putEntityChildrenAssociationFormFieldOptions} from './put-entity-children-association-form-fields';
import {SOGetEntityChildrenAssociation} from '../../core/entity-children-association/so-get-entity-children-association';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {SOGetApp} from '../../core/app/so-get-app';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class EntityChildrenAssociationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespEntityChildrenAssociation = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []


    const parentCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'entity');
    parentCore.soGet = SOGetEntity.construct({
      app: new SOGetApp(activeUserService.getScope('app').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, parentCore)
    parentCore.title = heading ? heading : parentCore.title
    parentCore.listTitle = 'Parent'

    if (entity.parent) {
        displayStringFactory.reconfigure(entity.parent, entityViewSpec.entityCore.type)
    }
    parentCore.configureForDisplay(entityViewSpec)

    const parentDetail = new DetailEntry(
      heading ? heading : parentCore.listTitle,
      entity.parent ? displayStringFactory.primaryString : null,
      'parent',
      BaseDetailType.pushedDetail,
      parentCore,
      EditableType.always,
      putEntityChildrenAssociationFormFieldOptions.parent
    )
    if (entity.parent) {
      parentDetail.cachedListEntry = parentCore.makeListEntry(entityViewSpec, parentCore, entity.parent, 0)
    }
    parentDetail.singleFieldSelect = true
    attributes.push(parentDetail);

    const childCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'entity');
    childCore.soGet = SOGetEntity.construct({
      app: new SOGetApp(activeUserService.getScope('app').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, childCore)
    childCore.title = heading ? heading : childCore.title
    childCore.listTitle = 'Child'

    childCore.configureForDisplay(entityViewSpec)

    const childDetail = new DetailEntry(
      heading ? heading : childCore.listTitle,
      null,
      'child',
      BaseDetailType.embeddedList,
      childCore,
      EditableType.always,
      putEntityChildrenAssociationFormFieldOptions.child
    )
    attributes.push(childDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
