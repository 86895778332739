import {SOPostRelationship} from '../../core/relationship/so-post-relationship';
import {RelationshipCore} from './relationship.core';
import {SOGetRelationship} from '../../core/relationship/so-get-relationship';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postRelationshipFormFieldOptions} from './post-relationship-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {EntityRelationshipAssociationCore} from '../bv-entity-relationship-association/entity-relationship-association.core';
import {SOGetEntityRelationshipAssociation} from '../../core/entity-relationship-association/so-get-entity-relationship-association';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {AppCore} from '../bv-app/app.core';
import {SOGetApp} from '../../core/app/so-get-app';

export class RAPostRelationship extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postRelationshipFormFieldOptions);

  RequestClass = SOPostRelationship

  constructor(
    public entityCore: RelationshipCore
  ) {
    super();
    if (postRelationshipFormFieldOptions.entity) {
      postRelationshipFormFieldOptions.entity.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      [this.getFormValueFromIdentifier('entity', SOGetEntityRelationshipAssociation)],
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('multiplicity'),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier(
        'app',
        SOGetApp,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}