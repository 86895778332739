import {MMQueryOptions} from 'ngx-lionheart-material';


export class SODeleteRelationshipStoreRelationshipSpecAssociation {

  constructor(
    public uid: string,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
}
