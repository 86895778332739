import { SOGetCertificate } from '../certificate/so-get-certificate';
import { SOGetRoleGrant } from '../role-grant/so-get-role-grant';


export class SOPostToken {

  constructor(
    public certificate: SOGetCertificate = null,
    public roleGrant: SOGetRoleGrant = null,
    public identityToken: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostToken(
      'certificate' in params ? params.certificate : null,
      'roleGrant' in params ? params.roleGrant : null,
      'identityToken' in params ? params.identityToken : null,
    );
  }
}
