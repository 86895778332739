import {SOPostAttributeResponseAttributeSpecAssociation} from '../../core/attribute-response-attribute-spec-association/so-post-attribute-response-attribute-spec-association';
import {AttributeResponseAttributeSpecAssociationCore} from './attribute-response-attribute-spec-association.core';
import {SOGetAttributeResponseAttributeSpecAssociation} from '../../core/attribute-response-attribute-spec-association/so-get-attribute-response-attribute-spec-association';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postAttributeResponseAttributeSpecAssociationFormFieldOptions} from './post-attribute-response-attribute-spec-association-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {AttributeCore} from '../bv-attribute/attribute.core';
import {SOGetAttribute} from '../../core/attribute/so-get-attribute';
import {ResponseAttributeSpecCore} from '../bv-response-attribute-spec/response-attribute-spec.core';
import {SOGetResponseAttributeSpec} from '../../core/response-attribute-spec/so-get-response-attribute-spec';
import {CommitCore} from '../bv-commit/commit.core';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostAttributeResponseAttributeSpecAssociation extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postAttributeResponseAttributeSpecAssociationFormFieldOptions);

  RequestClass = SOPostAttributeResponseAttributeSpecAssociation

  constructor(
    public entityCore: AttributeResponseAttributeSpecAssociationCore
  ) {
    super();
    if (postAttributeResponseAttributeSpecAssociationFormFieldOptions.attribute) {
      postAttributeResponseAttributeSpecAssociationFormFieldOptions.attribute.makeCore = true;
    }
    if (postAttributeResponseAttributeSpecAssociationFormFieldOptions.response) {
      postAttributeResponseAttributeSpecAssociationFormFieldOptions.response.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'attribute',
        SOGetAttribute,
      ),
      this.getFormValueFromIdentifier(
        'response',
        SOGetResponseAttributeSpec,
      ),
      this.getFormValueFromIdentifier(
        'commit',
        SOGetCommit,
        true,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}