import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostStore } from './so-post-store';
import { SOGetStore } from './so-get-store';
import { SOPutStore } from './so-put-store';
import { SODeleteStore } from './so-delete-store';
import {SORespStore} from './so-resp-store';
import {SOPluralStore} from './so-plural-store';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOStoreService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'store',
      entityCore
    );
  }

  /** GET Store by id. Will 404 if id not found */
  get(request: SOGetStore): Observable<SOPluralStore> {
    return this.httpClient.get<SOPluralStore>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralStore, resp) }),
      tap(_ => this.log(`fetched Store`)),
      catchError(this.handleError<SOPluralStore>(`get Store`))
    );
  }

  /** POST: add a new Store to the server */
  post(request: SOPostStore): Observable<SORespStore> {
    return this.httpClient.post<SORespStore>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespStore, resp) }),
      tap((response: SORespStore) => this.log(`added Store w/ id=${response.uid}`)),
      catchError(this.handleError<SORespStore>('Store post'))
    );
  }

  /** PUT: update Store  */
  put(request: SOPutStore): Observable<SORespStore> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespStore>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespStore, resp) }),
      tap((response: SORespStore) => this.log(`edited Store w/ uid=${uid}`)),
      catchError(this.handleError<SORespStore>('Store put'))
    );
  }

  /** DELETE: delete the Store from the server */
  delete(request: SODeleteStore): Observable<SORespStore> {
    return this.httpClient.delete<SORespStore>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Store uid=${request.uid}`)),
      catchError(this.handleError<SORespStore>('delete Store'))
    );
  }
}
