import { SOGetRequest } from '../request/so-get-request';
import { SOGetRequestRelationshipSpec } from '../request-relationship-spec/so-get-request-relationship-spec';
import { SOGetCommit } from '../commit/so-get-commit';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostRequestRequestRelationshipSpecAssociation {

  constructor(
    public request: SOGetRequest,
    public requestRelationshipSpec: SOGetRequestRelationshipSpec,
    public commit: SOGetCommit,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostRequestRequestRelationshipSpecAssociation(
      'request' in params ? params.request : null,
      'requestRelationshipSpec' in params ? params.requestRelationshipSpec : null,
      'commit' in params ? params.commit : null,
      'organization' in params ? params.organization : null,
    );
  }
}
