import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const nameField = new FormField(
  'name',
  'Name',
  null,
  FormFieldType.text,
  false,
)
const enumeratorsField = new FormField(
  'enumerators',
  'Enumerators',
  'enumeratorEnumeralAssociation',
  FormFieldType.pushedSelectionList,
  false,
)


export const putEnumeralFormFieldOptions: {[key: string]: FormField} = {
  name: nameField,
  enumerators: enumeratorsField,
};