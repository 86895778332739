import { SOGetResponse } from '../response/so-get-response';
import { SOGetResponseRelationshipSpec } from '../response-relationship-spec/so-get-response-relationship-spec';


export class SOPutResponseResponseRelationshipSpecAssociation {

  constructor(
    public uid: string,
    public response: SOGetResponse = null,
    public responseRelationshipSpec: SOGetResponseRelationshipSpec = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutResponseResponseRelationshipSpecAssociation(
      'uid' in params ? params.uid : null,
      'response' in params ? params.response : null,
      'responseRelationshipSpec' in params ? params.responseRelationshipSpec : null,
    );
  }
}
