import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const requestField = new FormField(
  'request',
  'Request',
  'request',
  FormFieldType.pushedSelectionList,
  false,
)
const requestRelationshipSpecField = new FormField(
  'requestRelationshipSpec',
  'Request relationship spec',
  'requestRelationshipSpec',
  FormFieldType.pushedSelectionList,
  false,
)


export const putRequestRequestRelationshipSpecAssociationFormFieldOptions: {[key: string]: FormField} = {
  request: requestField,
  requestRelationshipSpec: requestRelationshipSpecField,
};