import { Type } from 'class-transformer';
import { SORespEnumerator } from '../enumerator/so-resp-enumerator';
import { SORespEnumeral } from '../enumeral/so-resp-enumeral';
import { SORespCommit } from '../commit/so-resp-commit';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespEnumeratorEnumeralAssociation implements ObjectiveProtocol {

  entityType: string = 'enumeratorEnumeralAssociation';
  uid: string;
  @Type(() => SORespEnumerator)
  enumerator: SORespEnumerator;
  @Type(() => SORespEnumeral)
  enumeral: SORespEnumeral;
  @Type(() => SORespCommit)
  commit: SORespCommit;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
}