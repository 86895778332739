import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EORelationshipStoreRelationshipSpecAssociationService} from '../../core/relationship-store-relationship-spec-association/eo-relationship-store-relationship-spec-association';
import {RelationshipStoreRelationshipSpecAssociationListEntryFactory} from './relationship-store-relationship-spec-association-list-entry-factory';
import {RelationshipStoreRelationshipSpecAssociationDetailFactory} from './relationship-store-relationship-spec-association-detail-factory';
import {SOGetRelationshipStoreRelationshipSpecAssociation} from '../../core/relationship-store-relationship-spec-association/so-get-relationship-store-relationship-spec-association';
import {RAPutRelationshipStoreRelationshipSpecAssociation} from './ra-put-relationship-store-relationship-spec-association';
import {RAPostRelationshipStoreRelationshipSpecAssociation} from './ra-post-relationship-store-relationship-spec-association';
import {RADeleteRelationshipStoreRelationshipSpecAssociation} from './ra-delete-relationship-store-relationship-spec-association';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class RelationshipStoreRelationshipSpecAssociationCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'relationshipStoreRelationshipSpecAssociation';
  title = 'Relationship store relationship spec association';
  listEntryFactory = new RelationshipStoreRelationshipSpecAssociationListEntryFactory();
  detailFactory = new RelationshipStoreRelationshipSpecAssociationDetailFactory();

  entityService: EORelationshipStoreRelationshipSpecAssociationService;
  soGetClass = SOGetRelationshipStoreRelationshipSpecAssociation;
  soGet: SOGetRelationshipStoreRelationshipSpecAssociation;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EORelationshipStoreRelationshipSpecAssociationService
    );
    this.adapters = [
      new RAPostRelationshipStoreRelationshipSpecAssociation(this),
      new RAPutRelationshipStoreRelationshipSpecAssociation(this),
      new RADeleteRelationshipStoreRelationshipSpecAssociation(this),
    ];
    this.resetSoGet();
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetRelationshipStoreRelationshipSpecAssociation();
    this.soGet.commit = new SOGetCommit(
      this.activeUserService.getScope('commit').uid
    );
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
