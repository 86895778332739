import { SOGetEntity } from '../entity/so-get-entity';
import { SOGetResponse } from '../response/so-get-response';
import { SOGetCommit } from '../commit/so-get-commit';


export class SOPutEntityResponseAssociation {

  constructor(
    public uid: string,
    public entity: SOGetEntity = null,
    public response: SOGetResponse = null,
    public commit: SOGetCommit = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutEntityResponseAssociation(
      'uid' in params ? params.uid : null,
      'entity' in params ? params.entity : null,
      'response' in params ? params.response : null,
      'commit' in params ? params.commit : null,
    );
  }
}
