import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespUser implements ObjectiveProtocol {

  entityType: string = 'user';
  uid: string;
  username: string;
  givenName: string;
  surname: string;
  email: string = null;
  emailPending: string = null;
  emailVerified: boolean = false;
  @Type(() => Date)
  dateCreated: Date;
}