import {SORespResponseResponseRelationshipSpecAssociation} from '../../core/response-response-relationship-spec-association/so-resp-response-response-relationship-spec-association';
import {ResponseResponseRelationshipSpecAssociationCore} from './response-response-relationship-spec-association.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putResponseResponseRelationshipSpecAssociationFormFieldOptions} from './put-response-response-relationship-spec-association-form-fields';
import {SOGetResponseResponseRelationshipSpecAssociation} from '../../core/response-response-relationship-spec-association/so-get-response-response-relationship-spec-association';
import {SOGetResponse} from '../../core/response/so-get-response';
import {SOGetResponseRelationshipSpec} from '../../core/response-relationship-spec/so-get-response-relationship-spec';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetApp} from '../../core/app/so-get-app';


export class ResponseResponseRelationshipSpecAssociationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespResponseResponseRelationshipSpecAssociation = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = [];


    const responseCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'response');
    responseCore.soGet = SOGetResponse.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, responseCore);
    responseCore.title = heading ? heading : responseCore.title;
    responseCore.listTitle = 'Response';

    if (entity.response) {
        displayStringFactory.reconfigure(entity.response, entityViewSpec.entityCore.type);
    }
    responseCore.configureForDisplay(entityViewSpec);

    const responseDetail = new DetailEntry(
      heading ? heading : responseCore.listTitle,
      entity.response ? displayStringFactory.primaryString : null,
      'response',
      BaseDetailType.pushedDetail,
      responseCore,
      EditableType.always,
      putResponseResponseRelationshipSpecAssociationFormFieldOptions.response
    );
    if (entity.response) {
      responseDetail.cachedListEntry = responseCore.makeListEntry(entityViewSpec, responseCore, entity.response, 0);
    }
    responseDetail.singleFieldSelect = true;
    attributes.push(responseDetail);

    const responseRelationshipSpecCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'responseRelationshipSpec');
    responseRelationshipSpecCore.soGet = SOGetResponseRelationshipSpec.construct({
      response: new SOGetResponseResponseRelationshipSpecAssociation(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, responseRelationshipSpecCore);
    responseRelationshipSpecCore.title = heading ? heading : responseRelationshipSpecCore.title;
    responseRelationshipSpecCore.listTitle = 'Response relationship spec';

    if (entity.responseRelationshipSpec) {
        displayStringFactory.reconfigure(entity.responseRelationshipSpec, entityViewSpec.entityCore.type);
    }
    responseRelationshipSpecCore.configureForDisplay(entityViewSpec);

    const responseRelationshipSpecDetail = new DetailEntry(
      heading ? heading : responseRelationshipSpecCore.listTitle,
      entity.responseRelationshipSpec ? displayStringFactory.primaryString : null,
      'responseRelationshipSpec',
      BaseDetailType.pushedDetail,
      responseRelationshipSpecCore,
      EditableType.always,
      putResponseResponseRelationshipSpecAssociationFormFieldOptions.responseRelationshipSpec
    );
    if (entity.responseRelationshipSpec) {
      responseRelationshipSpecDetail.cachedListEntry = responseRelationshipSpecCore.makeListEntry(entityViewSpec, responseRelationshipSpecCore, entity.responseRelationshipSpec, 0);
    }
    responseRelationshipSpecDetail.singleFieldSelect = true;
    attributes.push(responseRelationshipSpecDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
