import { SOGetResponseResponseAttributeSpecAssociation } from '../response-response-attribute-spec-association/so-get-response-response-attribute-spec-association';
import { SOGetAttributeResponseAttributeSpecAssociation } from '../attribute-response-attribute-spec-association/so-get-attribute-response-attribute-spec-association';


export class SOPutResponseAttributeSpec {

  constructor(
    public uid: string,
    public isRequired: boolean = null,
    public defaultValue: string = null,
    public response: SOGetResponseResponseAttributeSpecAssociation[] = null,
    public attribute: SOGetAttributeResponseAttributeSpecAssociation[] = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutResponseAttributeSpec(
      'uid' in params ? params.uid : null,
      'isRequired' in params ? params.isRequired : null,
      'defaultValue' in params ? params.defaultValue : null,
      'response' in params ? params.response : null,
      'attribute' in params ? params.attribute : null,
    );
  }
}
