import {SORespCommitRelationshipLinkAssociation} from '../../core/commit-relationship-link-association/so-resp-commit-relationship-link-association';
import {CommitRelationshipLinkAssociationCore} from './commit-relationship-link-association.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putCommitRelationshipLinkAssociationFormFieldOptions} from './put-commit-relationship-link-association-form-fields';
import {SOGetCommitRelationshipLinkAssociation} from '../../core/commit-relationship-link-association/so-get-commit-relationship-link-association';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {SOGetRelationshipLink} from '../../core/relationship-link/so-get-relationship-link';
import {SOGetApp} from '../../core/app/so-get-app';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CommitRelationshipLinkAssociationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespCommitRelationshipLinkAssociation = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []


    const commitCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'commit');
    commitCore.soGet = SOGetCommit.construct({
      app: new SOGetApp(activeUserService.getScope('app').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, commitCore)
    commitCore.title = heading ? heading : commitCore.title
    commitCore.listTitle = 'Commit'

    if (entity.commit) {
        displayStringFactory.reconfigure(entity.commit, entityViewSpec.entityCore.type)
    }
    commitCore.configureForDisplay(entityViewSpec)

    const commitDetail = new DetailEntry(
      heading ? heading : commitCore.listTitle,
      entity.commit ? displayStringFactory.primaryString : null,
      'commit',
      BaseDetailType.pushedDetail,
      commitCore,
      EditableType.always,
      putCommitRelationshipLinkAssociationFormFieldOptions.commit
    )
    if (entity.commit) {
      commitDetail.cachedListEntry = commitCore.makeListEntry(entityViewSpec, commitCore, entity.commit, 0)
    }
    commitDetail.singleFieldSelect = true
    attributes.push(commitDetail);

    const entityCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'relationshipLink');
    entityCore.soGet = SOGetRelationshipLink.construct({
      app: new SOGetApp(activeUserService.getScope('app').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, entityCore)
    entityCore.title = heading ? heading : entityCore.title
    entityCore.listTitle = 'Entity'

    if (entity.entity) {
        displayStringFactory.reconfigure(entity.entity, entityViewSpec.entityCore.type)
    }
    entityCore.configureForDisplay(entityViewSpec)

    const entityDetail = new DetailEntry(
      heading ? heading : entityCore.listTitle,
      entity.entity ? displayStringFactory.primaryString : null,
      'entity',
      BaseDetailType.pushedDetail,
      entityCore,
      EditableType.always,
      putCommitRelationshipLinkAssociationFormFieldOptions.entity
    )
    if (entity.entity) {
      entityDetail.cachedListEntry = entityCore.makeListEntry(entityViewSpec, entityCore, entity.entity, 0)
    }
    entityDetail.singleFieldSelect = true
    attributes.push(entityDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
