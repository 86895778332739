import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EORelationshipService} from '../../core/relationship/eo-relationship';
import {RelationshipListEntryFactory} from './relationship-list-entry-factory';
import {RelationshipDetailFactory} from './relationship-detail-factory';
import {SOGetRelationship} from '../../core/relationship/so-get-relationship';
import {RAPutRelationship} from './ra-put-relationship';
import {RAPostRelationship} from './ra-post-relationship';
import {RADeleteRelationship} from './ra-delete-relationship';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import {SOGetApp} from '../../core/app/so-get-app'


export class RelationshipCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'relationship';
  title = 'Relationship';
  listEntryFactory = new RelationshipListEntryFactory();
  detailFactory = new RelationshipDetailFactory();

  entityService: EORelationshipService;
  soGetClass = SOGetRelationship;
  soGet: SOGetRelationship;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EORelationshipService
    );
    this.adapters = [
      new RAPostRelationship(this),
      new RAPutRelationship(this),
      new RADeleteRelationship(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetRelationship();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.app = new SOGetApp(
      this.activeUserService.getScope('app').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
