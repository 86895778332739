import {SOPostRelationshipResponseRelationshipSpecAssociation} from '../../core/relationship-response-relationship-spec-association/so-post-relationship-response-relationship-spec-association';
import {RelationshipResponseRelationshipSpecAssociationCore} from './relationship-response-relationship-spec-association.core';
import {SOGetRelationshipResponseRelationshipSpecAssociation} from '../../core/relationship-response-relationship-spec-association/so-get-relationship-response-relationship-spec-association';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postRelationshipResponseRelationshipSpecAssociationFormFieldOptions} from './post-relationship-response-relationship-spec-association-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {RelationshipCore} from '../bv-relationship/relationship.core';
import {SOGetRelationship} from '../../core/relationship/so-get-relationship';
import {ResponseRelationshipSpecCore} from '../bv-response-relationship-spec/response-relationship-spec.core';
import {SOGetResponseRelationshipSpec} from '../../core/response-relationship-spec/so-get-response-relationship-spec';
import {CommitCore} from '../bv-commit/commit.core';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostRelationshipResponseRelationshipSpecAssociation extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postRelationshipResponseRelationshipSpecAssociationFormFieldOptions);

  RequestClass = SOPostRelationshipResponseRelationshipSpecAssociation

  constructor(
    public entityCore: RelationshipResponseRelationshipSpecAssociationCore
  ) {
    super();
    if (postRelationshipResponseRelationshipSpecAssociationFormFieldOptions.relationship) {
      postRelationshipResponseRelationshipSpecAssociationFormFieldOptions.relationship.makeCore = true;
    }
    if (postRelationshipResponseRelationshipSpecAssociationFormFieldOptions.response) {
      postRelationshipResponseRelationshipSpecAssociationFormFieldOptions.response.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'relationship',
        SOGetRelationship,
      ),
      this.getFormValueFromIdentifier(
        'response',
        SOGetResponseRelationshipSpec,
      ),
      this.getFormValueFromIdentifier(
        'commit',
        SOGetCommit,
        true,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}