import {SOPutBranchCommitAssociation} from '../../core/branch-commit-association/so-put-branch-commit-association';
import {BranchCommitAssociationCore} from './branch-commit-association.core';
import {SOGetBranchCommitAssociation} from '../../core/branch-commit-association/so-get-branch-commit-association';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putBranchCommitAssociationFormFieldOptions} from './put-branch-commit-association-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {BranchCore} from '../bv-branch/branch.core';
import {SOGetBranch} from '../../core/branch/so-get-branch';
import {CommitCore} from '../bv-commit/commit.core';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPutBranchCommitAssociation extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putBranchCommitAssociationFormFieldOptions);

  RequestClass = SOPutBranchCommitAssociation

  constructor(
    public entityCore: BranchCommitAssociationCore
  ) {
    super();
    if (putBranchCommitAssociationFormFieldOptions.branch) {
      putBranchCommitAssociationFormFieldOptions.branch.makeCore = true;
    }
    if (putBranchCommitAssociationFormFieldOptions.commit) {
      putBranchCommitAssociationFormFieldOptions.commit.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier(
        'branch',
        SOGetBranch,
      ),
      this.getFormValueFromIdentifier(
        'commit',
        SOGetCommit,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}