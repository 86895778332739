import { Type } from 'class-transformer';
import { SORespStoreStoreRelationshipSpecAssociation } from '../store-store-relationship-spec-association/so-resp-store-store-relationship-spec-association';
import { SORespRelationshipStoreRelationshipSpecAssociation } from '../relationship-store-relationship-spec-association/so-resp-relationship-store-relationship-spec-association';
import { SORespOrganization } from '../organization/so-resp-organization';
import { SORespApp } from '../app/so-resp-app';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespStoreRelationshipSpec implements ObjectiveProtocol {

  entityType: string = 'storeRelationshipSpec';
  uid: string;
  isRequired: boolean;
  @Type(() => SORespStoreStoreRelationshipSpecAssociation)
  store: SORespStoreStoreRelationshipSpecAssociation[];
  @Type(() => SORespRelationshipStoreRelationshipSpecAssociation)
  relationship: SORespRelationshipStoreRelationshipSpecAssociation[];
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
  @Type(() => SORespApp)
  app: SORespApp;
}