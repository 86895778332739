import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOOrganizationService} from '../../core/organization/eo-organization';
import {OrganizationListEntryFactory} from './organization-list-entry-factory';
import {OrganizationDetailFactory} from './organization-detail-factory';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {RAPutOrganization} from './ra-put-organization';
import {RAPostOrganization} from './ra-post-organization';
import {RADeleteOrganization} from './ra-delete-organization';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';


export class OrganizationCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'organization';
  title = 'Organization';
  listEntryFactory = new OrganizationListEntryFactory();
  detailFactory = new OrganizationDetailFactory();

  entityService: EOOrganizationService;
  soGetClass = SOGetOrganization;
  soGet: SOGetOrganization;

  isScope = true;

  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOOrganizationService
    );
    this.adapters = [
      new RAPostOrganization(this),
      new RAPutOrganization(this),
      new RADeleteOrganization(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetOrganization();
    this.soGet.queryOptions.limit = this.limit;
  }


}
