import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOCodableService} from '../../core/codable/eo-codable';
import {CodableListEntryFactory} from './codable-list-entry-factory';
import {CodableDetailFactory} from './codable-detail-factory';
import {SOGetCodable} from '../../core/codable/so-get-codable';
import {RAPutCodable} from './ra-put-codable';
import {RAPostCodable} from './ra-post-codable';
import {RADeleteCodable} from './ra-delete-codable';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetApp} from '../../core/app/so-get-app'
import {SOGetOrganization} from '../../core/organization/so-get-organization'


export class CodableCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'codable';
  title = 'Codable';
  listEntryFactory = new CodableListEntryFactory();
  detailFactory = new CodableDetailFactory();

  entityService: EOCodableService;
  soGetClass = SOGetCodable;
  soGet: SOGetCodable;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOCodableService
    );
    this.adapters = [
      new RAPostCodable(this),
      new RAPutCodable(this),
      new RADeleteCodable(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetCodable();
    this.soGet.app = new SOGetApp(
      this.activeUserService.getScope('app').uid
    );
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
