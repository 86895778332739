

export class SOPutOrganization {

  constructor(
    public uid: string,
    public name: string = null,
    public identifier: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutOrganization(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'identifier' in params ? params.identifier : null,
    );
  }
}
