import { SOGetRelationship } from '../relationship/so-get-relationship';
import { SOGetResponseRelationshipSpec } from '../response-relationship-spec/so-get-response-relationship-spec';
import { SOGetCommit } from '../commit/so-get-commit';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostRelationshipResponseRelationshipSpecAssociation {

  constructor(
    public relationship: SOGetRelationship,
    public response: SOGetResponseRelationshipSpec,
    public commit: SOGetCommit,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostRelationshipResponseRelationshipSpecAssociation(
      'relationship' in params ? params.relationship : null,
      'response' in params ? params.response : null,
      'commit' in params ? params.commit : null,
      'organization' in params ? params.organization : null,
    );
  }
}
