import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOBranchService} from '../../core/branch/eo-branch';
import {BranchListEntryFactory} from './branch-list-entry-factory';
import {BranchDetailFactory} from './branch-detail-factory';
import {SOGetBranch} from '../../core/branch/so-get-branch';
import {RAPutBranch} from './ra-put-branch';
import {RAPostBranch} from './ra-post-branch';
import {RADeleteBranch} from './ra-delete-branch';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetApp} from '../../core/app/so-get-app'
import {SOGetOrganization} from '../../core/organization/so-get-organization'


export class BranchCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'branch';
  title = 'Branch';
  listEntryFactory = new BranchListEntryFactory();
  detailFactory = new BranchDetailFactory();

  entityService: EOBranchService;
  soGetClass = SOGetBranch;
  soGet: SOGetBranch;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOBranchService
    );
    this.adapters = [
      new RAPostBranch(this),
      new RAPutBranch(this),
      new RADeleteBranch(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetBranch();
    this.soGet.app = new SOGetApp(
      this.activeUserService.getScope('app').uid
    );
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
