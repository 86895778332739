import { SOGetAttribute } from '../attribute/so-get-attribute';
import { SOGetWidgetAttributeSpec } from '../widget-attribute-spec/so-get-widget-attribute-spec';
import { SOGetCommit } from '../commit/so-get-commit';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostAttributeWidgetAttributeSpecAssociation {

  constructor(
    public attribute: SOGetAttribute,
    public widget: SOGetWidgetAttributeSpec,
    public commit: SOGetCommit,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostAttributeWidgetAttributeSpecAssociation(
      'attribute' in params ? params.attribute : null,
      'widget' in params ? params.widget : null,
      'commit' in params ? params.commit : null,
      'organization' in params ? params.organization : null,
    );
  }
}
