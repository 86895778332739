import {SOPostEntityChildrenAssociation} from '../../core/entity-children-association/so-post-entity-children-association';
import {EntityChildrenAssociationCore} from './entity-children-association.core';
import {SOGetEntityChildrenAssociation} from '../../core/entity-children-association/so-get-entity-children-association';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postEntityChildrenAssociationFormFieldOptions} from './post-entity-children-association-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {EntityCore} from '../bv-entity/entity.core';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {CommitCore} from '../bv-commit/commit.core';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostEntityChildrenAssociation extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postEntityChildrenAssociationFormFieldOptions);

  RequestClass = SOPostEntityChildrenAssociation

  constructor(
    public entityCore: EntityChildrenAssociationCore
  ) {
    super();
    if (postEntityChildrenAssociationFormFieldOptions.parent) {
      postEntityChildrenAssociationFormFieldOptions.parent.makeCore = true;
    }
    if (postEntityChildrenAssociationFormFieldOptions.child) {
      postEntityChildrenAssociationFormFieldOptions.child.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'parent',
        SOGetEntity,
      ),
      [this.getFormValueFromIdentifier('child', SOGetEntity)],
      this.getFormValueFromIdentifier(
        'commit',
        SOGetCommit,
        true,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}