import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostEntityStoreAssociation } from './so-post-entity-store-association';
import { SOGetEntityStoreAssociation } from './so-get-entity-store-association';
import { SOPutEntityStoreAssociation } from './so-put-entity-store-association';
import { SODeleteEntityStoreAssociation } from './so-delete-entity-store-association';
import {SORespEntityStoreAssociation} from './so-resp-entity-store-association';
import {SOPluralEntityStoreAssociation} from './so-plural-entity-store-association';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOEntityStoreAssociationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'entity_store_association',
      entityCore
    );
  }

  /** GET EntityStoreAssociation by id. Will 404 if id not found */
  get(request: SOGetEntityStoreAssociation): Observable<SOPluralEntityStoreAssociation> {
    return this.httpClient.get<SOPluralEntityStoreAssociation>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralEntityStoreAssociation, resp) }),
      tap(_ => this.log(`fetched EntityStoreAssociation`)),
      catchError(this.handleError<SOPluralEntityStoreAssociation>(`get EntityStoreAssociation`))
    );
  }

  /** POST: add a new EntityStoreAssociation to the server */
  post(request: SOPostEntityStoreAssociation): Observable<SORespEntityStoreAssociation> {
    return this.httpClient.post<SORespEntityStoreAssociation>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespEntityStoreAssociation, resp) }),
      tap((response: SORespEntityStoreAssociation) => this.log(`added EntityStoreAssociation w/ id=${response.uid}`)),
      catchError(this.handleError<SORespEntityStoreAssociation>('EntityStoreAssociation post'))
    );
  }

  /** PUT: update EntityStoreAssociation  */
  put(request: SOPutEntityStoreAssociation): Observable<SORespEntityStoreAssociation> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespEntityStoreAssociation>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespEntityStoreAssociation, resp) }),
      tap((response: SORespEntityStoreAssociation) => this.log(`edited EntityStoreAssociation w/ uid=${uid}`)),
      catchError(this.handleError<SORespEntityStoreAssociation>('EntityStoreAssociation put'))
    );
  }

  /** DELETE: delete the EntityStoreAssociation from the server */
  delete(request: SODeleteEntityStoreAssociation): Observable<SORespEntityStoreAssociation> {
    return this.httpClient.delete<SORespEntityStoreAssociation>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted EntityStoreAssociation uid=${request.uid}`)),
      catchError(this.handleError<SORespEntityStoreAssociation>('delete EntityStoreAssociation'))
    );
  }
}
