import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const isRequiredField = new FormField(
  'isRequired',
  'Is required',
  null,
  FormFieldType.boolean,
  false,
)
const defaultValueField = new FormField(
  'defaultValue',
  'Default value',
  null,
  FormFieldType.text,
  false,
)
const widgetField = new FormField(
  'widget',
  'Widget',
  'widgetWidgetAttributeSpecAssociation',
  FormFieldType.pushedSelectionList,
  true,
)
const attributeField = new FormField(
  'attribute',
  'Attribute',
  'attributeWidgetAttributeSpecAssociation',
  FormFieldType.pushedSelectionList,
  true,
)


export const postWidgetAttributeSpecFormFieldOptions: {[key: string]: FormField} = {
  isRequired: isRequiredField,
  defaultValue: defaultValueField,
  widget: widgetField,
  attribute: attributeField,
};