import { SOGetEntityWidgetAssociation } from '../entity-widget-association/so-get-entity-widget-association';
import {ENWidgetType} from '../../enums';


export class SOPutWidget {

  constructor(
    public uid: string,
    public name: string = null,
    public widgetType: ENWidgetType = null,
    public isDefault: boolean = null,
    public entity: SOGetEntityWidgetAssociation[] = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutWidget(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'widgetType' in params ? params.widgetType : null,
      'isDefault' in params ? params.isDefault : null,
      'entity' in params ? params.entity : null,
    );
  }
}
