import {SORespRequestRequestAttributeSpecAssociation} from '../../core/request-request-attribute-spec-association/so-resp-request-request-attribute-spec-association';
import {RequestRequestAttributeSpecAssociationCore} from './request-request-attribute-spec-association.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putRequestRequestAttributeSpecAssociationFormFieldOptions} from './put-request-request-attribute-spec-association-form-fields';
import {SOGetRequestRequestAttributeSpecAssociation} from '../../core/request-request-attribute-spec-association/so-get-request-request-attribute-spec-association';
import {SOGetRequest} from '../../core/request/so-get-request';
import {SOGetRequestAttributeSpec} from '../../core/request-attribute-spec/so-get-request-attribute-spec';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetApp} from '../../core/app/so-get-app';


export class RequestRequestAttributeSpecAssociationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespRequestRequestAttributeSpecAssociation = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []


    const requestCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'request');
    requestCore.soGet = SOGetRequest.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, requestCore)
    requestCore.title = heading ? heading : requestCore.title
    requestCore.listTitle = 'Request'

    if (entity.request) {
        displayStringFactory.reconfigure(entity.request, entityViewSpec.entityCore.type)
    }
    requestCore.configureForDisplay(entityViewSpec)

    const requestDetail = new DetailEntry(
      heading ? heading : requestCore.listTitle,
      entity.request ? displayStringFactory.primaryString : null,
      'request',
      BaseDetailType.pushedDetail,
      requestCore,
      EditableType.always,
      putRequestRequestAttributeSpecAssociationFormFieldOptions.request
    )
    if (entity.request) {
      requestDetail.cachedListEntry = requestCore.makeListEntry(entityViewSpec, requestCore, entity.request, 0)
    }
    requestDetail.singleFieldSelect = true
    attributes.push(requestDetail);

    const requestAttributeSpecCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'requestAttributeSpec');
    requestAttributeSpecCore.soGet = SOGetRequestAttributeSpec.construct({
      request: new SOGetRequestRequestAttributeSpecAssociation(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, requestAttributeSpecCore)
    requestAttributeSpecCore.title = heading ? heading : requestAttributeSpecCore.title
    requestAttributeSpecCore.listTitle = 'Request attribute spec'

    if (entity.requestAttributeSpec) {
        displayStringFactory.reconfigure(entity.requestAttributeSpec, entityViewSpec.entityCore.type)
    }
    requestAttributeSpecCore.configureForDisplay(entityViewSpec)

    const requestAttributeSpecDetail = new DetailEntry(
      heading ? heading : requestAttributeSpecCore.listTitle,
      entity.requestAttributeSpec ? displayStringFactory.primaryString : null,
      'requestAttributeSpec',
      BaseDetailType.pushedDetail,
      requestAttributeSpecCore,
      EditableType.always,
      putRequestRequestAttributeSpecAssociationFormFieldOptions.requestAttributeSpec
    )
    if (entity.requestAttributeSpec) {
      requestAttributeSpecDetail.cachedListEntry = requestAttributeSpecCore.makeListEntry(entityViewSpec, requestAttributeSpecCore, entity.requestAttributeSpec, 0)
    }
    requestAttributeSpecDetail.singleFieldSelect = true
    attributes.push(requestAttributeSpecDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
