import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const isRequiredField = new FormField(
  'isRequired',
  'Is required',
  null,
  FormFieldType.boolean,
  false,
)
const widgetField = new FormField(
  'widget',
  'Widget',
  'widgetWidgetRelationshipSpecAssociation',
  FormFieldType.pushedSelectionList,
  false,
)
const relationshipField = new FormField(
  'relationship',
  'Relationship',
  'relationshipWidgetRelationshipSpecAssociation',
  FormFieldType.pushedSelectionList,
  false,
)


export const putWidgetRelationshipSpecFormFieldOptions: {[key: string]: FormField} = {
  isRequired: isRequiredField,
  widget: widgetField,
  relationship: relationshipField,
};