import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOResponseRelationshipSpecService} from '../../core/response-relationship-spec/eo-response-relationship-spec';
import {ResponseRelationshipSpecListEntryFactory} from './response-relationship-spec-list-entry-factory';
import {ResponseRelationshipSpecDetailFactory} from './response-relationship-spec-detail-factory';
import {SOGetResponseRelationshipSpec} from '../../core/response-relationship-spec/so-get-response-relationship-spec';
import {RAPutResponseRelationshipSpec} from './ra-put-response-relationship-spec';
import {RAPostResponseRelationshipSpec} from './ra-post-response-relationship-spec';
import {RADeleteResponseRelationshipSpec} from './ra-delete-response-relationship-spec';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import {SOGetApp} from '../../core/app/so-get-app'


export class ResponseRelationshipSpecCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'responseRelationshipSpec';
  title = 'Response relationship spec';
  listEntryFactory = new ResponseRelationshipSpecListEntryFactory();
  detailFactory = new ResponseRelationshipSpecDetailFactory();

  entityService: EOResponseRelationshipSpecService;
  soGetClass = SOGetResponseRelationshipSpec;
  soGet: SOGetResponseRelationshipSpec;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOResponseRelationshipSpecService
    );
    this.adapters = [
      new RAPostResponseRelationshipSpec(this),
      new RAPutResponseRelationshipSpec(this),
      new RADeleteResponseRelationshipSpec(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetResponseRelationshipSpec();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.app = new SOGetApp(
      this.activeUserService.getScope('app').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
