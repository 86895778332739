import {SOPutEntityAttributeAssociation} from '../../core/entity-attribute-association/so-put-entity-attribute-association';
import {EntityAttributeAssociationCore} from './entity-attribute-association.core';
import {SOGetEntityAttributeAssociation} from '../../core/entity-attribute-association/so-get-entity-attribute-association';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putEntityAttributeAssociationFormFieldOptions} from './put-entity-attribute-association-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {EntityCore} from '../bv-entity/entity.core';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {AttributeCore} from '../bv-attribute/attribute.core';
import {SOGetAttribute} from '../../core/attribute/so-get-attribute';
import {CommitCore} from '../bv-commit/commit.core';
import {SOGetCommit} from '../../core/commit/so-get-commit';

export class RAPutEntityAttributeAssociation extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putEntityAttributeAssociationFormFieldOptions);

  RequestClass = SOPutEntityAttributeAssociation

  constructor(
    public entityCore: EntityAttributeAssociationCore
  ) {
    super();
    if (putEntityAttributeAssociationFormFieldOptions.entity) {
      putEntityAttributeAssociationFormFieldOptions.entity.makeCore = true;
    }
    if (putEntityAttributeAssociationFormFieldOptions.attribute) {
      putEntityAttributeAssociationFormFieldOptions.attribute.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier(
        'entity',
        SOGetEntity,
      ),
      this.getFormValueFromIdentifier(
        'attribute',
        SOGetAttribute,
      ),
      this.getFormValueFromIdentifier(
        'commit',
        SOGetCommit,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}