import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostEntityChildrenAssociation } from './so-post-entity-children-association';
import { SOGetEntityChildrenAssociation } from './so-get-entity-children-association';
import { SOPutEntityChildrenAssociation } from './so-put-entity-children-association';
import { SODeleteEntityChildrenAssociation } from './so-delete-entity-children-association';
import {SORespEntityChildrenAssociation} from './so-resp-entity-children-association';
import {SOPluralEntityChildrenAssociation} from './so-plural-entity-children-association';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOEntityChildrenAssociationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'entity_children_association',
      entityCore
    );
  }

  /** GET EntityChildrenAssociation by id. Will 404 if id not found */
  get(request: SOGetEntityChildrenAssociation): Observable<SOPluralEntityChildrenAssociation> {
    return this.httpClient.get<SOPluralEntityChildrenAssociation>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralEntityChildrenAssociation, resp) }),
      tap(_ => this.log(`fetched EntityChildrenAssociation`)),
      catchError(this.handleError<SOPluralEntityChildrenAssociation>(`get EntityChildrenAssociation`))
    );
  }

  /** POST: add a new EntityChildrenAssociation to the server */
  post(request: SOPostEntityChildrenAssociation): Observable<SORespEntityChildrenAssociation> {
    return this.httpClient.post<SORespEntityChildrenAssociation>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespEntityChildrenAssociation, resp) }),
      tap((response: SORespEntityChildrenAssociation) => this.log(`added EntityChildrenAssociation w/ id=${response.uid}`)),
      catchError(this.handleError<SORespEntityChildrenAssociation>('EntityChildrenAssociation post'))
    );
  }

  /** PUT: update EntityChildrenAssociation  */
  put(request: SOPutEntityChildrenAssociation): Observable<SORespEntityChildrenAssociation> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespEntityChildrenAssociation>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespEntityChildrenAssociation, resp) }),
      tap((response: SORespEntityChildrenAssociation) => this.log(`edited EntityChildrenAssociation w/ uid=${uid}`)),
      catchError(this.handleError<SORespEntityChildrenAssociation>('EntityChildrenAssociation put'))
    );
  }

  /** DELETE: delete the EntityChildrenAssociation from the server */
  delete(request: SODeleteEntityChildrenAssociation): Observable<SORespEntityChildrenAssociation> {
    return this.httpClient.delete<SORespEntityChildrenAssociation>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted EntityChildrenAssociation uid=${request.uid}`)),
      catchError(this.handleError<SORespEntityChildrenAssociation>('delete EntityChildrenAssociation'))
    );
  }
}
