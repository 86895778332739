import { SOGetStore } from '../store/so-get-store';
import { SOGetStoreAttributeSpec } from '../store-attribute-spec/so-get-store-attribute-spec';


export class SOPutStoreStoreAttributeSpecAssociation {

  constructor(
    public uid: string,
    public store: SOGetStore = null,
    public storeAttributeSpec: SOGetStoreAttributeSpec = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutStoreStoreAttributeSpecAssociation(
      'uid' in params ? params.uid : null,
      'store' in params ? params.store : null,
      'storeAttributeSpec' in params ? params.storeAttributeSpec : null,
    );
  }
}
