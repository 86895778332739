import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const enumeratorField = new FormField(
  'enumerator',
  'Enumerator',
  'enumerator',
  FormFieldType.pushedSelectionList,
  true,
)
const enumeralField = new FormField(
  'enumeral',
  'Enumeral',
  'enumeral',
  FormFieldType.pushedSelectionList,
  true,
)


export const postEnumeratorEnumeralAssociationFormFieldOptions: {[key: string]: FormField} = {
  enumerator: enumeratorField,
  enumeral: enumeralField,
};