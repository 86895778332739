import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const isDefaultField = new FormField(
  'isDefault',
  'Is default',
  null,
  FormFieldType.boolean,
  true,
  true,
  false
)
const userField = new FormField(
  'user',
  'User',
  'user',
  FormFieldType.pushedSelectionList,
  true,
)
const roleField = new FormField(
  'role',
  'Role',
  'role',
  FormFieldType.pushedSelectionList,
  true,
)
const organizationField = new FormField(
  'organization',
  'Organization',
  'organization',
  FormFieldType.pushedSelectionList,
  false,
)


export const postRoleGrantFormFieldOptions: {[key: string]: FormField} = {
  isDefault: isDefaultField,
  user: userField,
  role: roleField,
  organization: organizationField,
};