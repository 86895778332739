import { SOGetRelationship } from '../relationship/so-get-relationship';
import { SOGetWidgetRelationshipSpec } from '../widget-relationship-spec/so-get-widget-relationship-spec';
import { SOGetCommit } from '../commit/so-get-commit';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostRelationshipWidgetRelationshipSpecAssociation {

  constructor(
    public relationship: SOGetRelationship,
    public widget: SOGetWidgetRelationshipSpec,
    public commit: SOGetCommit,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostRelationshipWidgetRelationshipSpecAssociation(
      'relationship' in params ? params.relationship : null,
      'widget' in params ? params.widget : null,
      'commit' in params ? params.commit : null,
      'organization' in params ? params.organization : null,
    );
  }
}
