import {SORespEntityRequestAssociation} from '../../core/entity-request-association/so-resp-entity-request-association';
import {EntityRequestAssociationCore} from './entity-request-association.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putEntityRequestAssociationFormFieldOptions} from './put-entity-request-association-form-fields';
import {SOGetEntityRequestAssociation} from '../../core/entity-request-association/so-get-entity-request-association';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {SOGetRequest} from '../../core/request/so-get-request';
import {SOGetApp} from '../../core/app/so-get-app';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class EntityRequestAssociationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespEntityRequestAssociation = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []


    const entityCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'entity');
    entityCore.soGet = SOGetEntity.construct({
      app: new SOGetApp(activeUserService.getScope('app').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, entityCore)
    entityCore.title = heading ? heading : entityCore.title
    entityCore.listTitle = 'Entity'

    if (entity.entity) {
        displayStringFactory.reconfigure(entity.entity, entityViewSpec.entityCore.type)
    }
    entityCore.configureForDisplay(entityViewSpec)

    const entityDetail = new DetailEntry(
      heading ? heading : entityCore.listTitle,
      entity.entity ? displayStringFactory.primaryString : null,
      'entity',
      BaseDetailType.pushedDetail,
      entityCore,
      EditableType.always,
      putEntityRequestAssociationFormFieldOptions.entity
    )
    if (entity.entity) {
      entityDetail.cachedListEntry = entityCore.makeListEntry(entityViewSpec, entityCore, entity.entity, 0)
    }
    entityDetail.singleFieldSelect = true
    attributes.push(entityDetail);

    const requestCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'request');
    requestCore.soGet = SOGetRequest.construct({
      entity: new SOGetEntityRequestAssociation(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, requestCore)
    requestCore.title = heading ? heading : requestCore.title
    requestCore.listTitle = 'Request'

    if (entity.request) {
        displayStringFactory.reconfigure(entity.request, entityViewSpec.entityCore.type)
    }
    requestCore.configureForDisplay(entityViewSpec)

    const requestDetail = new DetailEntry(
      heading ? heading : requestCore.listTitle,
      entity.request ? displayStringFactory.primaryString : null,
      'request',
      BaseDetailType.pushedDetail,
      requestCore,
      EditableType.always,
      putEntityRequestAssociationFormFieldOptions.request
    )
    if (entity.request) {
      requestDetail.cachedListEntry = requestCore.makeListEntry(entityViewSpec, requestCore, entity.request, 0)
    }
    requestDetail.singleFieldSelect = true
    attributes.push(requestDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
