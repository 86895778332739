import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const commitField = new FormField(
  'commit',
  'Commit',
  'commit',
  FormFieldType.pushedSelectionList,
  false,
)
const codableField = new FormField(
  'codable',
  'Codable',
  'codable',
  FormFieldType.pushedSelectionList,
  false,
)


export const putCommitCodableAssociationFormFieldOptions: {[key: string]: FormField} = {
  commit: commitField,
  codable: codableField,
};