import {SORespProduct} from '../../core/product/so-resp-product';
import {ProductCore} from './product.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putProductFormFieldOptions} from './put-product-form-fields';
import {SOGetProduct} from '../../core/product/so-get-product';
import {SOGetPrice} from '../../core/price/so-get-price';
import {SOGetAppliedConstraint} from '../../core/applied-constraint/so-get-applied-constraint';


export class ProductDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespProduct = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const nameDetail = new DetailEntry(
        'Name',
        entity.name,
        'name',
        BaseDetailType.text,
        null,
        EditableType.always,
        putProductFormFieldOptions.name
    )
    attributes.push(nameDetail)

    const skuDetail = new DetailEntry(
        'Sku',
        entity.sku,
        'sku',
        BaseDetailType.text,
        null,
        EditableType.always,
        putProductFormFieldOptions.sku
    )
    attributes.push(skuDetail)

    const exRefDetail = new DetailEntry(
        'Ex ref',
        entity.exRef,
        'exRef',
        BaseDetailType.text,
        null,
        EditableType.always,
        putProductFormFieldOptions.exRef
    )
    attributes.push(exRefDetail)

    const dateCreatedDetail = new DetailEntry(
        'Date created',
        activeUserService.time.niceDate(entity.dateCreated),
        'dateCreated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateCreatedDetail)

    const dateUpdatedDetail = new DetailEntry(
        'Date updated',
        activeUserService.time.niceDate(entity.dateUpdated),
        'dateUpdated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateUpdatedDetail)

    const activeDetail = new DetailEntry(
        'Active',
        entity.active,
        'active',
        BaseDetailType.toggle,
        null,
        EditableType.always,
        putProductFormFieldOptions.active
    )
    attributes.push(activeDetail)


    const pricesCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'price');
    pricesCore.soGet = SOGetPrice.construct({
      product: new SOGetProduct(entity.uid),
    });
    heading = getHeading(entityViewSpec.entityCore, pricesCore)
    pricesCore.title = heading ? heading : pricesCore.title
    pricesCore.listTitle = 'Prices'

    pricesCore.configureForDisplay(entityViewSpec)

    const pricesDetail = new DetailEntry(
      heading ? heading : pricesCore.listTitle,
      null,
      'prices',
      BaseDetailType.embeddedList,
      pricesCore,
      EditableType.never
    )
    attributes.push(pricesDetail);

    const appliedConstraintsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'appliedConstraint');
    appliedConstraintsCore.soGet = SOGetAppliedConstraint.construct({
      product: new SOGetProduct(entity.uid),
    });
    heading = getHeading(entityViewSpec.entityCore, appliedConstraintsCore)
    appliedConstraintsCore.title = heading ? heading : appliedConstraintsCore.title
    appliedConstraintsCore.listTitle = 'Applied constraints'

    appliedConstraintsCore.configureForDisplay(entityViewSpec)

    const appliedConstraintsDetail = new DetailEntry(
      heading ? heading : appliedConstraintsCore.listTitle,
      null,
      'appliedConstraints',
      BaseDetailType.embeddedList,
      appliedConstraintsCore,
      EditableType.never
    )
    attributes.push(appliedConstraintsDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
