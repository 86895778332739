import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const loginStyleField = new FormField(
  'loginStyle',
  'Login style',
  null,
  FormFieldType.text,
  true,
)


export const postHelloFormFieldOptions: {[key: string]: FormField} = {
  loginStyle: loginStyleField,
};