import {SORespEntityWidgetAssociation} from '../../core/entity-widget-association/so-resp-entity-widget-association';
import {EntityWidgetAssociationCore} from './entity-widget-association.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putEntityWidgetAssociationFormFieldOptions} from './put-entity-widget-association-form-fields';
import {SOGetEntityWidgetAssociation} from '../../core/entity-widget-association/so-get-entity-widget-association';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {SOGetWidget} from '../../core/widget/so-get-widget';
import {SOGetApp} from '../../core/app/so-get-app';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class EntityWidgetAssociationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespEntityWidgetAssociation = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []


    const entityCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'entity');
    entityCore.soGet = SOGetEntity.construct({
      app: new SOGetApp(activeUserService.getScope('app').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, entityCore)
    entityCore.title = heading ? heading : entityCore.title
    entityCore.listTitle = 'Entity'

    if (entity.entity) {
        displayStringFactory.reconfigure(entity.entity, entityViewSpec.entityCore.type)
    }
    entityCore.configureForDisplay(entityViewSpec)

    const entityDetail = new DetailEntry(
      heading ? heading : entityCore.listTitle,
      entity.entity ? displayStringFactory.primaryString : null,
      'entity',
      BaseDetailType.pushedDetail,
      entityCore,
      EditableType.always,
      putEntityWidgetAssociationFormFieldOptions.entity
    )
    if (entity.entity) {
      entityDetail.cachedListEntry = entityCore.makeListEntry(entityViewSpec, entityCore, entity.entity, 0)
    }
    entityDetail.singleFieldSelect = true
    attributes.push(entityDetail);

    const widgetCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'widget');
    widgetCore.soGet = SOGetWidget.construct({
      entity: new SOGetEntityWidgetAssociation(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, widgetCore)
    widgetCore.title = heading ? heading : widgetCore.title
    widgetCore.listTitle = 'Widget'

    if (entity.widget) {
        displayStringFactory.reconfigure(entity.widget, entityViewSpec.entityCore.type)
    }
    widgetCore.configureForDisplay(entityViewSpec)

    const widgetDetail = new DetailEntry(
      heading ? heading : widgetCore.listTitle,
      entity.widget ? displayStringFactory.primaryString : null,
      'widget',
      BaseDetailType.pushedDetail,
      widgetCore,
      EditableType.always,
      putEntityWidgetAssociationFormFieldOptions.widget
    )
    if (entity.widget) {
      widgetDetail.cachedListEntry = widgetCore.makeListEntry(entityViewSpec, widgetCore, entity.widget, 0)
    }
    widgetDetail.singleFieldSelect = true
    attributes.push(widgetDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
