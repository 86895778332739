import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOAttributeRequestAttributeSpecAssociationService} from '../../core/attribute-request-attribute-spec-association/eo-attribute-request-attribute-spec-association';
import {AttributeRequestAttributeSpecAssociationListEntryFactory} from './attribute-request-attribute-spec-association-list-entry-factory';
import {AttributeRequestAttributeSpecAssociationDetailFactory} from './attribute-request-attribute-spec-association-detail-factory';
import {SOGetAttributeRequestAttributeSpecAssociation} from '../../core/attribute-request-attribute-spec-association/so-get-attribute-request-attribute-spec-association';
import {RAPutAttributeRequestAttributeSpecAssociation} from './ra-put-attribute-request-attribute-spec-association';
import {RAPostAttributeRequestAttributeSpecAssociation} from './ra-post-attribute-request-attribute-spec-association';
import {RADeleteAttributeRequestAttributeSpecAssociation} from './ra-delete-attribute-request-attribute-spec-association';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class AttributeRequestAttributeSpecAssociationCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'attributeRequestAttributeSpecAssociation';
  title = 'Attribute request attribute spec association';
  listEntryFactory = new AttributeRequestAttributeSpecAssociationListEntryFactory();
  detailFactory = new AttributeRequestAttributeSpecAssociationDetailFactory();

  entityService: EOAttributeRequestAttributeSpecAssociationService;
  soGetClass = SOGetAttributeRequestAttributeSpecAssociation;
  soGet: SOGetAttributeRequestAttributeSpecAssociation;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOAttributeRequestAttributeSpecAssociationService
    );
    this.adapters = [
      new RAPostAttributeRequestAttributeSpecAssociation(this),
      new RAPutAttributeRequestAttributeSpecAssociation(this),
      new RADeleteAttributeRequestAttributeSpecAssociation(this),
    ];
    this.resetSoGet();
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetAttributeRequestAttributeSpecAssociation();
    this.soGet.commit = new SOGetCommit(
      this.activeUserService.getScope('commit').uid
    );
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
