import {SOPostAttributeCodableAssociation} from '../../core/attribute-codable-association/so-post-attribute-codable-association';
import {AttributeCodableAssociationCore} from './attribute-codable-association.core';
import {SOGetAttributeCodableAssociation} from '../../core/attribute-codable-association/so-get-attribute-codable-association';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postAttributeCodableAssociationFormFieldOptions} from './post-attribute-codable-association-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {AttributeCore} from '../bv-attribute/attribute.core';
import {SOGetAttribute} from '../../core/attribute/so-get-attribute';
import {CodableCore} from '../bv-codable/codable.core';
import {SOGetCodable} from '../../core/codable/so-get-codable';
import {CommitCore} from '../bv-commit/commit.core';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostAttributeCodableAssociation extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postAttributeCodableAssociationFormFieldOptions);

  RequestClass = SOPostAttributeCodableAssociation

  constructor(
    public entityCore: AttributeCodableAssociationCore
  ) {
    super();
    if (postAttributeCodableAssociationFormFieldOptions.attribute) {
      postAttributeCodableAssociationFormFieldOptions.attribute.makeCore = true;
    }
    if (postAttributeCodableAssociationFormFieldOptions.codable) {
      postAttributeCodableAssociationFormFieldOptions.codable.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'attribute',
        SOGetAttribute,
      ),
      this.getFormValueFromIdentifier(
        'codable',
        SOGetCodable,
      ),
      this.getFormValueFromIdentifier(
        'commit',
        SOGetCommit,
        true,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}