import {SOPutStoreAttributeSpec} from '../../core/store-attribute-spec/so-put-store-attribute-spec';
import {StoreAttributeSpecCore} from './store-attribute-spec.core';
import {SOGetStoreAttributeSpec} from '../../core/store-attribute-spec/so-get-store-attribute-spec';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putStoreAttributeSpecFormFieldOptions} from './put-store-attribute-spec-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {StoreStoreAttributeSpecAssociationCore} from '../bv-store-store-attribute-spec-association/store-store-attribute-spec-association.core';
import {SOGetStoreStoreAttributeSpecAssociation} from '../../core/store-store-attribute-spec-association/so-get-store-store-attribute-spec-association';
import {AttributeStoreAttributeSpecAssociationCore} from '../bv-attribute-store-attribute-spec-association/attribute-store-attribute-spec-association.core';
import {SOGetAttributeStoreAttributeSpecAssociation} from '../../core/attribute-store-attribute-spec-association/so-get-attribute-store-attribute-spec-association';

export class RAPutStoreAttributeSpec extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putStoreAttributeSpecFormFieldOptions);

  RequestClass = SOPutStoreAttributeSpec

  constructor(
    public entityCore: StoreAttributeSpecCore
  ) {
    super();
    if (putStoreAttributeSpecFormFieldOptions.store) {
      putStoreAttributeSpecFormFieldOptions.store.makeCore = true;
    }
    if (putStoreAttributeSpecFormFieldOptions.attribute) {
      putStoreAttributeSpecFormFieldOptions.attribute.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('isRequired'),
      this.getFormValueFromIdentifier('defaultValue'),
      [this.getFormValueFromIdentifier('store', SOGetStoreStoreAttributeSpecAssociation)],
      [this.getFormValueFromIdentifier('attribute', SOGetAttributeStoreAttributeSpecAssociation)],
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}