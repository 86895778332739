import { SOGetAttribute } from '../attribute/so-get-attribute';
import { SOGetResponseAttributeSpec } from '../response-attribute-spec/so-get-response-attribute-spec';
import { SOGetCommit } from '../commit/so-get-commit';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostAttributeResponseAttributeSpecAssociation {

  constructor(
    public attribute: SOGetAttribute,
    public response: SOGetResponseAttributeSpec,
    public commit: SOGetCommit,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostAttributeResponseAttributeSpecAssociation(
      'attribute' in params ? params.attribute : null,
      'response' in params ? params.response : null,
      'commit' in params ? params.commit : null,
      'organization' in params ? params.organization : null,
    );
  }
}
