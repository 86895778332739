import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const mediaField = new FormField(
  'media',
  'Media',
  'media',
  FormFieldType.pushedSelectionList,
  true,
)


export const postAttachmentFormFieldOptions: {[key: string]: FormField} = {
  media: mediaField,
};