import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';
import {ENViewType} from '../../enums';


const nameField = new FormField(
  'name',
  'Name',
  null,
  FormFieldType.text,
  true,
)
const identifierField = new FormField(
  'identifier',
  'Identifier',
  null,
  FormFieldType.text,
  true,
)
const typeField = new FormField(
  'type',
  'Type',
  null,
  FormFieldType.enumeration,
  false,
)
typeField.enumeration = ENViewType


export const postViewFormFieldOptions: {[key: string]: FormField} = {
  name: nameField,
  identifier: identifierField,
  type: typeField,
};