import { SOGetPrice } from '../price/so-get-price';
import { SOGetOrganization } from '../organization/so-get-organization';
import {ENSubscriptionStatus} from '../../enums';


export class SOPostSubscription {

  constructor(
    public price: SOGetPrice,
    public organization: SOGetOrganization,
    public status: ENSubscriptionStatus,
    public exRef: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostSubscription(
      'price' in params ? params.price : null,
      'organization' in params ? params.organization : null,
      'exRef' in params ? params.exRef : null,
      'status' in params ? params.status : null,
    );
  }
}
