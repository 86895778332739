import { SOGetAttribute } from '../attribute/so-get-attribute';
import { SOGetResponseAttributeSpec } from '../response-attribute-spec/so-get-response-attribute-spec';
import { SOGetCommit } from '../commit/so-get-commit';


export class SOPutAttributeResponseAttributeSpecAssociation {

  constructor(
    public uid: string,
    public attribute: SOGetAttribute = null,
    public response: SOGetResponseAttributeSpec = null,
    public commit: SOGetCommit = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutAttributeResponseAttributeSpecAssociation(
      'uid' in params ? params.uid : null,
      'attribute' in params ? params.attribute : null,
      'response' in params ? params.response : null,
      'commit' in params ? params.commit : null,
    );
  }
}
