import {SOPutCommit} from '../../core/commit/so-put-commit';
import {CommitCore} from './commit.core';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putCommitFormFieldOptions} from './put-commit-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';

export class RAPutCommit extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putCommitFormFieldOptions);

  RequestClass = SOPutCommit

  constructor(
    public entityCore: CommitCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('description'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}