import { Type } from 'class-transformer';
import { SORespAttribute } from '../attribute/so-resp-attribute';
import { SORespRequestAttributeSpec } from '../request-attribute-spec/so-resp-request-attribute-spec';
import { SORespCommit } from '../commit/so-resp-commit';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespAttributeRequestAttributeSpecAssociation implements ObjectiveProtocol {

  entityType: string = 'attributeRequestAttributeSpecAssociation';
  uid: string;
  @Type(() => SORespAttribute)
  attribute: SORespAttribute;
  @Type(() => SORespRequestAttributeSpec)
  request: SORespRequestAttributeSpec;
  @Type(() => SORespCommit)
  commit: SORespCommit;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
}