import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOWidgetWidgetRelationshipSpecAssociationService} from '../../core/widget-widget-relationship-spec-association/eo-widget-widget-relationship-spec-association';
import {WidgetWidgetRelationshipSpecAssociationListEntryFactory} from './widget-widget-relationship-spec-association-list-entry-factory';
import {WidgetWidgetRelationshipSpecAssociationDetailFactory} from './widget-widget-relationship-spec-association-detail-factory';
import {SOGetWidgetWidgetRelationshipSpecAssociation} from '../../core/widget-widget-relationship-spec-association/so-get-widget-widget-relationship-spec-association';
import {RAPutWidgetWidgetRelationshipSpecAssociation} from './ra-put-widget-widget-relationship-spec-association';
import {RAPostWidgetWidgetRelationshipSpecAssociation} from './ra-post-widget-widget-relationship-spec-association';
import {RADeleteWidgetWidgetRelationshipSpecAssociation} from './ra-delete-widget-widget-relationship-spec-association';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class WidgetWidgetRelationshipSpecAssociationCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'widgetWidgetRelationshipSpecAssociation';
  title = 'Widget widget relationship spec association';
  listEntryFactory = new WidgetWidgetRelationshipSpecAssociationListEntryFactory();
  detailFactory = new WidgetWidgetRelationshipSpecAssociationDetailFactory();

  entityService: EOWidgetWidgetRelationshipSpecAssociationService;
  soGetClass = SOGetWidgetWidgetRelationshipSpecAssociation;
  soGet: SOGetWidgetWidgetRelationshipSpecAssociation;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOWidgetWidgetRelationshipSpecAssociationService
    );
    this.adapters = [
      new RAPostWidgetWidgetRelationshipSpecAssociation(this),
      new RAPutWidgetWidgetRelationshipSpecAssociation(this),
      new RADeleteWidgetWidgetRelationshipSpecAssociation(this),
    ];
    this.resetSoGet();
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetWidgetWidgetRelationshipSpecAssociation();
    this.soGet.commit = new SOGetCommit(
      this.activeUserService.getScope('commit').uid
    );
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
