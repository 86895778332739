import {SOPostEnumeratorEnumeralAssociation} from '../../core/enumerator-enumeral-association/so-post-enumerator-enumeral-association';
import {EnumeratorEnumeralAssociationCore} from './enumerator-enumeral-association.core';
import {SOGetEnumeratorEnumeralAssociation} from '../../core/enumerator-enumeral-association/so-get-enumerator-enumeral-association';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postEnumeratorEnumeralAssociationFormFieldOptions} from './post-enumerator-enumeral-association-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {EnumeratorCore} from '../bv-enumerator/enumerator.core';
import {SOGetEnumerator} from '../../core/enumerator/so-get-enumerator';
import {EnumeralCore} from '../bv-enumeral/enumeral.core';
import {SOGetEnumeral} from '../../core/enumeral/so-get-enumeral';
import {CommitCore} from '../bv-commit/commit.core';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostEnumeratorEnumeralAssociation extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postEnumeratorEnumeralAssociationFormFieldOptions);

  RequestClass = SOPostEnumeratorEnumeralAssociation

  constructor(
    public entityCore: EnumeratorEnumeralAssociationCore
  ) {
    super();
    if (postEnumeratorEnumeralAssociationFormFieldOptions.enumerator) {
      postEnumeratorEnumeralAssociationFormFieldOptions.enumerator.makeCore = true;
    }
    if (postEnumeratorEnumeralAssociationFormFieldOptions.enumeral) {
      postEnumeratorEnumeralAssociationFormFieldOptions.enumeral.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'enumerator',
        SOGetEnumerator,
      ),
      this.getFormValueFromIdentifier(
        'enumeral',
        SOGetEnumeral,
      ),
      this.getFormValueFromIdentifier(
        'commit',
        SOGetCommit,
        true,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}