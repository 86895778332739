import { Type } from 'class-transformer';
import { SORespPrice } from '../price/so-resp-price';
import { SORespInvoice } from '../invoice/so-resp-invoice';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespInvoiceLine implements ObjectiveProtocol {

  entityType: string = 'invoiceLine';
  uid: string;
  amount: number;
  quantity: number;
  @Type(() => Date)
  dateCreated: Date;
  description: string = '';
  @Type(() => SORespPrice)
  price: SORespPrice;
  @Type(() => SORespInvoice)
  invoice: SORespInvoice;
}