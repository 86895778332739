import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostAttributeStoreAttributeSpecAssociation } from './so-post-attribute-store-attribute-spec-association';
import { SOGetAttributeStoreAttributeSpecAssociation } from './so-get-attribute-store-attribute-spec-association';
import { SOPutAttributeStoreAttributeSpecAssociation } from './so-put-attribute-store-attribute-spec-association';
import { SODeleteAttributeStoreAttributeSpecAssociation } from './so-delete-attribute-store-attribute-spec-association';
import {SORespAttributeStoreAttributeSpecAssociation} from './so-resp-attribute-store-attribute-spec-association';
import {SOPluralAttributeStoreAttributeSpecAssociation} from './so-plural-attribute-store-attribute-spec-association';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOAttributeStoreAttributeSpecAssociationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'attribute_store_attribute_spec_association',
      entityCore
    );
  }

  /** GET AttributeStoreAttributeSpecAssociation by id. Will 404 if id not found */
  get(request: SOGetAttributeStoreAttributeSpecAssociation): Observable<SOPluralAttributeStoreAttributeSpecAssociation> {
    return this.httpClient.get<SOPluralAttributeStoreAttributeSpecAssociation>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralAttributeStoreAttributeSpecAssociation, resp) }),
      tap(_ => this.log(`fetched AttributeStoreAttributeSpecAssociation`)),
      catchError(this.handleError<SOPluralAttributeStoreAttributeSpecAssociation>(`get AttributeStoreAttributeSpecAssociation`))
    );
  }

  /** POST: add a new AttributeStoreAttributeSpecAssociation to the server */
  post(request: SOPostAttributeStoreAttributeSpecAssociation): Observable<SORespAttributeStoreAttributeSpecAssociation> {
    return this.httpClient.post<SORespAttributeStoreAttributeSpecAssociation>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAttributeStoreAttributeSpecAssociation, resp) }),
      tap((response: SORespAttributeStoreAttributeSpecAssociation) => this.log(`added AttributeStoreAttributeSpecAssociation w/ id=${response.uid}`)),
      catchError(this.handleError<SORespAttributeStoreAttributeSpecAssociation>('AttributeStoreAttributeSpecAssociation post'))
    );
  }

  /** PUT: update AttributeStoreAttributeSpecAssociation  */
  put(request: SOPutAttributeStoreAttributeSpecAssociation): Observable<SORespAttributeStoreAttributeSpecAssociation> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespAttributeStoreAttributeSpecAssociation>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAttributeStoreAttributeSpecAssociation, resp) }),
      tap((response: SORespAttributeStoreAttributeSpecAssociation) => this.log(`edited AttributeStoreAttributeSpecAssociation w/ uid=${uid}`)),
      catchError(this.handleError<SORespAttributeStoreAttributeSpecAssociation>('AttributeStoreAttributeSpecAssociation put'))
    );
  }

  /** DELETE: delete the AttributeStoreAttributeSpecAssociation from the server */
  delete(request: SODeleteAttributeStoreAttributeSpecAssociation): Observable<SORespAttributeStoreAttributeSpecAssociation> {
    return this.httpClient.delete<SORespAttributeStoreAttributeSpecAssociation>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted AttributeStoreAttributeSpecAssociation uid=${request.uid}`)),
      catchError(this.handleError<SORespAttributeStoreAttributeSpecAssociation>('delete AttributeStoreAttributeSpecAssociation'))
    );
  }
}
