import {SOPostCommitRelationshipLinkAssociation} from '../../core/commit-relationship-link-association/so-post-commit-relationship-link-association';
import {CommitRelationshipLinkAssociationCore} from './commit-relationship-link-association.core';
import {SOGetCommitRelationshipLinkAssociation} from '../../core/commit-relationship-link-association/so-get-commit-relationship-link-association';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postCommitRelationshipLinkAssociationFormFieldOptions} from './post-commit-relationship-link-association-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {CommitCore} from '../bv-commit/commit.core';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {RelationshipLinkCore} from '../bv-relationship-link/relationship-link.core';
import {SOGetRelationshipLink} from '../../core/relationship-link/so-get-relationship-link';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostCommitRelationshipLinkAssociation extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postCommitRelationshipLinkAssociationFormFieldOptions);

  RequestClass = SOPostCommitRelationshipLinkAssociation

  constructor(
    public entityCore: CommitRelationshipLinkAssociationCore
  ) {
    super();
    if (postCommitRelationshipLinkAssociationFormFieldOptions.commit) {
      postCommitRelationshipLinkAssociationFormFieldOptions.commit.makeCore = true;
    }
    if (postCommitRelationshipLinkAssociationFormFieldOptions.entity) {
      postCommitRelationshipLinkAssociationFormFieldOptions.entity.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'commit',
        SOGetCommit,
      ),
      this.getFormValueFromIdentifier(
        'entity',
        SOGetRelationshipLink,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}