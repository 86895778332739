import { SOGetStoreStoreAttributeSpecAssociation } from '../store-store-attribute-spec-association/so-get-store-store-attribute-spec-association';
import { SOGetAttributeStoreAttributeSpecAssociation } from '../attribute-store-attribute-spec-association/so-get-attribute-store-attribute-spec-association';


export class SOPutStoreAttributeSpec {

  constructor(
    public uid: string,
    public isRequired: boolean = null,
    public defaultValue: string = null,
    public store: SOGetStoreStoreAttributeSpecAssociation[] = null,
    public attribute: SOGetAttributeStoreAttributeSpecAssociation[] = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutStoreAttributeSpec(
      'uid' in params ? params.uid : null,
      'isRequired' in params ? params.isRequired : null,
      'defaultValue' in params ? params.defaultValue : null,
      'store' in params ? params.store : null,
      'attribute' in params ? params.attribute : null,
    );
  }
}
