import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOStoreService} from '../../core/store/eo-store';
import {StoreListEntryFactory} from './store-list-entry-factory';
import {StoreDetailFactory} from './store-detail-factory';
import {SOGetStore} from '../../core/store/so-get-store';
import {RAPutStore} from './ra-put-store';
import {RAPostStore} from './ra-post-store';
import {RADeleteStore} from './ra-delete-store';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import {SOGetApp} from '../../core/app/so-get-app'


export class StoreCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'store';
  title = 'Store';
  listEntryFactory = new StoreListEntryFactory();
  detailFactory = new StoreDetailFactory();

  entityService: EOStoreService;
  soGetClass = SOGetStore;
  soGet: SOGetStore;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOStoreService
    );
    this.adapters = [
      new RAPostStore(this),
      new RAPutStore(this),
      new RADeleteStore(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetStore();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.app = new SOGetApp(
      this.activeUserService.getScope('app').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
