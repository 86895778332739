import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const attributeField = new FormField(
  'attribute',
  'Attribute',
  'attribute',
  FormFieldType.pushedSelectionList,
  true,
)
const responseField = new FormField(
  'response',
  'Response',
  'responseAttributeSpec',
  FormFieldType.pushedSelectionList,
  true,
)


export const postAttributeResponseAttributeSpecAssociationFormFieldOptions: {[key: string]: FormField} = {
  attribute: attributeField,
  response: responseField,
};