import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const entityField = new FormField(
  'entity',
  'Entity',
  'entity',
  FormFieldType.pushedSelectionList,
  false,
)
const widgetField = new FormField(
  'widget',
  'Widget',
  'widget',
  FormFieldType.pushedSelectionList,
  false,
)


export const putEntityWidgetAssociationFormFieldOptions: {[key: string]: FormField} = {
  entity: entityField,
  widget: widgetField,
};