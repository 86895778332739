import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostStoreStoreAttributeSpecAssociation } from './so-post-store-store-attribute-spec-association';
import { SOGetStoreStoreAttributeSpecAssociation } from './so-get-store-store-attribute-spec-association';
import { SOPutStoreStoreAttributeSpecAssociation } from './so-put-store-store-attribute-spec-association';
import { SODeleteStoreStoreAttributeSpecAssociation } from './so-delete-store-store-attribute-spec-association';
import {SORespStoreStoreAttributeSpecAssociation} from './so-resp-store-store-attribute-spec-association';
import {SOPluralStoreStoreAttributeSpecAssociation} from './so-plural-store-store-attribute-spec-association';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOStoreStoreAttributeSpecAssociationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'store_store_attribute_spec_association',
      entityCore
    );
  }

  /** GET StoreStoreAttributeSpecAssociation by id. Will 404 if id not found */
  get(request: SOGetStoreStoreAttributeSpecAssociation): Observable<SOPluralStoreStoreAttributeSpecAssociation> {
    return this.httpClient.get<SOPluralStoreStoreAttributeSpecAssociation>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralStoreStoreAttributeSpecAssociation, resp) }),
      tap(_ => this.log(`fetched StoreStoreAttributeSpecAssociation`)),
      catchError(this.handleError<SOPluralStoreStoreAttributeSpecAssociation>(`get StoreStoreAttributeSpecAssociation`))
    );
  }

  /** POST: add a new StoreStoreAttributeSpecAssociation to the server */
  post(request: SOPostStoreStoreAttributeSpecAssociation): Observable<SORespStoreStoreAttributeSpecAssociation> {
    return this.httpClient.post<SORespStoreStoreAttributeSpecAssociation>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespStoreStoreAttributeSpecAssociation, resp) }),
      tap((response: SORespStoreStoreAttributeSpecAssociation) => this.log(`added StoreStoreAttributeSpecAssociation w/ id=${response.uid}`)),
      catchError(this.handleError<SORespStoreStoreAttributeSpecAssociation>('StoreStoreAttributeSpecAssociation post'))
    );
  }

  /** PUT: update StoreStoreAttributeSpecAssociation  */
  put(request: SOPutStoreStoreAttributeSpecAssociation): Observable<SORespStoreStoreAttributeSpecAssociation> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespStoreStoreAttributeSpecAssociation>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespStoreStoreAttributeSpecAssociation, resp) }),
      tap((response: SORespStoreStoreAttributeSpecAssociation) => this.log(`edited StoreStoreAttributeSpecAssociation w/ uid=${uid}`)),
      catchError(this.handleError<SORespStoreStoreAttributeSpecAssociation>('StoreStoreAttributeSpecAssociation put'))
    );
  }

  /** DELETE: delete the StoreStoreAttributeSpecAssociation from the server */
  delete(request: SODeleteStoreStoreAttributeSpecAssociation): Observable<SORespStoreStoreAttributeSpecAssociation> {
    return this.httpClient.delete<SORespStoreStoreAttributeSpecAssociation>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted StoreStoreAttributeSpecAssociation uid=${request.uid}`)),
      catchError(this.handleError<SORespStoreStoreAttributeSpecAssociation>('delete StoreStoreAttributeSpecAssociation'))
    );
  }
}
