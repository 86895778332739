import { Type } from 'class-transformer';
import { SORespCommit } from '../commit/so-resp-commit';
import { SORespEntity } from '../entity/so-resp-entity';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespCommitEntityAssociation implements ObjectiveProtocol {

  entityType: string = 'commitEntityAssociation';
  uid: string;
  @Type(() => SORespCommit)
  commit: SORespCommit;
  @Type(() => SORespEntity)
  entity: SORespEntity;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
}