import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostResponseResponseRelationshipSpecAssociation } from './so-post-response-response-relationship-spec-association';
import { SOGetResponseResponseRelationshipSpecAssociation } from './so-get-response-response-relationship-spec-association';
import { SOPutResponseResponseRelationshipSpecAssociation } from './so-put-response-response-relationship-spec-association';
import { SODeleteResponseResponseRelationshipSpecAssociation } from './so-delete-response-response-relationship-spec-association';
import {SORespResponseResponseRelationshipSpecAssociation} from './so-resp-response-response-relationship-spec-association';
import {SOPluralResponseResponseRelationshipSpecAssociation} from './so-plural-response-response-relationship-spec-association';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOResponseResponseRelationshipSpecAssociationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'response_response_relationship_spec_association',
      entityCore
    );
  }

  /** GET ResponseResponseRelationshipSpecAssociation by id. Will 404 if id not found */
  get(request: SOGetResponseResponseRelationshipSpecAssociation): Observable<SOPluralResponseResponseRelationshipSpecAssociation> {
    return this.httpClient.get<SOPluralResponseResponseRelationshipSpecAssociation>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralResponseResponseRelationshipSpecAssociation, resp) }),
      tap(_ => this.log(`fetched ResponseResponseRelationshipSpecAssociation`)),
      catchError(this.handleError<SOPluralResponseResponseRelationshipSpecAssociation>(`get ResponseResponseRelationshipSpecAssociation`))
    );
  }

  /** POST: add a new ResponseResponseRelationshipSpecAssociation to the server */
  post(request: SOPostResponseResponseRelationshipSpecAssociation): Observable<SORespResponseResponseRelationshipSpecAssociation> {
    return this.httpClient.post<SORespResponseResponseRelationshipSpecAssociation>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespResponseResponseRelationshipSpecAssociation, resp) }),
      tap((response: SORespResponseResponseRelationshipSpecAssociation) => this.log(`added ResponseResponseRelationshipSpecAssociation w/ id=${response.uid}`)),
      catchError(this.handleError<SORespResponseResponseRelationshipSpecAssociation>('ResponseResponseRelationshipSpecAssociation post'))
    );
  }

  /** PUT: update ResponseResponseRelationshipSpecAssociation  */
  put(request: SOPutResponseResponseRelationshipSpecAssociation): Observable<SORespResponseResponseRelationshipSpecAssociation> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespResponseResponseRelationshipSpecAssociation>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespResponseResponseRelationshipSpecAssociation, resp) }),
      tap((response: SORespResponseResponseRelationshipSpecAssociation) => this.log(`edited ResponseResponseRelationshipSpecAssociation w/ uid=${uid}`)),
      catchError(this.handleError<SORespResponseResponseRelationshipSpecAssociation>('ResponseResponseRelationshipSpecAssociation put'))
    );
  }

  /** DELETE: delete the ResponseResponseRelationshipSpecAssociation from the server */
  delete(request: SODeleteResponseResponseRelationshipSpecAssociation): Observable<SORespResponseResponseRelationshipSpecAssociation> {
    return this.httpClient.delete<SORespResponseResponseRelationshipSpecAssociation>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted ResponseResponseRelationshipSpecAssociation uid=${request.uid}`)),
      catchError(this.handleError<SORespResponseResponseRelationshipSpecAssociation>('delete ResponseResponseRelationshipSpecAssociation'))
    );
  }
}
