import { SOGetEntity } from '../entity/so-get-entity';
import { SOGetRequest } from '../request/so-get-request';
import { SOGetCommit } from '../commit/so-get-commit';


export class SOPutEntityRequestAssociation {

  constructor(
    public uid: string,
    public entity: SOGetEntity = null,
    public request: SOGetRequest = null,
    public commit: SOGetCommit = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutEntityRequestAssociation(
      'uid' in params ? params.uid : null,
      'entity' in params ? params.entity : null,
      'request' in params ? params.request : null,
      'commit' in params ? params.commit : null,
    );
  }
}
