import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOAttributeService} from '../../core/attribute/eo-attribute';
import {AttributeListEntryFactory} from './attribute-list-entry-factory';
import {AttributeDetailFactory} from './attribute-detail-factory';
import {SOGetAttribute} from '../../core/attribute/so-get-attribute';
import {RAPutAttribute} from './ra-put-attribute';
import {RAPostAttribute} from './ra-post-attribute';
import {RADeleteAttribute} from './ra-delete-attribute';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import {SOGetApp} from '../../core/app/so-get-app'


export class AttributeCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'attribute';
  title = 'Attribute';
  listEntryFactory = new AttributeListEntryFactory();
  detailFactory = new AttributeDetailFactory();

  entityService: EOAttributeService;
  soGetClass = SOGetAttribute;
  soGet: SOGetAttribute;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOAttributeService
    );
    this.adapters = [
      new RAPostAttribute(this),
      new RAPutAttribute(this),
      new RADeleteAttribute(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetAttribute();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.app = new SOGetApp(
      this.activeUserService.getScope('app').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
