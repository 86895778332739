import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOAppService} from '../../core/app/eo-app';
import {AppListEntryFactory} from './app-list-entry-factory';
import {AppDetailFactory} from './app-detail-factory';
import {SOGetApp} from '../../core/app/so-get-app';
import {RAPutApp} from './ra-put-app';
import {RAPostApp} from './ra-post-app';
import {RADeleteApp} from './ra-delete-app';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetOrganization} from '../../core/organization/so-get-organization'


export class AppCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'app';
  title = 'App';
  listEntryFactory = new AppListEntryFactory();
  detailFactory = new AppDetailFactory();

  entityService: EOAppService;
  soGetClass = SOGetApp;
  soGet: SOGetApp;

  isScope = true;

  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOAppService
    );
    this.adapters = [
      new RAPostApp(this),
      new RAPutApp(this),
      new RADeleteApp(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetApp();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
