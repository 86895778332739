import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const providerField = new FormField(
  'provider',
  'Provider',
  null,
  FormFieldType.text,
  false,
)
const userField = new FormField(
  'user',
  'User',
  'user',
  FormFieldType.pushedSelectionList,
  false,
)


export const putIdentityFormFieldOptions: {[key: string]: FormField} = {
  provider: providerField,
  user: userField,
};