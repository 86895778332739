import { Type } from 'class-transformer';
import { SORespResponse } from '../response/so-resp-response';
import { SORespResponseAttributeSpec } from '../response-attribute-spec/so-resp-response-attribute-spec';
import { SORespCommit } from '../commit/so-resp-commit';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespResponseResponseAttributeSpecAssociation implements ObjectiveProtocol {

  entityType: string = 'responseResponseAttributeSpecAssociation';
  uid: string;
  @Type(() => SORespResponse)
  response: SORespResponse;
  @Type(() => SORespResponseAttributeSpec)
  responseAttributeSpec: SORespResponseAttributeSpec;
  @Type(() => SORespCommit)
  commit: SORespCommit;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
}