import { SOGetRelationship } from '../relationship/so-get-relationship';
import { SOGetRequestRelationshipSpec } from '../request-relationship-spec/so-get-request-relationship-spec';
import { SOGetCommit } from '../commit/so-get-commit';


export class SOPutRelationshipRequestRelationshipSpecAssociation {

  constructor(
    public uid: string,
    public relationship: SOGetRelationship = null,
    public request: SOGetRequestRelationshipSpec = null,
    public commit: SOGetCommit = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutRelationshipRequestRelationshipSpecAssociation(
      'uid' in params ? params.uid : null,
      'relationship' in params ? params.relationship : null,
      'request' in params ? params.request : null,
      'commit' in params ? params.commit : null,
    );
  }
}
