

export class SOPostConstraint {

  constructor(
    public name: string,
    public identifier: string,
    public description: string = '',
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostConstraint(
      'name' in params ? params.name : null,
      'identifier' in params ? params.identifier : null,
      'description' in params ? params.description : null,
    );
  }
}
