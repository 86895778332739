import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EORelationshipRequestRelationshipSpecAssociationService} from '../../core/relationship-request-relationship-spec-association/eo-relationship-request-relationship-spec-association';
import {RelationshipRequestRelationshipSpecAssociationListEntryFactory} from './relationship-request-relationship-spec-association-list-entry-factory';
import {RelationshipRequestRelationshipSpecAssociationDetailFactory} from './relationship-request-relationship-spec-association-detail-factory';
import {SOGetRelationshipRequestRelationshipSpecAssociation} from '../../core/relationship-request-relationship-spec-association/so-get-relationship-request-relationship-spec-association';
import {RAPutRelationshipRequestRelationshipSpecAssociation} from './ra-put-relationship-request-relationship-spec-association';
import {RAPostRelationshipRequestRelationshipSpecAssociation} from './ra-post-relationship-request-relationship-spec-association';
import {RADeleteRelationshipRequestRelationshipSpecAssociation} from './ra-delete-relationship-request-relationship-spec-association';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class RelationshipRequestRelationshipSpecAssociationCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'relationshipRequestRelationshipSpecAssociation';
  title = 'Relationship request relationship spec association';
  listEntryFactory = new RelationshipRequestRelationshipSpecAssociationListEntryFactory();
  detailFactory = new RelationshipRequestRelationshipSpecAssociationDetailFactory();

  entityService: EORelationshipRequestRelationshipSpecAssociationService;
  soGetClass = SOGetRelationshipRequestRelationshipSpecAssociation;
  soGet: SOGetRelationshipRequestRelationshipSpecAssociation;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EORelationshipRequestRelationshipSpecAssociationService
    );
    this.adapters = [
      new RAPostRelationshipRequestRelationshipSpecAssociation(this),
      new RAPutRelationshipRequestRelationshipSpecAssociation(this),
      new RADeleteRelationshipRequestRelationshipSpecAssociation(this),
    ];
    this.resetSoGet();
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetRelationshipRequestRelationshipSpecAssociation();
    this.soGet.commit = new SOGetCommit(
      this.activeUserService.getScope('commit').uid
    );
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
