import { Type } from 'class-transformer';
import { SORespMedia } from '../media/so-resp-media';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespAttachment implements ObjectiveProtocol {

  entityType: string = 'attachment';
  uid: string;
  @Type(() => SORespMedia)
  media: SORespMedia;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
}