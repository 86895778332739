import { SOGetEntityResponseAssociation } from '../entity-response-association/so-get-entity-response-association';
import {ENResponseType} from '../../enums';


export class SOPutResponse {

  constructor(
    public uid: string,
    public name: string = null,
    public responseType: ENResponseType = null,
    public isDefault: boolean = null,
    public entity: SOGetEntityResponseAssociation[] = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutResponse(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'responseType' in params ? params.responseType : null,
      'isDefault' in params ? params.isDefault : null,
      'entity' in params ? params.entity : null,
    );
  }
}
