import {SORespAttributeStoreAttributeSpecAssociation} from '../../core/attribute-store-attribute-spec-association/so-resp-attribute-store-attribute-spec-association';
import {AttributeStoreAttributeSpecAssociationCore} from './attribute-store-attribute-spec-association.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putAttributeStoreAttributeSpecAssociationFormFieldOptions} from './put-attribute-store-attribute-spec-association-form-fields';
import {SOGetAttributeStoreAttributeSpecAssociation} from '../../core/attribute-store-attribute-spec-association/so-get-attribute-store-attribute-spec-association';
import {SOGetAttribute} from '../../core/attribute/so-get-attribute';
import {SOGetStoreAttributeSpec} from '../../core/store-attribute-spec/so-get-store-attribute-spec';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetApp} from '../../core/app/so-get-app';


export class AttributeStoreAttributeSpecAssociationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespAttributeStoreAttributeSpecAssociation = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []


    const attributeCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'attribute');
    attributeCore.soGet = SOGetAttribute.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, attributeCore)
    attributeCore.title = heading ? heading : attributeCore.title
    attributeCore.listTitle = 'Attribute'

    if (entity.attribute) {
        displayStringFactory.reconfigure(entity.attribute, entityViewSpec.entityCore.type)
    }
    attributeCore.configureForDisplay(entityViewSpec)

    const attributeDetail = new DetailEntry(
      heading ? heading : attributeCore.listTitle,
      entity.attribute ? displayStringFactory.primaryString : null,
      'attribute',
      BaseDetailType.pushedDetail,
      attributeCore,
      EditableType.always,
      putAttributeStoreAttributeSpecAssociationFormFieldOptions.attribute
    )
    if (entity.attribute) {
      attributeDetail.cachedListEntry = attributeCore.makeListEntry(entityViewSpec, attributeCore, entity.attribute, 0)
    }
    attributeDetail.singleFieldSelect = true
    attributes.push(attributeDetail);

    const storeCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'storeAttributeSpec');
    storeCore.soGet = SOGetStoreAttributeSpec.construct({
      attribute: new SOGetAttributeStoreAttributeSpecAssociation(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, storeCore)
    storeCore.title = heading ? heading : storeCore.title
    storeCore.listTitle = 'Store'

    if (entity.store) {
        displayStringFactory.reconfigure(entity.store, entityViewSpec.entityCore.type)
    }
    storeCore.configureForDisplay(entityViewSpec)

    const storeDetail = new DetailEntry(
      heading ? heading : storeCore.listTitle,
      entity.store ? displayStringFactory.primaryString : null,
      'store',
      BaseDetailType.pushedDetail,
      storeCore,
      EditableType.always,
      putAttributeStoreAttributeSpecAssociationFormFieldOptions.store
    )
    if (entity.store) {
      storeDetail.cachedListEntry = storeCore.makeListEntry(entityViewSpec, storeCore, entity.store, 0)
    }
    storeDetail.singleFieldSelect = true
    attributes.push(storeDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
