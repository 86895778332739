import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const amountField = new FormField(
  'amount',
  'Amount',
  null,
  FormFieldType.text,
  false,
)
const quantityField = new FormField(
  'quantity',
  'Quantity',
  null,
  FormFieldType.text,
  false,
)
const descriptionField = new FormField(
  'description',
  'Description',
  null,
  FormFieldType.text,
  false,
  true,
  ''
)
const priceField = new FormField(
  'price',
  'Price',
  'price',
  FormFieldType.pushedSelectionList,
  false,
)
const invoiceField = new FormField(
  'invoice',
  'Invoice',
  'invoice',
  FormFieldType.pushedSelectionList,
  false,
)


export const putInvoiceLineFormFieldOptions: {[key: string]: FormField} = {
  amount: amountField,
  quantity: quantityField,
  description: descriptionField,
  price: priceField,
  invoice: invoiceField,
};