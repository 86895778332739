import { SOGetEntity } from '../entity/so-get-entity';
import { SOGetResponse } from '../response/so-get-response';
import { SOGetCommit } from '../commit/so-get-commit';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostEntityResponseAssociation {

  constructor(
    public entity: SOGetEntity,
    public response: SOGetResponse,
    public commit: SOGetCommit,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostEntityResponseAssociation(
      'entity' in params ? params.entity : null,
      'response' in params ? params.response : null,
      'commit' in params ? params.commit : null,
      'organization' in params ? params.organization : null,
    );
  }
}
