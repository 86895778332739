import {SOPostStoreStoreRelationshipSpecAssociation} from '../../core/store-store-relationship-spec-association/so-post-store-store-relationship-spec-association';
import {StoreStoreRelationshipSpecAssociationCore} from './store-store-relationship-spec-association.core';
import {SOGetStoreStoreRelationshipSpecAssociation} from '../../core/store-store-relationship-spec-association/so-get-store-store-relationship-spec-association';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postStoreStoreRelationshipSpecAssociationFormFieldOptions} from './post-store-store-relationship-spec-association-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {StoreCore} from '../bv-store/store.core';
import {SOGetStore} from '../../core/store/so-get-store';
import {StoreRelationshipSpecCore} from '../bv-store-relationship-spec/store-relationship-spec.core';
import {SOGetStoreRelationshipSpec} from '../../core/store-relationship-spec/so-get-store-relationship-spec';
import {CommitCore} from '../bv-commit/commit.core';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostStoreStoreRelationshipSpecAssociation extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postStoreStoreRelationshipSpecAssociationFormFieldOptions);

  RequestClass = SOPostStoreStoreRelationshipSpecAssociation

  constructor(
    public entityCore: StoreStoreRelationshipSpecAssociationCore
  ) {
    super();
    if (postStoreStoreRelationshipSpecAssociationFormFieldOptions.store) {
      postStoreStoreRelationshipSpecAssociationFormFieldOptions.store.makeCore = true;
    }
    if (postStoreStoreRelationshipSpecAssociationFormFieldOptions.storeRelationshipSpec) {
      postStoreStoreRelationshipSpecAssociationFormFieldOptions.storeRelationshipSpec.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'store',
        SOGetStore,
      ),
      this.getFormValueFromIdentifier(
        'storeRelationshipSpec',
        SOGetStoreRelationshipSpec,
      ),
      this.getFormValueFromIdentifier(
        'commit',
        SOGetCommit,
        true,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}