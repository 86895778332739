import {SORespStoreRelationshipSpec} from '../../core/store-relationship-spec/so-resp-store-relationship-spec';
import {StoreRelationshipSpecCore} from './store-relationship-spec.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putStoreRelationshipSpecFormFieldOptions} from './put-store-relationship-spec-form-fields';
import {SOGetStoreRelationshipSpec} from '../../core/store-relationship-spec/so-get-store-relationship-spec';
import {SOGetStoreStoreRelationshipSpecAssociation} from '../../core/store-store-relationship-spec-association/so-get-store-store-relationship-spec-association';
import {SOGetRelationshipStoreRelationshipSpecAssociation} from '../../core/relationship-store-relationship-spec-association/so-get-relationship-store-relationship-spec-association';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class StoreRelationshipSpecDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespStoreRelationshipSpec = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const isRequiredDetail = new DetailEntry(
        'Is required',
        entity.isRequired,
        'isRequired',
        BaseDetailType.toggle,
        null,
        EditableType.always,
        putStoreRelationshipSpecFormFieldOptions.isRequired
    )
    attributes.push(isRequiredDetail)


    const storeCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'storeStoreRelationshipSpecAssociation');
    storeCore.soGet = SOGetStoreStoreRelationshipSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, storeCore)
    storeCore.title = heading ? heading : storeCore.title
    storeCore.listTitle = 'Store'

    storeCore.configureForDisplay(entityViewSpec)

    const storeDetail = new DetailEntry(
      heading ? heading : storeCore.listTitle,
      null,
      'store',
      BaseDetailType.embeddedList,
      storeCore,
      EditableType.always,
      putStoreRelationshipSpecFormFieldOptions.store
    )
    attributes.push(storeDetail);

    const relationshipCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'relationshipStoreRelationshipSpecAssociation');
    relationshipCore.soGet = SOGetRelationshipStoreRelationshipSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, relationshipCore)
    relationshipCore.title = heading ? heading : relationshipCore.title
    relationshipCore.listTitle = 'Relationship'

    relationshipCore.configureForDisplay(entityViewSpec)

    const relationshipDetail = new DetailEntry(
      heading ? heading : relationshipCore.listTitle,
      null,
      'relationship',
      BaseDetailType.embeddedList,
      relationshipCore,
      EditableType.always,
      putStoreRelationshipSpecFormFieldOptions.relationship
    )
    attributes.push(relationshipDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
