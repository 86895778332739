import { Type } from 'class-transformer';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespConstraint implements ObjectiveProtocol {

  entityType: string = 'constraint';
  uid: string;
  name: string;
  identifier: string;
  description: string = '';
}