import {SORespApp} from '../../core/app/so-resp-app';
import {AppCore} from './app.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putAppFormFieldOptions} from './put-app-form-fields';
import {SOGetApp} from '../../core/app/so-get-app';
import {SOGetBranch} from '../../core/branch/so-get-branch';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class AppDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespApp = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const nameDetail = new DetailEntry(
        'Name',
        entity.name,
        'name',
        BaseDetailType.text,
        null,
        EditableType.always,
        putAppFormFieldOptions.name
    )
    attributes.push(nameDetail)


    const branchesCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'branch');
    branchesCore.soGet = SOGetBranch.construct({
      app: new SOGetApp(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, branchesCore)
    branchesCore.title = heading ? heading : branchesCore.title
    branchesCore.listTitle = 'Branches'

    branchesCore.configureForDisplay(entityViewSpec)

    const branchesDetail = new DetailEntry(
      heading ? heading : branchesCore.listTitle,
      null,
      'branches',
      BaseDetailType.embeddedList,
      branchesCore,
      EditableType.never
    )
    attributes.push(branchesDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
