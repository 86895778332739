import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOStoreStoreRelationshipSpecAssociationService} from '../../core/store-store-relationship-spec-association/eo-store-store-relationship-spec-association';
import {StoreStoreRelationshipSpecAssociationListEntryFactory} from './store-store-relationship-spec-association-list-entry-factory';
import {StoreStoreRelationshipSpecAssociationDetailFactory} from './store-store-relationship-spec-association-detail-factory';
import {SOGetStoreStoreRelationshipSpecAssociation} from '../../core/store-store-relationship-spec-association/so-get-store-store-relationship-spec-association';
import {RAPutStoreStoreRelationshipSpecAssociation} from './ra-put-store-store-relationship-spec-association';
import {RAPostStoreStoreRelationshipSpecAssociation} from './ra-post-store-store-relationship-spec-association';
import {RADeleteStoreStoreRelationshipSpecAssociation} from './ra-delete-store-store-relationship-spec-association';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetCommit} from '../../core/commit/so-get-commit'
import {SOGetOrganization} from '../../core/organization/so-get-organization'


export class StoreStoreRelationshipSpecAssociationCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'storeStoreRelationshipSpecAssociation';
  title = 'Store store relationship spec association';
  listEntryFactory = new StoreStoreRelationshipSpecAssociationListEntryFactory();
  detailFactory = new StoreStoreRelationshipSpecAssociationDetailFactory();

  entityService: EOStoreStoreRelationshipSpecAssociationService;
  soGetClass = SOGetStoreStoreRelationshipSpecAssociation;
  soGet: SOGetStoreStoreRelationshipSpecAssociation;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOStoreStoreRelationshipSpecAssociationService
    );
    this.adapters = [
      new RAPostStoreStoreRelationshipSpecAssociation(this),
      new RAPutStoreStoreRelationshipSpecAssociation(this),
      new RADeleteStoreStoreRelationshipSpecAssociation(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetStoreStoreRelationshipSpecAssociation();
    this.soGet.commit = new SOGetCommit(
      this.activeUserService.getScope('commit').uid
    );
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
