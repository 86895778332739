import {SOPutRelationshipWidgetRelationshipSpecAssociation} from '../../core/relationship-widget-relationship-spec-association/so-put-relationship-widget-relationship-spec-association';
import {RelationshipWidgetRelationshipSpecAssociationCore} from './relationship-widget-relationship-spec-association.core';
import {SOGetRelationshipWidgetRelationshipSpecAssociation} from '../../core/relationship-widget-relationship-spec-association/so-get-relationship-widget-relationship-spec-association';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putRelationshipWidgetRelationshipSpecAssociationFormFieldOptions} from './put-relationship-widget-relationship-spec-association-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {RelationshipCore} from '../bv-relationship/relationship.core';
import {SOGetRelationship} from '../../core/relationship/so-get-relationship';
import {WidgetRelationshipSpecCore} from '../bv-widget-relationship-spec/widget-relationship-spec.core';
import {SOGetWidgetRelationshipSpec} from '../../core/widget-relationship-spec/so-get-widget-relationship-spec';
import {CommitCore} from '../bv-commit/commit.core';
import {SOGetCommit} from '../../core/commit/so-get-commit';

export class RAPutRelationshipWidgetRelationshipSpecAssociation extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putRelationshipWidgetRelationshipSpecAssociationFormFieldOptions);

  RequestClass = SOPutRelationshipWidgetRelationshipSpecAssociation

  constructor(
    public entityCore: RelationshipWidgetRelationshipSpecAssociationCore
  ) {
    super();
    if (putRelationshipWidgetRelationshipSpecAssociationFormFieldOptions.relationship) {
      putRelationshipWidgetRelationshipSpecAssociationFormFieldOptions.relationship.makeCore = true;
    }
    if (putRelationshipWidgetRelationshipSpecAssociationFormFieldOptions.widget) {
      putRelationshipWidgetRelationshipSpecAssociationFormFieldOptions.widget.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier(
        'relationship',
        SOGetRelationship,
      ),
      this.getFormValueFromIdentifier(
        'widget',
        SOGetWidgetRelationshipSpec,
      ),
      this.getFormValueFromIdentifier(
        'commit',
        SOGetCommit,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}