import {SORespEntityAttributeAssociation} from '../../core/entity-attribute-association/so-resp-entity-attribute-association';
import {EntityAttributeAssociationCore} from './entity-attribute-association.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putEntityAttributeAssociationFormFieldOptions} from './put-entity-attribute-association-form-fields';
import {SOGetEntityAttributeAssociation} from '../../core/entity-attribute-association/so-get-entity-attribute-association';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {SOGetAttribute} from '../../core/attribute/so-get-attribute';
import {SOGetApp} from '../../core/app/so-get-app';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class EntityAttributeAssociationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespEntityAttributeAssociation = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []


    const entityCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'entity');
    entityCore.soGet = SOGetEntity.construct({
      app: new SOGetApp(activeUserService.getScope('app').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, entityCore)
    entityCore.title = heading ? heading : entityCore.title
    entityCore.listTitle = 'Entity'

    if (entity.entity) {
        displayStringFactory.reconfigure(entity.entity, entityViewSpec.entityCore.type)
    }
    entityCore.configureForDisplay(entityViewSpec)

    const entityDetail = new DetailEntry(
      heading ? heading : entityCore.listTitle,
      entity.entity ? displayStringFactory.primaryString : null,
      'entity',
      BaseDetailType.pushedDetail,
      entityCore,
      EditableType.always,
      putEntityAttributeAssociationFormFieldOptions.entity
    )
    if (entity.entity) {
      entityDetail.cachedListEntry = entityCore.makeListEntry(entityViewSpec, entityCore, entity.entity, 0)
    }
    entityDetail.singleFieldSelect = true
    attributes.push(entityDetail);

    const attributeCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'attribute');
    attributeCore.soGet = SOGetAttribute.construct({
      entity: new SOGetEntityAttributeAssociation(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, attributeCore)
    attributeCore.title = heading ? heading : attributeCore.title
    attributeCore.listTitle = 'Attribute'

    if (entity.attribute) {
        displayStringFactory.reconfigure(entity.attribute, entityViewSpec.entityCore.type)
    }
    attributeCore.configureForDisplay(entityViewSpec)

    const attributeDetail = new DetailEntry(
      heading ? heading : attributeCore.listTitle,
      entity.attribute ? displayStringFactory.primaryString : null,
      'attribute',
      BaseDetailType.pushedDetail,
      attributeCore,
      EditableType.always,
      putEntityAttributeAssociationFormFieldOptions.attribute
    )
    if (entity.attribute) {
      attributeDetail.cachedListEntry = attributeCore.makeListEntry(entityViewSpec, attributeCore, entity.attribute, 0)
    }
    attributeDetail.singleFieldSelect = true
    attributes.push(attributeDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
