import {SORespResponseAttributeSpec} from '../../core/response-attribute-spec/so-resp-response-attribute-spec';
import {ResponseAttributeSpecCore} from './response-attribute-spec.core';
import { ListEntry, ListEntryFactory, ListEntryFactoryProtocol} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';


export class ResponseAttributeSpecListEntryFactory  extends ListEntryFactory implements ListEntryFactoryProtocol {

  makeListEntry(parentCoreViewSpec, entityCore: EntityCoreProtocol, entity: SORespResponseAttributeSpec, section: number = 0): ListEntry {
    const resp = super.makeListEntry(parentCoreViewSpec, entityCore, entity, section)
    return resp
  }
}
