import {SOPostAppliedConstraint} from '../../core/applied-constraint/so-post-applied-constraint';
import {AppliedConstraintCore} from './applied-constraint.core';
import {SOGetAppliedConstraint} from '../../core/applied-constraint/so-get-applied-constraint';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postAppliedConstraintFormFieldOptions} from './post-applied-constraint-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {ConstraintCore} from '../bv-constraint/constraint.core';
import {SOGetConstraint} from '../../core/constraint/so-get-constraint';
import {ProductCore} from '../bv-product/product.core';
import {SOGetProduct} from '../../core/product/so-get-product';

export class RAPostAppliedConstraint extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postAppliedConstraintFormFieldOptions);

  RequestClass = SOPostAppliedConstraint

  constructor(
    public entityCore: AppliedConstraintCore
  ) {
    super();
    if (postAppliedConstraintFormFieldOptions.constraint) {
      postAppliedConstraintFormFieldOptions.constraint.makeCore = true;
    }
    if (postAppliedConstraintFormFieldOptions.product) {
      postAppliedConstraintFormFieldOptions.product.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'constraint',
        SOGetConstraint,
      ),
      this.getFormValueFromIdentifier(
        'product',
        SOGetProduct,
      ),
      this.getFormValueFromIdentifier('quantity'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}