import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EORelationshipLinkService} from '../../core/relationship-link/eo-relationship-link';
import {RelationshipLinkListEntryFactory} from './relationship-link-list-entry-factory';
import {RelationshipLinkDetailFactory} from './relationship-link-detail-factory';
import {SOGetRelationshipLink} from '../../core/relationship-link/so-get-relationship-link';
import {RAPutRelationshipLink} from './ra-put-relationship-link';
import {RAPostRelationshipLink} from './ra-post-relationship-link';
import {RADeleteRelationshipLink} from './ra-delete-relationship-link';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetApp} from '../../core/app/so-get-app'
import {SOGetOrganization} from '../../core/organization/so-get-organization'


export class RelationshipLinkCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'relationshipLink';
  title = 'Relationship link';
  listEntryFactory = new RelationshipLinkListEntryFactory();
  detailFactory = new RelationshipLinkDetailFactory();

  entityService: EORelationshipLinkService;
  soGetClass = SOGetRelationshipLink;
  soGet: SOGetRelationshipLink;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EORelationshipLinkService
    );
    this.adapters = [
      new RAPostRelationshipLink(this),
      new RAPutRelationshipLink(this),
      new RADeleteRelationshipLink(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetRelationshipLink();
    this.soGet.app = new SOGetApp(
      this.activeUserService.getScope('app').uid
    );
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
