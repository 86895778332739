import {SOPostMedia} from '../../core/media/so-post-media';
import {MediaCore} from './media.core';
import {SOGetMedia} from '../../core/media/so-get-media';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postMediaFormFieldOptions} from './post-media-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostMedia extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postMediaFormFieldOptions);

  RequestClass = SOPostMedia

  constructor(
    public entityCore: MediaCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('description'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}