import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const isRequiredField = new FormField(
  'isRequired',
  'Is required',
  null,
  FormFieldType.boolean,
  true,
)
const defaultValueField = new FormField(
  'defaultValue',
  'Default value',
  null,
  FormFieldType.text,
  false,
)
const requestField = new FormField(
  'request',
  'Request',
  'requestRequestAttributeSpecAssociation',
  FormFieldType.pushedSelectionList,
  true,
)
const attributeField = new FormField(
  'attribute',
  'Attribute',
  'attributeRequestAttributeSpecAssociation',
  FormFieldType.pushedSelectionList,
  true,
)


export const postRequestAttributeSpecFormFieldOptions: {[key: string]: FormField} = {
  isRequired: isRequiredField,
  defaultValue: defaultValueField,
  request: requestField,
  attribute: attributeField,
};