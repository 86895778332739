import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostEnumeral } from './so-post-enumeral';
import { SOGetEnumeral } from './so-get-enumeral';
import { SOPutEnumeral } from './so-put-enumeral';
import { SODeleteEnumeral } from './so-delete-enumeral';
import {SORespEnumeral} from './so-resp-enumeral';
import {SOPluralEnumeral} from './so-plural-enumeral';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOEnumeralService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'enumeral',
      entityCore
    );
  }

  /** GET Enumeral by id. Will 404 if id not found */
  get(request: SOGetEnumeral): Observable<SOPluralEnumeral> {
    return this.httpClient.get<SOPluralEnumeral>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralEnumeral, resp) }),
      tap(_ => this.log(`fetched Enumeral`)),
      catchError(this.handleError<SOPluralEnumeral>(`get Enumeral`))
    );
  }

  /** POST: add a new Enumeral to the server */
  post(request: SOPostEnumeral): Observable<SORespEnumeral> {
    return this.httpClient.post<SORespEnumeral>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespEnumeral, resp) }),
      tap((response: SORespEnumeral) => this.log(`added Enumeral w/ id=${response.uid}`)),
      catchError(this.handleError<SORespEnumeral>('Enumeral post'))
    );
  }

  /** PUT: update Enumeral  */
  put(request: SOPutEnumeral): Observable<SORespEnumeral> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespEnumeral>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespEnumeral, resp) }),
      tap((response: SORespEnumeral) => this.log(`edited Enumeral w/ uid=${uid}`)),
      catchError(this.handleError<SORespEnumeral>('Enumeral put'))
    );
  }

  /** DELETE: delete the Enumeral from the server */
  delete(request: SODeleteEnumeral): Observable<SORespEnumeral> {
    return this.httpClient.delete<SORespEnumeral>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Enumeral uid=${request.uid}`)),
      catchError(this.handleError<SORespEnumeral>('delete Enumeral'))
    );
  }
}
