import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostCommit } from './so-post-commit';
import { SOGetCommit } from './so-get-commit';
import { SOPutCommit } from './so-put-commit';
import { SODeleteCommit } from './so-delete-commit';
import {SORespCommit} from './so-resp-commit';
import {SOPluralCommit} from './so-plural-commit';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOCommitService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'commit',
      entityCore
    );
  }

  /** GET Commit by id. Will 404 if id not found */
  get(request: SOGetCommit): Observable<SOPluralCommit> {
    return this.httpClient.get<SOPluralCommit>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralCommit, resp) }),
      tap(_ => this.log(`fetched Commit`)),
      catchError(this.handleError<SOPluralCommit>(`get Commit`))
    );
  }

  /** POST: add a new Commit to the server */
  post(request: SOPostCommit): Observable<SORespCommit> {
    return this.httpClient.post<SORespCommit>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespCommit, resp) }),
      tap((response: SORespCommit) => this.log(`added Commit w/ id=${response.uid}`)),
      catchError(this.handleError<SORespCommit>('Commit post'))
    );
  }

  /** PUT: update Commit  */
  put(request: SOPutCommit): Observable<SORespCommit> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespCommit>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespCommit, resp) }),
      tap((response: SORespCommit) => this.log(`edited Commit w/ uid=${uid}`)),
      catchError(this.handleError<SORespCommit>('Commit put'))
    );
  }

  /** DELETE: delete the Commit from the server */
  delete(request: SODeleteCommit): Observable<SORespCommit> {
    return this.httpClient.delete<SORespCommit>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Commit uid=${request.uid}`)),
      catchError(this.handleError<SORespCommit>('delete Commit'))
    );
  }
}
