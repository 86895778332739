import { SOGetCommit } from '../commit/so-get-commit';
import { SOGetRelationshipLink } from '../relationship-link/so-get-relationship-link';
import { SOGetOrganization } from '../organization/so-get-organization';
import {MMQueryOptions} from 'ngx-lionheart-material';
import {QueryProtocol} from 'ngx-lionheart-material';


export class SOGetCommitRelationshipLinkAssociation implements QueryProtocol {

  constructor(
    public uid: string = null,
    public commit: SOGetCommit = null,
    public entity: SOGetRelationshipLink = null,
    public organization: SOGetOrganization = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetCommitRelationshipLinkAssociation(
      'uid' in params ? params.uid : null,
      'commit' in params ? params.commit : null,
      'entity' in params ? params.entity : null,
      'organization' in params ? params.organization : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
