import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const relationshipField = new FormField(
  'relationship',
  'Relationship',
  'relationship',
  FormFieldType.pushedSelectionList,
  true,
)
const responseField = new FormField(
  'response',
  'Response',
  'responseRelationshipSpec',
  FormFieldType.pushedSelectionList,
  true,
)


export const postRelationshipResponseRelationshipSpecAssociationFormFieldOptions: {[key: string]: FormField} = {
  relationship: relationshipField,
  response: responseField,
};