import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostRelationshipWidgetRelationshipSpecAssociation } from './so-post-relationship-widget-relationship-spec-association';
import { SOGetRelationshipWidgetRelationshipSpecAssociation } from './so-get-relationship-widget-relationship-spec-association';
import { SOPutRelationshipWidgetRelationshipSpecAssociation } from './so-put-relationship-widget-relationship-spec-association';
import { SODeleteRelationshipWidgetRelationshipSpecAssociation } from './so-delete-relationship-widget-relationship-spec-association';
import {SORespRelationshipWidgetRelationshipSpecAssociation} from './so-resp-relationship-widget-relationship-spec-association';
import {SOPluralRelationshipWidgetRelationshipSpecAssociation} from './so-plural-relationship-widget-relationship-spec-association';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EORelationshipWidgetRelationshipSpecAssociationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'relationship_widget_relationship_spec_association',
      entityCore
    );
  }

  /** GET RelationshipWidgetRelationshipSpecAssociation by id. Will 404 if id not found */
  get(request: SOGetRelationshipWidgetRelationshipSpecAssociation): Observable<SOPluralRelationshipWidgetRelationshipSpecAssociation> {
    return this.httpClient.get<SOPluralRelationshipWidgetRelationshipSpecAssociation>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralRelationshipWidgetRelationshipSpecAssociation, resp) }),
      tap(_ => this.log(`fetched RelationshipWidgetRelationshipSpecAssociation`)),
      catchError(this.handleError<SOPluralRelationshipWidgetRelationshipSpecAssociation>(`get RelationshipWidgetRelationshipSpecAssociation`))
    );
  }

  /** POST: add a new RelationshipWidgetRelationshipSpecAssociation to the server */
  post(request: SOPostRelationshipWidgetRelationshipSpecAssociation): Observable<SORespRelationshipWidgetRelationshipSpecAssociation> {
    return this.httpClient.post<SORespRelationshipWidgetRelationshipSpecAssociation>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespRelationshipWidgetRelationshipSpecAssociation, resp) }),
      tap((response: SORespRelationshipWidgetRelationshipSpecAssociation) => this.log(`added RelationshipWidgetRelationshipSpecAssociation w/ id=${response.uid}`)),
      catchError(this.handleError<SORespRelationshipWidgetRelationshipSpecAssociation>('RelationshipWidgetRelationshipSpecAssociation post'))
    );
  }

  /** PUT: update RelationshipWidgetRelationshipSpecAssociation  */
  put(request: SOPutRelationshipWidgetRelationshipSpecAssociation): Observable<SORespRelationshipWidgetRelationshipSpecAssociation> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespRelationshipWidgetRelationshipSpecAssociation>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespRelationshipWidgetRelationshipSpecAssociation, resp) }),
      tap((response: SORespRelationshipWidgetRelationshipSpecAssociation) => this.log(`edited RelationshipWidgetRelationshipSpecAssociation w/ uid=${uid}`)),
      catchError(this.handleError<SORespRelationshipWidgetRelationshipSpecAssociation>('RelationshipWidgetRelationshipSpecAssociation put'))
    );
  }

  /** DELETE: delete the RelationshipWidgetRelationshipSpecAssociation from the server */
  delete(request: SODeleteRelationshipWidgetRelationshipSpecAssociation): Observable<SORespRelationshipWidgetRelationshipSpecAssociation> {
    return this.httpClient.delete<SORespRelationshipWidgetRelationshipSpecAssociation>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted RelationshipWidgetRelationshipSpecAssociation uid=${request.uid}`)),
      catchError(this.handleError<SORespRelationshipWidgetRelationshipSpecAssociation>('delete RelationshipWidgetRelationshipSpecAssociation'))
    );
  }
}
