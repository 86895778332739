import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOEntityRequestAssociationService} from '../../core/entity-request-association/eo-entity-request-association';
import {EntityRequestAssociationListEntryFactory} from './entity-request-association-list-entry-factory';
import {EntityRequestAssociationDetailFactory} from './entity-request-association-detail-factory';
import {SOGetEntityRequestAssociation} from '../../core/entity-request-association/so-get-entity-request-association';
import {RAPutEntityRequestAssociation} from './ra-put-entity-request-association';
import {RAPostEntityRequestAssociation} from './ra-post-entity-request-association';
import {RADeleteEntityRequestAssociation} from './ra-delete-entity-request-association';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetCommit} from '../../core/commit/so-get-commit'
import {SOGetOrganization} from '../../core/organization/so-get-organization'


export class EntityRequestAssociationCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'entityRequestAssociation';
  title = 'Entity request association';
  listEntryFactory = new EntityRequestAssociationListEntryFactory();
  detailFactory = new EntityRequestAssociationDetailFactory();

  entityService: EOEntityRequestAssociationService;
  soGetClass = SOGetEntityRequestAssociation;
  soGet: SOGetEntityRequestAssociation;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOEntityRequestAssociationService
    );
    this.adapters = [
      new RAPostEntityRequestAssociation(this),
      new RAPutEntityRequestAssociation(this),
      new RADeleteEntityRequestAssociation(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetEntityRequestAssociation();
    this.soGet.commit = new SOGetCommit(
      this.activeUserService.getScope('commit').uid
    );
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
