import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOAttributeCodableAssociationService} from '../../core/attribute-codable-association/eo-attribute-codable-association';
import {AttributeCodableAssociationListEntryFactory} from './attribute-codable-association-list-entry-factory';
import {AttributeCodableAssociationDetailFactory} from './attribute-codable-association-detail-factory';
import {SOGetAttributeCodableAssociation} from '../../core/attribute-codable-association/so-get-attribute-codable-association';
import {RAPutAttributeCodableAssociation} from './ra-put-attribute-codable-association';
import {RAPostAttributeCodableAssociation} from './ra-post-attribute-codable-association';
import {RADeleteAttributeCodableAssociation} from './ra-delete-attribute-codable-association';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetCommit} from '../../core/commit/so-get-commit'
import {SOGetOrganization} from '../../core/organization/so-get-organization'


export class AttributeCodableAssociationCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'attributeCodableAssociation';
  title = 'Attribute codable association';
  listEntryFactory = new AttributeCodableAssociationListEntryFactory();
  detailFactory = new AttributeCodableAssociationDetailFactory();

  entityService: EOAttributeCodableAssociationService;
  soGetClass = SOGetAttributeCodableAssociation;
  soGet: SOGetAttributeCodableAssociation;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOAttributeCodableAssociationService
    );
    this.adapters = [
      new RAPostAttributeCodableAssociation(this),
      new RAPutAttributeCodableAssociation(this),
      new RADeleteAttributeCodableAssociation(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetAttributeCodableAssociation();
    this.soGet.commit = new SOGetCommit(
      this.activeUserService.getScope('commit').uid
    );
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
