import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostEntity } from './so-post-entity';
import { SOGetEntity } from './so-get-entity';
import { SOPutEntity } from './so-put-entity';
import { SODeleteEntity } from './so-delete-entity';
import {SORespEntity} from './so-resp-entity';
import {SOPluralEntity} from './so-plural-entity';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOEntityService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'entity',
      entityCore
    );
  }

  /** GET Entity by id. Will 404 if id not found */
  get(request: SOGetEntity): Observable<SOPluralEntity> {
    return this.httpClient.get<SOPluralEntity>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralEntity, resp) }),
      tap(_ => this.log(`fetched Entity`)),
      catchError(this.handleError<SOPluralEntity>(`get Entity`))
    );
  }

  /** POST: add a new Entity to the server */
  post(request: SOPostEntity): Observable<SORespEntity> {
    return this.httpClient.post<SORespEntity>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespEntity, resp) }),
      tap((response: SORespEntity) => this.log(`added Entity w/ id=${response.uid}`)),
      catchError(this.handleError<SORespEntity>('Entity post'))
    );
  }

  /** PUT: update Entity  */
  put(request: SOPutEntity): Observable<SORespEntity> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespEntity>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespEntity, resp) }),
      tap((response: SORespEntity) => this.log(`edited Entity w/ uid=${uid}`)),
      catchError(this.handleError<SORespEntity>('Entity put'))
    );
  }

  /** DELETE: delete the Entity from the server */
  delete(request: SODeleteEntity): Observable<SORespEntity> {
    return this.httpClient.delete<SORespEntity>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Entity uid=${request.uid}`)),
      catchError(this.handleError<SORespEntity>('delete Entity'))
    );
  }
}
