import { Type } from 'class-transformer';
import { SORespWidgetWidgetRelationshipSpecAssociation } from '../widget-widget-relationship-spec-association/so-resp-widget-widget-relationship-spec-association';
import { SORespRelationshipWidgetRelationshipSpecAssociation } from '../relationship-widget-relationship-spec-association/so-resp-relationship-widget-relationship-spec-association';
import { SORespOrganization } from '../organization/so-resp-organization';
import { SORespApp } from '../app/so-resp-app';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespWidgetRelationshipSpec implements ObjectiveProtocol {

  entityType: string = 'widgetRelationshipSpec';
  uid: string;
  isRequired: boolean;
  @Type(() => SORespWidgetWidgetRelationshipSpecAssociation)
  widget: SORespWidgetWidgetRelationshipSpecAssociation[];
  @Type(() => SORespRelationshipWidgetRelationshipSpecAssociation)
  relationship: SORespRelationshipWidgetRelationshipSpecAssociation[];
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
  @Type(() => SORespApp)
  app: SORespApp;
}