import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOAppliedConstraintService} from '../../core/applied-constraint/eo-applied-constraint';
import {AppliedConstraintListEntryFactory} from './applied-constraint-list-entry-factory';
import {AppliedConstraintDetailFactory} from './applied-constraint-detail-factory';
import {SOGetAppliedConstraint} from '../../core/applied-constraint/so-get-applied-constraint';
import {RAPutAppliedConstraint} from './ra-put-applied-constraint';
import {RAPostAppliedConstraint} from './ra-post-applied-constraint';
import {RADeleteAppliedConstraint} from './ra-delete-applied-constraint';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';


export class AppliedConstraintCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'appliedConstraint';
  title = 'Applied constraint';
  listEntryFactory = new AppliedConstraintListEntryFactory();
  detailFactory = new AppliedConstraintDetailFactory();

  entityService: EOAppliedConstraintService;
  soGetClass = SOGetAppliedConstraint;
  soGet: SOGetAppliedConstraint;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOAppliedConstraintService
    );
    this.adapters = [
      new RAPostAppliedConstraint(this),
      new RAPutAppliedConstraint(this),
      new RADeleteAppliedConstraint(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetAppliedConstraint();
    this.soGet.queryOptions.limit = this.limit;
  }


}
