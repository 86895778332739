import {SORespAttachment} from '../../core/attachment/so-resp-attachment';
import {AttachmentCore} from './attachment.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putAttachmentFormFieldOptions} from './put-attachment-form-fields';
import {SOGetAttachment} from '../../core/attachment/so-get-attachment';
import {SOGetMedia} from '../../core/media/so-get-media';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class AttachmentDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespAttachment = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []


    const mediaCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'media');
    mediaCore.soGet = SOGetMedia.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, mediaCore)
    mediaCore.title = heading ? heading : mediaCore.title
    mediaCore.listTitle = 'Media'
    mediaCore.listSpecType = ListSpecType.thumbList

    if (entity.media) {
        displayStringFactory.reconfigure(entity.media, entityViewSpec.entityCore.type)
    }
    mediaCore.configureForDisplay(entityViewSpec)

    const mediaDetail = new DetailEntry(
      heading ? heading : mediaCore.listTitle,
      entity.media ? displayStringFactory.primaryString : null,
      'media',
      BaseDetailType.pushedDetail,
      mediaCore,
      EditableType.never
    )
    if (entity.media) {
      mediaDetail.cachedListEntry = mediaCore.makeListEntry(entityViewSpec, mediaCore, entity.media, 0)
    }
    mediaDetail.singleFieldSelect = true
    attributes.push(mediaDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
