import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOStoreRelationshipSpecService} from '../../core/store-relationship-spec/eo-store-relationship-spec';
import {StoreRelationshipSpecListEntryFactory} from './store-relationship-spec-list-entry-factory';
import {StoreRelationshipSpecDetailFactory} from './store-relationship-spec-detail-factory';
import {SOGetStoreRelationshipSpec} from '../../core/store-relationship-spec/so-get-store-relationship-spec';
import {RAPutStoreRelationshipSpec} from './ra-put-store-relationship-spec';
import {RAPostStoreRelationshipSpec} from './ra-post-store-relationship-spec';
import {RADeleteStoreRelationshipSpec} from './ra-delete-store-relationship-spec';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import {SOGetApp} from '../../core/app/so-get-app'


export class StoreRelationshipSpecCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'storeRelationshipSpec';
  title = 'Store relationship spec';
  listEntryFactory = new StoreRelationshipSpecListEntryFactory();
  detailFactory = new StoreRelationshipSpecDetailFactory();

  entityService: EOStoreRelationshipSpecService;
  soGetClass = SOGetStoreRelationshipSpec;
  soGet: SOGetStoreRelationshipSpec;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOStoreRelationshipSpecService
    );
    this.adapters = [
      new RAPostStoreRelationshipSpec(this),
      new RAPutStoreRelationshipSpec(this),
      new RADeleteStoreRelationshipSpec(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetStoreRelationshipSpec();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.app = new SOGetApp(
      this.activeUserService.getScope('app').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
