import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const attributeField = new FormField(
  'attribute',
  'Attribute',
  'attribute',
  FormFieldType.pushedSelectionList,
  true,
)
const widgetField = new FormField(
  'widget',
  'Widget',
  'widgetAttributeSpec',
  FormFieldType.pushedSelectionList,
  true,
)


export const postAttributeWidgetAttributeSpecAssociationFormFieldOptions: {[key: string]: FormField} = {
  attribute: attributeField,
  widget: widgetField,
};