import { SOGetResponseResponseAttributeSpecAssociation } from '../response-response-attribute-spec-association/so-get-response-response-attribute-spec-association';
import { SOGetAttributeResponseAttributeSpecAssociation } from '../attribute-response-attribute-spec-association/so-get-attribute-response-attribute-spec-association';
import { SOGetOrganization } from '../organization/so-get-organization';
import { SOGetApp } from '../app/so-get-app';


export class SOPostResponseAttributeSpec {

  constructor(
    public isRequired: boolean,
    public response: SOGetResponseResponseAttributeSpecAssociation[],
    public attribute: SOGetAttributeResponseAttributeSpecAssociation[],
    public organization: SOGetOrganization,
    public app: SOGetApp,
    public defaultValue: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostResponseAttributeSpec(
      'isRequired' in params ? params.isRequired : null,
      'defaultValue' in params ? params.defaultValue : null,
      'response' in params ? params.response : null,
      'attribute' in params ? params.attribute : null,
      'organization' in params ? params.organization : null,
      'app' in params ? params.app : null,
    );
  }
}
