import {SORespRelationshipRequestRelationshipSpecAssociation} from '../../core/relationship-request-relationship-spec-association/so-resp-relationship-request-relationship-spec-association';
import {RelationshipRequestRelationshipSpecAssociationCore} from './relationship-request-relationship-spec-association.core';
import { ListEntry, ListEntryFactory, ListEntryFactoryProtocol} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';


export class RelationshipRequestRelationshipSpecAssociationListEntryFactory  extends ListEntryFactory implements ListEntryFactoryProtocol {

  makeListEntry(parentCoreViewSpec, entityCore: EntityCoreProtocol, entity: SORespRelationshipRequestRelationshipSpecAssociation, section: number = 0): ListEntry {
    const resp = super.makeListEntry(parentCoreViewSpec, entityCore, entity, section)
    return resp
  }
}
