import {SOPostEntityRelationshipAssociation} from '../../core/entity-relationship-association/so-post-entity-relationship-association';
import {EntityRelationshipAssociationCore} from './entity-relationship-association.core';
import {SOGetEntityRelationshipAssociation} from '../../core/entity-relationship-association/so-get-entity-relationship-association';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postEntityRelationshipAssociationFormFieldOptions} from './post-entity-relationship-association-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {EntityCore} from '../bv-entity/entity.core';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {RelationshipCore} from '../bv-relationship/relationship.core';
import {SOGetRelationship} from '../../core/relationship/so-get-relationship';
import {CommitCore} from '../bv-commit/commit.core';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostEntityRelationshipAssociation extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postEntityRelationshipAssociationFormFieldOptions);

  RequestClass = SOPostEntityRelationshipAssociation

  constructor(
    public entityCore: EntityRelationshipAssociationCore
  ) {
    super();
    if (postEntityRelationshipAssociationFormFieldOptions.entity) {
      postEntityRelationshipAssociationFormFieldOptions.entity.makeCore = true;
    }
    if (postEntityRelationshipAssociationFormFieldOptions.relationship) {
      postEntityRelationshipAssociationFormFieldOptions.relationship.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'entity',
        SOGetEntity,
      ),
      this.getFormValueFromIdentifier(
        'relationship',
        SOGetRelationship,
      ),
      this.getFormValueFromIdentifier(
        'commit',
        SOGetCommit,
        true,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}