import {AppCore} from './entities/bv-app/app.core';
import {BranchCore} from './entities/bv-branch/branch.core';
import {BranchCommitAssociationCore} from './entities/bv-branch-commit-association/branch-commit-association.core';
import {CommitCore} from './entities/bv-commit/commit.core';
import {CommitEntityAssociationCore} from './entities/bv-commit-entity-association/commit-entity-association.core';
import {CommitRelationshipLinkAssociationCore} from './entities/bv-commit-relationship-link-association/commit-relationship-link-association.core';
import {CommitCodableAssociationCore} from './entities/bv-commit-codable-association/commit-codable-association.core';
import {EntityCore} from './entities/bv-entity/entity.core';
import {EntityChildrenAssociationCore} from './entities/bv-entity-children-association/entity-children-association.core';
import {RelationshipLinkCore} from './entities/bv-relationship-link/relationship-link.core';
import {CodableCore} from './entities/bv-codable/codable.core';
import {EnumeratorCore} from './entities/bv-enumerator/enumerator.core';
import {EnumeratorEnumeralAssociationCore} from './entities/bv-enumerator-enumeral-association/enumerator-enumeral-association.core';
import {EnumeralCore} from './entities/bv-enumeral/enumeral.core';
import {EntityAttributeAssociationCore} from './entities/bv-entity-attribute-association/entity-attribute-association.core';
import {EntityRelationshipAssociationCore} from './entities/bv-entity-relationship-association/entity-relationship-association.core';
import {AttributeCore} from './entities/bv-attribute/attribute.core';
import {AttributeCodableAssociationCore} from './entities/bv-attribute-codable-association/attribute-codable-association.core';
import {AttributeWidgetAttributeSpecAssociationCore} from './entities/bv-attribute-widget-attribute-spec-association/attribute-widget-attribute-spec-association.core';
import {AttributeRequestAttributeSpecAssociationCore} from './entities/bv-attribute-request-attribute-spec-association/attribute-request-attribute-spec-association.core';
import {AttributeStoreAttributeSpecAssociationCore} from './entities/bv-attribute-store-attribute-spec-association/attribute-store-attribute-spec-association.core';
import {AttributeResponseAttributeSpecAssociationCore} from './entities/bv-attribute-response-attribute-spec-association/attribute-response-attribute-spec-association.core';
import {RelationshipCore} from './entities/bv-relationship/relationship.core';
import {RelationshipWidgetRelationshipSpecAssociationCore} from './entities/bv-relationship-widget-relationship-spec-association/relationship-widget-relationship-spec-association.core';
import {RelationshipRequestRelationshipSpecAssociationCore} from './entities/bv-relationship-request-relationship-spec-association/relationship-request-relationship-spec-association.core';
import {RelationshipStoreRelationshipSpecAssociationCore} from './entities/bv-relationship-store-relationship-spec-association/relationship-store-relationship-spec-association.core';
import {RelationshipResponseRelationshipSpecAssociationCore} from './entities/bv-relationship-response-relationship-spec-association/relationship-response-relationship-spec-association.core';
import {EntityWidgetAssociationCore} from './entities/bv-entity-widget-association/entity-widget-association.core';
import {WidgetCore} from './entities/bv-widget/widget.core';
import {WidgetWidgetAttributeSpecAssociationCore} from './entities/bv-widget-widget-attribute-spec-association/widget-widget-attribute-spec-association.core';
import {WidgetWidgetRelationshipSpecAssociationCore} from './entities/bv-widget-widget-relationship-spec-association/widget-widget-relationship-spec-association.core';
import {WidgetAttributeSpecCore} from './entities/bv-widget-attribute-spec/widget-attribute-spec.core';
import {WidgetRelationshipSpecCore} from './entities/bv-widget-relationship-spec/widget-relationship-spec.core';
import {EntityRequestAssociationCore} from './entities/bv-entity-request-association/entity-request-association.core';
import {RequestCore} from './entities/bv-request/request.core';
import {RequestRequestAttributeSpecAssociationCore} from './entities/bv-request-request-attribute-spec-association/request-request-attribute-spec-association.core';
import {RequestRequestRelationshipSpecAssociationCore} from './entities/bv-request-request-relationship-spec-association/request-request-relationship-spec-association.core';
import {RequestAttributeSpecCore} from './entities/bv-request-attribute-spec/request-attribute-spec.core';
import {RequestRelationshipSpecCore} from './entities/bv-request-relationship-spec/request-relationship-spec.core';
import {EntityResponseAssociationCore} from './entities/bv-entity-response-association/entity-response-association.core';
import {ResponseCore} from './entities/bv-response/response.core';
import {ResponseResponseAttributeSpecAssociationCore} from './entities/bv-response-response-attribute-spec-association/response-response-attribute-spec-association.core';
import {ResponseResponseRelationshipSpecAssociationCore} from './entities/bv-response-response-relationship-spec-association/response-response-relationship-spec-association.core';
import {ResponseAttributeSpecCore} from './entities/bv-response-attribute-spec/response-attribute-spec.core';
import {ResponseRelationshipSpecCore} from './entities/bv-response-relationship-spec/response-relationship-spec.core';
import {EntityStoreAssociationCore} from './entities/bv-entity-store-association/entity-store-association.core';
import {StoreCore} from './entities/bv-store/store.core';
import {StoreStoreAttributeSpecAssociationCore} from './entities/bv-store-store-attribute-spec-association/store-store-attribute-spec-association.core';
import {StoreStoreRelationshipSpecAssociationCore} from './entities/bv-store-store-relationship-spec-association/store-store-relationship-spec-association.core';
import {StoreAttributeSpecCore} from './entities/bv-store-attribute-spec/store-attribute-spec.core';
import {StoreRelationshipSpecCore} from './entities/bv-store-relationship-spec/store-relationship-spec.core';
import {MediaCore} from './entities/bv-media/media.core';
import {AttachmentCore} from './entities/bv-attachment/attachment.core';
import {OrganizationCore} from './entities/bv-organization/organization.core';
import {ProductCore} from './entities/bv-product/product.core';
import {PriceCore} from './entities/bv-price/price.core';
import {InvoiceCore} from './entities/bv-invoice/invoice.core';
import {InvoiceLineCore} from './entities/bv-invoice-line/invoice-line.core';
import {PaymentIntentCore} from './entities/bv-payment-intent/payment-intent.core';
import {SubscriptionCore} from './entities/bv-subscription/subscription.core';
import {ConstraintCore} from './entities/bv-constraint/constraint.core';
import {AppliedConstraintCore} from './entities/bv-applied-constraint/applied-constraint.core';
import {UserCore} from './entities/bv-user/user.core';
import {OrganizationInviteCore} from './entities/bv-organization-invite/organization-invite.core';
import {RoleGrantCore} from './entities/bv-role-grant/role-grant.core';
import {CertificateCore} from './entities/bv-certificate/certificate.core';
import {IdentityCore} from './entities/bv-identity/identity.core';
import {TokenCore} from './entities/bv-token/token.core';
import {RoleCore} from './entities/bv-role/role.core';
import {ViewCore} from './entities/bv-view/view.core';
import {ViewGrantCore} from './entities/bv-view-grant/view-grant.core';
import {HelloCore} from './entities/bv-hello/hello.core';
import {SesMessageCore} from './entities/bv-ses-message/ses-message.core';
import {IngestCore} from './entities/bv-ingest/ingest.core';


export const entityCoreMap = {
  app: AppCore,
  branch: BranchCore,
  branchCommitAssociation: BranchCommitAssociationCore,
  commit: CommitCore,
  commitEntityAssociation: CommitEntityAssociationCore,
  commitRelationshipLinkAssociation: CommitRelationshipLinkAssociationCore,
  commitCodableAssociation: CommitCodableAssociationCore,
  entity: EntityCore,
  entityChildrenAssociation: EntityChildrenAssociationCore,
  relationshipLink: RelationshipLinkCore,
  codable: CodableCore,
  enumerator: EnumeratorCore,
  enumeratorEnumeralAssociation: EnumeratorEnumeralAssociationCore,
  enumeral: EnumeralCore,
  entityAttributeAssociation: EntityAttributeAssociationCore,
  entityRelationshipAssociation: EntityRelationshipAssociationCore,
  attribute: AttributeCore,
  attributeCodableAssociation: AttributeCodableAssociationCore,
  attributeWidgetAttributeSpecAssociation: AttributeWidgetAttributeSpecAssociationCore,
  attributeRequestAttributeSpecAssociation: AttributeRequestAttributeSpecAssociationCore,
  attributeStoreAttributeSpecAssociation: AttributeStoreAttributeSpecAssociationCore,
  attributeResponseAttributeSpecAssociation: AttributeResponseAttributeSpecAssociationCore,
  relationship: RelationshipCore,
  relationshipWidgetRelationshipSpecAssociation: RelationshipWidgetRelationshipSpecAssociationCore,
  relationshipRequestRelationshipSpecAssociation: RelationshipRequestRelationshipSpecAssociationCore,
  relationshipStoreRelationshipSpecAssociation: RelationshipStoreRelationshipSpecAssociationCore,
  relationshipResponseRelationshipSpecAssociation: RelationshipResponseRelationshipSpecAssociationCore,
  entityWidgetAssociation: EntityWidgetAssociationCore,
  widget: WidgetCore,
  widgetWidgetAttributeSpecAssociation: WidgetWidgetAttributeSpecAssociationCore,
  widgetWidgetRelationshipSpecAssociation: WidgetWidgetRelationshipSpecAssociationCore,
  widgetAttributeSpec: WidgetAttributeSpecCore,
  widgetRelationshipSpec: WidgetRelationshipSpecCore,
  entityRequestAssociation: EntityRequestAssociationCore,
  request: RequestCore,
  requestRequestAttributeSpecAssociation: RequestRequestAttributeSpecAssociationCore,
  requestRequestRelationshipSpecAssociation: RequestRequestRelationshipSpecAssociationCore,
  requestAttributeSpec: RequestAttributeSpecCore,
  requestRelationshipSpec: RequestRelationshipSpecCore,
  entityResponseAssociation: EntityResponseAssociationCore,
  response: ResponseCore,
  responseResponseAttributeSpecAssociation: ResponseResponseAttributeSpecAssociationCore,
  responseResponseRelationshipSpecAssociation: ResponseResponseRelationshipSpecAssociationCore,
  responseAttributeSpec: ResponseAttributeSpecCore,
  responseRelationshipSpec: ResponseRelationshipSpecCore,
  entityStoreAssociation: EntityStoreAssociationCore,
  store: StoreCore,
  storeStoreAttributeSpecAssociation: StoreStoreAttributeSpecAssociationCore,
  storeStoreRelationshipSpecAssociation: StoreStoreRelationshipSpecAssociationCore,
  storeAttributeSpec: StoreAttributeSpecCore,
  storeRelationshipSpec: StoreRelationshipSpecCore,
  media: MediaCore,
  attachment: AttachmentCore,
  organization: OrganizationCore,
  product: ProductCore,
  price: PriceCore,
  invoice: InvoiceCore,
  invoiceLine: InvoiceLineCore,
  paymentIntent: PaymentIntentCore,
  subscription: SubscriptionCore,
  constraint: ConstraintCore,
  appliedConstraint: AppliedConstraintCore,
  user: UserCore,
  organizationInvite: OrganizationInviteCore,
  roleGrant: RoleGrantCore,
  certificate: CertificateCore,
  identity: IdentityCore,
  token: TokenCore,
  role: RoleCore,
  view: ViewCore,
  viewGrant: ViewGrantCore,
  hello: HelloCore,
  sesMessage: SesMessageCore,
  ingest: IngestCore,
};

export const entityCoreNameMap = {
  AppCore: AppCore,
  BranchCore: BranchCore,
  BranchCommitAssociationCore: BranchCommitAssociationCore,
  CommitCore: CommitCore,
  CommitEntityAssociationCore: CommitEntityAssociationCore,
  CommitRelationshipLinkAssociationCore: CommitRelationshipLinkAssociationCore,
  CommitCodableAssociationCore: CommitCodableAssociationCore,
  EntityCore: EntityCore,
  EntityChildrenAssociationCore: EntityChildrenAssociationCore,
  RelationshipLinkCore: RelationshipLinkCore,
  CodableCore: CodableCore,
  EnumeratorCore: EnumeratorCore,
  EnumeratorEnumeralAssociationCore: EnumeratorEnumeralAssociationCore,
  EnumeralCore: EnumeralCore,
  EntityAttributeAssociationCore: EntityAttributeAssociationCore,
  EntityRelationshipAssociationCore: EntityRelationshipAssociationCore,
  AttributeCore: AttributeCore,
  AttributeCodableAssociationCore: AttributeCodableAssociationCore,
  AttributeWidgetAttributeSpecAssociationCore: AttributeWidgetAttributeSpecAssociationCore,
  AttributeRequestAttributeSpecAssociationCore: AttributeRequestAttributeSpecAssociationCore,
  AttributeStoreAttributeSpecAssociationCore: AttributeStoreAttributeSpecAssociationCore,
  AttributeResponseAttributeSpecAssociationCore: AttributeResponseAttributeSpecAssociationCore,
  RelationshipCore: RelationshipCore,
  RelationshipWidgetRelationshipSpecAssociationCore: RelationshipWidgetRelationshipSpecAssociationCore,
  RelationshipRequestRelationshipSpecAssociationCore: RelationshipRequestRelationshipSpecAssociationCore,
  RelationshipStoreRelationshipSpecAssociationCore: RelationshipStoreRelationshipSpecAssociationCore,
  RelationshipResponseRelationshipSpecAssociationCore: RelationshipResponseRelationshipSpecAssociationCore,
  EntityWidgetAssociationCore: EntityWidgetAssociationCore,
  WidgetCore: WidgetCore,
  WidgetWidgetAttributeSpecAssociationCore: WidgetWidgetAttributeSpecAssociationCore,
  WidgetWidgetRelationshipSpecAssociationCore: WidgetWidgetRelationshipSpecAssociationCore,
  WidgetAttributeSpecCore: WidgetAttributeSpecCore,
  WidgetRelationshipSpecCore: WidgetRelationshipSpecCore,
  EntityRequestAssociationCore: EntityRequestAssociationCore,
  RequestCore: RequestCore,
  RequestRequestAttributeSpecAssociationCore: RequestRequestAttributeSpecAssociationCore,
  RequestRequestRelationshipSpecAssociationCore: RequestRequestRelationshipSpecAssociationCore,
  RequestAttributeSpecCore: RequestAttributeSpecCore,
  RequestRelationshipSpecCore: RequestRelationshipSpecCore,
  EntityResponseAssociationCore: EntityResponseAssociationCore,
  ResponseCore: ResponseCore,
  ResponseResponseAttributeSpecAssociationCore: ResponseResponseAttributeSpecAssociationCore,
  ResponseResponseRelationshipSpecAssociationCore: ResponseResponseRelationshipSpecAssociationCore,
  ResponseAttributeSpecCore: ResponseAttributeSpecCore,
  ResponseRelationshipSpecCore: ResponseRelationshipSpecCore,
  EntityStoreAssociationCore: EntityStoreAssociationCore,
  StoreCore: StoreCore,
  StoreStoreAttributeSpecAssociationCore: StoreStoreAttributeSpecAssociationCore,
  StoreStoreRelationshipSpecAssociationCore: StoreStoreRelationshipSpecAssociationCore,
  StoreAttributeSpecCore: StoreAttributeSpecCore,
  StoreRelationshipSpecCore: StoreRelationshipSpecCore,
  MediaCore: MediaCore,
  AttachmentCore: AttachmentCore,
  OrganizationCore: OrganizationCore,
  ProductCore: ProductCore,
  PriceCore: PriceCore,
  InvoiceCore: InvoiceCore,
  InvoiceLineCore: InvoiceLineCore,
  PaymentIntentCore: PaymentIntentCore,
  SubscriptionCore: SubscriptionCore,
  ConstraintCore: ConstraintCore,
  AppliedConstraintCore: AppliedConstraintCore,
  UserCore: UserCore,
  OrganizationInviteCore: OrganizationInviteCore,
  RoleGrantCore: RoleGrantCore,
  CertificateCore: CertificateCore,
  IdentityCore: IdentityCore,
  TokenCore: TokenCore,
  RoleCore: RoleCore,
  ViewCore: ViewCore,
  ViewGrantCore: ViewGrantCore,
  HelloCore: HelloCore,
  SesMessageCore: SesMessageCore,
  IngestCore: IngestCore,
};