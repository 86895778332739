import {SOPutStoreStoreAttributeSpecAssociation} from '../../core/store-store-attribute-spec-association/so-put-store-store-attribute-spec-association';
import {StoreStoreAttributeSpecAssociationCore} from './store-store-attribute-spec-association.core';
import {SOGetStoreStoreAttributeSpecAssociation} from '../../core/store-store-attribute-spec-association/so-get-store-store-attribute-spec-association';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putStoreStoreAttributeSpecAssociationFormFieldOptions} from './put-store-store-attribute-spec-association-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {StoreCore} from '../bv-store/store.core';
import {SOGetStore} from '../../core/store/so-get-store';
import {StoreAttributeSpecCore} from '../bv-store-attribute-spec/store-attribute-spec.core';
import {SOGetStoreAttributeSpec} from '../../core/store-attribute-spec/so-get-store-attribute-spec';

export class RAPutStoreStoreAttributeSpecAssociation extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putStoreStoreAttributeSpecAssociationFormFieldOptions);

  RequestClass = SOPutStoreStoreAttributeSpecAssociation

  constructor(
    public entityCore: StoreStoreAttributeSpecAssociationCore
  ) {
    super();
    if (putStoreStoreAttributeSpecAssociationFormFieldOptions.store) {
      putStoreStoreAttributeSpecAssociationFormFieldOptions.store.makeCore = true;
    }
    if (putStoreStoreAttributeSpecAssociationFormFieldOptions.storeAttributeSpec) {
      putStoreStoreAttributeSpecAssociationFormFieldOptions.storeAttributeSpec.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier(
        'store',
        SOGetStore,
      ),
      this.getFormValueFromIdentifier(
        'storeAttributeSpec',
        SOGetStoreAttributeSpec,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}