import { Type } from 'class-transformer';
import { SORespRole } from '../role/so-resp-role';
import { SORespView } from '../view/so-resp-view';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespViewGrant implements ObjectiveProtocol {

  entityType: string = 'viewGrant';
  uid: string;
  @Type(() => SORespRole)
  role: SORespRole;
  @Type(() => SORespView)
  view: SORespView;
}