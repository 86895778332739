import { SOGetWidgetWidgetAttributeSpecAssociation } from '../widget-widget-attribute-spec-association/so-get-widget-widget-attribute-spec-association';
import { SOGetAttributeWidgetAttributeSpecAssociation } from '../attribute-widget-attribute-spec-association/so-get-attribute-widget-attribute-spec-association';
import { SOGetOrganization } from '../organization/so-get-organization';
import { SOGetApp } from '../app/so-get-app';
import {MMQueryOptions} from 'ngx-lionheart-material';
import {QueryProtocol} from 'ngx-lionheart-material';


export class SOGetWidgetAttributeSpec implements QueryProtocol {

  constructor(
    public uid: string = null,
    public widget: SOGetWidgetWidgetAttributeSpecAssociation[] = null,
    public attribute: SOGetAttributeWidgetAttributeSpecAssociation[] = null,
    public organization: SOGetOrganization = null,
    public app: SOGetApp = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetWidgetAttributeSpec(
      'uid' in params ? params.uid : null,
      'widget' in params ? params.widget : null,
      'attribute' in params ? params.attribute : null,
      'organization' in params ? params.organization : null,
      'app' in params ? params.app : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
