import { SOGetEntityResponseAssociation } from '../entity-response-association/so-get-entity-response-association';
import { SOGetOrganization } from '../organization/so-get-organization';
import { SOGetApp } from '../app/so-get-app';
import {ENResponseType} from '../../enums';


export class SOPostResponse {

  constructor(
    public responseType: ENResponseType,
    public entity: SOGetEntityResponseAssociation[],
    public organization: SOGetOrganization,
    public app: SOGetApp,
    public name: string = "",
    public isDefault: boolean = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostResponse(
      'name' in params ? params.name : null,
      'responseType' in params ? params.responseType : null,
      'isDefault' in params ? params.isDefault : null,
      'entity' in params ? params.entity : null,
      'organization' in params ? params.organization : null,
      'app' in params ? params.app : null,
    );
  }
}
