import {SORespCommit} from '../../core/commit/so-resp-commit';
import {CommitCore} from './commit.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putCommitFormFieldOptions} from './put-commit-form-fields';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {SOGetBranchCommitAssociation} from '../../core/branch-commit-association/so-get-branch-commit-association';
import {SOGetCommitEntityAssociation} from '../../core/commit-entity-association/so-get-commit-entity-association';
import {SOGetCommitRelationshipLinkAssociation} from '../../core/commit-relationship-link-association/so-get-commit-relationship-link-association';
import {SOGetCommitCodableAssociation} from '../../core/commit-codable-association/so-get-commit-codable-association';
import {SOGetEntityChildrenAssociation} from '../../core/entity-children-association/so-get-entity-children-association';
import {SOGetEnumeratorEnumeralAssociation} from '../../core/enumerator-enumeral-association/so-get-enumerator-enumeral-association';
import {SOGetEntityAttributeAssociation} from '../../core/entity-attribute-association/so-get-entity-attribute-association';
import {SOGetAttributeCodableAssociation} from '../../core/attribute-codable-association/so-get-attribute-codable-association';
import {SOGetEntityRelationshipAssociation} from '../../core/entity-relationship-association/so-get-entity-relationship-association';
import {SOGetEntityWidgetAssociation} from '../../core/entity-widget-association/so-get-entity-widget-association';
import {SOGetEntityRequestAssociation} from '../../core/entity-request-association/so-get-entity-request-association';
import {SOGetEntityResponseAssociation} from '../../core/entity-response-association/so-get-entity-response-association';
import {SOGetEntityStoreAssociation} from '../../core/entity-store-association/so-get-entity-store-association';
import {SOGetWidgetWidgetAttributeSpecAssociation} from '../../core/widget-widget-attribute-spec-association/so-get-widget-widget-attribute-spec-association';
import {SOGetWidgetWidgetRelationshipSpecAssociation} from '../../core/widget-widget-relationship-spec-association/so-get-widget-widget-relationship-spec-association';
import {SOGetRequestRequestAttributeSpecAssociation} from '../../core/request-request-attribute-spec-association/so-get-request-request-attribute-spec-association';
import {SOGetRequestRequestRelationshipSpecAssociation} from '../../core/request-request-relationship-spec-association/so-get-request-request-relationship-spec-association';
import {SOGetResponseResponseAttributeSpecAssociation} from '../../core/response-response-attribute-spec-association/so-get-response-response-attribute-spec-association';
import {SOGetResponseResponseRelationshipSpecAssociation} from '../../core/response-response-relationship-spec-association/so-get-response-response-relationship-spec-association';
import {SOGetStoreStoreAttributeSpecAssociation} from '../../core/store-store-attribute-spec-association/so-get-store-store-attribute-spec-association';
import {SOGetStoreStoreRelationshipSpecAssociation} from '../../core/store-store-relationship-spec-association/so-get-store-store-relationship-spec-association';
import {SOGetAttributeRequestAttributeSpecAssociation} from '../../core/attribute-request-attribute-spec-association/so-get-attribute-request-attribute-spec-association';
import {SOGetAttributeResponseAttributeSpecAssociation} from '../../core/attribute-response-attribute-spec-association/so-get-attribute-response-attribute-spec-association';
import {SOGetAttributeStoreAttributeSpecAssociation} from '../../core/attribute-store-attribute-spec-association/so-get-attribute-store-attribute-spec-association';
import {SOGetAttributeWidgetAttributeSpecAssociation} from '../../core/attribute-widget-attribute-spec-association/so-get-attribute-widget-attribute-spec-association';
import {SOGetRelationshipRequestRelationshipSpecAssociation} from '../../core/relationship-request-relationship-spec-association/so-get-relationship-request-relationship-spec-association';
import {SOGetRelationshipResponseRelationshipSpecAssociation} from '../../core/relationship-response-relationship-spec-association/so-get-relationship-response-relationship-spec-association';
import {SOGetRelationshipStoreRelationshipSpecAssociation} from '../../core/relationship-store-relationship-spec-association/so-get-relationship-store-relationship-spec-association';
import {SOGetRelationshipWidgetRelationshipSpecAssociation} from '../../core/relationship-widget-relationship-spec-association/so-get-relationship-widget-relationship-spec-association';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CommitDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespCommit = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const descriptionDetail = new DetailEntry(
        'Description',
        entity.description,
        'description',
        BaseDetailType.text,
        null,
        EditableType.always,
        putCommitFormFieldOptions.description
    )
    attributes.push(descriptionDetail)

    const dateCreatedDetail = new DetailEntry(
        'Date created',
        activeUserService.time.niceDate(entity.dateCreated),
        'dateCreated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateCreatedDetail)


    const branchCommitAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'branchCommitAssociation');
    branchCommitAssociationsCore.soGet = SOGetBranchCommitAssociation.construct({
      commit: new SOGetCommit(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, branchCommitAssociationsCore)
    branchCommitAssociationsCore.title = heading ? heading : branchCommitAssociationsCore.title
    branchCommitAssociationsCore.listTitle = 'Branch commit associations'

    branchCommitAssociationsCore.configureForDisplay(entityViewSpec)

    const branchCommitAssociationsDetail = new DetailEntry(
      heading ? heading : branchCommitAssociationsCore.listTitle,
      null,
      'branchCommitAssociations',
      BaseDetailType.embeddedList,
      branchCommitAssociationsCore,
      EditableType.never
    )
    attributes.push(branchCommitAssociationsDetail);

    const commitEntityAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'commitEntityAssociation');
    commitEntityAssociationsCore.soGet = SOGetCommitEntityAssociation.construct({
      commit: new SOGetCommit(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, commitEntityAssociationsCore)
    commitEntityAssociationsCore.title = heading ? heading : commitEntityAssociationsCore.title
    commitEntityAssociationsCore.listTitle = 'Commit entity associations'

    commitEntityAssociationsCore.configureForDisplay(entityViewSpec)

    const commitEntityAssociationsDetail = new DetailEntry(
      heading ? heading : commitEntityAssociationsCore.listTitle,
      null,
      'commitEntityAssociations',
      BaseDetailType.embeddedList,
      commitEntityAssociationsCore,
      EditableType.never
    )
    attributes.push(commitEntityAssociationsDetail);

    const commitRelationshipLinkAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'commitRelationshipLinkAssociation');
    commitRelationshipLinkAssociationsCore.soGet = SOGetCommitRelationshipLinkAssociation.construct({
      commit: new SOGetCommit(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, commitRelationshipLinkAssociationsCore)
    commitRelationshipLinkAssociationsCore.title = heading ? heading : commitRelationshipLinkAssociationsCore.title
    commitRelationshipLinkAssociationsCore.listTitle = 'Commit relationship link associations'

    commitRelationshipLinkAssociationsCore.configureForDisplay(entityViewSpec)

    const commitRelationshipLinkAssociationsDetail = new DetailEntry(
      heading ? heading : commitRelationshipLinkAssociationsCore.listTitle,
      null,
      'commitRelationshipLinkAssociations',
      BaseDetailType.embeddedList,
      commitRelationshipLinkAssociationsCore,
      EditableType.never
    )
    attributes.push(commitRelationshipLinkAssociationsDetail);

    const commitCodabeAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'commitCodableAssociation');
    commitCodabeAssociationsCore.soGet = SOGetCommitCodableAssociation.construct({
      commit: new SOGetCommit(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, commitCodabeAssociationsCore)
    commitCodabeAssociationsCore.title = heading ? heading : commitCodabeAssociationsCore.title
    commitCodabeAssociationsCore.listTitle = 'Commit codabe associations'

    commitCodabeAssociationsCore.configureForDisplay(entityViewSpec)

    const commitCodabeAssociationsDetail = new DetailEntry(
      heading ? heading : commitCodabeAssociationsCore.listTitle,
      null,
      'commitCodabeAssociations',
      BaseDetailType.embeddedList,
      commitCodabeAssociationsCore,
      EditableType.never
    )
    attributes.push(commitCodabeAssociationsDetail);

    const entityParentChildAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'entityChildrenAssociation');
    entityParentChildAssociationsCore.soGet = SOGetEntityChildrenAssociation.construct({
      commit: new SOGetCommit(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, entityParentChildAssociationsCore)
    entityParentChildAssociationsCore.title = heading ? heading : entityParentChildAssociationsCore.title
    entityParentChildAssociationsCore.listTitle = 'Entity parent child associations'

    entityParentChildAssociationsCore.configureForDisplay(entityViewSpec)

    const entityParentChildAssociationsDetail = new DetailEntry(
      heading ? heading : entityParentChildAssociationsCore.listTitle,
      null,
      'entityParentChildAssociations',
      BaseDetailType.embeddedList,
      entityParentChildAssociationsCore,
      EditableType.never
    )
    attributes.push(entityParentChildAssociationsDetail);

    const enumeratorEnumeralAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'enumeratorEnumeralAssociation');
    enumeratorEnumeralAssociationsCore.soGet = SOGetEnumeratorEnumeralAssociation.construct({
      commit: new SOGetCommit(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, enumeratorEnumeralAssociationsCore)
    enumeratorEnumeralAssociationsCore.title = heading ? heading : enumeratorEnumeralAssociationsCore.title
    enumeratorEnumeralAssociationsCore.listTitle = 'Enumerator enumeral associations'

    enumeratorEnumeralAssociationsCore.configureForDisplay(entityViewSpec)

    const enumeratorEnumeralAssociationsDetail = new DetailEntry(
      heading ? heading : enumeratorEnumeralAssociationsCore.listTitle,
      null,
      'enumeratorEnumeralAssociations',
      BaseDetailType.embeddedList,
      enumeratorEnumeralAssociationsCore,
      EditableType.never
    )
    attributes.push(enumeratorEnumeralAssociationsDetail);

    const entityAttributeAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'entityAttributeAssociation');
    entityAttributeAssociationsCore.soGet = SOGetEntityAttributeAssociation.construct({
      commit: new SOGetCommit(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, entityAttributeAssociationsCore)
    entityAttributeAssociationsCore.title = heading ? heading : entityAttributeAssociationsCore.title
    entityAttributeAssociationsCore.listTitle = 'Entity attribute associations'

    entityAttributeAssociationsCore.configureForDisplay(entityViewSpec)

    const entityAttributeAssociationsDetail = new DetailEntry(
      heading ? heading : entityAttributeAssociationsCore.listTitle,
      null,
      'entityAttributeAssociations',
      BaseDetailType.embeddedList,
      entityAttributeAssociationsCore,
      EditableType.never
    )
    attributes.push(entityAttributeAssociationsDetail);

    const attributeCodableAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'attributeCodableAssociation');
    attributeCodableAssociationsCore.soGet = SOGetAttributeCodableAssociation.construct({
      commit: new SOGetCommit(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, attributeCodableAssociationsCore)
    attributeCodableAssociationsCore.title = heading ? heading : attributeCodableAssociationsCore.title
    attributeCodableAssociationsCore.listTitle = 'Attribute codable associations'

    attributeCodableAssociationsCore.configureForDisplay(entityViewSpec)

    const attributeCodableAssociationsDetail = new DetailEntry(
      heading ? heading : attributeCodableAssociationsCore.listTitle,
      null,
      'attributeCodableAssociations',
      BaseDetailType.embeddedList,
      attributeCodableAssociationsCore,
      EditableType.never
    )
    attributes.push(attributeCodableAssociationsDetail);

    const entityRelationshipAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'entityRelationshipAssociation');
    entityRelationshipAssociationsCore.soGet = SOGetEntityRelationshipAssociation.construct({
      commit: new SOGetCommit(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, entityRelationshipAssociationsCore)
    entityRelationshipAssociationsCore.title = heading ? heading : entityRelationshipAssociationsCore.title
    entityRelationshipAssociationsCore.listTitle = 'Entity relationship associations'

    entityRelationshipAssociationsCore.configureForDisplay(entityViewSpec)

    const entityRelationshipAssociationsDetail = new DetailEntry(
      heading ? heading : entityRelationshipAssociationsCore.listTitle,
      null,
      'entityRelationshipAssociations',
      BaseDetailType.embeddedList,
      entityRelationshipAssociationsCore,
      EditableType.never
    )
    attributes.push(entityRelationshipAssociationsDetail);

    const entityWidgetAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'entityWidgetAssociation');
    entityWidgetAssociationsCore.soGet = SOGetEntityWidgetAssociation.construct({
      commit: new SOGetCommit(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, entityWidgetAssociationsCore)
    entityWidgetAssociationsCore.title = heading ? heading : entityWidgetAssociationsCore.title
    entityWidgetAssociationsCore.listTitle = 'Entity widget associations'

    entityWidgetAssociationsCore.configureForDisplay(entityViewSpec)

    const entityWidgetAssociationsDetail = new DetailEntry(
      heading ? heading : entityWidgetAssociationsCore.listTitle,
      null,
      'entityWidgetAssociations',
      BaseDetailType.embeddedList,
      entityWidgetAssociationsCore,
      EditableType.never
    )
    attributes.push(entityWidgetAssociationsDetail);

    const entityRequestAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'entityRequestAssociation');
    entityRequestAssociationsCore.soGet = SOGetEntityRequestAssociation.construct({
      commit: new SOGetCommit(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, entityRequestAssociationsCore)
    entityRequestAssociationsCore.title = heading ? heading : entityRequestAssociationsCore.title
    entityRequestAssociationsCore.listTitle = 'Entity request associations'

    entityRequestAssociationsCore.configureForDisplay(entityViewSpec)

    const entityRequestAssociationsDetail = new DetailEntry(
      heading ? heading : entityRequestAssociationsCore.listTitle,
      null,
      'entityRequestAssociations',
      BaseDetailType.embeddedList,
      entityRequestAssociationsCore,
      EditableType.never
    )
    attributes.push(entityRequestAssociationsDetail);

    const entityResponseAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'entityResponseAssociation');
    entityResponseAssociationsCore.soGet = SOGetEntityResponseAssociation.construct({
      commit: new SOGetCommit(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, entityResponseAssociationsCore)
    entityResponseAssociationsCore.title = heading ? heading : entityResponseAssociationsCore.title
    entityResponseAssociationsCore.listTitle = 'Entity response associations'

    entityResponseAssociationsCore.configureForDisplay(entityViewSpec)

    const entityResponseAssociationsDetail = new DetailEntry(
      heading ? heading : entityResponseAssociationsCore.listTitle,
      null,
      'entityResponseAssociations',
      BaseDetailType.embeddedList,
      entityResponseAssociationsCore,
      EditableType.never
    )
    attributes.push(entityResponseAssociationsDetail);

    const entityStoreAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'entityStoreAssociation');
    entityStoreAssociationsCore.soGet = SOGetEntityStoreAssociation.construct({
      commit: new SOGetCommit(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, entityStoreAssociationsCore)
    entityStoreAssociationsCore.title = heading ? heading : entityStoreAssociationsCore.title
    entityStoreAssociationsCore.listTitle = 'Entity store associations'

    entityStoreAssociationsCore.configureForDisplay(entityViewSpec)

    const entityStoreAssociationsDetail = new DetailEntry(
      heading ? heading : entityStoreAssociationsCore.listTitle,
      null,
      'entityStoreAssociations',
      BaseDetailType.embeddedList,
      entityStoreAssociationsCore,
      EditableType.never
    )
    attributes.push(entityStoreAssociationsDetail);

    const widgetWidgetAttributeSpecAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'widgetWidgetAttributeSpecAssociation');
    widgetWidgetAttributeSpecAssociationsCore.soGet = SOGetWidgetWidgetAttributeSpecAssociation.construct({
      commit: new SOGetCommit(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, widgetWidgetAttributeSpecAssociationsCore)
    widgetWidgetAttributeSpecAssociationsCore.title = heading ? heading : widgetWidgetAttributeSpecAssociationsCore.title
    widgetWidgetAttributeSpecAssociationsCore.listTitle = 'Widget widget attribute spec associations'

    widgetWidgetAttributeSpecAssociationsCore.configureForDisplay(entityViewSpec)

    const widgetWidgetAttributeSpecAssociationsDetail = new DetailEntry(
      heading ? heading : widgetWidgetAttributeSpecAssociationsCore.listTitle,
      null,
      'widgetWidgetAttributeSpecAssociations',
      BaseDetailType.embeddedList,
      widgetWidgetAttributeSpecAssociationsCore,
      EditableType.never
    )
    attributes.push(widgetWidgetAttributeSpecAssociationsDetail);

    const widgetWidgetRelationshipSpecAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'widgetWidgetRelationshipSpecAssociation');
    widgetWidgetRelationshipSpecAssociationsCore.soGet = SOGetWidgetWidgetRelationshipSpecAssociation.construct({
      commit: new SOGetCommit(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, widgetWidgetRelationshipSpecAssociationsCore)
    widgetWidgetRelationshipSpecAssociationsCore.title = heading ? heading : widgetWidgetRelationshipSpecAssociationsCore.title
    widgetWidgetRelationshipSpecAssociationsCore.listTitle = 'Widget widget relationship spec associations'

    widgetWidgetRelationshipSpecAssociationsCore.configureForDisplay(entityViewSpec)

    const widgetWidgetRelationshipSpecAssociationsDetail = new DetailEntry(
      heading ? heading : widgetWidgetRelationshipSpecAssociationsCore.listTitle,
      null,
      'widgetWidgetRelationshipSpecAssociations',
      BaseDetailType.embeddedList,
      widgetWidgetRelationshipSpecAssociationsCore,
      EditableType.never
    )
    attributes.push(widgetWidgetRelationshipSpecAssociationsDetail);

    const requestRequestAttributeSpecAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'requestRequestAttributeSpecAssociation');
    requestRequestAttributeSpecAssociationsCore.soGet = SOGetRequestRequestAttributeSpecAssociation.construct({
      commit: new SOGetCommit(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, requestRequestAttributeSpecAssociationsCore)
    requestRequestAttributeSpecAssociationsCore.title = heading ? heading : requestRequestAttributeSpecAssociationsCore.title
    requestRequestAttributeSpecAssociationsCore.listTitle = 'Request request attribute spec associations'

    requestRequestAttributeSpecAssociationsCore.configureForDisplay(entityViewSpec)

    const requestRequestAttributeSpecAssociationsDetail = new DetailEntry(
      heading ? heading : requestRequestAttributeSpecAssociationsCore.listTitle,
      null,
      'requestRequestAttributeSpecAssociations',
      BaseDetailType.embeddedList,
      requestRequestAttributeSpecAssociationsCore,
      EditableType.never
    )
    attributes.push(requestRequestAttributeSpecAssociationsDetail);

    const requestRequestRelationshipSpecAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'requestRequestRelationshipSpecAssociation');
    requestRequestRelationshipSpecAssociationsCore.soGet = SOGetRequestRequestRelationshipSpecAssociation.construct({
      commit: new SOGetCommit(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, requestRequestRelationshipSpecAssociationsCore)
    requestRequestRelationshipSpecAssociationsCore.title = heading ? heading : requestRequestRelationshipSpecAssociationsCore.title
    requestRequestRelationshipSpecAssociationsCore.listTitle = 'Request request relationship spec associations'

    requestRequestRelationshipSpecAssociationsCore.configureForDisplay(entityViewSpec)

    const requestRequestRelationshipSpecAssociationsDetail = new DetailEntry(
      heading ? heading : requestRequestRelationshipSpecAssociationsCore.listTitle,
      null,
      'requestRequestRelationshipSpecAssociations',
      BaseDetailType.embeddedList,
      requestRequestRelationshipSpecAssociationsCore,
      EditableType.never
    )
    attributes.push(requestRequestRelationshipSpecAssociationsDetail);

    const responseResponseAttributeSpecAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'responseResponseAttributeSpecAssociation');
    responseResponseAttributeSpecAssociationsCore.soGet = SOGetResponseResponseAttributeSpecAssociation.construct({
      commit: new SOGetCommit(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, responseResponseAttributeSpecAssociationsCore)
    responseResponseAttributeSpecAssociationsCore.title = heading ? heading : responseResponseAttributeSpecAssociationsCore.title
    responseResponseAttributeSpecAssociationsCore.listTitle = 'Response response attribute spec associations'

    responseResponseAttributeSpecAssociationsCore.configureForDisplay(entityViewSpec)

    const responseResponseAttributeSpecAssociationsDetail = new DetailEntry(
      heading ? heading : responseResponseAttributeSpecAssociationsCore.listTitle,
      null,
      'responseResponseAttributeSpecAssociations',
      BaseDetailType.embeddedList,
      responseResponseAttributeSpecAssociationsCore,
      EditableType.never
    )
    attributes.push(responseResponseAttributeSpecAssociationsDetail);

    const responseResponseRelationshipSpecAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'responseResponseRelationshipSpecAssociation');
    responseResponseRelationshipSpecAssociationsCore.soGet = SOGetResponseResponseRelationshipSpecAssociation.construct({
      commit: new SOGetCommit(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, responseResponseRelationshipSpecAssociationsCore)
    responseResponseRelationshipSpecAssociationsCore.title = heading ? heading : responseResponseRelationshipSpecAssociationsCore.title
    responseResponseRelationshipSpecAssociationsCore.listTitle = 'Response response relationship spec associations'

    responseResponseRelationshipSpecAssociationsCore.configureForDisplay(entityViewSpec)

    const responseResponseRelationshipSpecAssociationsDetail = new DetailEntry(
      heading ? heading : responseResponseRelationshipSpecAssociationsCore.listTitle,
      null,
      'responseResponseRelationshipSpecAssociations',
      BaseDetailType.embeddedList,
      responseResponseRelationshipSpecAssociationsCore,
      EditableType.never
    )
    attributes.push(responseResponseRelationshipSpecAssociationsDetail);

    const storeStoreAttributeSpecAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'storeStoreAttributeSpecAssociation');
    storeStoreAttributeSpecAssociationsCore.soGet = SOGetStoreStoreAttributeSpecAssociation.construct({
      commit: new SOGetCommit(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, storeStoreAttributeSpecAssociationsCore)
    storeStoreAttributeSpecAssociationsCore.title = heading ? heading : storeStoreAttributeSpecAssociationsCore.title
    storeStoreAttributeSpecAssociationsCore.listTitle = 'Store store attribute spec associations'

    storeStoreAttributeSpecAssociationsCore.configureForDisplay(entityViewSpec)

    const storeStoreAttributeSpecAssociationsDetail = new DetailEntry(
      heading ? heading : storeStoreAttributeSpecAssociationsCore.listTitle,
      null,
      'storeStoreAttributeSpecAssociations',
      BaseDetailType.embeddedList,
      storeStoreAttributeSpecAssociationsCore,
      EditableType.never
    )
    attributes.push(storeStoreAttributeSpecAssociationsDetail);

    const storeStoreRelationshipSpecAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'storeStoreRelationshipSpecAssociation');
    storeStoreRelationshipSpecAssociationsCore.soGet = SOGetStoreStoreRelationshipSpecAssociation.construct({
      commit: new SOGetCommit(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, storeStoreRelationshipSpecAssociationsCore)
    storeStoreRelationshipSpecAssociationsCore.title = heading ? heading : storeStoreRelationshipSpecAssociationsCore.title
    storeStoreRelationshipSpecAssociationsCore.listTitle = 'Store store relationship spec associations'

    storeStoreRelationshipSpecAssociationsCore.configureForDisplay(entityViewSpec)

    const storeStoreRelationshipSpecAssociationsDetail = new DetailEntry(
      heading ? heading : storeStoreRelationshipSpecAssociationsCore.listTitle,
      null,
      'storeStoreRelationshipSpecAssociations',
      BaseDetailType.embeddedList,
      storeStoreRelationshipSpecAssociationsCore,
      EditableType.never
    )
    attributes.push(storeStoreRelationshipSpecAssociationsDetail);

    const attributeRequestAttributeSpecAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'attributeRequestAttributeSpecAssociation');
    attributeRequestAttributeSpecAssociationsCore.soGet = SOGetAttributeRequestAttributeSpecAssociation.construct({
      commit: new SOGetCommit(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, attributeRequestAttributeSpecAssociationsCore)
    attributeRequestAttributeSpecAssociationsCore.title = heading ? heading : attributeRequestAttributeSpecAssociationsCore.title
    attributeRequestAttributeSpecAssociationsCore.listTitle = 'Attribute request attribute spec associations'

    attributeRequestAttributeSpecAssociationsCore.configureForDisplay(entityViewSpec)

    const attributeRequestAttributeSpecAssociationsDetail = new DetailEntry(
      heading ? heading : attributeRequestAttributeSpecAssociationsCore.listTitle,
      null,
      'attributeRequestAttributeSpecAssociations',
      BaseDetailType.embeddedList,
      attributeRequestAttributeSpecAssociationsCore,
      EditableType.never
    )
    attributes.push(attributeRequestAttributeSpecAssociationsDetail);

    const attributeResponseAttributeSpecAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'attributeResponseAttributeSpecAssociation');
    attributeResponseAttributeSpecAssociationsCore.soGet = SOGetAttributeResponseAttributeSpecAssociation.construct({
      commit: new SOGetCommit(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, attributeResponseAttributeSpecAssociationsCore)
    attributeResponseAttributeSpecAssociationsCore.title = heading ? heading : attributeResponseAttributeSpecAssociationsCore.title
    attributeResponseAttributeSpecAssociationsCore.listTitle = 'Attribute response attribute spec associations'

    attributeResponseAttributeSpecAssociationsCore.configureForDisplay(entityViewSpec)

    const attributeResponseAttributeSpecAssociationsDetail = new DetailEntry(
      heading ? heading : attributeResponseAttributeSpecAssociationsCore.listTitle,
      null,
      'attributeResponseAttributeSpecAssociations',
      BaseDetailType.embeddedList,
      attributeResponseAttributeSpecAssociationsCore,
      EditableType.never
    )
    attributes.push(attributeResponseAttributeSpecAssociationsDetail);

    const attributeStoreAttributeSpecAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'attributeStoreAttributeSpecAssociation');
    attributeStoreAttributeSpecAssociationsCore.soGet = SOGetAttributeStoreAttributeSpecAssociation.construct({
      commit: new SOGetCommit(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, attributeStoreAttributeSpecAssociationsCore)
    attributeStoreAttributeSpecAssociationsCore.title = heading ? heading : attributeStoreAttributeSpecAssociationsCore.title
    attributeStoreAttributeSpecAssociationsCore.listTitle = 'Attribute store attribute spec associations'

    attributeStoreAttributeSpecAssociationsCore.configureForDisplay(entityViewSpec)

    const attributeStoreAttributeSpecAssociationsDetail = new DetailEntry(
      heading ? heading : attributeStoreAttributeSpecAssociationsCore.listTitle,
      null,
      'attributeStoreAttributeSpecAssociations',
      BaseDetailType.embeddedList,
      attributeStoreAttributeSpecAssociationsCore,
      EditableType.never
    )
    attributes.push(attributeStoreAttributeSpecAssociationsDetail);

    const attributeWidgetAttributeSpecAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'attributeWidgetAttributeSpecAssociation');
    attributeWidgetAttributeSpecAssociationsCore.soGet = SOGetAttributeWidgetAttributeSpecAssociation.construct({
      commit: new SOGetCommit(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, attributeWidgetAttributeSpecAssociationsCore)
    attributeWidgetAttributeSpecAssociationsCore.title = heading ? heading : attributeWidgetAttributeSpecAssociationsCore.title
    attributeWidgetAttributeSpecAssociationsCore.listTitle = 'Attribute widget attribute spec associations'

    attributeWidgetAttributeSpecAssociationsCore.configureForDisplay(entityViewSpec)

    const attributeWidgetAttributeSpecAssociationsDetail = new DetailEntry(
      heading ? heading : attributeWidgetAttributeSpecAssociationsCore.listTitle,
      null,
      'attributeWidgetAttributeSpecAssociations',
      BaseDetailType.embeddedList,
      attributeWidgetAttributeSpecAssociationsCore,
      EditableType.never
    )
    attributes.push(attributeWidgetAttributeSpecAssociationsDetail);

    const relationshipRequestAttributeSpecAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'relationshipRequestRelationshipSpecAssociation');
    relationshipRequestAttributeSpecAssociationsCore.soGet = SOGetRelationshipRequestRelationshipSpecAssociation.construct({
      commit: new SOGetCommit(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, relationshipRequestAttributeSpecAssociationsCore)
    relationshipRequestAttributeSpecAssociationsCore.title = heading ? heading : relationshipRequestAttributeSpecAssociationsCore.title
    relationshipRequestAttributeSpecAssociationsCore.listTitle = 'Relationship request attribute spec associations'

    relationshipRequestAttributeSpecAssociationsCore.configureForDisplay(entityViewSpec)

    const relationshipRequestAttributeSpecAssociationsDetail = new DetailEntry(
      heading ? heading : relationshipRequestAttributeSpecAssociationsCore.listTitle,
      null,
      'relationshipRequestAttributeSpecAssociations',
      BaseDetailType.embeddedList,
      relationshipRequestAttributeSpecAssociationsCore,
      EditableType.never
    )
    attributes.push(relationshipRequestAttributeSpecAssociationsDetail);

    const relationshipResponseAttributeSpecAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'relationshipResponseRelationshipSpecAssociation');
    relationshipResponseAttributeSpecAssociationsCore.soGet = SOGetRelationshipResponseRelationshipSpecAssociation.construct({
      commit: new SOGetCommit(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, relationshipResponseAttributeSpecAssociationsCore)
    relationshipResponseAttributeSpecAssociationsCore.title = heading ? heading : relationshipResponseAttributeSpecAssociationsCore.title
    relationshipResponseAttributeSpecAssociationsCore.listTitle = 'Relationship response attribute spec associations'

    relationshipResponseAttributeSpecAssociationsCore.configureForDisplay(entityViewSpec)

    const relationshipResponseAttributeSpecAssociationsDetail = new DetailEntry(
      heading ? heading : relationshipResponseAttributeSpecAssociationsCore.listTitle,
      null,
      'relationshipResponseAttributeSpecAssociations',
      BaseDetailType.embeddedList,
      relationshipResponseAttributeSpecAssociationsCore,
      EditableType.never
    )
    attributes.push(relationshipResponseAttributeSpecAssociationsDetail);

    const relationshipStoreAttributeSpecAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'relationshipStoreRelationshipSpecAssociation');
    relationshipStoreAttributeSpecAssociationsCore.soGet = SOGetRelationshipStoreRelationshipSpecAssociation.construct({
      commit: new SOGetCommit(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, relationshipStoreAttributeSpecAssociationsCore)
    relationshipStoreAttributeSpecAssociationsCore.title = heading ? heading : relationshipStoreAttributeSpecAssociationsCore.title
    relationshipStoreAttributeSpecAssociationsCore.listTitle = 'Relationship store attribute spec associations'

    relationshipStoreAttributeSpecAssociationsCore.configureForDisplay(entityViewSpec)

    const relationshipStoreAttributeSpecAssociationsDetail = new DetailEntry(
      heading ? heading : relationshipStoreAttributeSpecAssociationsCore.listTitle,
      null,
      'relationshipStoreAttributeSpecAssociations',
      BaseDetailType.embeddedList,
      relationshipStoreAttributeSpecAssociationsCore,
      EditableType.never
    )
    attributes.push(relationshipStoreAttributeSpecAssociationsDetail);

    const relationshipWidgetAttributeSpecAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'relationshipWidgetRelationshipSpecAssociation');
    relationshipWidgetAttributeSpecAssociationsCore.soGet = SOGetRelationshipWidgetRelationshipSpecAssociation.construct({
      commit: new SOGetCommit(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, relationshipWidgetAttributeSpecAssociationsCore)
    relationshipWidgetAttributeSpecAssociationsCore.title = heading ? heading : relationshipWidgetAttributeSpecAssociationsCore.title
    relationshipWidgetAttributeSpecAssociationsCore.listTitle = 'Relationship widget attribute spec associations'

    relationshipWidgetAttributeSpecAssociationsCore.configureForDisplay(entityViewSpec)

    const relationshipWidgetAttributeSpecAssociationsDetail = new DetailEntry(
      heading ? heading : relationshipWidgetAttributeSpecAssociationsCore.listTitle,
      null,
      'relationshipWidgetAttributeSpecAssociations',
      BaseDetailType.embeddedList,
      relationshipWidgetAttributeSpecAssociationsCore,
      EditableType.never
    )
    attributes.push(relationshipWidgetAttributeSpecAssociationsDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
