import {SOPutStoreRelationshipSpec} from '../../core/store-relationship-spec/so-put-store-relationship-spec';
import {StoreRelationshipSpecCore} from './store-relationship-spec.core';
import {SOGetStoreRelationshipSpec} from '../../core/store-relationship-spec/so-get-store-relationship-spec';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putStoreRelationshipSpecFormFieldOptions} from './put-store-relationship-spec-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {StoreStoreRelationshipSpecAssociationCore} from '../bv-store-store-relationship-spec-association/store-store-relationship-spec-association.core';
import {SOGetStoreStoreRelationshipSpecAssociation} from '../../core/store-store-relationship-spec-association/so-get-store-store-relationship-spec-association';
import {RelationshipStoreRelationshipSpecAssociationCore} from '../bv-relationship-store-relationship-spec-association/relationship-store-relationship-spec-association.core';
import {SOGetRelationshipStoreRelationshipSpecAssociation} from '../../core/relationship-store-relationship-spec-association/so-get-relationship-store-relationship-spec-association';

export class RAPutStoreRelationshipSpec extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putStoreRelationshipSpecFormFieldOptions);

  RequestClass = SOPutStoreRelationshipSpec

  constructor(
    public entityCore: StoreRelationshipSpecCore
  ) {
    super();
    if (putStoreRelationshipSpecFormFieldOptions.store) {
      putStoreRelationshipSpecFormFieldOptions.store.makeCore = true;
    }
    if (putStoreRelationshipSpecFormFieldOptions.relationship) {
      putStoreRelationshipSpecFormFieldOptions.relationship.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('isRequired'),
      [this.getFormValueFromIdentifier('store', SOGetStoreStoreRelationshipSpecAssociation)],
      [this.getFormValueFromIdentifier('relationship', SOGetRelationshipStoreRelationshipSpecAssociation)],
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}