import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostWidget } from './so-post-widget';
import { SOGetWidget } from './so-get-widget';
import { SOPutWidget } from './so-put-widget';
import { SODeleteWidget } from './so-delete-widget';
import {SORespWidget} from './so-resp-widget';
import {SOPluralWidget} from './so-plural-widget';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOWidgetService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'widget',
      entityCore
    );
  }

  /** GET Widget by id. Will 404 if id not found */
  get(request: SOGetWidget): Observable<SOPluralWidget> {
    return this.httpClient.get<SOPluralWidget>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralWidget, resp) }),
      tap(_ => this.log(`fetched Widget`)),
      catchError(this.handleError<SOPluralWidget>(`get Widget`))
    );
  }

  /** POST: add a new Widget to the server */
  post(request: SOPostWidget): Observable<SORespWidget> {
    return this.httpClient.post<SORespWidget>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespWidget, resp) }),
      tap((response: SORespWidget) => this.log(`added Widget w/ id=${response.uid}`)),
      catchError(this.handleError<SORespWidget>('Widget post'))
    );
  }

  /** PUT: update Widget  */
  put(request: SOPutWidget): Observable<SORespWidget> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespWidget>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespWidget, resp) }),
      tap((response: SORespWidget) => this.log(`edited Widget w/ uid=${uid}`)),
      catchError(this.handleError<SORespWidget>('Widget put'))
    );
  }

  /** DELETE: delete the Widget from the server */
  delete(request: SODeleteWidget): Observable<SORespWidget> {
    return this.httpClient.delete<SORespWidget>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Widget uid=${request.uid}`)),
      catchError(this.handleError<SORespWidget>('delete Widget'))
    );
  }
}
