import {SOPutWidgetRelationshipSpec} from '../../core/widget-relationship-spec/so-put-widget-relationship-spec';
import {WidgetRelationshipSpecCore} from './widget-relationship-spec.core';
import {SOGetWidgetRelationshipSpec} from '../../core/widget-relationship-spec/so-get-widget-relationship-spec';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putWidgetRelationshipSpecFormFieldOptions} from './put-widget-relationship-spec-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {WidgetWidgetRelationshipSpecAssociationCore} from '../bv-widget-widget-relationship-spec-association/widget-widget-relationship-spec-association.core';
import {SOGetWidgetWidgetRelationshipSpecAssociation} from '../../core/widget-widget-relationship-spec-association/so-get-widget-widget-relationship-spec-association';
import {RelationshipWidgetRelationshipSpecAssociationCore} from '../bv-relationship-widget-relationship-spec-association/relationship-widget-relationship-spec-association.core';
import {SOGetRelationshipWidgetRelationshipSpecAssociation} from '../../core/relationship-widget-relationship-spec-association/so-get-relationship-widget-relationship-spec-association';

export class RAPutWidgetRelationshipSpec extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putWidgetRelationshipSpecFormFieldOptions);

  RequestClass = SOPutWidgetRelationshipSpec

  constructor(
    public entityCore: WidgetRelationshipSpecCore
  ) {
    super();
    if (putWidgetRelationshipSpecFormFieldOptions.widget) {
      putWidgetRelationshipSpecFormFieldOptions.widget.makeCore = true;
    }
    if (putWidgetRelationshipSpecFormFieldOptions.relationship) {
      putWidgetRelationshipSpecFormFieldOptions.relationship.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('isRequired'),
      [this.getFormValueFromIdentifier('widget', SOGetWidgetWidgetRelationshipSpecAssociation)],
      [this.getFormValueFromIdentifier('relationship', SOGetRelationshipWidgetRelationshipSpecAssociation)],
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}