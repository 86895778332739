import { SOGetConstraint } from '../constraint/so-get-constraint';
import { SOGetProduct } from '../product/so-get-product';
import {MMQueryOptions} from 'ngx-lionheart-material';
import {QueryProtocol} from 'ngx-lionheart-material';


export class SOGetAppliedConstraint implements QueryProtocol {

  constructor(
    public uid: string = null,
    public constraint: SOGetConstraint = null,
    public product: SOGetProduct = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetAppliedConstraint(
      'uid' in params ? params.uid : null,
      'constraint' in params ? params.constraint : null,
      'product' in params ? params.product : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
