import {SORespRelationshipResponseRelationshipSpecAssociation} from '../../core/relationship-response-relationship-spec-association/so-resp-relationship-response-relationship-spec-association';
import {RelationshipResponseRelationshipSpecAssociationCore} from './relationship-response-relationship-spec-association.core';
import { ListEntry, ListEntryFactory, ListEntryFactoryProtocol} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';


export class RelationshipResponseRelationshipSpecAssociationListEntryFactory  extends ListEntryFactory implements ListEntryFactoryProtocol {

  makeListEntry(parentCoreViewSpec, entityCore: EntityCoreProtocol, entity: SORespRelationshipResponseRelationshipSpecAssociation, section: number = 0): ListEntry {
    const resp = super.makeListEntry(parentCoreViewSpec, entityCore, entity, section)
    return resp
  }
}
