import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const isRequiredField = new FormField(
  'isRequired',
  'Is required',
  null,
  FormFieldType.boolean,
  true,
)
const defaultValueField = new FormField(
  'defaultValue',
  'Default value',
  null,
  FormFieldType.text,
  false,
)
const storeField = new FormField(
  'store',
  'Store',
  'storeStoreAttributeSpecAssociation',
  FormFieldType.pushedSelectionList,
  true,
)
const attributeField = new FormField(
  'attribute',
  'Attribute',
  'attributeStoreAttributeSpecAssociation',
  FormFieldType.pushedSelectionList,
  true,
)


export const postStoreAttributeSpecFormFieldOptions: {[key: string]: FormField} = {
  isRequired: isRequiredField,
  defaultValue: defaultValueField,
  store: storeField,
  attribute: attributeField,
};