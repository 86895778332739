import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const branchField = new FormField(
  'branch',
  'Branch',
  'branch',
  FormFieldType.pushedSelectionList,
  false,
)
const commitField = new FormField(
  'commit',
  'Commit',
  'commit',
  FormFieldType.pushedSelectionList,
  false,
)


export const putBranchCommitAssociationFormFieldOptions: {[key: string]: FormField} = {
  branch: branchField,
  commit: commitField,
};