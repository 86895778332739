import { SOGetStoreStoreAttributeSpecAssociation } from '../store-store-attribute-spec-association/so-get-store-store-attribute-spec-association';
import { SOGetAttributeStoreAttributeSpecAssociation } from '../attribute-store-attribute-spec-association/so-get-attribute-store-attribute-spec-association';
import { SOGetOrganization } from '../organization/so-get-organization';
import { SOGetApp } from '../app/so-get-app';


export class SOPostStoreAttributeSpec {

  constructor(
    public isRequired: boolean,
    public store: SOGetStoreStoreAttributeSpecAssociation[],
    public attribute: SOGetAttributeStoreAttributeSpecAssociation[],
    public organization: SOGetOrganization,
    public app: SOGetApp,
    public defaultValue: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostStoreAttributeSpec(
      'isRequired' in params ? params.isRequired : null,
      'defaultValue' in params ? params.defaultValue : null,
      'store' in params ? params.store : null,
      'attribute' in params ? params.attribute : null,
      'organization' in params ? params.organization : null,
      'app' in params ? params.app : null,
    );
  }
}
