import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOEntityWidgetAssociationService} from '../../core/entity-widget-association/eo-entity-widget-association';
import {EntityWidgetAssociationListEntryFactory} from './entity-widget-association-list-entry-factory';
import {EntityWidgetAssociationDetailFactory} from './entity-widget-association-detail-factory';
import {SOGetEntityWidgetAssociation} from '../../core/entity-widget-association/so-get-entity-widget-association';
import {RAPutEntityWidgetAssociation} from './ra-put-entity-widget-association';
import {RAPostEntityWidgetAssociation} from './ra-post-entity-widget-association';
import {RADeleteEntityWidgetAssociation} from './ra-delete-entity-widget-association';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetCommit} from '../../core/commit/so-get-commit'
import {SOGetOrganization} from '../../core/organization/so-get-organization'


export class EntityWidgetAssociationCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'entityWidgetAssociation';
  title = 'Entity widget association';
  listEntryFactory = new EntityWidgetAssociationListEntryFactory();
  detailFactory = new EntityWidgetAssociationDetailFactory();

  entityService: EOEntityWidgetAssociationService;
  soGetClass = SOGetEntityWidgetAssociation;
  soGet: SOGetEntityWidgetAssociation;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOEntityWidgetAssociationService
    );
    this.adapters = [
      new RAPostEntityWidgetAssociation(this),
      new RAPutEntityWidgetAssociation(this),
      new RADeleteEntityWidgetAssociation(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetEntityWidgetAssociation();
    this.soGet.commit = new SOGetCommit(
      this.activeUserService.getScope('commit').uid
    );
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
