import {SORespAttribute} from '../../core/attribute/so-resp-attribute';
import {AttributeCore} from './attribute.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putAttributeFormFieldOptions} from './put-attribute-form-fields';
import {SOGetAttribute} from '../../core/attribute/so-get-attribute';
import {SOGetEntityAttributeAssociation} from '../../core/entity-attribute-association/so-get-entity-attribute-association';
import {SOGetAttributeCodableAssociation} from '../../core/attribute-codable-association/so-get-attribute-codable-association';
import {SOGetAttributeWidgetAttributeSpecAssociation} from '../../core/attribute-widget-attribute-spec-association/so-get-attribute-widget-attribute-spec-association';
import {SOGetAttributeRequestAttributeSpecAssociation} from '../../core/attribute-request-attribute-spec-association/so-get-attribute-request-attribute-spec-association';
import {SOGetAttributeResponseAttributeSpecAssociation} from '../../core/attribute-response-attribute-spec-association/so-get-attribute-response-attribute-spec-association';
import {SOGetAttributeStoreAttributeSpecAssociation} from '../../core/attribute-store-attribute-spec-association/so-get-attribute-store-attribute-spec-association';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class AttributeDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespAttribute = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const nameDetail = new DetailEntry(
        'Name',
        entity.name,
        'name',
        BaseDetailType.text,
        null,
        EditableType.always,
        putAttributeFormFieldOptions.name
    )
    attributes.push(nameDetail)


    const entityCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'entityAttributeAssociation');
    entityCore.soGet = SOGetEntityAttributeAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, entityCore)
    entityCore.title = heading ? heading : entityCore.title
    entityCore.listTitle = 'Entity'

    entityCore.configureForDisplay(entityViewSpec)

    const entityDetail = new DetailEntry(
      heading ? heading : entityCore.listTitle,
      null,
      'entity',
      BaseDetailType.embeddedList,
      entityCore,
      EditableType.never
    )
    attributes.push(entityDetail);

    const codableCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'attributeCodableAssociation');
    codableCore.soGet = SOGetAttributeCodableAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, codableCore)
    codableCore.title = heading ? heading : codableCore.title
    codableCore.listTitle = 'Codable'

    codableCore.configureForDisplay(entityViewSpec)

    const codableDetail = new DetailEntry(
      heading ? heading : codableCore.listTitle,
      null,
      'codable',
      BaseDetailType.embeddedList,
      codableCore,
      EditableType.never
    )
    attributes.push(codableDetail);

    const widgetAttributeSpecsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'attributeWidgetAttributeSpecAssociation');
    widgetAttributeSpecsCore.soGet = SOGetAttributeWidgetAttributeSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, widgetAttributeSpecsCore)
    widgetAttributeSpecsCore.title = heading ? heading : widgetAttributeSpecsCore.title
    widgetAttributeSpecsCore.listTitle = 'Widget attribute specs'

    widgetAttributeSpecsCore.configureForDisplay(entityViewSpec)

    const widgetAttributeSpecsDetail = new DetailEntry(
      heading ? heading : widgetAttributeSpecsCore.listTitle,
      null,
      'widgetAttributeSpecs',
      BaseDetailType.embeddedList,
      widgetAttributeSpecsCore,
      EditableType.never
    )
    attributes.push(widgetAttributeSpecsDetail);

    const requestAttributeSpecsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'attributeRequestAttributeSpecAssociation');
    requestAttributeSpecsCore.soGet = SOGetAttributeRequestAttributeSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, requestAttributeSpecsCore)
    requestAttributeSpecsCore.title = heading ? heading : requestAttributeSpecsCore.title
    requestAttributeSpecsCore.listTitle = 'Request attribute specs'

    requestAttributeSpecsCore.configureForDisplay(entityViewSpec)

    const requestAttributeSpecsDetail = new DetailEntry(
      heading ? heading : requestAttributeSpecsCore.listTitle,
      null,
      'requestAttributeSpecs',
      BaseDetailType.embeddedList,
      requestAttributeSpecsCore,
      EditableType.never
    )
    attributes.push(requestAttributeSpecsDetail);

    const responseAttributeSpecsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'attributeResponseAttributeSpecAssociation');
    responseAttributeSpecsCore.soGet = SOGetAttributeResponseAttributeSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, responseAttributeSpecsCore)
    responseAttributeSpecsCore.title = heading ? heading : responseAttributeSpecsCore.title
    responseAttributeSpecsCore.listTitle = 'Response attribute specs'

    responseAttributeSpecsCore.configureForDisplay(entityViewSpec)

    const responseAttributeSpecsDetail = new DetailEntry(
      heading ? heading : responseAttributeSpecsCore.listTitle,
      null,
      'responseAttributeSpecs',
      BaseDetailType.embeddedList,
      responseAttributeSpecsCore,
      EditableType.never
    )
    attributes.push(responseAttributeSpecsDetail);

    const storeAttributeSpecsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'attributeStoreAttributeSpecAssociation');
    storeAttributeSpecsCore.soGet = SOGetAttributeStoreAttributeSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, storeAttributeSpecsCore)
    storeAttributeSpecsCore.title = heading ? heading : storeAttributeSpecsCore.title
    storeAttributeSpecsCore.listTitle = 'Store attribute specs'

    storeAttributeSpecsCore.configureForDisplay(entityViewSpec)

    const storeAttributeSpecsDetail = new DetailEntry(
      heading ? heading : storeAttributeSpecsCore.listTitle,
      null,
      'storeAttributeSpecs',
      BaseDetailType.embeddedList,
      storeAttributeSpecsCore,
      EditableType.never
    )
    attributes.push(storeAttributeSpecsDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
