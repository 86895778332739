import { Type } from 'class-transformer';
import { SORespSubscription } from '../subscription/so-resp-subscription';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespInvoice implements ObjectiveProtocol {

  entityType: string = 'invoice';
  uid: string;
  ref: string;
  exRef: string = null;
  amountDue: number;
  amountPaid: number;
  amountRemaining: number;
  paid: boolean = false;
  @Type(() => Date)
  dateUpdated: Date;
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => Date)
  dateDue: Date;
  @Type(() => Date)
  periodStart: Date;
  @Type(() => Date)
  periodEnd: Date;
  @Type(() => SORespSubscription)
  subscription: SORespSubscription;
}