import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOWidgetAttributeSpecService} from '../../core/widget-attribute-spec/eo-widget-attribute-spec';
import {WidgetAttributeSpecListEntryFactory} from './widget-attribute-spec-list-entry-factory';
import {WidgetAttributeSpecDetailFactory} from './widget-attribute-spec-detail-factory';
import {SOGetWidgetAttributeSpec} from '../../core/widget-attribute-spec/so-get-widget-attribute-spec';
import {RAPutWidgetAttributeSpec} from './ra-put-widget-attribute-spec';
import {RAPostWidgetAttributeSpec} from './ra-post-widget-attribute-spec';
import {RADeleteWidgetAttributeSpec} from './ra-delete-widget-attribute-spec';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import {SOGetApp} from '../../core/app/so-get-app'


export class WidgetAttributeSpecCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'widgetAttributeSpec';
  title = 'Widget attribute spec';
  listEntryFactory = new WidgetAttributeSpecListEntryFactory();
  detailFactory = new WidgetAttributeSpecDetailFactory();

  entityService: EOWidgetAttributeSpecService;
  soGetClass = SOGetWidgetAttributeSpec;
  soGet: SOGetWidgetAttributeSpec;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOWidgetAttributeSpecService
    );
    this.adapters = [
      new RAPostWidgetAttributeSpec(this),
      new RAPutWidgetAttributeSpec(this),
      new RADeleteWidgetAttributeSpec(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetWidgetAttributeSpec();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.app = new SOGetApp(
      this.activeUserService.getScope('app').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
