import {SORespSesMessage} from '../../core/ses-message/so-resp-ses-message';
import {SesMessageCore} from './ses-message.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putSesMessageFormFieldOptions} from './put-ses-message-form-fields';
import {SOGetSesMessage} from '../../core/ses-message/so-get-ses-message';


export class SesMessageDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespSesMessage = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const dataDetail = new DetailEntry(
        'Data',
        entity.data,
        'data',
        BaseDetailType.text,
        null,
        EditableType.always,
        putSesMessageFormFieldOptions.data
    )
    attributes.push(dataDetail)

    const dateCreatedDetail = new DetailEntry(
        'Date created',
        activeUserService.time.niceDate(entity.dateCreated),
        'dateCreated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateCreatedDetail)

    const messageTypeDetail = new DetailEntry(
        'Message type',
        entity.messageType,
        'messageType',
        BaseDetailType.toggleGroup,
        null,
        EditableType.always,
        putSesMessageFormFieldOptions.messageType
    )
    attributes.push(messageTypeDetail)


    return new EntityAttributesForDisplay(attributes);

  }
}
