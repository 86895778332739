import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';
import {ENInviteStatus} from '../../enums';


const statusField = new FormField(
  'status',
  'Status',
  null,
  FormFieldType.enumeration,
  false,
  true,
  ENInviteStatus.sent
)
statusField.enumeration = ENInviteStatus


export const putOrganizationInviteFormFieldOptions: {[key: string]: FormField} = {
  status: statusField,
};