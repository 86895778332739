import {SOPutEnumerator} from '../../core/enumerator/so-put-enumerator';
import {EnumeratorCore} from './enumerator.core';
import {SOGetEnumerator} from '../../core/enumerator/so-get-enumerator';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putEnumeratorFormFieldOptions} from './put-enumerator-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {CommitCodableAssociationCore} from '../bv-commit-codable-association/commit-codable-association.core';
import {SOGetCommitCodableAssociation} from '../../core/commit-codable-association/so-get-commit-codable-association';

export class RAPutEnumerator extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putEnumeratorFormFieldOptions);

  RequestClass = SOPutEnumerator

  constructor(
    public entityCore: EnumeratorCore
  ) {
    super();
    if (putEnumeratorFormFieldOptions.commitCodableAssociations) {
      putEnumeratorFormFieldOptions.commitCodableAssociations.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      [this.getFormValueFromIdentifier('commitCodableAssociations', SOGetCommitCodableAssociation)],
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}