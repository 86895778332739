import {SORespEntityStoreAssociation} from '../../core/entity-store-association/so-resp-entity-store-association';
import {EntityStoreAssociationCore} from './entity-store-association.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putEntityStoreAssociationFormFieldOptions} from './put-entity-store-association-form-fields';
import {SOGetEntityStoreAssociation} from '../../core/entity-store-association/so-get-entity-store-association';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {SOGetStore} from '../../core/store/so-get-store';
import {SOGetApp} from '../../core/app/so-get-app';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class EntityStoreAssociationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespEntityStoreAssociation = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []


    const entityCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'entity');
    entityCore.soGet = SOGetEntity.construct({
      app: new SOGetApp(activeUserService.getScope('app').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, entityCore)
    entityCore.title = heading ? heading : entityCore.title
    entityCore.listTitle = 'Entity'

    if (entity.entity) {
        displayStringFactory.reconfigure(entity.entity, entityViewSpec.entityCore.type)
    }
    entityCore.configureForDisplay(entityViewSpec)

    const entityDetail = new DetailEntry(
      heading ? heading : entityCore.listTitle,
      entity.entity ? displayStringFactory.primaryString : null,
      'entity',
      BaseDetailType.pushedDetail,
      entityCore,
      EditableType.always,
      putEntityStoreAssociationFormFieldOptions.entity
    )
    if (entity.entity) {
      entityDetail.cachedListEntry = entityCore.makeListEntry(entityViewSpec, entityCore, entity.entity, 0)
    }
    entityDetail.singleFieldSelect = true
    attributes.push(entityDetail);

    const storeCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'store');
    storeCore.soGet = SOGetStore.construct({
      entity: new SOGetEntityStoreAssociation(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, storeCore)
    storeCore.title = heading ? heading : storeCore.title
    storeCore.listTitle = 'Store'

    if (entity.store) {
        displayStringFactory.reconfigure(entity.store, entityViewSpec.entityCore.type)
    }
    storeCore.configureForDisplay(entityViewSpec)

    const storeDetail = new DetailEntry(
      heading ? heading : storeCore.listTitle,
      entity.store ? displayStringFactory.primaryString : null,
      'store',
      BaseDetailType.pushedDetail,
      storeCore,
      EditableType.always,
      putEntityStoreAssociationFormFieldOptions.store
    )
    if (entity.store) {
      storeDetail.cachedListEntry = storeCore.makeListEntry(entityViewSpec, storeCore, entity.store, 0)
    }
    storeDetail.singleFieldSelect = true
    attributes.push(storeDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
