import { Type } from 'class-transformer';
import { SORespRequestRequestAttributeSpecAssociation } from '../request-request-attribute-spec-association/so-resp-request-request-attribute-spec-association';
import { SORespAttributeRequestAttributeSpecAssociation } from '../attribute-request-attribute-spec-association/so-resp-attribute-request-attribute-spec-association';
import { SORespOrganization } from '../organization/so-resp-organization';
import { SORespApp } from '../app/so-resp-app';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespRequestAttributeSpec implements ObjectiveProtocol {

  entityType: string = 'requestAttributeSpec';
  uid: string;
  isRequired: boolean;
  defaultValue: string = null;
  @Type(() => SORespRequestRequestAttributeSpecAssociation)
  request: SORespRequestRequestAttributeSpecAssociation[];
  @Type(() => SORespAttributeRequestAttributeSpecAssociation)
  attribute: SORespAttributeRequestAttributeSpecAssociation[];
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
  @Type(() => SORespApp)
  app: SORespApp;
}