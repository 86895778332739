import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';
import {ENRequestType} from '../../enums';


const nameField = new FormField(
  'name',
  'Name',
  null,
  FormFieldType.text,
  false,
  true,
  ""
)
const requestTypeField = new FormField(
  'requestType',
  'Request type',
  null,
  FormFieldType.enumeration,
  false,
)
requestTypeField.enumeration = ENRequestType
const isDefaultField = new FormField(
  'isDefault',
  'Is default',
  null,
  FormFieldType.boolean,
  false,
)
const entityField = new FormField(
  'entity',
  'Entity',
  'entityRequestAssociation',
  FormFieldType.pushedSelectionList,
  false,
)


export const putRequestFormFieldOptions: {[key: string]: FormField} = {
  name: nameField,
  requestType: requestTypeField,
  isDefault: isDefaultField,
  entity: entityField,
};