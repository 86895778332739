import { SOGetResponseResponseRelationshipSpecAssociation } from '../response-response-relationship-spec-association/so-get-response-response-relationship-spec-association';
import { SOGetRelationshipResponseRelationshipSpecAssociation } from '../relationship-response-relationship-spec-association/so-get-relationship-response-relationship-spec-association';


export class SOPutResponseRelationshipSpec {

  constructor(
    public uid: string,
    public isRequired: boolean = null,
    public response: SOGetResponseResponseRelationshipSpecAssociation[] = null,
    public relationship: SOGetRelationshipResponseRelationshipSpecAssociation[] = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutResponseRelationshipSpec(
      'uid' in params ? params.uid : null,
      'isRequired' in params ? params.isRequired : null,
      'response' in params ? params.response : null,
      'relationship' in params ? params.relationship : null,
    );
  }
}
