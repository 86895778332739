import { Type } from 'class-transformer';
import { SORespRequest } from '../request/so-resp-request';
import { SORespRequestAttributeSpec } from '../request-attribute-spec/so-resp-request-attribute-spec';
import { SORespCommit } from '../commit/so-resp-commit';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespRequestRequestAttributeSpecAssociation implements ObjectiveProtocol {

  entityType: string = 'requestRequestAttributeSpecAssociation';
  uid: string;
  @Type(() => SORespRequest)
  request: SORespRequest;
  @Type(() => SORespRequestAttributeSpec)
  requestAttributeSpec: SORespRequestAttributeSpec;
  @Type(() => SORespCommit)
  commit: SORespCommit;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
}