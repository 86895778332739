import {SOPostResponseAttributeSpec} from '../../core/response-attribute-spec/so-post-response-attribute-spec';
import {ResponseAttributeSpecCore} from './response-attribute-spec.core';
import {SOGetResponseAttributeSpec} from '../../core/response-attribute-spec/so-get-response-attribute-spec';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postResponseAttributeSpecFormFieldOptions} from './post-response-attribute-spec-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {ResponseResponseAttributeSpecAssociationCore} from '../bv-response-response-attribute-spec-association/response-response-attribute-spec-association.core';
import {SOGetResponseResponseAttributeSpecAssociation} from '../../core/response-response-attribute-spec-association/so-get-response-response-attribute-spec-association';
import {AttributeResponseAttributeSpecAssociationCore} from '../bv-attribute-response-attribute-spec-association/attribute-response-attribute-spec-association.core';
import {SOGetAttributeResponseAttributeSpecAssociation} from '../../core/attribute-response-attribute-spec-association/so-get-attribute-response-attribute-spec-association';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {AppCore} from '../bv-app/app.core';
import {SOGetApp} from '../../core/app/so-get-app';

export class RAPostResponseAttributeSpec extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postResponseAttributeSpecFormFieldOptions);

  RequestClass = SOPostResponseAttributeSpec

  constructor(
    public entityCore: ResponseAttributeSpecCore
  ) {
    super();
    if (postResponseAttributeSpecFormFieldOptions.response) {
      postResponseAttributeSpecFormFieldOptions.response.makeCore = true;
    }
    if (postResponseAttributeSpecFormFieldOptions.attribute) {
      postResponseAttributeSpecFormFieldOptions.attribute.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('isRequired'),
      [this.getFormValueFromIdentifier('response', SOGetResponseResponseAttributeSpecAssociation)],
      [this.getFormValueFromIdentifier('attribute', SOGetAttributeResponseAttributeSpecAssociation)],
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier(
        'app',
        SOGetApp,
        true,
      ),
      this.getFormValueFromIdentifier('defaultValue'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}