import {SORespPrice} from '../../core/price/so-resp-price';
import {PriceCore} from './price.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putPriceFormFieldOptions} from './put-price-form-fields';
import {SOGetPrice} from '../../core/price/so-get-price';
import {SOGetProduct} from '../../core/product/so-get-product';


export class PriceDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespPrice = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const nameDetail = new DetailEntry(
        'Name',
        entity.name,
        'name',
        BaseDetailType.text,
        null,
        EditableType.always,
        putPriceFormFieldOptions.name
    )
    attributes.push(nameDetail)

    const skuDetail = new DetailEntry(
        'Sku',
        entity.sku,
        'sku',
        BaseDetailType.text,
        null,
        EditableType.always,
        putPriceFormFieldOptions.sku
    )
    attributes.push(skuDetail)

    const trailDaysDetail = new DetailEntry(
        'Trail days',
        entity.trailDays,
        'trailDays',
        BaseDetailType.text,
        null,
        EditableType.always,
        putPriceFormFieldOptions.trailDays
    )
    attributes.push(trailDaysDetail)

    const dateUpdatedDetail = new DetailEntry(
        'Date updated',
        activeUserService.time.niceDate(entity.dateUpdated),
        'dateUpdated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateUpdatedDetail)

    const dateCreatedDetail = new DetailEntry(
        'Date created',
        activeUserService.time.niceDate(entity.dateCreated),
        'dateCreated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateCreatedDetail)

    const exRefDetail = new DetailEntry(
        'Ex ref',
        entity.exRef,
        'exRef',
        BaseDetailType.text,
        null,
        EditableType.always,
        putPriceFormFieldOptions.exRef
    )
    attributes.push(exRefDetail)

    const amountDetail = new DetailEntry(
        'Amount',
        entity.amount,
        'amount',
        BaseDetailType.text,
        null,
        EditableType.always,
        putPriceFormFieldOptions.amount
    )
    attributes.push(amountDetail)

    const intervalDetail = new DetailEntry(
        'Interval',
        entity.interval,
        'interval',
        BaseDetailType.toggleGroup,
        null,
        EditableType.always,
        putPriceFormFieldOptions.interval
    )
    attributes.push(intervalDetail)

    const activeDetail = new DetailEntry(
        'Active',
        entity.active,
        'active',
        BaseDetailType.toggle,
        null,
        EditableType.always,
        putPriceFormFieldOptions.active
    )
    attributes.push(activeDetail)


    const productCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'product');
    productCore.soGet = SOGetProduct.construct({
    });
    heading = getHeading(entityViewSpec.entityCore, productCore)
    productCore.title = heading ? heading : productCore.title
    productCore.listTitle = 'Product'

    if (entity.product) {
        displayStringFactory.reconfigure(entity.product, entityViewSpec.entityCore.type)
    }
    productCore.configureForDisplay(entityViewSpec)

    const productDetail = new DetailEntry(
      heading ? heading : productCore.listTitle,
      entity.product ? displayStringFactory.primaryString : null,
      'product',
      BaseDetailType.pushedDetail,
      productCore,
      EditableType.always,
      putPriceFormFieldOptions.product
    )
    if (entity.product) {
      productDetail.cachedListEntry = productCore.makeListEntry(entityViewSpec, productCore, entity.product, 0)
    }
    productDetail.singleFieldSelect = true
    attributes.push(productDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
