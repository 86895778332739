import {SOPostRequestAttributeSpec} from '../../core/request-attribute-spec/so-post-request-attribute-spec';
import {RequestAttributeSpecCore} from './request-attribute-spec.core';
import {SOGetRequestAttributeSpec} from '../../core/request-attribute-spec/so-get-request-attribute-spec';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postRequestAttributeSpecFormFieldOptions} from './post-request-attribute-spec-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {RequestRequestAttributeSpecAssociationCore} from '../bv-request-request-attribute-spec-association/request-request-attribute-spec-association.core';
import {SOGetRequestRequestAttributeSpecAssociation} from '../../core/request-request-attribute-spec-association/so-get-request-request-attribute-spec-association';
import {AttributeRequestAttributeSpecAssociationCore} from '../bv-attribute-request-attribute-spec-association/attribute-request-attribute-spec-association.core';
import {SOGetAttributeRequestAttributeSpecAssociation} from '../../core/attribute-request-attribute-spec-association/so-get-attribute-request-attribute-spec-association';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {AppCore} from '../bv-app/app.core';
import {SOGetApp} from '../../core/app/so-get-app';

export class RAPostRequestAttributeSpec extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postRequestAttributeSpecFormFieldOptions);

  RequestClass = SOPostRequestAttributeSpec

  constructor(
    public entityCore: RequestAttributeSpecCore
  ) {
    super();
    if (postRequestAttributeSpecFormFieldOptions.request) {
      postRequestAttributeSpecFormFieldOptions.request.makeCore = true;
    }
    if (postRequestAttributeSpecFormFieldOptions.attribute) {
      postRequestAttributeSpecFormFieldOptions.attribute.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('isRequired'),
      [this.getFormValueFromIdentifier('request', SOGetRequestRequestAttributeSpecAssociation)],
      [this.getFormValueFromIdentifier('attribute', SOGetAttributeRequestAttributeSpecAssociation)],
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier(
        'app',
        SOGetApp,
        true,
      ),
      this.getFormValueFromIdentifier('defaultValue'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}