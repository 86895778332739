import {SOPostViewGrant} from '../../core/view-grant/so-post-view-grant';
import {ViewGrantCore} from './view-grant.core';
import {SOGetViewGrant} from '../../core/view-grant/so-get-view-grant';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postViewGrantFormFieldOptions} from './post-view-grant-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {RoleCore} from '../bv-role/role.core';
import {SOGetRole} from '../../core/role/so-get-role';
import {ViewCore} from '../bv-view/view.core';
import {SOGetView} from '../../core/view/so-get-view';

export class RAPostViewGrant extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postViewGrantFormFieldOptions);

  RequestClass = SOPostViewGrant

  constructor(
    public entityCore: ViewGrantCore
  ) {
    super();
    if (postViewGrantFormFieldOptions.role) {
      postViewGrantFormFieldOptions.role.makeCore = true;
    }
    if (postViewGrantFormFieldOptions.view) {
      postViewGrantFormFieldOptions.view.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'role',
        SOGetRole,
      ),
      this.getFormValueFromIdentifier(
        'view',
        SOGetView,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}