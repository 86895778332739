import { SOGetEntityRequestAssociation } from '../entity-request-association/so-get-entity-request-association';
import { SOGetOrganization } from '../organization/so-get-organization';
import { SOGetApp } from '../app/so-get-app';
import {ENRequestType} from '../../enums';


export class SOPostRequest {

  constructor(
    public requestType: ENRequestType,
    public entity: SOGetEntityRequestAssociation[],
    public organization: SOGetOrganization,
    public app: SOGetApp,
    public name: string = "",
    public isDefault: boolean = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostRequest(
      'name' in params ? params.name : null,
      'requestType' in params ? params.requestType : null,
      'isDefault' in params ? params.isDefault : null,
      'entity' in params ? params.entity : null,
      'organization' in params ? params.organization : null,
      'app' in params ? params.app : null,
    );
  }
}
