import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';
import {ENSubscriptionStatus} from '../../enums';


const dateExpiresField = new FormField(
  'dateExpires',
  'Date expires',
  null,
  FormFieldType.datetime,
  false,
)
const statusField = new FormField(
  'status',
  'Status',
  null,
  FormFieldType.enumeration,
  false,
)
statusField.enumeration = ENSubscriptionStatus


export const putSubscriptionFormFieldOptions: {[key: string]: FormField} = {
  dateExpires: dateExpiresField,
  status: statusField,
};