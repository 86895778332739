import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostAppliedConstraint } from './so-post-applied-constraint';
import { SOGetAppliedConstraint } from './so-get-applied-constraint';
import { SOPutAppliedConstraint } from './so-put-applied-constraint';
import { SODeleteAppliedConstraint } from './so-delete-applied-constraint';
import {SORespAppliedConstraint} from './so-resp-applied-constraint';
import {SOPluralAppliedConstraint} from './so-plural-applied-constraint';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOAppliedConstraintService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'applied_constraint',
      entityCore
    );
  }

  /** GET AppliedConstraint by id. Will 404 if id not found */
  get(request: SOGetAppliedConstraint): Observable<SOPluralAppliedConstraint> {
    return this.httpClient.get<SOPluralAppliedConstraint>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralAppliedConstraint, resp) }),
      tap(_ => this.log(`fetched AppliedConstraint`)),
      catchError(this.handleError<SOPluralAppliedConstraint>(`get AppliedConstraint`))
    );
  }

  /** POST: add a new AppliedConstraint to the server */
  post(request: SOPostAppliedConstraint): Observable<SORespAppliedConstraint> {
    return this.httpClient.post<SORespAppliedConstraint>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAppliedConstraint, resp) }),
      tap((response: SORespAppliedConstraint) => this.log(`added AppliedConstraint w/ id=${response.uid}`)),
      catchError(this.handleError<SORespAppliedConstraint>('AppliedConstraint post'))
    );
  }

  /** PUT: update AppliedConstraint  */
  put(request: SOPutAppliedConstraint): Observable<SORespAppliedConstraint> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespAppliedConstraint>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAppliedConstraint, resp) }),
      tap((response: SORespAppliedConstraint) => this.log(`edited AppliedConstraint w/ uid=${uid}`)),
      catchError(this.handleError<SORespAppliedConstraint>('AppliedConstraint put'))
    );
  }

  /** DELETE: delete the AppliedConstraint from the server */
  delete(request: SODeleteAppliedConstraint): Observable<SORespAppliedConstraint> {
    return this.httpClient.delete<SORespAppliedConstraint>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted AppliedConstraint uid=${request.uid}`)),
      catchError(this.handleError<SORespAppliedConstraint>('delete AppliedConstraint'))
    );
  }
}
