import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostEntityRequestAssociation } from './so-post-entity-request-association';
import { SOGetEntityRequestAssociation } from './so-get-entity-request-association';
import { SOPutEntityRequestAssociation } from './so-put-entity-request-association';
import { SODeleteEntityRequestAssociation } from './so-delete-entity-request-association';
import {SORespEntityRequestAssociation} from './so-resp-entity-request-association';
import {SOPluralEntityRequestAssociation} from './so-plural-entity-request-association';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOEntityRequestAssociationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'entity_request_association',
      entityCore
    );
  }

  /** GET EntityRequestAssociation by id. Will 404 if id not found */
  get(request: SOGetEntityRequestAssociation): Observable<SOPluralEntityRequestAssociation> {
    return this.httpClient.get<SOPluralEntityRequestAssociation>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralEntityRequestAssociation, resp) }),
      tap(_ => this.log(`fetched EntityRequestAssociation`)),
      catchError(this.handleError<SOPluralEntityRequestAssociation>(`get EntityRequestAssociation`))
    );
  }

  /** POST: add a new EntityRequestAssociation to the server */
  post(request: SOPostEntityRequestAssociation): Observable<SORespEntityRequestAssociation> {
    return this.httpClient.post<SORespEntityRequestAssociation>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespEntityRequestAssociation, resp) }),
      tap((response: SORespEntityRequestAssociation) => this.log(`added EntityRequestAssociation w/ id=${response.uid}`)),
      catchError(this.handleError<SORespEntityRequestAssociation>('EntityRequestAssociation post'))
    );
  }

  /** PUT: update EntityRequestAssociation  */
  put(request: SOPutEntityRequestAssociation): Observable<SORespEntityRequestAssociation> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespEntityRequestAssociation>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespEntityRequestAssociation, resp) }),
      tap((response: SORespEntityRequestAssociation) => this.log(`edited EntityRequestAssociation w/ uid=${uid}`)),
      catchError(this.handleError<SORespEntityRequestAssociation>('EntityRequestAssociation put'))
    );
  }

  /** DELETE: delete the EntityRequestAssociation from the server */
  delete(request: SODeleteEntityRequestAssociation): Observable<SORespEntityRequestAssociation> {
    return this.httpClient.delete<SORespEntityRequestAssociation>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted EntityRequestAssociation uid=${request.uid}`)),
      catchError(this.handleError<SORespEntityRequestAssociation>('delete EntityRequestAssociation'))
    );
  }
}
