import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';
import {ENMultiplicity} from '../../enums';


const entityField = new FormField(
  'entity',
  'Entity',
  'entityRelationshipAssociation',
  FormFieldType.pushedSelectionList,
  true,
)
const nameField = new FormField(
  'name',
  'Name',
  null,
  FormFieldType.text,
  true,
)
const multiplicityField = new FormField(
  'multiplicity',
  'Multiplicity',
  null,
  FormFieldType.enumeration,
  true,
)
multiplicityField.enumeration = ENMultiplicity


export const postRelationshipFormFieldOptions: {[key: string]: FormField} = {
  entity: entityField,
  name: nameField,
  multiplicity: multiplicityField,
};