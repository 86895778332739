import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const isRequiredField = new FormField(
  'isRequired',
  'Is required',
  null,
  FormFieldType.boolean,
  false,
)
const defaultValueField = new FormField(
  'defaultValue',
  'Default value',
  null,
  FormFieldType.text,
  false,
)
const responseField = new FormField(
  'response',
  'Response',
  'responseResponseAttributeSpecAssociation',
  FormFieldType.pushedSelectionList,
  false,
)
const attributeField = new FormField(
  'attribute',
  'Attribute',
  'attributeResponseAttributeSpecAssociation',
  FormFieldType.pushedSelectionList,
  false,
)


export const putResponseAttributeSpecFormFieldOptions: {[key: string]: FormField} = {
  isRequired: isRequiredField,
  defaultValue: defaultValueField,
  response: responseField,
  attribute: attributeField,
};