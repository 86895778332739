import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const relationshipField = new FormField(
  'relationship',
  'Relationship',
  'relationship',
  FormFieldType.pushedSelectionList,
  true,
)
const widgetField = new FormField(
  'widget',
  'Widget',
  'widgetRelationshipSpec',
  FormFieldType.pushedSelectionList,
  true,
)


export const postRelationshipWidgetRelationshipSpecAssociationFormFieldOptions: {[key: string]: FormField} = {
  relationship: relationshipField,
  widget: widgetField,
};