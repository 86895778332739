import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOViewGrantService} from '../../core/view-grant/eo-view-grant';
import {ViewGrantListEntryFactory} from './view-grant-list-entry-factory';
import {ViewGrantDetailFactory} from './view-grant-detail-factory';
import {SOGetViewGrant} from '../../core/view-grant/so-get-view-grant';
import {RAPutViewGrant} from './ra-put-view-grant';
import {RAPostViewGrant} from './ra-post-view-grant';
import {RADeleteViewGrant} from './ra-delete-view-grant';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';


export class ViewGrantCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'viewGrant';
  title = 'View grant';
  listEntryFactory = new ViewGrantListEntryFactory();
  detailFactory = new ViewGrantDetailFactory();

  entityService: EOViewGrantService;
  soGetClass = SOGetViewGrant;
  soGet: SOGetViewGrant;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOViewGrantService
    );
    this.adapters = [
      new RAPostViewGrant(this),
      new RAPutViewGrant(this),
      new RADeleteViewGrant(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetViewGrant();
    this.soGet.queryOptions.limit = this.limit;
  }


}
