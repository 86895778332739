import { SOGetCommit } from '../commit/so-get-commit';
import { SOGetOrganization } from '../organization/so-get-organization';
import {MMQueryOptions} from 'ngx-lionheart-material';
import {QueryProtocol} from 'ngx-lionheart-material';


export class SOGetEnumeratorEnumeralAssociation implements QueryProtocol {

  constructor(
    public uid: string = null,
    public commit: SOGetCommit = null,
    public organization: SOGetOrganization = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetEnumeratorEnumeralAssociation(
      'uid' in params ? params.uid : null,
      'commit' in params ? params.commit : null,
      'organization' in params ? params.organization : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
