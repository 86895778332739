import {SORespWidgetWidgetAttributeSpecAssociation} from '../../core/widget-widget-attribute-spec-association/so-resp-widget-widget-attribute-spec-association';
import {WidgetWidgetAttributeSpecAssociationCore} from './widget-widget-attribute-spec-association.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putWidgetWidgetAttributeSpecAssociationFormFieldOptions} from './put-widget-widget-attribute-spec-association-form-fields';
import {SOGetWidgetWidgetAttributeSpecAssociation} from '../../core/widget-widget-attribute-spec-association/so-get-widget-widget-attribute-spec-association';
import {SOGetWidget} from '../../core/widget/so-get-widget';
import {SOGetWidgetAttributeSpec} from '../../core/widget-attribute-spec/so-get-widget-attribute-spec';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetApp} from '../../core/app/so-get-app';


export class WidgetWidgetAttributeSpecAssociationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespWidgetWidgetAttributeSpecAssociation = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []


    const widgetCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'widget');
    widgetCore.soGet = SOGetWidget.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, widgetCore)
    widgetCore.title = heading ? heading : widgetCore.title
    widgetCore.listTitle = 'Widget'

    if (entity.widget) {
        displayStringFactory.reconfigure(entity.widget, entityViewSpec.entityCore.type)
    }
    widgetCore.configureForDisplay(entityViewSpec)

    const widgetDetail = new DetailEntry(
      heading ? heading : widgetCore.listTitle,
      entity.widget ? displayStringFactory.primaryString : null,
      'widget',
      BaseDetailType.pushedDetail,
      widgetCore,
      EditableType.always,
      putWidgetWidgetAttributeSpecAssociationFormFieldOptions.widget
    )
    if (entity.widget) {
      widgetDetail.cachedListEntry = widgetCore.makeListEntry(entityViewSpec, widgetCore, entity.widget, 0)
    }
    widgetDetail.singleFieldSelect = true
    attributes.push(widgetDetail);

    const widgetAttributeSpecCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'widgetAttributeSpec');
    widgetAttributeSpecCore.soGet = SOGetWidgetAttributeSpec.construct({
      widget: new SOGetWidgetWidgetAttributeSpecAssociation(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, widgetAttributeSpecCore)
    widgetAttributeSpecCore.title = heading ? heading : widgetAttributeSpecCore.title
    widgetAttributeSpecCore.listTitle = 'Widget attribute spec'

    if (entity.widgetAttributeSpec) {
        displayStringFactory.reconfigure(entity.widgetAttributeSpec, entityViewSpec.entityCore.type)
    }
    widgetAttributeSpecCore.configureForDisplay(entityViewSpec)

    const widgetAttributeSpecDetail = new DetailEntry(
      heading ? heading : widgetAttributeSpecCore.listTitle,
      entity.widgetAttributeSpec ? displayStringFactory.primaryString : null,
      'widgetAttributeSpec',
      BaseDetailType.pushedDetail,
      widgetAttributeSpecCore,
      EditableType.always,
      putWidgetWidgetAttributeSpecAssociationFormFieldOptions.widgetAttributeSpec
    )
    if (entity.widgetAttributeSpec) {
      widgetAttributeSpecDetail.cachedListEntry = widgetAttributeSpecCore.makeListEntry(entityViewSpec, widgetAttributeSpecCore, entity.widgetAttributeSpec, 0)
    }
    widgetAttributeSpecDetail.singleFieldSelect = true
    attributes.push(widgetAttributeSpecDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
