import { SOGetResponse } from '../response/so-get-response';
import { SOGetResponseAttributeSpec } from '../response-attribute-spec/so-get-response-attribute-spec';


export class SOPutResponseResponseAttributeSpecAssociation {

  constructor(
    public uid: string,
    public response: SOGetResponse = null,
    public responseAttributeSpec: SOGetResponseAttributeSpec = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutResponseResponseAttributeSpecAssociation(
      'uid' in params ? params.uid : null,
      'response' in params ? params.response : null,
      'responseAttributeSpec' in params ? params.responseAttributeSpec : null,
    );
  }
}
