import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostRequest } from './so-post-request';
import { SOGetRequest } from './so-get-request';
import { SOPutRequest } from './so-put-request';
import { SODeleteRequest } from './so-delete-request';
import {SORespRequest} from './so-resp-request';
import {SOPluralRequest} from './so-plural-request';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EORequestService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'request',
      entityCore
    );
  }

  /** GET Request by id. Will 404 if id not found */
  get(request: SOGetRequest): Observable<SOPluralRequest> {
    return this.httpClient.get<SOPluralRequest>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralRequest, resp) }),
      tap(_ => this.log(`fetched Request`)),
      catchError(this.handleError<SOPluralRequest>(`get Request`))
    );
  }

  /** POST: add a new Request to the server */
  post(request: SOPostRequest): Observable<SORespRequest> {
    return this.httpClient.post<SORespRequest>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespRequest, resp) }),
      tap((response: SORespRequest) => this.log(`added Request w/ id=${response.uid}`)),
      catchError(this.handleError<SORespRequest>('Request post'))
    );
  }

  /** PUT: update Request  */
  put(request: SOPutRequest): Observable<SORespRequest> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespRequest>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespRequest, resp) }),
      tap((response: SORespRequest) => this.log(`edited Request w/ uid=${uid}`)),
      catchError(this.handleError<SORespRequest>('Request put'))
    );
  }

  /** DELETE: delete the Request from the server */
  delete(request: SODeleteRequest): Observable<SORespRequest> {
    return this.httpClient.delete<SORespRequest>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Request uid=${request.uid}`)),
      catchError(this.handleError<SORespRequest>('delete Request'))
    );
  }
}
