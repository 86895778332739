import {SORespStoreStoreRelationshipSpecAssociation} from '../../core/store-store-relationship-spec-association/so-resp-store-store-relationship-spec-association';
import {StoreStoreRelationshipSpecAssociationCore} from './store-store-relationship-spec-association.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putStoreStoreRelationshipSpecAssociationFormFieldOptions} from './put-store-store-relationship-spec-association-form-fields';
import {SOGetStoreStoreRelationshipSpecAssociation} from '../../core/store-store-relationship-spec-association/so-get-store-store-relationship-spec-association';
import {SOGetStore} from '../../core/store/so-get-store';
import {SOGetStoreRelationshipSpec} from '../../core/store-relationship-spec/so-get-store-relationship-spec';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetApp} from '../../core/app/so-get-app';


export class StoreStoreRelationshipSpecAssociationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespStoreStoreRelationshipSpecAssociation = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []


    const storeCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'store');
    storeCore.soGet = SOGetStore.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, storeCore)
    storeCore.title = heading ? heading : storeCore.title
    storeCore.listTitle = 'Store'

    if (entity.store) {
        displayStringFactory.reconfigure(entity.store, entityViewSpec.entityCore.type)
    }
    storeCore.configureForDisplay(entityViewSpec)

    const storeDetail = new DetailEntry(
      heading ? heading : storeCore.listTitle,
      entity.store ? displayStringFactory.primaryString : null,
      'store',
      BaseDetailType.pushedDetail,
      storeCore,
      EditableType.always,
      putStoreStoreRelationshipSpecAssociationFormFieldOptions.store
    )
    if (entity.store) {
      storeDetail.cachedListEntry = storeCore.makeListEntry(entityViewSpec, storeCore, entity.store, 0)
    }
    storeDetail.singleFieldSelect = true
    attributes.push(storeDetail);

    const storeRelationshipSpecCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'storeRelationshipSpec');
    storeRelationshipSpecCore.soGet = SOGetStoreRelationshipSpec.construct({
      store: new SOGetStoreStoreRelationshipSpecAssociation(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, storeRelationshipSpecCore)
    storeRelationshipSpecCore.title = heading ? heading : storeRelationshipSpecCore.title
    storeRelationshipSpecCore.listTitle = 'Store relationship spec'

    if (entity.storeRelationshipSpec) {
        displayStringFactory.reconfigure(entity.storeRelationshipSpec, entityViewSpec.entityCore.type)
    }
    storeRelationshipSpecCore.configureForDisplay(entityViewSpec)

    const storeRelationshipSpecDetail = new DetailEntry(
      heading ? heading : storeRelationshipSpecCore.listTitle,
      entity.storeRelationshipSpec ? displayStringFactory.primaryString : null,
      'storeRelationshipSpec',
      BaseDetailType.pushedDetail,
      storeRelationshipSpecCore,
      EditableType.always,
      putStoreStoreRelationshipSpecAssociationFormFieldOptions.storeRelationshipSpec
    )
    if (entity.storeRelationshipSpec) {
      storeRelationshipSpecDetail.cachedListEntry = storeRelationshipSpecCore.makeListEntry(entityViewSpec, storeRelationshipSpecCore, entity.storeRelationshipSpec, 0)
    }
    storeRelationshipSpecDetail.singleFieldSelect = true
    attributes.push(storeRelationshipSpecDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
