import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOEnumeralService} from '../../core/enumeral/eo-enumeral';
import {EnumeralListEntryFactory} from './enumeral-list-entry-factory';
import {EnumeralDetailFactory} from './enumeral-detail-factory';
import {SOGetEnumeral} from '../../core/enumeral/so-get-enumeral';
import {RAPutEnumeral} from './ra-put-enumeral';
import {RAPostEnumeral} from './ra-post-enumeral';
import {RADeleteEnumeral} from './ra-delete-enumeral';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import {SOGetApp} from '../../core/app/so-get-app'


export class EnumeralCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'enumeral';
  title = 'Enumeral';
  listEntryFactory = new EnumeralListEntryFactory();
  detailFactory = new EnumeralDetailFactory();

  entityService: EOEnumeralService;
  soGetClass = SOGetEnumeral;
  soGet: SOGetEnumeral;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOEnumeralService
    );
    this.adapters = [
      new RAPostEnumeral(this),
      new RAPutEnumeral(this),
      new RADeleteEnumeral(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetEnumeral();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.app = new SOGetApp(
      this.activeUserService.getScope('app').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
