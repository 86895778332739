import { SOGetEnumeratorEnumeralAssociation } from '../enumerator-enumeral-association/so-get-enumerator-enumeral-association';
import { SOGetOrganization } from '../organization/so-get-organization';
import { SOGetApp } from '../app/so-get-app';


export class SOPostEnumeral {

  constructor(
    public name: string,
    public enumerators: SOGetEnumeratorEnumeralAssociation[],
    public organization: SOGetOrganization,
    public app: SOGetApp,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostEnumeral(
      'name' in params ? params.name : null,
      'enumerators' in params ? params.enumerators : null,
      'organization' in params ? params.organization : null,
      'app' in params ? params.app : null,
    );
  }
}
