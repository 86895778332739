import {SORespResponse} from '../../core/response/so-resp-response';
import {ResponseCore} from './response.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putResponseFormFieldOptions} from './put-response-form-fields';
import {SOGetResponse} from '../../core/response/so-get-response';
import {SOGetResponseResponseAttributeSpecAssociation} from '../../core/response-response-attribute-spec-association/so-get-response-response-attribute-spec-association';
import {SOGetResponseResponseRelationshipSpecAssociation} from '../../core/response-response-relationship-spec-association/so-get-response-response-relationship-spec-association';
import {SOGetEntityResponseAssociation} from '../../core/entity-response-association/so-get-entity-response-association';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class ResponseDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespResponse = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const nameDetail = new DetailEntry(
        'Name',
        entity.name,
        'name',
        BaseDetailType.text,
        null,
        EditableType.always,
        putResponseFormFieldOptions.name
    )
    attributes.push(nameDetail)

    const responseTypeDetail = new DetailEntry(
        'Response type',
        entity.responseType,
        'responseType',
        BaseDetailType.toggleGroup,
        null,
        EditableType.always,
        putResponseFormFieldOptions.responseType
    )
    attributes.push(responseTypeDetail)

    const isDefaultDetail = new DetailEntry(
        'Is default',
        entity.isDefault,
        'isDefault',
        BaseDetailType.toggle,
        null,
        EditableType.always,
        putResponseFormFieldOptions.isDefault
    )
    attributes.push(isDefaultDetail)


    const attributeSpecsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'responseResponseAttributeSpecAssociation');
    attributeSpecsCore.soGet = SOGetResponseResponseAttributeSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, attributeSpecsCore)
    attributeSpecsCore.title = heading ? heading : attributeSpecsCore.title
    attributeSpecsCore.listTitle = 'Attribute specs'

    attributeSpecsCore.configureForDisplay(entityViewSpec)

    const attributeSpecsDetail = new DetailEntry(
      heading ? heading : attributeSpecsCore.listTitle,
      null,
      'attributeSpecs',
      BaseDetailType.embeddedList,
      attributeSpecsCore,
      EditableType.never
    )
    attributes.push(attributeSpecsDetail);

    const relationshipSpecsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'responseResponseRelationshipSpecAssociation');
    relationshipSpecsCore.soGet = SOGetResponseResponseRelationshipSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, relationshipSpecsCore)
    relationshipSpecsCore.title = heading ? heading : relationshipSpecsCore.title
    relationshipSpecsCore.listTitle = 'Relationship specs'

    relationshipSpecsCore.configureForDisplay(entityViewSpec)

    const relationshipSpecsDetail = new DetailEntry(
      heading ? heading : relationshipSpecsCore.listTitle,
      null,
      'relationshipSpecs',
      BaseDetailType.embeddedList,
      relationshipSpecsCore,
      EditableType.never
    )
    attributes.push(relationshipSpecsDetail);

    const entityCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'entityResponseAssociation');
    entityCore.soGet = SOGetEntityResponseAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, entityCore)
    entityCore.title = heading ? heading : entityCore.title
    entityCore.listTitle = 'Entity'

    entityCore.configureForDisplay(entityViewSpec)

    const entityDetail = new DetailEntry(
      heading ? heading : entityCore.listTitle,
      null,
      'entity',
      BaseDetailType.embeddedList,
      entityCore,
      EditableType.always,
      putResponseFormFieldOptions.entity
    )
    attributes.push(entityDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
