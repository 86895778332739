import { SOGetWidgetWidgetAttributeSpecAssociation } from '../widget-widget-attribute-spec-association/so-get-widget-widget-attribute-spec-association';
import { SOGetAttributeWidgetAttributeSpecAssociation } from '../attribute-widget-attribute-spec-association/so-get-attribute-widget-attribute-spec-association';


export class SOPutWidgetAttributeSpec {

  constructor(
    public uid: string,
    public isRequired: boolean = null,
    public defaultValue: string = null,
    public widget: SOGetWidgetWidgetAttributeSpecAssociation[] = null,
    public attribute: SOGetAttributeWidgetAttributeSpecAssociation[] = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutWidgetAttributeSpec(
      'uid' in params ? params.uid : null,
      'isRequired' in params ? params.isRequired : null,
      'defaultValue' in params ? params.defaultValue : null,
      'widget' in params ? params.widget : null,
      'attribute' in params ? params.attribute : null,
    );
  }
}
