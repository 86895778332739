import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const userField = new FormField(
  'user',
  'User',
  'user',
  FormFieldType.pushedSelectionList,
  true,
)


export const postCertificateFormFieldOptions: {[key: string]: FormField} = {
  user: userField,
};