import { SOGetWidget } from '../widget/so-get-widget';
import { SOGetWidgetRelationshipSpec } from '../widget-relationship-spec/so-get-widget-relationship-spec';
import { SOGetCommit } from '../commit/so-get-commit';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostWidgetWidgetRelationshipSpecAssociation {

  constructor(
    public widget: SOGetWidget,
    public widgetRelationshipSpec: SOGetWidgetRelationshipSpec,
    public commit: SOGetCommit,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostWidgetWidgetRelationshipSpecAssociation(
      'widget' in params ? params.widget : null,
      'widgetRelationshipSpec' in params ? params.widgetRelationshipSpec : null,
      'commit' in params ? params.commit : null,
      'organization' in params ? params.organization : null,
    );
  }
}
