import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostRelationshipStoreRelationshipSpecAssociation } from './so-post-relationship-store-relationship-spec-association';
import { SOGetRelationshipStoreRelationshipSpecAssociation } from './so-get-relationship-store-relationship-spec-association';
import { SOPutRelationshipStoreRelationshipSpecAssociation } from './so-put-relationship-store-relationship-spec-association';
import { SODeleteRelationshipStoreRelationshipSpecAssociation } from './so-delete-relationship-store-relationship-spec-association';
import {SORespRelationshipStoreRelationshipSpecAssociation} from './so-resp-relationship-store-relationship-spec-association';
import {SOPluralRelationshipStoreRelationshipSpecAssociation} from './so-plural-relationship-store-relationship-spec-association';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EORelationshipStoreRelationshipSpecAssociationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'relationship_store_relationship_spec_association',
      entityCore
    );
  }

  /** GET RelationshipStoreRelationshipSpecAssociation by id. Will 404 if id not found */
  get(request: SOGetRelationshipStoreRelationshipSpecAssociation): Observable<SOPluralRelationshipStoreRelationshipSpecAssociation> {
    return this.httpClient.get<SOPluralRelationshipStoreRelationshipSpecAssociation>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralRelationshipStoreRelationshipSpecAssociation, resp) }),
      tap(_ => this.log(`fetched RelationshipStoreRelationshipSpecAssociation`)),
      catchError(this.handleError<SOPluralRelationshipStoreRelationshipSpecAssociation>(`get RelationshipStoreRelationshipSpecAssociation`))
    );
  }

  /** POST: add a new RelationshipStoreRelationshipSpecAssociation to the server */
  post(request: SOPostRelationshipStoreRelationshipSpecAssociation): Observable<SORespRelationshipStoreRelationshipSpecAssociation> {
    return this.httpClient.post<SORespRelationshipStoreRelationshipSpecAssociation>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespRelationshipStoreRelationshipSpecAssociation, resp) }),
      tap((response: SORespRelationshipStoreRelationshipSpecAssociation) => this.log(`added RelationshipStoreRelationshipSpecAssociation w/ id=${response.uid}`)),
      catchError(this.handleError<SORespRelationshipStoreRelationshipSpecAssociation>('RelationshipStoreRelationshipSpecAssociation post'))
    );
  }

  /** PUT: update RelationshipStoreRelationshipSpecAssociation  */
  put(request: SOPutRelationshipStoreRelationshipSpecAssociation): Observable<SORespRelationshipStoreRelationshipSpecAssociation> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespRelationshipStoreRelationshipSpecAssociation>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespRelationshipStoreRelationshipSpecAssociation, resp) }),
      tap((response: SORespRelationshipStoreRelationshipSpecAssociation) => this.log(`edited RelationshipStoreRelationshipSpecAssociation w/ uid=${uid}`)),
      catchError(this.handleError<SORespRelationshipStoreRelationshipSpecAssociation>('RelationshipStoreRelationshipSpecAssociation put'))
    );
  }

  /** DELETE: delete the RelationshipStoreRelationshipSpecAssociation from the server */
  delete(request: SODeleteRelationshipStoreRelationshipSpecAssociation): Observable<SORespRelationshipStoreRelationshipSpecAssociation> {
    return this.httpClient.delete<SORespRelationshipStoreRelationshipSpecAssociation>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted RelationshipStoreRelationshipSpecAssociation uid=${request.uid}`)),
      catchError(this.handleError<SORespRelationshipStoreRelationshipSpecAssociation>('delete RelationshipStoreRelationshipSpecAssociation'))
    );
  }
}
