import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostWidgetWidgetAttributeSpecAssociation } from './so-post-widget-widget-attribute-spec-association';
import { SOGetWidgetWidgetAttributeSpecAssociation } from './so-get-widget-widget-attribute-spec-association';
import { SOPutWidgetWidgetAttributeSpecAssociation } from './so-put-widget-widget-attribute-spec-association';
import { SODeleteWidgetWidgetAttributeSpecAssociation } from './so-delete-widget-widget-attribute-spec-association';
import {SORespWidgetWidgetAttributeSpecAssociation} from './so-resp-widget-widget-attribute-spec-association';
import {SOPluralWidgetWidgetAttributeSpecAssociation} from './so-plural-widget-widget-attribute-spec-association';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOWidgetWidgetAttributeSpecAssociationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'widget_widget_attribute_spec_association',
      entityCore
    );
  }

  /** GET WidgetWidgetAttributeSpecAssociation by id. Will 404 if id not found */
  get(request: SOGetWidgetWidgetAttributeSpecAssociation): Observable<SOPluralWidgetWidgetAttributeSpecAssociation> {
    return this.httpClient.get<SOPluralWidgetWidgetAttributeSpecAssociation>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralWidgetWidgetAttributeSpecAssociation, resp) }),
      tap(_ => this.log(`fetched WidgetWidgetAttributeSpecAssociation`)),
      catchError(this.handleError<SOPluralWidgetWidgetAttributeSpecAssociation>(`get WidgetWidgetAttributeSpecAssociation`))
    );
  }

  /** POST: add a new WidgetWidgetAttributeSpecAssociation to the server */
  post(request: SOPostWidgetWidgetAttributeSpecAssociation): Observable<SORespWidgetWidgetAttributeSpecAssociation> {
    return this.httpClient.post<SORespWidgetWidgetAttributeSpecAssociation>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespWidgetWidgetAttributeSpecAssociation, resp) }),
      tap((response: SORespWidgetWidgetAttributeSpecAssociation) => this.log(`added WidgetWidgetAttributeSpecAssociation w/ id=${response.uid}`)),
      catchError(this.handleError<SORespWidgetWidgetAttributeSpecAssociation>('WidgetWidgetAttributeSpecAssociation post'))
    );
  }

  /** PUT: update WidgetWidgetAttributeSpecAssociation  */
  put(request: SOPutWidgetWidgetAttributeSpecAssociation): Observable<SORespWidgetWidgetAttributeSpecAssociation> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespWidgetWidgetAttributeSpecAssociation>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespWidgetWidgetAttributeSpecAssociation, resp) }),
      tap((response: SORespWidgetWidgetAttributeSpecAssociation) => this.log(`edited WidgetWidgetAttributeSpecAssociation w/ uid=${uid}`)),
      catchError(this.handleError<SORespWidgetWidgetAttributeSpecAssociation>('WidgetWidgetAttributeSpecAssociation put'))
    );
  }

  /** DELETE: delete the WidgetWidgetAttributeSpecAssociation from the server */
  delete(request: SODeleteWidgetWidgetAttributeSpecAssociation): Observable<SORespWidgetWidgetAttributeSpecAssociation> {
    return this.httpClient.delete<SORespWidgetWidgetAttributeSpecAssociation>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted WidgetWidgetAttributeSpecAssociation uid=${request.uid}`)),
      catchError(this.handleError<SORespWidgetWidgetAttributeSpecAssociation>('delete WidgetWidgetAttributeSpecAssociation'))
    );
  }
}
