import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostResponseAttributeSpec } from './so-post-response-attribute-spec';
import { SOGetResponseAttributeSpec } from './so-get-response-attribute-spec';
import { SOPutResponseAttributeSpec } from './so-put-response-attribute-spec';
import { SODeleteResponseAttributeSpec } from './so-delete-response-attribute-spec';
import {SORespResponseAttributeSpec} from './so-resp-response-attribute-spec';
import {SOPluralResponseAttributeSpec} from './so-plural-response-attribute-spec';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOResponseAttributeSpecService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'response_attribute_spec',
      entityCore
    );
  }

  /** GET ResponseAttributeSpec by id. Will 404 if id not found */
  get(request: SOGetResponseAttributeSpec): Observable<SOPluralResponseAttributeSpec> {
    return this.httpClient.get<SOPluralResponseAttributeSpec>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralResponseAttributeSpec, resp) }),
      tap(_ => this.log(`fetched ResponseAttributeSpec`)),
      catchError(this.handleError<SOPluralResponseAttributeSpec>(`get ResponseAttributeSpec`))
    );
  }

  /** POST: add a new ResponseAttributeSpec to the server */
  post(request: SOPostResponseAttributeSpec): Observable<SORespResponseAttributeSpec> {
    return this.httpClient.post<SORespResponseAttributeSpec>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespResponseAttributeSpec, resp) }),
      tap((response: SORespResponseAttributeSpec) => this.log(`added ResponseAttributeSpec w/ id=${response.uid}`)),
      catchError(this.handleError<SORespResponseAttributeSpec>('ResponseAttributeSpec post'))
    );
  }

  /** PUT: update ResponseAttributeSpec  */
  put(request: SOPutResponseAttributeSpec): Observable<SORespResponseAttributeSpec> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespResponseAttributeSpec>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespResponseAttributeSpec, resp) }),
      tap((response: SORespResponseAttributeSpec) => this.log(`edited ResponseAttributeSpec w/ uid=${uid}`)),
      catchError(this.handleError<SORespResponseAttributeSpec>('ResponseAttributeSpec put'))
    );
  }

  /** DELETE: delete the ResponseAttributeSpec from the server */
  delete(request: SODeleteResponseAttributeSpec): Observable<SORespResponseAttributeSpec> {
    return this.httpClient.delete<SORespResponseAttributeSpec>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted ResponseAttributeSpec uid=${request.uid}`)),
      catchError(this.handleError<SORespResponseAttributeSpec>('delete ResponseAttributeSpec'))
    );
  }
}
