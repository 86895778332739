import {SORespRequestAttributeSpec} from '../../core/request-attribute-spec/so-resp-request-attribute-spec';
import {RequestAttributeSpecCore} from './request-attribute-spec.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putRequestAttributeSpecFormFieldOptions} from './put-request-attribute-spec-form-fields';
import {SOGetRequestAttributeSpec} from '../../core/request-attribute-spec/so-get-request-attribute-spec';
import {SOGetRequestRequestAttributeSpecAssociation} from '../../core/request-request-attribute-spec-association/so-get-request-request-attribute-spec-association';
import {SOGetAttributeRequestAttributeSpecAssociation} from '../../core/attribute-request-attribute-spec-association/so-get-attribute-request-attribute-spec-association';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class RequestAttributeSpecDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespRequestAttributeSpec = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const isRequiredDetail = new DetailEntry(
        'Is required',
        entity.isRequired,
        'isRequired',
        BaseDetailType.toggle,
        null,
        EditableType.always,
        putRequestAttributeSpecFormFieldOptions.isRequired
    )
    attributes.push(isRequiredDetail)

    const defaultValueDetail = new DetailEntry(
        'Default value',
        entity.defaultValue,
        'defaultValue',
        BaseDetailType.text,
        null,
        EditableType.always,
        putRequestAttributeSpecFormFieldOptions.defaultValue
    )
    attributes.push(defaultValueDetail)


    const requestCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'requestRequestAttributeSpecAssociation');
    requestCore.soGet = SOGetRequestRequestAttributeSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, requestCore)
    requestCore.title = heading ? heading : requestCore.title
    requestCore.listTitle = 'Request'

    requestCore.configureForDisplay(entityViewSpec)

    const requestDetail = new DetailEntry(
      heading ? heading : requestCore.listTitle,
      null,
      'request',
      BaseDetailType.embeddedList,
      requestCore,
      EditableType.always,
      putRequestAttributeSpecFormFieldOptions.request
    )
    attributes.push(requestDetail);

    const attributeCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'attributeRequestAttributeSpecAssociation');
    attributeCore.soGet = SOGetAttributeRequestAttributeSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, attributeCore)
    attributeCore.title = heading ? heading : attributeCore.title
    attributeCore.listTitle = 'Attribute'

    attributeCore.configureForDisplay(entityViewSpec)

    const attributeDetail = new DetailEntry(
      heading ? heading : attributeCore.listTitle,
      null,
      'attribute',
      BaseDetailType.embeddedList,
      attributeCore,
      EditableType.always,
      putRequestAttributeSpecFormFieldOptions.attribute
    )
    attributes.push(attributeDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
