import {SOPutEntityStoreAssociation} from '../../core/entity-store-association/so-put-entity-store-association';
import {EntityStoreAssociationCore} from './entity-store-association.core';
import {SOGetEntityStoreAssociation} from '../../core/entity-store-association/so-get-entity-store-association';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putEntityStoreAssociationFormFieldOptions} from './put-entity-store-association-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {EntityCore} from '../bv-entity/entity.core';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {StoreCore} from '../bv-store/store.core';
import {SOGetStore} from '../../core/store/so-get-store';
import {CommitCore} from '../bv-commit/commit.core';
import {SOGetCommit} from '../../core/commit/so-get-commit';

export class RAPutEntityStoreAssociation extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putEntityStoreAssociationFormFieldOptions);

  RequestClass = SOPutEntityStoreAssociation

  constructor(
    public entityCore: EntityStoreAssociationCore
  ) {
    super();
    if (putEntityStoreAssociationFormFieldOptions.entity) {
      putEntityStoreAssociationFormFieldOptions.entity.makeCore = true;
    }
    if (putEntityStoreAssociationFormFieldOptions.store) {
      putEntityStoreAssociationFormFieldOptions.store.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier(
        'entity',
        SOGetEntity,
      ),
      this.getFormValueFromIdentifier(
        'store',
        SOGetStore,
      ),
      this.getFormValueFromIdentifier(
        'commit',
        SOGetCommit,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}