import {SORespResponseResponseAttributeSpecAssociation} from '../../core/response-response-attribute-spec-association/so-resp-response-response-attribute-spec-association';
import {ResponseResponseAttributeSpecAssociationCore} from './response-response-attribute-spec-association.core';
import { ListEntry, ListEntryFactory, ListEntryFactoryProtocol} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';


export class ResponseResponseAttributeSpecAssociationListEntryFactory  extends ListEntryFactory implements ListEntryFactoryProtocol {

  makeListEntry(parentCoreViewSpec, entityCore: EntityCoreProtocol, entity: SORespResponseResponseAttributeSpecAssociation, section: number = 0): ListEntry {
    const resp = super.makeListEntry(parentCoreViewSpec, entityCore, entity, section);
    return resp;
  }
}
