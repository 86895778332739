import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostWidgetWidgetRelationshipSpecAssociation } from './so-post-widget-widget-relationship-spec-association';
import { SOGetWidgetWidgetRelationshipSpecAssociation } from './so-get-widget-widget-relationship-spec-association';
import { SOPutWidgetWidgetRelationshipSpecAssociation } from './so-put-widget-widget-relationship-spec-association';
import { SODeleteWidgetWidgetRelationshipSpecAssociation } from './so-delete-widget-widget-relationship-spec-association';
import {SORespWidgetWidgetRelationshipSpecAssociation} from './so-resp-widget-widget-relationship-spec-association';
import {SOPluralWidgetWidgetRelationshipSpecAssociation} from './so-plural-widget-widget-relationship-spec-association';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOWidgetWidgetRelationshipSpecAssociationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'widget_widget_relationship_spec_association',
      entityCore
    );
  }

  /** GET WidgetWidgetRelationshipSpecAssociation by id. Will 404 if id not found */
  get(request: SOGetWidgetWidgetRelationshipSpecAssociation): Observable<SOPluralWidgetWidgetRelationshipSpecAssociation> {
    return this.httpClient.get<SOPluralWidgetWidgetRelationshipSpecAssociation>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralWidgetWidgetRelationshipSpecAssociation, resp) }),
      tap(_ => this.log(`fetched WidgetWidgetRelationshipSpecAssociation`)),
      catchError(this.handleError<SOPluralWidgetWidgetRelationshipSpecAssociation>(`get WidgetWidgetRelationshipSpecAssociation`))
    );
  }

  /** POST: add a new WidgetWidgetRelationshipSpecAssociation to the server */
  post(request: SOPostWidgetWidgetRelationshipSpecAssociation): Observable<SORespWidgetWidgetRelationshipSpecAssociation> {
    return this.httpClient.post<SORespWidgetWidgetRelationshipSpecAssociation>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespWidgetWidgetRelationshipSpecAssociation, resp) }),
      tap((response: SORespWidgetWidgetRelationshipSpecAssociation) => this.log(`added WidgetWidgetRelationshipSpecAssociation w/ id=${response.uid}`)),
      catchError(this.handleError<SORespWidgetWidgetRelationshipSpecAssociation>('WidgetWidgetRelationshipSpecAssociation post'))
    );
  }

  /** PUT: update WidgetWidgetRelationshipSpecAssociation  */
  put(request: SOPutWidgetWidgetRelationshipSpecAssociation): Observable<SORespWidgetWidgetRelationshipSpecAssociation> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespWidgetWidgetRelationshipSpecAssociation>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespWidgetWidgetRelationshipSpecAssociation, resp) }),
      tap((response: SORespWidgetWidgetRelationshipSpecAssociation) => this.log(`edited WidgetWidgetRelationshipSpecAssociation w/ uid=${uid}`)),
      catchError(this.handleError<SORespWidgetWidgetRelationshipSpecAssociation>('WidgetWidgetRelationshipSpecAssociation put'))
    );
  }

  /** DELETE: delete the WidgetWidgetRelationshipSpecAssociation from the server */
  delete(request: SODeleteWidgetWidgetRelationshipSpecAssociation): Observable<SORespWidgetWidgetRelationshipSpecAssociation> {
    return this.httpClient.delete<SORespWidgetWidgetRelationshipSpecAssociation>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted WidgetWidgetRelationshipSpecAssociation uid=${request.uid}`)),
      catchError(this.handleError<SORespWidgetWidgetRelationshipSpecAssociation>('delete WidgetWidgetRelationshipSpecAssociation'))
    );
  }
}
