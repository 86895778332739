import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOGetCertificate } from './so-get-certificate';
import { SOPostCertificate } from './so-post-certificate';
import { SOPutCertificate } from './so-put-certificate';
import { SODeleteCertificate } from './so-delete-certificate';
import {SORespCertificate} from './so-resp-certificate';
import {SOPluralCertificate} from './so-plural-certificate';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOCertificateService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'certificate',
      entityCore
    );
  }

  /** GET Certificate by id. Will 404 if id not found */
  get(request: SOGetCertificate): Observable<SOPluralCertificate> {
    return this.httpClient.get<SOPluralCertificate>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralCertificate, resp) }),
      tap(_ => this.log(`fetched Certificate`)),
      catchError(this.handleError<SOPluralCertificate>(`get Certificate`))
    );
  }

  /** POST: add a new Certificate to the server */
  post(request: SOPostCertificate): Observable<SORespCertificate> {
    return this.httpClient.post<SORespCertificate>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespCertificate, resp) }),
      tap((response: SORespCertificate) => this.log(`added Certificate w/ id=${response.uid}`)),
      catchError(this.handleError<SORespCertificate>('Certificate post'))
    );
  }

  /** PUT: update Certificate  */
  put(request: SOPutCertificate): Observable<SORespCertificate> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespCertificate>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespCertificate, resp) }),
      tap((response: SORespCertificate) => this.log(`edited Certificate w/ uid=${uid}`)),
      catchError(this.handleError<SORespCertificate>('Certificate put'))
    );
  }

  /** DELETE: delete the Certificate from the server */
  delete(request: SODeleteCertificate): Observable<SORespCertificate> {
    return this.httpClient.delete<SORespCertificate>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Certificate uid=${request.uid}`)),
      catchError(this.handleError<SORespCertificate>('delete Certificate'))
    );
  }
}
