import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOTokenService} from '../../core/token/eo-token';
import {TokenListEntryFactory} from './token-list-entry-factory';
import {TokenDetailFactory} from './token-detail-factory';
import {SOGetToken} from '../../core/token/so-get-token';
import {RAPutToken} from './ra-put-token';
import {RAPostToken} from './ra-post-token';
import {RADeleteToken} from './ra-delete-token';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';


export class TokenCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'token';
  title = 'Token';
  listEntryFactory = new TokenListEntryFactory();
  detailFactory = new TokenDetailFactory();

  entityService: EOTokenService;
  soGetClass = SOGetToken;
  soGet: SOGetToken;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOTokenService
    );
    this.adapters = [
      new RAPostToken(this),
      new RAPutToken(this),
      new RADeleteToken(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetToken();
    this.soGet.queryOptions.limit = this.limit;
  }


}
