import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPutSesMessage } from './so-put-ses-message';
import { SOPostSesMessage } from './so-post-ses-message';
import { SOGetSesMessage } from './so-get-ses-message';
import { SODeleteSesMessage } from './so-delete-ses-message';
import {SORespSesMessage} from './so-resp-ses-message';
import {SOPluralSesMessage} from './so-plural-ses-message';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOSesMessageService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'ses_message',
      entityCore
    );
  }

  /** GET SesMessage by id. Will 404 if id not found */
  get(request: SOGetSesMessage): Observable<SOPluralSesMessage> {
    return this.httpClient.get<SOPluralSesMessage>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralSesMessage, resp) }),
      tap(_ => this.log(`fetched SesMessage`)),
      catchError(this.handleError<SOPluralSesMessage>(`get SesMessage`))
    );
  }

  /** POST: add a new SesMessage to the server */
  post(request: SOPostSesMessage): Observable<SORespSesMessage> {
    return this.httpClient.post<SORespSesMessage>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespSesMessage, resp) }),
      tap((response: SORespSesMessage) => this.log(`added SesMessage w/ id=${response.uid}`)),
      catchError(this.handleError<SORespSesMessage>('SesMessage post'))
    );
  }

  /** PUT: update SesMessage  */
  put(request: SOPutSesMessage): Observable<SORespSesMessage> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespSesMessage>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespSesMessage, resp) }),
      tap((response: SORespSesMessage) => this.log(`edited SesMessage w/ uid=${uid}`)),
      catchError(this.handleError<SORespSesMessage>('SesMessage put'))
    );
  }

  /** DELETE: delete the SesMessage from the server */
  delete(request: SODeleteSesMessage): Observable<SORespSesMessage> {
    return this.httpClient.delete<SORespSesMessage>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted SesMessage uid=${request.uid}`)),
      catchError(this.handleError<SORespSesMessage>('delete SesMessage'))
    );
  }
}
