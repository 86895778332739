import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOConstraintService} from '../../core/constraint/eo-constraint';
import {ConstraintListEntryFactory} from './constraint-list-entry-factory';
import {ConstraintDetailFactory} from './constraint-detail-factory';
import {SOGetConstraint} from '../../core/constraint/so-get-constraint';
import {RAPutConstraint} from './ra-put-constraint';
import {RAPostConstraint} from './ra-post-constraint';
import {RADeleteConstraint} from './ra-delete-constraint';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';


export class ConstraintCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'constraint';
  title = 'Constraint';
  listEntryFactory = new ConstraintListEntryFactory();
  detailFactory = new ConstraintDetailFactory();

  entityService: EOConstraintService;
  soGetClass = SOGetConstraint;
  soGet: SOGetConstraint;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOConstraintService
    );
    this.adapters = [
      new RAPostConstraint(this),
      new RAPutConstraint(this),
      new RADeleteConstraint(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetConstraint();
    this.soGet.queryOptions.limit = this.limit;
  }


}
