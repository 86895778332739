import {SORespBranchCommitAssociation} from '../../core/branch-commit-association/so-resp-branch-commit-association';
import {BranchCommitAssociationCore} from './branch-commit-association.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putBranchCommitAssociationFormFieldOptions} from './put-branch-commit-association-form-fields';
import {SOGetBranchCommitAssociation} from '../../core/branch-commit-association/so-get-branch-commit-association';
import {SOGetBranch} from '../../core/branch/so-get-branch';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {SOGetApp} from '../../core/app/so-get-app';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class BranchCommitAssociationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespBranchCommitAssociation = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []


    const branchCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'branch');
    branchCore.soGet = SOGetBranch.construct({
      app: new SOGetApp(activeUserService.getScope('app').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, branchCore)
    branchCore.title = heading ? heading : branchCore.title
    branchCore.listTitle = 'Branch'

    if (entity.branch) {
        displayStringFactory.reconfigure(entity.branch, entityViewSpec.entityCore.type)
    }
    branchCore.configureForDisplay(entityViewSpec)

    const branchDetail = new DetailEntry(
      heading ? heading : branchCore.listTitle,
      entity.branch ? displayStringFactory.primaryString : null,
      'branch',
      BaseDetailType.pushedDetail,
      branchCore,
      EditableType.always,
      putBranchCommitAssociationFormFieldOptions.branch
    )
    if (entity.branch) {
      branchDetail.cachedListEntry = branchCore.makeListEntry(entityViewSpec, branchCore, entity.branch, 0)
    }
    branchDetail.singleFieldSelect = true
    attributes.push(branchDetail);

    const commitCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'commit');
    commitCore.soGet = SOGetCommit.construct({
      app: new SOGetApp(activeUserService.getScope('app').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, commitCore)
    commitCore.title = heading ? heading : commitCore.title
    commitCore.listTitle = 'Commit'

    if (entity.commit) {
        displayStringFactory.reconfigure(entity.commit, entityViewSpec.entityCore.type)
    }
    commitCore.configureForDisplay(entityViewSpec)

    const commitDetail = new DetailEntry(
      heading ? heading : commitCore.listTitle,
      entity.commit ? displayStringFactory.primaryString : null,
      'commit',
      BaseDetailType.pushedDetail,
      commitCore,
      EditableType.always,
      putBranchCommitAssociationFormFieldOptions.commit
    )
    if (entity.commit) {
      commitDetail.cachedListEntry = commitCore.makeListEntry(entityViewSpec, commitCore, entity.commit, 0)
    }
    commitDetail.singleFieldSelect = true
    attributes.push(commitDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
