import {SORespRequestRelationshipSpec} from '../../core/request-relationship-spec/so-resp-request-relationship-spec';
import {RequestRelationshipSpecCore} from './request-relationship-spec.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putRequestRelationshipSpecFormFieldOptions} from './put-request-relationship-spec-form-fields';
import {SOGetRequestRelationshipSpec} from '../../core/request-relationship-spec/so-get-request-relationship-spec';
import {SOGetRequestRequestRelationshipSpecAssociation} from '../../core/request-request-relationship-spec-association/so-get-request-request-relationship-spec-association';
import {SOGetRelationshipRequestRelationshipSpecAssociation} from '../../core/relationship-request-relationship-spec-association/so-get-relationship-request-relationship-spec-association';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class RequestRelationshipSpecDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespRequestRelationshipSpec = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const isRequiredDetail = new DetailEntry(
        'Is required',
        entity.isRequired,
        'isRequired',
        BaseDetailType.toggle,
        null,
        EditableType.always,
        putRequestRelationshipSpecFormFieldOptions.isRequired
    )
    attributes.push(isRequiredDetail)


    const requestCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'requestRequestRelationshipSpecAssociation');
    requestCore.soGet = SOGetRequestRequestRelationshipSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, requestCore)
    requestCore.title = heading ? heading : requestCore.title
    requestCore.listTitle = 'Request'

    requestCore.configureForDisplay(entityViewSpec)

    const requestDetail = new DetailEntry(
      heading ? heading : requestCore.listTitle,
      null,
      'request',
      BaseDetailType.embeddedList,
      requestCore,
      EditableType.always,
      putRequestRelationshipSpecFormFieldOptions.request
    )
    attributes.push(requestDetail);

    const relationshipCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'relationshipRequestRelationshipSpecAssociation');
    relationshipCore.soGet = SOGetRelationshipRequestRelationshipSpecAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, relationshipCore)
    relationshipCore.title = heading ? heading : relationshipCore.title
    relationshipCore.listTitle = 'Relationship'

    relationshipCore.configureForDisplay(entityViewSpec)

    const relationshipDetail = new DetailEntry(
      heading ? heading : relationshipCore.listTitle,
      null,
      'relationship',
      BaseDetailType.embeddedList,
      relationshipCore,
      EditableType.always,
      putRequestRelationshipSpecFormFieldOptions.relationship
    )
    attributes.push(relationshipDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
