import {SORespCommitRelationshipLinkAssociation} from '../../core/commit-relationship-link-association/so-resp-commit-relationship-link-association';
import {CommitRelationshipLinkAssociationCore} from './commit-relationship-link-association.core';
import { ListEntry, ListEntryFactory, ListEntryFactoryProtocol} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';


export class CommitRelationshipLinkAssociationListEntryFactory  extends ListEntryFactory implements ListEntryFactoryProtocol {

  makeListEntry(parentCoreViewSpec, entityCore: EntityCoreProtocol, entity: SORespCommitRelationshipLinkAssociation, section: number = 0): ListEntry {
    const resp = super.makeListEntry(parentCoreViewSpec, entityCore, entity, section)
    return resp
  }
}
