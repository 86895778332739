import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const requestField = new FormField(
  'request',
  'Request',
  'request',
  FormFieldType.pushedSelectionList,
  false,
)
const requestAttributeSpecField = new FormField(
  'requestAttributeSpec',
  'Request attribute spec',
  'requestAttributeSpec',
  FormFieldType.pushedSelectionList,
  false,
)


export const putRequestRequestAttributeSpecAssociationFormFieldOptions: {[key: string]: FormField} = {
  request: requestField,
  requestAttributeSpec: requestAttributeSpecField,
};