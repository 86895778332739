import { SOGetWidgetWidgetAttributeSpecAssociation } from '../widget-widget-attribute-spec-association/so-get-widget-widget-attribute-spec-association';
import { SOGetAttributeWidgetAttributeSpecAssociation } from '../attribute-widget-attribute-spec-association/so-get-attribute-widget-attribute-spec-association';
import { SOGetOrganization } from '../organization/so-get-organization';
import { SOGetApp } from '../app/so-get-app';


export class SOPostWidgetAttributeSpec {

  constructor(
    public widget: SOGetWidgetWidgetAttributeSpecAssociation[],
    public attribute: SOGetAttributeWidgetAttributeSpecAssociation[],
    public organization: SOGetOrganization,
    public app: SOGetApp,
    public isRequired: boolean = null,
    public defaultValue: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostWidgetAttributeSpec(
      'isRequired' in params ? params.isRequired : null,
      'defaultValue' in params ? params.defaultValue : null,
      'widget' in params ? params.widget : null,
      'attribute' in params ? params.attribute : null,
      'organization' in params ? params.organization : null,
      'app' in params ? params.app : null,
    );
  }
}
