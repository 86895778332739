import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOResponseService} from '../../core/response/eo-response';
import {ResponseListEntryFactory} from './response-list-entry-factory';
import {ResponseDetailFactory} from './response-detail-factory';
import {SOGetResponse} from '../../core/response/so-get-response';
import {RAPutResponse} from './ra-put-response';
import {RAPostResponse} from './ra-post-response';
import {RADeleteResponse} from './ra-delete-response';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import {SOGetApp} from '../../core/app/so-get-app'


export class ResponseCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'response';
  title = 'Response';
  listEntryFactory = new ResponseListEntryFactory();
  detailFactory = new ResponseDetailFactory();

  entityService: EOResponseService;
  soGetClass = SOGetResponse;
  soGet: SOGetResponse;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOResponseService
    );
    this.adapters = [
      new RAPostResponse(this),
      new RAPutResponse(this),
      new RADeleteResponse(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetResponse();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.app = new SOGetApp(
      this.activeUserService.getScope('app').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
