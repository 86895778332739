import { SOGetAttribute } from '../attribute/so-get-attribute';
import { SOGetWidgetAttributeSpec } from '../widget-attribute-spec/so-get-widget-attribute-spec';
import { SOGetCommit } from '../commit/so-get-commit';


export class SOPutAttributeWidgetAttributeSpecAssociation {

  constructor(
    public uid: string,
    public attribute: SOGetAttribute = null,
    public widget: SOGetWidgetAttributeSpec = null,
    public commit: SOGetCommit = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutAttributeWidgetAttributeSpecAssociation(
      'uid' in params ? params.uid : null,
      'attribute' in params ? params.attribute : null,
      'widget' in params ? params.widget : null,
      'commit' in params ? params.commit : null,
    );
  }
}
