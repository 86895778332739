import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOCommitRelationshipLinkAssociationService} from '../../core/commit-relationship-link-association/eo-commit-relationship-link-association';
import {CommitRelationshipLinkAssociationListEntryFactory} from './commit-relationship-link-association-list-entry-factory';
import {CommitRelationshipLinkAssociationDetailFactory} from './commit-relationship-link-association-detail-factory';
import {SOGetCommitRelationshipLinkAssociation} from '../../core/commit-relationship-link-association/so-get-commit-relationship-link-association';
import {RAPutCommitRelationshipLinkAssociation} from './ra-put-commit-relationship-link-association';
import {RAPostCommitRelationshipLinkAssociation} from './ra-post-commit-relationship-link-association';
import {RADeleteCommitRelationshipLinkAssociation} from './ra-delete-commit-relationship-link-association';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetOrganization} from '../../core/organization/so-get-organization'


export class CommitRelationshipLinkAssociationCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'commitRelationshipLinkAssociation';
  title = 'Commit relationship link association';
  listEntryFactory = new CommitRelationshipLinkAssociationListEntryFactory();
  detailFactory = new CommitRelationshipLinkAssociationDetailFactory();

  entityService: EOCommitRelationshipLinkAssociationService;
  soGetClass = SOGetCommitRelationshipLinkAssociation;
  soGet: SOGetCommitRelationshipLinkAssociation;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOCommitRelationshipLinkAssociationService
    );
    this.adapters = [
      new RAPostCommitRelationshipLinkAssociation(this),
      new RAPutCommitRelationshipLinkAssociation(this),
      new RADeleteCommitRelationshipLinkAssociation(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetCommitRelationshipLinkAssociation();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
