import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const entityField = new FormField(
  'entity',
  'Entity',
  'entity',
  FormFieldType.pushedSelectionList,
  false,
)
const responseField = new FormField(
  'response',
  'Response',
  'response',
  FormFieldType.pushedSelectionList,
  false,
)


export const putEntityResponseAssociationFormFieldOptions: {[key: string]: FormField} = {
  entity: entityField,
  response: responseField,
};