import {SORespIdentity} from '../../core/identity/so-resp-identity';
import {IdentityCore} from './identity.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putIdentityFormFieldOptions} from './put-identity-form-fields';
import {SOGetIdentity} from '../../core/identity/so-get-identity';
import {SOGetUser} from '../../core/user/so-get-user';


export class IdentityDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespIdentity = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const providerDetail = new DetailEntry(
        'Provider',
        entity.provider,
        'provider',
        BaseDetailType.text,
        null,
        EditableType.always,
        putIdentityFormFieldOptions.provider
    )
    attributes.push(providerDetail)


    const userCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'user');
    userCore.soGet = SOGetUser.construct({
    });
    heading = getHeading(entityViewSpec.entityCore, userCore)
    userCore.title = heading ? heading : userCore.title
    userCore.listTitle = 'User'

    if (entity.user) {
        displayStringFactory.reconfigure(entity.user, entityViewSpec.entityCore.type)
    }
    userCore.configureForDisplay(entityViewSpec)

    const userDetail = new DetailEntry(
      heading ? heading : userCore.listTitle,
      entity.user ? displayStringFactory.primaryString : null,
      'user',
      BaseDetailType.pushedDetail,
      userCore,
      EditableType.always,
      putIdentityFormFieldOptions.user
    )
    if (entity.user) {
      userDetail.cachedListEntry = userCore.makeListEntry(entityViewSpec, userCore, entity.user, 0)
    }
    userDetail.singleFieldSelect = true
    attributes.push(userDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
