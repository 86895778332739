import {SORespRequestRequestAttributeSpecAssociation} from '../../core/request-request-attribute-spec-association/so-resp-request-request-attribute-spec-association';
import {RequestRequestAttributeSpecAssociationCore} from './request-request-attribute-spec-association.core';
import { ListEntry, ListEntryFactory, ListEntryFactoryProtocol} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';


export class RequestRequestAttributeSpecAssociationListEntryFactory  extends ListEntryFactory implements ListEntryFactoryProtocol {

  makeListEntry(parentCoreViewSpec, entityCore: EntityCoreProtocol, entity: SORespRequestRequestAttributeSpecAssociation, section: number = 0): ListEntry {
    const resp = super.makeListEntry(parentCoreViewSpec, entityCore, entity, section);
    return resp;
  }
}
