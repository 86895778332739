import { Type } from 'class-transformer';
import { SORespRequestRequestRelationshipSpecAssociation } from '../request-request-relationship-spec-association/so-resp-request-request-relationship-spec-association';
import { SORespRelationshipRequestRelationshipSpecAssociation } from '../relationship-request-relationship-spec-association/so-resp-relationship-request-relationship-spec-association';
import { SORespOrganization } from '../organization/so-resp-organization';
import { SORespApp } from '../app/so-resp-app';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespRequestRelationshipSpec implements ObjectiveProtocol {

  entityType: string = 'requestRelationshipSpec';
  uid: string;
  isRequired: boolean;
  @Type(() => SORespRequestRequestRelationshipSpecAssociation)
  request: SORespRequestRequestRelationshipSpecAssociation[];
  @Type(() => SORespRelationshipRequestRelationshipSpecAssociation)
  relationship: SORespRelationshipRequestRelationshipSpecAssociation[];
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
  @Type(() => SORespApp)
  app: SORespApp;
}