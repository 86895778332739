import { SOGetWidget } from '../widget/so-get-widget';
import { SOGetWidgetRelationshipSpec } from '../widget-relationship-spec/so-get-widget-relationship-spec';


export class SOPutWidgetWidgetRelationshipSpecAssociation {

  constructor(
    public uid: string,
    public widget: SOGetWidget = null,
    public widgetRelationshipSpec: SOGetWidgetRelationshipSpec = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutWidgetWidgetRelationshipSpecAssociation(
      'uid' in params ? params.uid : null,
      'widget' in params ? params.widget : null,
      'widgetRelationshipSpec' in params ? params.widgetRelationshipSpec : null,
    );
  }
}
