import {SORespAttributeWidgetAttributeSpecAssociation} from '../../core/attribute-widget-attribute-spec-association/so-resp-attribute-widget-attribute-spec-association';
import {AttributeWidgetAttributeSpecAssociationCore} from './attribute-widget-attribute-spec-association.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putAttributeWidgetAttributeSpecAssociationFormFieldOptions} from './put-attribute-widget-attribute-spec-association-form-fields';
import {SOGetAttributeWidgetAttributeSpecAssociation} from '../../core/attribute-widget-attribute-spec-association/so-get-attribute-widget-attribute-spec-association';
import {SOGetAttribute} from '../../core/attribute/so-get-attribute';
import {SOGetWidgetAttributeSpec} from '../../core/widget-attribute-spec/so-get-widget-attribute-spec';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetApp} from '../../core/app/so-get-app';


export class AttributeWidgetAttributeSpecAssociationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespAttributeWidgetAttributeSpecAssociation = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = [];


    const attributeCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'attribute');
    attributeCore.soGet = SOGetAttribute.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, attributeCore);
    attributeCore.title = heading ? heading : attributeCore.title;
    attributeCore.listTitle = 'Attribute';

    if (entity.attribute) {
        displayStringFactory.reconfigure(entity.attribute, entityViewSpec.entityCore.type);
    }
    attributeCore.configureForDisplay(entityViewSpec);

    const attributeDetail = new DetailEntry(
      heading ? heading : attributeCore.listTitle,
      entity.attribute ? displayStringFactory.primaryString : null,
      'attribute',
      BaseDetailType.pushedDetail,
      attributeCore,
      EditableType.always,
      putAttributeWidgetAttributeSpecAssociationFormFieldOptions.attribute
    );
    if (entity.attribute) {
      attributeDetail.cachedListEntry = attributeCore.makeListEntry(entityViewSpec, attributeCore, entity.attribute, 0);
    }
    attributeDetail.singleFieldSelect = true;
    attributes.push(attributeDetail);

    const widgetCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'widgetAttributeSpec');
    widgetCore.soGet = SOGetWidgetAttributeSpec.construct({
      attribute: new SOGetAttributeWidgetAttributeSpecAssociation(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, widgetCore);
    widgetCore.title = heading ? heading : widgetCore.title;
    widgetCore.listTitle = 'Widget';

    if (entity.widget) {
        displayStringFactory.reconfigure(entity.widget, entityViewSpec.entityCore.type);
    }
    widgetCore.configureForDisplay(entityViewSpec);

    const widgetDetail = new DetailEntry(
      heading ? heading : widgetCore.listTitle,
      entity.widget ? displayStringFactory.primaryString : null,
      'widget',
      BaseDetailType.pushedDetail,
      widgetCore,
      EditableType.always,
      putAttributeWidgetAttributeSpecAssociationFormFieldOptions.widget
    );
    if (entity.widget) {
      widgetDetail.cachedListEntry = widgetCore.makeListEntry(entityViewSpec, widgetCore, entity.widget, 0);
    }
    widgetDetail.singleFieldSelect = true;
    attributes.push(widgetDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
