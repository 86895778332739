import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const parentField = new FormField(
  'parent',
  'Parent',
  'entity',
  FormFieldType.pushedSelectionList,
  true,
)
const childField = new FormField(
  'child',
  'Child',
  'entity',
  FormFieldType.pushedSelectionList,
  true,
)


export const postEntityChildrenAssociationFormFieldOptions: {[key: string]: FormField} = {
  parent: parentField,
  child: childField,
};