import {SORespEnumeral} from '../../core/enumeral/so-resp-enumeral';
import {EnumeralCore} from './enumeral.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putEnumeralFormFieldOptions} from './put-enumeral-form-fields';
import {SOGetEnumeral} from '../../core/enumeral/so-get-enumeral';
import {SOGetEnumeratorEnumeralAssociation} from '../../core/enumerator-enumeral-association/so-get-enumerator-enumeral-association';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class EnumeralDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespEnumeral = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const nameDetail = new DetailEntry(
        'Name',
        entity.name,
        'name',
        BaseDetailType.text,
        null,
        EditableType.always,
        putEnumeralFormFieldOptions.name
    )
    attributes.push(nameDetail)


    const enumeratorsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'enumeratorEnumeralAssociation');
    enumeratorsCore.soGet = SOGetEnumeratorEnumeralAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, enumeratorsCore)
    enumeratorsCore.title = heading ? heading : enumeratorsCore.title
    enumeratorsCore.listTitle = 'Enumerators'

    enumeratorsCore.configureForDisplay(entityViewSpec)

    const enumeratorsDetail = new DetailEntry(
      heading ? heading : enumeratorsCore.listTitle,
      null,
      'enumerators',
      BaseDetailType.embeddedList,
      enumeratorsCore,
      EditableType.always,
      putEnumeralFormFieldOptions.enumerators
    )
    attributes.push(enumeratorsDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
