import {SORespAttributeResponseAttributeSpecAssociation} from '../../core/attribute-response-attribute-spec-association/so-resp-attribute-response-attribute-spec-association';
import {AttributeResponseAttributeSpecAssociationCore} from './attribute-response-attribute-spec-association.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putAttributeResponseAttributeSpecAssociationFormFieldOptions} from './put-attribute-response-attribute-spec-association-form-fields';
import {SOGetAttributeResponseAttributeSpecAssociation} from '../../core/attribute-response-attribute-spec-association/so-get-attribute-response-attribute-spec-association';
import {SOGetAttribute} from '../../core/attribute/so-get-attribute';
import {SOGetResponseAttributeSpec} from '../../core/response-attribute-spec/so-get-response-attribute-spec';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetApp} from '../../core/app/so-get-app';


export class AttributeResponseAttributeSpecAssociationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespAttributeResponseAttributeSpecAssociation = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = [];


    const attributeCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'attribute');
    attributeCore.soGet = SOGetAttribute.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, attributeCore);
    attributeCore.title = heading ? heading : attributeCore.title;
    attributeCore.listTitle = 'Attribute';

    if (entity.attribute) {
        displayStringFactory.reconfigure(entity.attribute, entityViewSpec.entityCore.type);
    }
    attributeCore.configureForDisplay(entityViewSpec);

    const attributeDetail = new DetailEntry(
      heading ? heading : attributeCore.listTitle,
      entity.attribute ? displayStringFactory.primaryString : null,
      'attribute',
      BaseDetailType.pushedDetail,
      attributeCore,
      EditableType.always,
      putAttributeResponseAttributeSpecAssociationFormFieldOptions.attribute
    );
    if (entity.attribute) {
      attributeDetail.cachedListEntry = attributeCore.makeListEntry(entityViewSpec, attributeCore, entity.attribute, 0);
    }
    attributeDetail.singleFieldSelect = true;
    attributes.push(attributeDetail);

    const responseCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'responseAttributeSpec');
    responseCore.soGet = SOGetResponseAttributeSpec.construct({
      attribute: new SOGetAttributeResponseAttributeSpecAssociation(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, responseCore);
    responseCore.title = heading ? heading : responseCore.title;
    responseCore.listTitle = 'Response';

    if (entity.response) {
        displayStringFactory.reconfigure(entity.response, entityViewSpec.entityCore.type);
    }
    responseCore.configureForDisplay(entityViewSpec);

    const responseDetail = new DetailEntry(
      heading ? heading : responseCore.listTitle,
      entity.response ? displayStringFactory.primaryString : null,
      'response',
      BaseDetailType.pushedDetail,
      responseCore,
      EditableType.always,
      putAttributeResponseAttributeSpecAssociationFormFieldOptions.response
    );
    if (entity.response) {
      responseDetail.cachedListEntry = responseCore.makeListEntry(entityViewSpec, responseCore, entity.response, 0);
    }
    responseDetail.singleFieldSelect = true;
    attributes.push(responseDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
