import {SOPutAttributeRequestAttributeSpecAssociation} from '../../core/attribute-request-attribute-spec-association/so-put-attribute-request-attribute-spec-association';
import {AttributeRequestAttributeSpecAssociationCore} from './attribute-request-attribute-spec-association.core';
import {SOGetAttributeRequestAttributeSpecAssociation} from '../../core/attribute-request-attribute-spec-association/so-get-attribute-request-attribute-spec-association';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putAttributeRequestAttributeSpecAssociationFormFieldOptions} from './put-attribute-request-attribute-spec-association-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {AttributeCore} from '../bv-attribute/attribute.core';
import {SOGetAttribute} from '../../core/attribute/so-get-attribute';
import {RequestAttributeSpecCore} from '../bv-request-attribute-spec/request-attribute-spec.core';
import {SOGetRequestAttributeSpec} from '../../core/request-attribute-spec/so-get-request-attribute-spec';
import {CommitCore} from '../bv-commit/commit.core';
import {SOGetCommit} from '../../core/commit/so-get-commit';

export class RAPutAttributeRequestAttributeSpecAssociation extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putAttributeRequestAttributeSpecAssociationFormFieldOptions);

  RequestClass = SOPutAttributeRequestAttributeSpecAssociation

  constructor(
    public entityCore: AttributeRequestAttributeSpecAssociationCore
  ) {
    super();
    if (putAttributeRequestAttributeSpecAssociationFormFieldOptions.attribute) {
      putAttributeRequestAttributeSpecAssociationFormFieldOptions.attribute.makeCore = true;
    }
    if (putAttributeRequestAttributeSpecAssociationFormFieldOptions.request) {
      putAttributeRequestAttributeSpecAssociationFormFieldOptions.request.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier(
        'attribute',
        SOGetAttribute,
      ),
      this.getFormValueFromIdentifier(
        'request',
        SOGetRequestAttributeSpec,
      ),
      this.getFormValueFromIdentifier(
        'commit',
        SOGetCommit,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}