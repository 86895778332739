import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {NgxLionheartMaterialModule} from 'ngx-lionheart-material';
import {BrowserModule} from "@angular/platform-browser";
import {RouterModule} from "@angular/router";
import {AppRoutingModule} from "./app-routing.module";


@NgModule({
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    NgxLionheartMaterialModule,
  ],
  declarations: [
    AppComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
