import {SOPostCommitEntityAssociation} from '../../core/commit-entity-association/so-post-commit-entity-association';
import {CommitEntityAssociationCore} from './commit-entity-association.core';
import {SOGetCommitEntityAssociation} from '../../core/commit-entity-association/so-get-commit-entity-association';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postCommitEntityAssociationFormFieldOptions} from './post-commit-entity-association-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {CommitCore} from '../bv-commit/commit.core';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {EntityCore} from '../bv-entity/entity.core';
import {SOGetEntity} from '../../core/entity/so-get-entity';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostCommitEntityAssociation extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postCommitEntityAssociationFormFieldOptions);

  RequestClass = SOPostCommitEntityAssociation

  constructor(
    public entityCore: CommitEntityAssociationCore
  ) {
    super();
    if (postCommitEntityAssociationFormFieldOptions.commit) {
      postCommitEntityAssociationFormFieldOptions.commit.makeCore = true;
    }
    if (postCommitEntityAssociationFormFieldOptions.entity) {
      postCommitEntityAssociationFormFieldOptions.entity.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'commit',
        SOGetCommit,
      ),
      this.getFormValueFromIdentifier(
        'entity',
        SOGetEntity,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}