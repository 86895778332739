import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOResponseAttributeSpecService} from '../../core/response-attribute-spec/eo-response-attribute-spec';
import {ResponseAttributeSpecListEntryFactory} from './response-attribute-spec-list-entry-factory';
import {ResponseAttributeSpecDetailFactory} from './response-attribute-spec-detail-factory';
import {SOGetResponseAttributeSpec} from '../../core/response-attribute-spec/so-get-response-attribute-spec';
import {RAPutResponseAttributeSpec} from './ra-put-response-attribute-spec';
import {RAPostResponseAttributeSpec} from './ra-post-response-attribute-spec';
import {RADeleteResponseAttributeSpec} from './ra-delete-response-attribute-spec';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetOrganization} from '../../core/organization/so-get-organization'
import {SOGetApp} from '../../core/app/so-get-app'


export class ResponseAttributeSpecCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'responseAttributeSpec';
  title = 'Response attribute spec';
  listEntryFactory = new ResponseAttributeSpecListEntryFactory();
  detailFactory = new ResponseAttributeSpecDetailFactory();

  entityService: EOResponseAttributeSpecService;
  soGetClass = SOGetResponseAttributeSpec;
  soGet: SOGetResponseAttributeSpec;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOResponseAttributeSpecService
    );
    this.adapters = [
      new RAPostResponseAttributeSpec(this),
      new RAPutResponseAttributeSpec(this),
      new RADeleteResponseAttributeSpec(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetResponseAttributeSpec();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.app = new SOGetApp(
      this.activeUserService.getScope('app').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
