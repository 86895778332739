import {SOPostResponseRelationshipSpec} from '../../core/response-relationship-spec/so-post-response-relationship-spec';
import {ResponseRelationshipSpecCore} from './response-relationship-spec.core';
import {SOGetResponseRelationshipSpec} from '../../core/response-relationship-spec/so-get-response-relationship-spec';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postResponseRelationshipSpecFormFieldOptions} from './post-response-relationship-spec-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {ResponseResponseRelationshipSpecAssociationCore} from '../bv-response-response-relationship-spec-association/response-response-relationship-spec-association.core';
import {SOGetResponseResponseRelationshipSpecAssociation} from '../../core/response-response-relationship-spec-association/so-get-response-response-relationship-spec-association';
import {RelationshipResponseRelationshipSpecAssociationCore} from '../bv-relationship-response-relationship-spec-association/relationship-response-relationship-spec-association.core';
import {SOGetRelationshipResponseRelationshipSpecAssociation} from '../../core/relationship-response-relationship-spec-association/so-get-relationship-response-relationship-spec-association';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {AppCore} from '../bv-app/app.core';
import {SOGetApp} from '../../core/app/so-get-app';

export class RAPostResponseRelationshipSpec extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postResponseRelationshipSpecFormFieldOptions);

  RequestClass = SOPostResponseRelationshipSpec

  constructor(
    public entityCore: ResponseRelationshipSpecCore
  ) {
    super();
    if (postResponseRelationshipSpecFormFieldOptions.response) {
      postResponseRelationshipSpecFormFieldOptions.response.makeCore = true;
    }
    if (postResponseRelationshipSpecFormFieldOptions.relationship) {
      postResponseRelationshipSpecFormFieldOptions.relationship.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('isRequired'),
      [this.getFormValueFromIdentifier('response', SOGetResponseResponseRelationshipSpecAssociation)],
      [this.getFormValueFromIdentifier('relationship', SOGetRelationshipResponseRelationshipSpecAssociation)],
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier(
        'app',
        SOGetApp,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}