import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const isRequiredField = new FormField(
  'isRequired',
  'Is required',
  null,
  FormFieldType.boolean,
  false,
)
const responseField = new FormField(
  'response',
  'Response',
  'responseResponseRelationshipSpecAssociation',
  FormFieldType.pushedSelectionList,
  false,
)
const relationshipField = new FormField(
  'relationship',
  'Relationship',
  'relationshipResponseRelationshipSpecAssociation',
  FormFieldType.pushedSelectionList,
  false,
)


export const putResponseRelationshipSpecFormFieldOptions: {[key: string]: FormField} = {
  isRequired: isRequiredField,
  response: responseField,
  relationship: relationshipField,
};