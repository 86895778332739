import {SORespResponseResponseAttributeSpecAssociation} from '../../core/response-response-attribute-spec-association/so-resp-response-response-attribute-spec-association';
import {ResponseResponseAttributeSpecAssociationCore} from './response-response-attribute-spec-association.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putResponseResponseAttributeSpecAssociationFormFieldOptions} from './put-response-response-attribute-spec-association-form-fields';
import {SOGetResponseResponseAttributeSpecAssociation} from '../../core/response-response-attribute-spec-association/so-get-response-response-attribute-spec-association';
import {SOGetResponse} from '../../core/response/so-get-response';
import {SOGetResponseAttributeSpec} from '../../core/response-attribute-spec/so-get-response-attribute-spec';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetApp} from '../../core/app/so-get-app';


export class ResponseResponseAttributeSpecAssociationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespResponseResponseAttributeSpecAssociation = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = [];


    const responseCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'response');
    responseCore.soGet = SOGetResponse.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, responseCore);
    responseCore.title = heading ? heading : responseCore.title;
    responseCore.listTitle = 'Response';

    if (entity.response) {
        displayStringFactory.reconfigure(entity.response, entityViewSpec.entityCore.type);
    }
    responseCore.configureForDisplay(entityViewSpec);

    const responseDetail = new DetailEntry(
      heading ? heading : responseCore.listTitle,
      entity.response ? displayStringFactory.primaryString : null,
      'response',
      BaseDetailType.pushedDetail,
      responseCore,
      EditableType.always,
      putResponseResponseAttributeSpecAssociationFormFieldOptions.response
    );
    if (entity.response) {
      responseDetail.cachedListEntry = responseCore.makeListEntry(entityViewSpec, responseCore, entity.response, 0);
    }
    responseDetail.singleFieldSelect = true;
    attributes.push(responseDetail);

    const responseAttributeSpecCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'responseAttributeSpec');
    responseAttributeSpecCore.soGet = SOGetResponseAttributeSpec.construct({
      response: new SOGetResponseResponseAttributeSpecAssociation(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
      app: new SOGetApp(activeUserService.getScope('app').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, responseAttributeSpecCore);
    responseAttributeSpecCore.title = heading ? heading : responseAttributeSpecCore.title;
    responseAttributeSpecCore.listTitle = 'Response attribute spec';

    if (entity.responseAttributeSpec) {
        displayStringFactory.reconfigure(entity.responseAttributeSpec, entityViewSpec.entityCore.type);
    }
    responseAttributeSpecCore.configureForDisplay(entityViewSpec);

    const responseAttributeSpecDetail = new DetailEntry(
      heading ? heading : responseAttributeSpecCore.listTitle,
      entity.responseAttributeSpec ? displayStringFactory.primaryString : null,
      'responseAttributeSpec',
      BaseDetailType.pushedDetail,
      responseAttributeSpecCore,
      EditableType.always,
      putResponseResponseAttributeSpecAssociationFormFieldOptions.responseAttributeSpec
    );
    if (entity.responseAttributeSpec) {
      responseAttributeSpecDetail.cachedListEntry = responseAttributeSpecCore.makeListEntry(entityViewSpec, responseAttributeSpecCore, entity.responseAttributeSpec, 0);
    }
    responseAttributeSpecDetail.singleFieldSelect = true;
    attributes.push(responseAttributeSpecDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
