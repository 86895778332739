import { SOGetEntityRelationshipAssociation } from '../entity-relationship-association/so-get-entity-relationship-association';
import { SOGetRelationshipLink } from '../relationship-link/so-get-relationship-link';
import {ENMultiplicity} from '../../enums';


export class SOPutRelationship {

  constructor(
    public uid: string,
    public entity: SOGetEntityRelationshipAssociation[] = null,
    public name: string = null,
    public multiplicity: ENMultiplicity = null,
    public relationshipLink: SOGetRelationshipLink = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutRelationship(
      'uid' in params ? params.uid : null,
      'entity' in params ? params.entity : null,
      'name' in params ? params.name : null,
      'multiplicity' in params ? params.multiplicity : null,
      'relationshipLink' in params ? params.relationshipLink : null,
    );
  }
}
