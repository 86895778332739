import {SORespCommitCodableAssociation} from '../../core/commit-codable-association/so-resp-commit-codable-association';
import {CommitCodableAssociationCore} from './commit-codable-association.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putCommitCodableAssociationFormFieldOptions} from './put-commit-codable-association-form-fields';
import {SOGetCommitCodableAssociation} from '../../core/commit-codable-association/so-get-commit-codable-association';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {SOGetCodable} from '../../core/codable/so-get-codable';
import {SOGetApp} from '../../core/app/so-get-app';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CommitCodableAssociationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespCommitCodableAssociation = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []


    const commitCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'commit');
    commitCore.soGet = SOGetCommit.construct({
      app: new SOGetApp(activeUserService.getScope('app').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, commitCore)
    commitCore.title = heading ? heading : commitCore.title
    commitCore.listTitle = 'Commit'

    if (entity.commit) {
        displayStringFactory.reconfigure(entity.commit, entityViewSpec.entityCore.type)
    }
    commitCore.configureForDisplay(entityViewSpec)

    const commitDetail = new DetailEntry(
      heading ? heading : commitCore.listTitle,
      entity.commit ? displayStringFactory.primaryString : null,
      'commit',
      BaseDetailType.pushedDetail,
      commitCore,
      EditableType.always,
      putCommitCodableAssociationFormFieldOptions.commit
    )
    if (entity.commit) {
      commitDetail.cachedListEntry = commitCore.makeListEntry(entityViewSpec, commitCore, entity.commit, 0)
    }
    commitDetail.singleFieldSelect = true
    attributes.push(commitDetail);

    const codableCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'codable');
    codableCore.soGet = SOGetCodable.construct({
      app: new SOGetApp(activeUserService.getScope('app').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, codableCore)
    codableCore.title = heading ? heading : codableCore.title
    codableCore.listTitle = 'Codable'

    if (entity.codable) {
        displayStringFactory.reconfigure(entity.codable, entityViewSpec.entityCore.type)
    }
    codableCore.configureForDisplay(entityViewSpec)

    const codableDetail = new DetailEntry(
      heading ? heading : codableCore.listTitle,
      entity.codable ? displayStringFactory.primaryString : null,
      'codable',
      BaseDetailType.pushedDetail,
      codableCore,
      EditableType.always,
      putCommitCodableAssociationFormFieldOptions.codable
    )
    if (entity.codable) {
      codableDetail.cachedListEntry = codableCore.makeListEntry(entityViewSpec, codableCore, entity.codable, 0)
    }
    codableDetail.singleFieldSelect = true
    attributes.push(codableDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
