import { Type } from 'class-transformer';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespApp implements ObjectiveProtocol {

  entityType: string = 'app';
  uid: string;
  name: string;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
}