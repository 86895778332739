import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostCommitEntityAssociation } from './so-post-commit-entity-association';
import { SOGetCommitEntityAssociation } from './so-get-commit-entity-association';
import { SOPutCommitEntityAssociation } from './so-put-commit-entity-association';
import { SODeleteCommitEntityAssociation } from './so-delete-commit-entity-association';
import {SORespCommitEntityAssociation} from './so-resp-commit-entity-association';
import {SOPluralCommitEntityAssociation} from './so-plural-commit-entity-association';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOCommitEntityAssociationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'commit_entity_association',
      entityCore
    );
  }

  /** GET CommitEntityAssociation by id. Will 404 if id not found */
  get(request: SOGetCommitEntityAssociation): Observable<SOPluralCommitEntityAssociation> {
    return this.httpClient.get<SOPluralCommitEntityAssociation>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralCommitEntityAssociation, resp) }),
      tap(_ => this.log(`fetched CommitEntityAssociation`)),
      catchError(this.handleError<SOPluralCommitEntityAssociation>(`get CommitEntityAssociation`))
    );
  }

  /** POST: add a new CommitEntityAssociation to the server */
  post(request: SOPostCommitEntityAssociation): Observable<SORespCommitEntityAssociation> {
    return this.httpClient.post<SORespCommitEntityAssociation>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespCommitEntityAssociation, resp) }),
      tap((response: SORespCommitEntityAssociation) => this.log(`added CommitEntityAssociation w/ id=${response.uid}`)),
      catchError(this.handleError<SORespCommitEntityAssociation>('CommitEntityAssociation post'))
    );
  }

  /** PUT: update CommitEntityAssociation  */
  put(request: SOPutCommitEntityAssociation): Observable<SORespCommitEntityAssociation> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespCommitEntityAssociation>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespCommitEntityAssociation, resp) }),
      tap((response: SORespCommitEntityAssociation) => this.log(`edited CommitEntityAssociation w/ uid=${uid}`)),
      catchError(this.handleError<SORespCommitEntityAssociation>('CommitEntityAssociation put'))
    );
  }

  /** DELETE: delete the CommitEntityAssociation from the server */
  delete(request: SODeleteCommitEntityAssociation): Observable<SORespCommitEntityAssociation> {
    return this.httpClient.delete<SORespCommitEntityAssociation>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted CommitEntityAssociation uid=${request.uid}`)),
      catchError(this.handleError<SORespCommitEntityAssociation>('delete CommitEntityAssociation'))
    );
  }
}
