import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOResponseResponseAttributeSpecAssociationService} from '../../core/response-response-attribute-spec-association/eo-response-response-attribute-spec-association';
import {ResponseResponseAttributeSpecAssociationListEntryFactory} from './response-response-attribute-spec-association-list-entry-factory';
import {ResponseResponseAttributeSpecAssociationDetailFactory} from './response-response-attribute-spec-association-detail-factory';
import {SOGetResponseResponseAttributeSpecAssociation} from '../../core/response-response-attribute-spec-association/so-get-response-response-attribute-spec-association';
import {RAPutResponseResponseAttributeSpecAssociation} from './ra-put-response-response-attribute-spec-association';
import {RAPostResponseResponseAttributeSpecAssociation} from './ra-post-response-response-attribute-spec-association';
import {RADeleteResponseResponseAttributeSpecAssociation} from './ra-delete-response-response-attribute-spec-association';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class ResponseResponseAttributeSpecAssociationCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'responseResponseAttributeSpecAssociation';
  title = 'Response response attribute spec association';
  listEntryFactory = new ResponseResponseAttributeSpecAssociationListEntryFactory();
  detailFactory = new ResponseResponseAttributeSpecAssociationDetailFactory();

  entityService: EOResponseResponseAttributeSpecAssociationService;
  soGetClass = SOGetResponseResponseAttributeSpecAssociation;
  soGet: SOGetResponseResponseAttributeSpecAssociation;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOResponseResponseAttributeSpecAssociationService
    );
    this.adapters = [
      new RAPostResponseResponseAttributeSpecAssociation(this),
      new RAPutResponseResponseAttributeSpecAssociation(this),
      new RADeleteResponseResponseAttributeSpecAssociation(this),
    ];
    this.resetSoGet();
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetResponseResponseAttributeSpecAssociation();
    this.soGet.commit = new SOGetCommit(
      this.activeUserService.getScope('commit').uid
    );
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
