import {SOPutResponse} from '../../core/response/so-put-response';
import {ResponseCore} from './response.core';
import {SOGetResponse} from '../../core/response/so-get-response';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putResponseFormFieldOptions} from './put-response-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {EntityResponseAssociationCore} from '../bv-entity-response-association/entity-response-association.core';
import {SOGetEntityResponseAssociation} from '../../core/entity-response-association/so-get-entity-response-association';

export class RAPutResponse extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putResponseFormFieldOptions);

  RequestClass = SOPutResponse

  constructor(
    public entityCore: ResponseCore
  ) {
    super();
    if (putResponseFormFieldOptions.entity) {
      putResponseFormFieldOptions.entity.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('responseType'),
      this.getFormValueFromIdentifier('isDefault'),
      [this.getFormValueFromIdentifier('entity', SOGetEntityResponseAssociation)],
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}