import {SORespCodable} from '../../core/codable/so-resp-codable';
import {CodableCore} from './codable.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putCodableFormFieldOptions} from './put-codable-form-fields';
import {SOGetCodable} from '../../core/codable/so-get-codable';
import {SOGetAttributeCodableAssociation} from '../../core/attribute-codable-association/so-get-attribute-codable-association';
import {SOGetCommitCodableAssociation} from '../../core/commit-codable-association/so-get-commit-codable-association';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class CodableDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespCodable = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []

    const nameDetail = new DetailEntry(
        'Name',
        entity.name,
        'name',
        BaseDetailType.text,
        null,
        EditableType.always,
        putCodableFormFieldOptions.name
    )
    attributes.push(nameDetail)


    const attributesCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'attributeCodableAssociation');
    attributesCore.soGet = SOGetAttributeCodableAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, attributesCore)
    attributesCore.title = heading ? heading : attributesCore.title
    attributesCore.listTitle = 'Attributes'

    attributesCore.configureForDisplay(entityViewSpec)

    const attributesDetail = new DetailEntry(
      heading ? heading : attributesCore.listTitle,
      null,
      'attributes',
      BaseDetailType.embeddedList,
      attributesCore,
      EditableType.never
    )
    attributes.push(attributesDetail);

    const commitCodableAssociationsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'commitCodableAssociation');
    commitCodableAssociationsCore.soGet = SOGetCommitCodableAssociation.construct({
      codable: new SOGetCodable(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, commitCodableAssociationsCore)
    commitCodableAssociationsCore.title = heading ? heading : commitCodableAssociationsCore.title
    commitCodableAssociationsCore.listTitle = 'Commit codable associations'

    commitCodableAssociationsCore.configureForDisplay(entityViewSpec)

    const commitCodableAssociationsDetail = new DetailEntry(
      heading ? heading : commitCodableAssociationsCore.listTitle,
      null,
      'commitCodableAssociations',
      BaseDetailType.embeddedList,
      commitCodableAssociationsCore,
      EditableType.never
    )
    attributes.push(commitCodableAssociationsDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
