import { SOGetEntity } from '../entity/so-get-entity';
import { SOGetWidget } from '../widget/so-get-widget';
import { SOGetCommit } from '../commit/so-get-commit';


export class SOPutEntityWidgetAssociation {

  constructor(
    public uid: string,
    public entity: SOGetEntity = null,
    public widget: SOGetWidget = null,
    public commit: SOGetCommit = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutEntityWidgetAssociation(
      'uid' in params ? params.uid : null,
      'entity' in params ? params.entity : null,
      'widget' in params ? params.widget : null,
      'commit' in params ? params.commit : null,
    );
  }
}
