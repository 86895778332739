import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOCommitEntityAssociationService} from '../../core/commit-entity-association/eo-commit-entity-association';
import {CommitEntityAssociationListEntryFactory} from './commit-entity-association-list-entry-factory';
import {CommitEntityAssociationDetailFactory} from './commit-entity-association-detail-factory';
import {SOGetCommitEntityAssociation} from '../../core/commit-entity-association/so-get-commit-entity-association';
import {RAPutCommitEntityAssociation} from './ra-put-commit-entity-association';
import {RAPostCommitEntityAssociation} from './ra-post-commit-entity-association';
import {RADeleteCommitEntityAssociation} from './ra-delete-commit-entity-association';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetOrganization} from '../../core/organization/so-get-organization'


export class CommitEntityAssociationCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'commitEntityAssociation';
  title = 'Commit entity association';
  listEntryFactory = new CommitEntityAssociationListEntryFactory();
  detailFactory = new CommitEntityAssociationDetailFactory();

  entityService: EOCommitEntityAssociationService;
  soGetClass = SOGetCommitEntityAssociation;
  soGet: SOGetCommitEntityAssociation;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOCommitEntityAssociationService
    );
    this.adapters = [
      new RAPostCommitEntityAssociation(this),
      new RAPutCommitEntityAssociation(this),
      new RADeleteCommitEntityAssociation(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetCommitEntityAssociation();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
