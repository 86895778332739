import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOBranchCommitAssociationService} from '../../core/branch-commit-association/eo-branch-commit-association';
import {BranchCommitAssociationListEntryFactory} from './branch-commit-association-list-entry-factory';
import {BranchCommitAssociationDetailFactory} from './branch-commit-association-detail-factory';
import {SOGetBranchCommitAssociation} from '../../core/branch-commit-association/so-get-branch-commit-association';
import {RAPutBranchCommitAssociation} from './ra-put-branch-commit-association';
import {RAPostBranchCommitAssociation} from './ra-post-branch-commit-association';
import {RADeleteBranchCommitAssociation} from './ra-delete-branch-commit-association';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetOrganization} from '../../core/organization/so-get-organization'


export class BranchCommitAssociationCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'branchCommitAssociation';
  title = 'Branch commit association';
  listEntryFactory = new BranchCommitAssociationListEntryFactory();
  detailFactory = new BranchCommitAssociationDetailFactory();

  entityService: EOBranchCommitAssociationService;
  soGetClass = SOGetBranchCommitAssociation;
  soGet: SOGetBranchCommitAssociation;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOBranchCommitAssociationService
    );
    this.adapters = [
      new RAPostBranchCommitAssociation(this),
      new RAPutBranchCommitAssociation(this),
      new RADeleteBranchCommitAssociation(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetBranchCommitAssociation();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
