import { SOGetRequest } from '../request/so-get-request';
import { SOGetRequestRelationshipSpec } from '../request-relationship-spec/so-get-request-relationship-spec';


export class SOPutRequestRequestRelationshipSpecAssociation {

  constructor(
    public uid: string,
    public request: SOGetRequest = null,
    public requestRelationshipSpec: SOGetRequestRelationshipSpec = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutRequestRequestRelationshipSpecAssociation(
      'uid' in params ? params.uid : null,
      'request' in params ? params.request : null,
      'requestRelationshipSpec' in params ? params.requestRelationshipSpec : null,
    );
  }
}
