import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostAttributeWidgetAttributeSpecAssociation } from './so-post-attribute-widget-attribute-spec-association';
import { SOGetAttributeWidgetAttributeSpecAssociation } from './so-get-attribute-widget-attribute-spec-association';
import { SOPutAttributeWidgetAttributeSpecAssociation } from './so-put-attribute-widget-attribute-spec-association';
import { SODeleteAttributeWidgetAttributeSpecAssociation } from './so-delete-attribute-widget-attribute-spec-association';
import {SORespAttributeWidgetAttributeSpecAssociation} from './so-resp-attribute-widget-attribute-spec-association';
import {SOPluralAttributeWidgetAttributeSpecAssociation} from './so-plural-attribute-widget-attribute-spec-association';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOAttributeWidgetAttributeSpecAssociationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'attribute_widget_attribute_spec_association',
      entityCore
    );
  }

  /** GET AttributeWidgetAttributeSpecAssociation by id. Will 404 if id not found */
  get(request: SOGetAttributeWidgetAttributeSpecAssociation): Observable<SOPluralAttributeWidgetAttributeSpecAssociation> {
    return this.httpClient.get<SOPluralAttributeWidgetAttributeSpecAssociation>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralAttributeWidgetAttributeSpecAssociation, resp) }),
      tap(_ => this.log(`fetched AttributeWidgetAttributeSpecAssociation`)),
      catchError(this.handleError<SOPluralAttributeWidgetAttributeSpecAssociation>(`get AttributeWidgetAttributeSpecAssociation`))
    );
  }

  /** POST: add a new AttributeWidgetAttributeSpecAssociation to the server */
  post(request: SOPostAttributeWidgetAttributeSpecAssociation): Observable<SORespAttributeWidgetAttributeSpecAssociation> {
    return this.httpClient.post<SORespAttributeWidgetAttributeSpecAssociation>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAttributeWidgetAttributeSpecAssociation, resp) }),
      tap((response: SORespAttributeWidgetAttributeSpecAssociation) => this.log(`added AttributeWidgetAttributeSpecAssociation w/ id=${response.uid}`)),
      catchError(this.handleError<SORespAttributeWidgetAttributeSpecAssociation>('AttributeWidgetAttributeSpecAssociation post'))
    );
  }

  /** PUT: update AttributeWidgetAttributeSpecAssociation  */
  put(request: SOPutAttributeWidgetAttributeSpecAssociation): Observable<SORespAttributeWidgetAttributeSpecAssociation> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespAttributeWidgetAttributeSpecAssociation>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAttributeWidgetAttributeSpecAssociation, resp) }),
      tap((response: SORespAttributeWidgetAttributeSpecAssociation) => this.log(`edited AttributeWidgetAttributeSpecAssociation w/ uid=${uid}`)),
      catchError(this.handleError<SORespAttributeWidgetAttributeSpecAssociation>('AttributeWidgetAttributeSpecAssociation put'))
    );
  }

  /** DELETE: delete the AttributeWidgetAttributeSpecAssociation from the server */
  delete(request: SODeleteAttributeWidgetAttributeSpecAssociation): Observable<SORespAttributeWidgetAttributeSpecAssociation> {
    return this.httpClient.delete<SORespAttributeWidgetAttributeSpecAssociation>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted AttributeWidgetAttributeSpecAssociation uid=${request.uid}`)),
      catchError(this.handleError<SORespAttributeWidgetAttributeSpecAssociation>('delete AttributeWidgetAttributeSpecAssociation'))
    );
  }
}
