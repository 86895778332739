import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';
import {ENStoreType} from '../../enums';


const nameField = new FormField(
  'name',
  'Name',
  null,
  FormFieldType.text,
  false,
  true,
  ""
)
const storeTypeField = new FormField(
  'storeType',
  'Store type',
  null,
  FormFieldType.enumeration,
  true,
)
storeTypeField.enumeration = ENStoreType
const isDefaultField = new FormField(
  'isDefault',
  'Is default',
  null,
  FormFieldType.boolean,
  false,
)
const entityField = new FormField(
  'entity',
  'Entity',
  'entityStoreAssociation',
  FormFieldType.pushedSelectionList,
  true,
)


export const postStoreFormFieldOptions: {[key: string]: FormField} = {
  name: nameField,
  storeType: storeTypeField,
  isDefault: isDefaultField,
  entity: entityField,
};