import { SOGetEntity } from '../entity/so-get-entity';
import { SOGetStore } from '../store/so-get-store';
import { SOGetCommit } from '../commit/so-get-commit';


export class SOPutEntityStoreAssociation {

  constructor(
    public uid: string,
    public entity: SOGetEntity = null,
    public store: SOGetStore = null,
    public commit: SOGetCommit = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutEntityStoreAssociation(
      'uid' in params ? params.uid : null,
      'entity' in params ? params.entity : null,
      'store' in params ? params.store : null,
      'commit' in params ? params.commit : null,
    );
  }
}
