import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const isRequiredField = new FormField(
  'isRequired',
  'Is required',
  null,
  FormFieldType.boolean,
  false,
)
const requestField = new FormField(
  'request',
  'Request',
  'requestRequestRelationshipSpecAssociation',
  FormFieldType.pushedSelectionList,
  false,
)
const relationshipField = new FormField(
  'relationship',
  'Relationship',
  'relationshipRequestRelationshipSpecAssociation',
  FormFieldType.pushedSelectionList,
  false,
)


export const putRequestRelationshipSpecFormFieldOptions: {[key: string]: FormField} = {
  isRequired: isRequiredField,
  request: requestField,
  relationship: relationshipField,
};