import { SOGetProduct } from '../product/so-get-product';
import {ENInterval} from '../../enums';


export class SOPutPrice {

  constructor(
    public uid: string,
    public name: string = null,
    public sku: string = null,
    public trailDays: number = null,
    public exRef: string = null,
    public amount: number = null,
    public interval: ENInterval = null,
    public active: boolean = null,
    public product: SOGetProduct = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutPrice(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'sku' in params ? params.sku : null,
      'trailDays' in params ? params.trailDays : null,
      'exRef' in params ? params.exRef : null,
      'amount' in params ? params.amount : null,
      'interval' in params ? params.interval : null,
      'active' in params ? params.active : null,
      'product' in params ? params.product : null,
    );
  }
}
