import { SOGetApp } from '../app/so-get-app';
import { SOGetOrganization } from '../organization/so-get-organization';
import { SOGetCommitRelationshipLinkAssociation } from '../commit-relationship-link-association/so-get-commit-relationship-link-association';


export class SOPutRelationshipLink {

  constructor(
    public uid: string,
    public app: SOGetApp = null,
    public organization: SOGetOrganization = null,
    public commitRelationshipLinkAssociations: SOGetCommitRelationshipLinkAssociation[] = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutRelationshipLink(
      'uid' in params ? params.uid : null,
      'app' in params ? params.app : null,
      'organization' in params ? params.organization : null,
      'commitRelationshipLinkAssociations' in params ? params.commitRelationshipLinkAssociations : null,
    );
  }
}
