import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostResponseRelationshipSpec } from './so-post-response-relationship-spec';
import { SOGetResponseRelationshipSpec } from './so-get-response-relationship-spec';
import { SOPutResponseRelationshipSpec } from './so-put-response-relationship-spec';
import { SODeleteResponseRelationshipSpec } from './so-delete-response-relationship-spec';
import {SORespResponseRelationshipSpec} from './so-resp-response-relationship-spec';
import {SOPluralResponseRelationshipSpec} from './so-plural-response-relationship-spec';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOResponseRelationshipSpecService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'response_relationship_spec',
      entityCore
    );
  }

  /** GET ResponseRelationshipSpec by id. Will 404 if id not found */
  get(request: SOGetResponseRelationshipSpec): Observable<SOPluralResponseRelationshipSpec> {
    return this.httpClient.get<SOPluralResponseRelationshipSpec>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralResponseRelationshipSpec, resp) }),
      tap(_ => this.log(`fetched ResponseRelationshipSpec`)),
      catchError(this.handleError<SOPluralResponseRelationshipSpec>(`get ResponseRelationshipSpec`))
    );
  }

  /** POST: add a new ResponseRelationshipSpec to the server */
  post(request: SOPostResponseRelationshipSpec): Observable<SORespResponseRelationshipSpec> {
    return this.httpClient.post<SORespResponseRelationshipSpec>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespResponseRelationshipSpec, resp) }),
      tap((response: SORespResponseRelationshipSpec) => this.log(`added ResponseRelationshipSpec w/ id=${response.uid}`)),
      catchError(this.handleError<SORespResponseRelationshipSpec>('ResponseRelationshipSpec post'))
    );
  }

  /** PUT: update ResponseRelationshipSpec  */
  put(request: SOPutResponseRelationshipSpec): Observable<SORespResponseRelationshipSpec> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespResponseRelationshipSpec>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespResponseRelationshipSpec, resp) }),
      tap((response: SORespResponseRelationshipSpec) => this.log(`edited ResponseRelationshipSpec w/ uid=${uid}`)),
      catchError(this.handleError<SORespResponseRelationshipSpec>('ResponseRelationshipSpec put'))
    );
  }

  /** DELETE: delete the ResponseRelationshipSpec from the server */
  delete(request: SODeleteResponseRelationshipSpec): Observable<SORespResponseRelationshipSpec> {
    return this.httpClient.delete<SORespResponseRelationshipSpec>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted ResponseRelationshipSpec uid=${request.uid}`)),
      catchError(this.handleError<SORespResponseRelationshipSpec>('delete ResponseRelationshipSpec'))
    );
  }
}
