import {SOPutStoreStoreRelationshipSpecAssociation} from '../../core/store-store-relationship-spec-association/so-put-store-store-relationship-spec-association';
import {StoreStoreRelationshipSpecAssociationCore} from './store-store-relationship-spec-association.core';
import {SOGetStoreStoreRelationshipSpecAssociation} from '../../core/store-store-relationship-spec-association/so-get-store-store-relationship-spec-association';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putStoreStoreRelationshipSpecAssociationFormFieldOptions} from './put-store-store-relationship-spec-association-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {StoreCore} from '../bv-store/store.core';
import {SOGetStore} from '../../core/store/so-get-store';
import {StoreRelationshipSpecCore} from '../bv-store-relationship-spec/store-relationship-spec.core';
import {SOGetStoreRelationshipSpec} from '../../core/store-relationship-spec/so-get-store-relationship-spec';

export class RAPutStoreStoreRelationshipSpecAssociation extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putStoreStoreRelationshipSpecAssociationFormFieldOptions);

  RequestClass = SOPutStoreStoreRelationshipSpecAssociation

  constructor(
    public entityCore: StoreStoreRelationshipSpecAssociationCore
  ) {
    super();
    if (putStoreStoreRelationshipSpecAssociationFormFieldOptions.store) {
      putStoreStoreRelationshipSpecAssociationFormFieldOptions.store.makeCore = true;
    }
    if (putStoreStoreRelationshipSpecAssociationFormFieldOptions.storeRelationshipSpec) {
      putStoreStoreRelationshipSpecAssociationFormFieldOptions.storeRelationshipSpec.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier(
        'store',
        SOGetStore,
      ),
      this.getFormValueFromIdentifier(
        'storeRelationshipSpec',
        SOGetStoreRelationshipSpec,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}