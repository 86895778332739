import {SOPostWidgetRelationshipSpec} from '../../core/widget-relationship-spec/so-post-widget-relationship-spec';
import {WidgetRelationshipSpecCore} from './widget-relationship-spec.core';
import {SOGetWidgetRelationshipSpec} from '../../core/widget-relationship-spec/so-get-widget-relationship-spec';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postWidgetRelationshipSpecFormFieldOptions} from './post-widget-relationship-spec-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {WidgetWidgetRelationshipSpecAssociationCore} from '../bv-widget-widget-relationship-spec-association/widget-widget-relationship-spec-association.core';
import {SOGetWidgetWidgetRelationshipSpecAssociation} from '../../core/widget-widget-relationship-spec-association/so-get-widget-widget-relationship-spec-association';
import {RelationshipWidgetRelationshipSpecAssociationCore} from '../bv-relationship-widget-relationship-spec-association/relationship-widget-relationship-spec-association.core';
import {SOGetRelationshipWidgetRelationshipSpecAssociation} from '../../core/relationship-widget-relationship-spec-association/so-get-relationship-widget-relationship-spec-association';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {AppCore} from '../bv-app/app.core';
import {SOGetApp} from '../../core/app/so-get-app';

export class RAPostWidgetRelationshipSpec extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postWidgetRelationshipSpecFormFieldOptions);

  RequestClass = SOPostWidgetRelationshipSpec

  constructor(
    public entityCore: WidgetRelationshipSpecCore
  ) {
    super();
    if (postWidgetRelationshipSpecFormFieldOptions.widget) {
      postWidgetRelationshipSpecFormFieldOptions.widget.makeCore = true;
    }
    if (postWidgetRelationshipSpecFormFieldOptions.relationship) {
      postWidgetRelationshipSpecFormFieldOptions.relationship.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('isRequired'),
      [this.getFormValueFromIdentifier('widget', SOGetWidgetWidgetRelationshipSpecAssociation)],
      [this.getFormValueFromIdentifier('relationship', SOGetRelationshipWidgetRelationshipSpecAssociation)],
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier(
        'app',
        SOGetApp,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}