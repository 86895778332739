import { Type } from 'class-transformer';
import { SORespResponse } from '../response/so-resp-response';
import { SORespResponseRelationshipSpec } from '../response-relationship-spec/so-resp-response-relationship-spec';
import { SORespCommit } from '../commit/so-resp-commit';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespResponseResponseRelationshipSpecAssociation implements ObjectiveProtocol {

  entityType: string = 'responseResponseRelationshipSpecAssociation';
  uid: string;
  @Type(() => SORespResponse)
  response: SORespResponse;
  @Type(() => SORespResponseRelationshipSpec)
  responseRelationshipSpec: SORespResponseRelationshipSpec;
  @Type(() => SORespCommit)
  commit: SORespCommit;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
}