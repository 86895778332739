import {SOPutRequestRequestRelationshipSpecAssociation} from '../../core/request-request-relationship-spec-association/so-put-request-request-relationship-spec-association';
import {RequestRequestRelationshipSpecAssociationCore} from './request-request-relationship-spec-association.core';
import {SOGetRequestRequestRelationshipSpecAssociation} from '../../core/request-request-relationship-spec-association/so-get-request-request-relationship-spec-association';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {putRequestRequestRelationshipSpecAssociationFormFieldOptions} from './put-request-request-relationship-spec-association-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePut} from 'ngx-lionheart-material';
import {RequestCore} from '../bv-request/request.core';
import {SOGetRequest} from '../../core/request/so-get-request';
import {RequestRelationshipSpecCore} from '../bv-request-relationship-spec/request-relationship-spec.core';
import {SOGetRequestRelationshipSpec} from '../../core/request-relationship-spec/so-get-request-relationship-spec';

export class RAPutRequestRequestRelationshipSpecAssociation extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putRequestRequestRelationshipSpecAssociationFormFieldOptions);

  RequestClass = SOPutRequestRequestRelationshipSpecAssociation

  constructor(
    public entityCore: RequestRequestRelationshipSpecAssociationCore
  ) {
    super();
    if (putRequestRequestRelationshipSpecAssociationFormFieldOptions.request) {
      putRequestRequestRelationshipSpecAssociationFormFieldOptions.request.makeCore = true;
    }
    if (putRequestRequestRelationshipSpecAssociationFormFieldOptions.requestRelationshipSpec) {
      putRequestRequestRelationshipSpecAssociationFormFieldOptions.requestRelationshipSpec.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier(
        'request',
        SOGetRequest,
      ),
      this.getFormValueFromIdentifier(
        'requestRelationshipSpec',
        SOGetRequestRelationshipSpec,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}