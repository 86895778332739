import {ActiveUserService} from 'ngx-lionheart-material';
import {HttpClient} from 'ngx-lionheart-material';
import {EOAttributeWidgetAttributeSpecAssociationService} from '../../core/attribute-widget-attribute-spec-association/eo-attribute-widget-attribute-spec-association';
import {AttributeWidgetAttributeSpecAssociationListEntryFactory} from './attribute-widget-attribute-spec-association-list-entry-factory';
import {AttributeWidgetAttributeSpecAssociationDetailFactory} from './attribute-widget-attribute-spec-association-detail-factory';
import {SOGetAttributeWidgetAttributeSpecAssociation} from '../../core/attribute-widget-attribute-spec-association/so-get-attribute-widget-attribute-spec-association';
import {RAPutAttributeWidgetAttributeSpecAssociation} from './ra-put-attribute-widget-attribute-spec-association';
import {RAPostAttributeWidgetAttributeSpecAssociation} from './ra-post-attribute-widget-attribute-spec-association';
import {RADeleteAttributeWidgetAttributeSpecAssociation} from './ra-delete-attribute-widget-attribute-spec-association';
import {AbstractEntityCore} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {SOGetCommit} from '../../core/commit/so-get-commit'
import {SOGetOrganization} from '../../core/organization/so-get-organization'


export class AttributeWidgetAttributeSpecAssociationCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = 'attributeWidgetAttributeSpecAssociation';
  title = 'Attribute widget attribute spec association';
  listEntryFactory = new AttributeWidgetAttributeSpecAssociationListEntryFactory();
  detailFactory = new AttributeWidgetAttributeSpecAssociationDetailFactory();

  entityService: EOAttributeWidgetAttributeSpecAssociationService;
  soGetClass = SOGetAttributeWidgetAttributeSpecAssociation;
  soGet: SOGetAttributeWidgetAttributeSpecAssociation;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOAttributeWidgetAttributeSpecAssociationService
    );
    this.adapters = [
      new RAPostAttributeWidgetAttributeSpecAssociation(this),
      new RAPutAttributeWidgetAttributeSpecAssociation(this),
      new RADeleteAttributeWidgetAttributeSpecAssociation(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetAttributeWidgetAttributeSpecAssociation();
    this.soGet.commit = new SOGetCommit(
      this.activeUserService.getScope('commit').uid
    );
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}
