import {SORespEnumerator} from '../../core/enumerator/so-resp-enumerator';
import {EnumeratorCore} from './enumerator.core';
import {ListEntry} from 'ngx-lionheart-material';
import {
  DetailEntry,
  DetailFactory, DetailFactoryProtocol, EditableType
} from 'ngx-lionheart-material';
import {BaseDetailType} from 'ngx-lionheart-material';
import {EntityAttributesForDisplay} from 'ngx-lionheart-material';
import {getHeading} from 'ngx-lionheart-material';
import {ListSpecType} from 'ngx-lionheart-material';
import {DisplayVectorStringFactory} from 'ngx-lionheart-material';
import {ActiveUserService} from 'ngx-lionheart-material';
import {EntityViewSpecProtocol} from 'ngx-lionheart-material';
import {putEnumeratorFormFieldOptions} from './put-enumerator-form-fields';
import {SOGetEnumerator} from '../../core/enumerator/so-get-enumerator';
import {SOGetEnumeratorEnumeralAssociation} from '../../core/enumerator-enumeral-association/so-get-enumerator-enumeral-association';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {SOGetOrganization} from '../../core/organization/so-get-organization';


export class EnumeratorDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespEnumerator = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(activeUserService.appSpec.displayOptions);
    let heading;

    const attributes = []


    const enumeralsCore = activeUserService.appSpec.makeCore(activeUserService, listEntry.entityCore.httpClient, 'enumeratorEnumeralAssociation');
    enumeralsCore.soGet = SOGetEnumeratorEnumeralAssociation.construct({
      commit: new SOGetCommit(activeUserService.getScope('commit').uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, enumeralsCore)
    enumeralsCore.title = heading ? heading : enumeralsCore.title
    enumeralsCore.listTitle = 'Enumerals'

    enumeralsCore.configureForDisplay(entityViewSpec)

    const enumeralsDetail = new DetailEntry(
      heading ? heading : enumeralsCore.listTitle,
      null,
      'enumerals',
      BaseDetailType.embeddedList,
      enumeralsCore,
      EditableType.never
    )
    attributes.push(enumeralsDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}
