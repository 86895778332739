import { SOGetRelationship } from '../relationship/so-get-relationship';
import { SOGetResponseRelationshipSpec } from '../response-relationship-spec/so-get-response-relationship-spec';
import { SOGetCommit } from '../commit/so-get-commit';


export class SOPutRelationshipResponseRelationshipSpecAssociation {

  constructor(
    public uid: string,
    public relationship: SOGetRelationship = null,
    public response: SOGetResponseRelationshipSpec = null,
    public commit: SOGetCommit = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutRelationshipResponseRelationshipSpecAssociation(
      'uid' in params ? params.uid : null,
      'relationship' in params ? params.relationship : null,
      'response' in params ? params.response : null,
      'commit' in params ? params.commit : null,
    );
  }
}
