import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const passwordField = new FormField(
  'password',
  'Password',
  null,
  FormFieldType.password,
  false,
)
passwordField.inputType = 'password'
const givenNameField = new FormField(
  'givenName',
  'Given name',
  null,
  FormFieldType.text,
  false,
)
const surnameField = new FormField(
  'surname',
  'Surname',
  null,
  FormFieldType.text,
  false,
)
const emailField = new FormField(
  'email',
  'Email',
  null,
  FormFieldType.text,
  false,
)


export const putUserFormFieldOptions: {[key: string]: FormField} = {
  password: passwordField,
  givenName: givenNameField,
  surname: surnameField,
  email: emailField,
};