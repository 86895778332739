import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';


import { SOPostAttributeRequestAttributeSpecAssociation } from './so-post-attribute-request-attribute-spec-association';
import { SOGetAttributeRequestAttributeSpecAssociation } from './so-get-attribute-request-attribute-spec-association';
import { SOPutAttributeRequestAttributeSpecAssociation } from './so-put-attribute-request-attribute-spec-association';
import { SODeleteAttributeRequestAttributeSpecAssociation } from './so-delete-attribute-request-attribute-spec-association';
import {SORespAttributeRequestAttributeSpecAssociation} from './so-resp-attribute-request-attribute-spec-association';
import {SOPluralAttributeRequestAttributeSpecAssociation} from './so-plural-attribute-request-attribute-spec-association';

import {MMEntityService} from 'ngx-lionheart-material';
import {EntityCoreProtocol} from 'ngx-lionheart-material';
import {MMEntityServiceBase} from 'ngx-lionheart-material';
import {HttpHeaders} from "@angular/common/http";


export class EOAttributeRequestAttributeSpecAssociationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'attribute_request_attribute_spec_association',
      entityCore
    );
  }

  /** GET AttributeRequestAttributeSpecAssociation by id. Will 404 if id not found */
  get(request: SOGetAttributeRequestAttributeSpecAssociation): Observable<SOPluralAttributeRequestAttributeSpecAssociation> {
    return this.httpClient.get<SOPluralAttributeRequestAttributeSpecAssociation>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralAttributeRequestAttributeSpecAssociation, resp) }),
      tap(_ => this.log(`fetched AttributeRequestAttributeSpecAssociation`)),
      catchError(this.handleError<SOPluralAttributeRequestAttributeSpecAssociation>(`get AttributeRequestAttributeSpecAssociation`))
    );
  }

  /** POST: add a new AttributeRequestAttributeSpecAssociation to the server */
  post(request: SOPostAttributeRequestAttributeSpecAssociation): Observable<SORespAttributeRequestAttributeSpecAssociation> {
    return this.httpClient.post<SORespAttributeRequestAttributeSpecAssociation>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAttributeRequestAttributeSpecAssociation, resp) }),
      tap((response: SORespAttributeRequestAttributeSpecAssociation) => this.log(`added AttributeRequestAttributeSpecAssociation w/ id=${response.uid}`)),
      catchError(this.handleError<SORespAttributeRequestAttributeSpecAssociation>('AttributeRequestAttributeSpecAssociation post'))
    );
  }

  /** PUT: update AttributeRequestAttributeSpecAssociation  */
  put(request: SOPutAttributeRequestAttributeSpecAssociation): Observable<SORespAttributeRequestAttributeSpecAssociation> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespAttributeRequestAttributeSpecAssociation>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAttributeRequestAttributeSpecAssociation, resp) }),
      tap((response: SORespAttributeRequestAttributeSpecAssociation) => this.log(`edited AttributeRequestAttributeSpecAssociation w/ uid=${uid}`)),
      catchError(this.handleError<SORespAttributeRequestAttributeSpecAssociation>('AttributeRequestAttributeSpecAssociation put'))
    );
  }

  /** DELETE: delete the AttributeRequestAttributeSpecAssociation from the server */
  delete(request: SODeleteAttributeRequestAttributeSpecAssociation): Observable<SORespAttributeRequestAttributeSpecAssociation> {
    return this.httpClient.delete<SORespAttributeRequestAttributeSpecAssociation>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted AttributeRequestAttributeSpecAssociation uid=${request.uid}`)),
      catchError(this.handleError<SORespAttributeRequestAttributeSpecAssociation>('delete AttributeRequestAttributeSpecAssociation'))
    );
  }
}
