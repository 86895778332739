import { Type } from 'class-transformer';
import { SORespRoleGrant } from '../role-grant/so-resp-role-grant';
import {ENTokenStatus} from '../../enums';
import {ObjectiveProtocol} from 'ngx-lionheart-material';


export class SORespToken implements ObjectiveProtocol {

  entityType: string = 'token';
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  status: ENTokenStatus = null;
  @Type(() => SORespRoleGrant)
  roleGrant: SORespRoleGrant;
}