import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';


const widgetField = new FormField(
  'widget',
  'Widget',
  'widget',
  FormFieldType.pushedSelectionList,
  true,
)
const widgetAttributeSpecField = new FormField(
  'widgetAttributeSpec',
  'Widget attribute spec',
  'widgetAttributeSpec',
  FormFieldType.pushedSelectionList,
  true,
)


export const postWidgetWidgetAttributeSpecAssociationFormFieldOptions: {[key: string]: FormField} = {
  widget: widgetField,
  widgetAttributeSpec: widgetAttributeSpecField,
};