import {SOPostWidgetWidgetAttributeSpecAssociation} from '../../core/widget-widget-attribute-spec-association/so-post-widget-widget-attribute-spec-association';
import {WidgetWidgetAttributeSpecAssociationCore} from './widget-widget-attribute-spec-association.core';
import {SOGetWidgetWidgetAttributeSpecAssociation} from '../../core/widget-widget-attribute-spec-association/so-get-widget-widget-attribute-spec-association';
import {FormField, SelectionOption} from 'ngx-lionheart-material';
import {postWidgetWidgetAttributeSpecAssociationFormFieldOptions} from './post-widget-widget-attribute-spec-association-form-fields';
import {AdapterProtocol} from 'ngx-lionheart-material';
import {BasePost} from 'ngx-lionheart-material';
import {WidgetCore} from '../bv-widget/widget.core';
import {SOGetWidget} from '../../core/widget/so-get-widget';
import {WidgetAttributeSpecCore} from '../bv-widget-attribute-spec/widget-attribute-spec.core';
import {SOGetWidgetAttributeSpec} from '../../core/widget-attribute-spec/so-get-widget-attribute-spec';
import {CommitCore} from '../bv-commit/commit.core';
import {SOGetCommit} from '../../core/commit/so-get-commit';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostWidgetWidgetAttributeSpecAssociation extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postWidgetWidgetAttributeSpecAssociationFormFieldOptions);

  RequestClass = SOPostWidgetWidgetAttributeSpecAssociation

  constructor(
    public entityCore: WidgetWidgetAttributeSpecAssociationCore
  ) {
    super();
    if (postWidgetWidgetAttributeSpecAssociationFormFieldOptions.widget) {
      postWidgetWidgetAttributeSpecAssociationFormFieldOptions.widget.makeCore = true;
    }
    if (postWidgetWidgetAttributeSpecAssociationFormFieldOptions.widgetAttributeSpec) {
      postWidgetWidgetAttributeSpecAssociationFormFieldOptions.widgetAttributeSpec.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'widget',
        SOGetWidget,
      ),
      this.getFormValueFromIdentifier(
        'widgetAttributeSpec',
        SOGetWidgetAttributeSpec,
      ),
      this.getFormValueFromIdentifier(
        'commit',
        SOGetCommit,
        true,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}