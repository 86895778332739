import {FormField, FormFieldType, SelectionOption} from 'ngx-lionheart-material';
import {ENResponseType} from '../../enums';


const nameField = new FormField(
  'name',
  'Name',
  null,
  FormFieldType.text,
  false,
  true,
  ""
)
const responseTypeField = new FormField(
  'responseType',
  'Response type',
  null,
  FormFieldType.enumeration,
  true,
)
responseTypeField.enumeration = ENResponseType
const isDefaultField = new FormField(
  'isDefault',
  'Is default',
  null,
  FormFieldType.boolean,
  false,
)
const entityField = new FormField(
  'entity',
  'Entity',
  'entityResponseAssociation',
  FormFieldType.pushedSelectionList,
  true,
)


export const postResponseFormFieldOptions: {[key: string]: FormField} = {
  name: nameField,
  responseType: responseTypeField,
  isDefault: isDefaultField,
  entity: entityField,
};